import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RegisterItemDecorator from './RegisterItemDecorator';
import LabelWithLink from './LabelWithLink';

export default class LabelResource extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    resource: PropTypes.string.isRequired,
  };

  render() {
    const { resource, id, link } = this.props;
    return (
      <RegisterItemDecorator
        resource={resource}
        resourceId={id}
        component={LabelWithLink}
        componentProps={{ link }}
      />
    );
  }
}
