import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table, Loader, Grid } from 'semantic-ui-react';
import {
  BarChart,
  Bar,
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import { connect } from 'react-redux';
import Filter from '../common/filter/Filter';
import { inUuidQueryBuilder } from '../../services/api';
import { fetchReport } from '../../actions/index';
import NoData from './NoData';

import COLORS from '../../constants/colors';

class Links extends Component {
  static propTypes = {
    filter: PropTypes.object.isRequired,
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData(e) {
    if (e) {
      e.preventDefault();
    }
    let where = {};
    if (this.props.filter.template && this.props.filter.template.value) {
      where = inUuidQueryBuilder(
        where,
        this.props.filter.template.value,
        'templateId',
      );
    }
    if (this.props.filter.project && this.props.filter.project.value) {
      where = inUuidQueryBuilder(
        where,
        this.props.filter.project.value,
        'projectId',
      );
    }
    const query = {
      from: this.props.filter.from.value,
      to: this.props.filter.to.value,
      where: where,
    };
    this.props.fetchReport('links', query);
  }

  render() {
    if (!this.props.report || this.props.report.loading) {
      return <Loader active inline="centered" />;
    }

    return (
      <div>
        <p>{this.props.description}</p>
        <Filter
          project={true}
          template={true}
          from={true}
          to={true}
          onFilter={() => {
            this.fetchData();
          }}
        />

        <Grid>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <div>
                <h3>Line chart</h3>
                {this.props.report.data.length === 0 && <NoData />}
                {this.props.report.data.length > 0 && (
                  <ResponsiveContainer minWidth={468} minHeight={300}>
                    <BarChart data={this.props.report.data}>
                      <XAxis dataKey="url" />
                      <YAxis />
                      <CartesianGrid strokeDasharray="3 3" />
                      <Tooltip />
                      <Legend />
                      <Bar
                        dataKey="count"
                        fill={COLORS[0]}
                        stroke={COLORS[0]}
                        strokeWidth={2}
                      />
                    </BarChart>
                  </ResponsiveContainer>
                )}
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <div>
                <h3>Data table</h3>
                {this.props.report.data.length === 0 && <NoData />}
                {this.props.report.data.length > 0 && (
                  <Table celled>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Period</Table.HeaderCell>
                        <Table.HeaderCell>Count</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {this.props.report.data.map((v, i) => (
                        <Table.Row key={i}>
                          <Table.Cell>{v.url}</Table.Cell>
                          <Table.Cell>{'' + v.count}</Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                )}
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

export default connect(
  (state, props) => ({
    filter: state.filter,
    report: state.report['links'],
  }),
  { fetchReport },
)(Links);
