import React, { Component } from 'react';
import BasePassOverview from './BasePassOverview';

export default class PassInWalletOverview extends Component {
  render() {
    return (
      <BasePassOverview
        serviceType="register_in_wallet"
        description="This dashboard shows which registration has been intersection with selected period"
      />
    );
  }
}
