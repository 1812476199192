import React, { Component } from 'react';
import { Table } from 'semantic-ui-react';
import { connect } from 'react-redux';
import {
  DOC_API_URL,
  CORE_API_URL,
  DISTRIBUTION_API_URL,
  ISSUE_URL,
} from '../services/api';

class ApiEndpoints extends Component {
  render() {
    return (
      <>
        <Table celled>
          <Table.Body>
            <Table.Row>
              <Table.Cell>API_URL</Table.Cell>
              <Table.Cell>{`${CORE_API_URL}`}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>DISTRIBUTION_URL</Table.Cell>
              <Table.Cell>{`${DISTRIBUTION_API_URL}`}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>ISSUE_URL</Table.Cell>
              <Table.Cell>{`${ISSUE_URL}`}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        API Documentation:{' '}
        <a href={DOC_API_URL} target="_blank">
          {DOC_API_URL}
        </a>
      </>
    );
  }
}

export default connect(
  (state, props) => ({ config: state.config }),
  {},
)(ApiEndpoints);
