import { store } from '../store/index';

export const CORE_API_URL =
  process.env.REACT_APP_CORE_API_URL || window.APP_CONFIG?.coreApiUrl;
export const TICKET_API_URL =
  process.env.REACT_APP_TICKET_API_URL || window.REACT_APP_TICKET_API_URL;
export const LOGIN_URL =
  process.env.REACT_APP_LOGIN_PAGE_URL || window.APP_CONFIG?.loginPageURL;
export const DISTRIBUTION_API_URL =
  process.env.REACT_APP_DISTRIBUTION_API_URL ||
  window.REACT_APP_DISTRIBUTION_API_URL;
export const DOC_API_URL =
  process.env.REACT_APP_DOC_API_URL || window.APP_CONFIG?.docUrl;
export const ISSUE_URL =
  process.env.REACT_APP_ISSUE_URL || window.APP_CONFIG?.issueUrl;
export const GOOGLE_MAP_API_KEY =
  process.env.REACT_APP_GOOGLE_MAP_API_KEY ||
  window.APP_CONFIG?.googleMapApiKey;

function convertValue(item) {
  if (typeof item === 'object' && item.hasOwnProperty('id')) {
    return item.id;
  } else {
    return item;
  }
}

function convertValueArray(list) {
  return list.map(convertValue);
}

export function eqQueryBuilder(where, value, columnName) {
  if (!where[columnName]) {
    where[columnName] = {};
  }
  where[columnName]['$eq'] = convertValue(value);
  return where;
}

export function inUuidQueryBuilder(where, value, columnName) {
  if (value.length > 0) {
    if (!where[columnName]) {
      where[columnName] = {};
    }
    where[columnName]['$inUuid'] = convertValueArray(value);
  }
  return where;
}

export function inQueryBuilder(where, value, columnName) {
  if (value.length > 0) {
    if (!where[columnName]) {
      where[columnName] = {};
    }
    where[columnName]['$in'] = convertValueArray(value);
  }
  return where;
}

export function likeQueryBuilder(where, value, columnName) {
  if (value.length > 0) {
    if (!where[columnName]) {
      where[columnName] = {};
    }
    where[columnName]['$like'] = `%${value}%`;
  }
  return where;
}
export function gtQueryBuilder(where, value, columnName) {
  if (!where[columnName]) {
    where[columnName] = {};
  }
  where[columnName]['$gt'] = convertValue(value);
  return where;
}

export function ltQueryBuilder(where, value, columnName) {
  if (!where[columnName]) {
    where[columnName] = {};
  }

  where[columnName]['$lt'] = convertValue(value);
  return where;
}

export default function(method, url, payload = {}) {
  let options = {
    credentials: 'include',
    // credentials: 'cors'
    headers: {},
  };

  const state = store.getState();
  if (state.token && state.token.token) {
    options.headers.Authorization = `Bearer ${state.token.token}`;
  }

  if (
    (payload.constructor === Object && Object.keys(payload).length !== 0) ||
    payload instanceof Array
  ) {
    options.body = JSON.stringify(payload);
    options.headers['Content-Type'] = 'application/json';
  }

  // const urlWithTimeStamp =
  //   url + (url.indexOf('?') < 0 ? '?' : '&') + '_=' + new Date().getTime();

  return fetch(url, {
    ...options,
    method,
  }).then(resp => {
    if (resp.status === 401) {
      const redirect =
        window.location.pathname === '/'
          ? ''
          : `?redirect=${window.location.pathname}`;
      window.location = `/login${redirect}`;
    }
    if (resp.status < 200 || resp.status >= 300) {
      return Promise.reject(resp);
    }

    if (resp.headers.get('Content-Type')) {
      if (resp.headers.get('Content-Type').includes('application/json')) {
        return resp.json();
      }
      if (resp.headers.get('Content-Type').includes('plain/text')) {
        return resp.text();
      }
    }
    return resp;
  });
}

export function fetchNoCredentials(method, url, payload = {}) {
  let options = {
    // credentials: 'include'
    // credentials: 'cors'
    headers: {},
  };
  if (payload.constructor === Object && Object.keys(payload).length !== 0) {
    options.body = JSON.stringify(payload);
    options.headers['Content-Type'] = 'application/json';
  }

  // const urlWithTimeStamp =
  //   url + (url.indexOf('?') < 0 ? '?' : '&') + '_=' + new Date().getTime();

  return fetch(url, {
    ...options,
    method,
  }).then(resp => {
    if (resp.status === 401) {
      const redirect =
        window.location.pathname === '/'
          ? ''
          : `?redirect=${window.location.pathname}`;
      window.location = `/login${redirect}`;
    }
    if (resp.status < 200 || resp.status >= 300) {
      return Promise.reject(resp);
    }
    if (resp.headers.get('Content-Type').includes('application/json')) {
      return resp.json();
    }
    if (resp.headers.get('Content-Type').includes('plain/text')) {
      return resp.text();
    }
    return resp;
  });
}
