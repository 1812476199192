const schema = {
  $id: 'http://yourpass.cz/ticketProduct.json',
  type: 'object',
  definitions: {},
  $schema: 'http://json-schema.org/draft-06/schema#',
  properties: {
    id: {
      $id: '/properties/id',
      type: 'string',
      title: 'Id of ticket product',
      description: 'An explanation about the purpose of this instance.',
      default: '',
      minLength: 3,
    },
    name: {
      $id: '/properties/name',
      type: 'string',
      title: 'Name of product',
      description: 'An explanation about the purpose of this instance.',
      default: 'Name of product',
      minLength: 3,
    },
    order: {
      $id: '/properties/order',
      type: 'integer',
      title: 'The Order Schema',
      description: 'An explanation about the purpose of this instance.',
      default: 0,
      examples: [1],
    },
    units: {
      $id: '/properties/units',
      type: 'string',
      title: 'The Units Schema',
      description: 'An explanation about the purpose of this instance.',
      default: 'CZK',
      examples: ['CZK'],
    },
    priceWithoutVat: {
      $id: '/properties/priceWithoutVat',
      type: 'number',
      title: 'The Pricewithoutvat Schema',
      description: 'An explanation about the purpose of this instance.',
      default: 100,
      examples: [100],
    },
    vat: {
      $id: '/properties/vat',
      type: 'number',
      title: 'The Vat Schema',
      description: 'An explanation about the purpose of this instance.',
      default: 0,
      examples: [0],
    },
    period: {
      $id: '/properties/period',
      type: 'object',
      properties: {
        periodType: {
          $id: '/properties/period/properties/periodType',
          type: 'string',
          title: 'The Periodtype Schema',
          description: 'An explanation about the purpose of this instance.',
          enum: ['days', 'interval'],
        },
        daysMaximalValidity: {
          $id: '/properties/period/properties/daysMaximalValidity',
          type: 'integer',
          title: 'The Daysmaximalvalidity Schema',
          description: 'An explanation about the purpose of this instance.',
          default: 0,
          examples: [30],
        },
        intervalFrom: {
          $id: '/properties/period/properties/intervalFrom',
          type: ['string', 'null'],
          title: 'The Intervalfrom Schema',
          description: 'An explanation about the purpose of this instance.',
          default: '',
          examples: ['2017-06-04T00:00:00+02:00'],
        },
        intervalTo: {
          $id: '/properties/period/properties/intervalTo',
          type: ['string', 'null'],
          title: 'The Intervalto Schema',
          description: 'An explanation about the purpose of this instance.',
          default: '',
          examples: ['2017-06-04T23:59:00+02:00'],
        },
      },
      dependencies: {
        daysMaximalValidity: {
          properties: {
            periodType: {
              value: 'days',
            },
          },
        },
        intervalFrom: {
          properties: {
            periodType: {
              value: 'interval',
            },
          },
        },
        intervalTo: {
          properties: {
            periodType: {
              value: 'interval',
            },
          },
        },
      },
      required: ['periodType'],
    },
    numberServiceConfig: {
      $id: '/properties/numberServiceConfig',
      type: 'object',
      properties: {
        tariff: {
          $id: '/properties/numberServiceConfig/properties/tariff',
          type: 'integer',
          title: 'The Tariff Schema',
          description: 'An explanation about the purpose of this instance.',
          default: 0,
          examples: [1],
        },
      },
    },
    numberServiceUrl: {
      $id: '/properties/numberServiceUrl',
      type: 'string',
      title: 'The Numberserviceurl Schema',
      description: 'An explanation about the purpose of this instance.',
      default: '',
      examples: ['simple'],
    },
    maxItems: {
      $id: '/properties/maxItems',
      type: 'integer',
      title: 'The Maxitems Schema',
      description: 'An explanation about the purpose of this instance.',
      default: 10,
    },
    maxEntries: {
      $id: '/properties/maxEntries',
      type: 'integer',
      title: 'The Maxentries Schema',
      description: 'An explanation about the purpose of this instance.',
      default: 1,
    },
    active: {
      $id: '/properties/active',
      type: 'boolean',
      title: 'The Active Schema',
      description: 'An explanation about the purpose of this instance.',
      default: false,
    },
    eshopId: {
      $id: '/properties/eshopId',
      type: 'string',
      title: 'The Eshopid Schema',
      description: 'An explanation about the purpose of this instance.',
      default: '',
    },
    templateIds: {
      $id: '/properties/templateIds',
      type: 'array',
      uniqueItems: true,
      items: {
        $id: '/properties/templateIds/items',
        type: 'string',
        title: 'The 0 Schema',
        description: 'An explanation about the purpose of this instance.',
        default: '',
      },
      minItems: 1,
    },
    title: {
      $id: '/properties/title',
      type: 'object',
      properties: {},
    },
    warningEnabled: {
      $id: '/properties/warningEnabled',
      type: 'boolean',
      title: 'The Warningenabled Schema',
      description: 'An explanation about the purpose of this instance.',
    },
    warningTitle: {
      $id: '/properties/warningTitle',
      type: ['object', 'null'],
      properties: {},
    },
  },
  dependencies: {
    warningTitle: {
      properties: {
        warningEnabled: {
          value: true,
        },
      },
    },
  },
};

const titleLocaleSchema = (locale, name) => {
  return {
    $id: `/properties/${name}/properties/${locale}`,
    type: 'string',
    title: `Title in ${locale}`,
    description: 'An explanation about the purpose of this instance.',
    default: '',
    minLength: 3,
  };
};

const titleSchema = locales => {
  const properties = {};
  locales.map(l => {
    properties[l] = titleLocaleSchema(l, 'title');
    return l;
  });
  return properties;
};

const warningSchema = locales => {
  const properties = {};
  locales.map(l => {
    properties[l] = titleLocaleSchema(l, 'warningTitle');
    return l;
  });
  return properties;
};

export default function(locales) {
  schema.properties.title.properties = titleSchema(locales);
  schema.properties.title.required = locales;
  schema.properties.warningTitle.properties = warningSchema(locales);
  schema.properties.warningTitle.required = locales;

  return schema;
}
