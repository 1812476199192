export const FETCH_REPORT_LOADING = 'FETCH_REPORT_LOADING';
export const FETCH_REPORT_SUCCESS = 'FETCH_REPORT_SUCCESS';
export const FETCH_REPORT_FAILURE = 'FETCH_REPORT_FAILURE';
export const FILTER_SET_VALUE = 'FILTER_SET_VALUE';
export const FILTER_SET_DATA = 'FILTER_SET_DATA';

export function fetchReport(serviceType, query) {
  return {
    type: FETCH_REPORT_LOADING,
    serviceType,
    query,
  };
}

export function filterSet(data) {
  return {
    type: FILTER_SET_VALUE,
    data,
  };
}

export function filterSetData(data) {
  return {
    type: FILTER_SET_DATA,
    data,
  };
}
