import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import pdf417 from './barcodeImage_pdf417.svg';
import svg128 from './barcodeImage_128.svg';
import qr from './barcodeImage_qr.svg';
import aztec from './barcodeImage_aztec.svg';
export default class BarcodeImage extends Component {
  static propTypes = {
    altText: PropTypes.string,
    barcodeType: PropTypes.string,
    color: PropTypes.string,
  };

  render() {
    const { barcodeType, color, altText } = this.props;

    let Preview = null;

    switch (barcodeType) {
      case 'PKBarcodeFormatQR':
        Preview = (
          <img
            key={'barcode-icon'}
            src={qr}
            alt="presentation"
            style={{ width: 130, height: 'auto' }}
          />
        );
        break;

      case 'PKBarcodeFormatAztec':
        Preview = (
          <img
            key={'barcode-icon'}
            src={aztec}
            alt="presentation"
            style={{ width: 130, height: 'auto' }}
          />
        );
        break;

      case 'PKBarcodeFormatPDF417':
        Preview = (
          <img
            key={'barcode-icon'}
            src={pdf417}
            alt="presentation"
            style={{ width: 230, minHeight: 45 }}
          />
        );
        break;
      case 'PKBarcodeFormatCode128':
        Preview = (
          <img
            key={'barcode-icon'}
            src={svg128}
            alt="presentation"
            style={{ width: 230, minHeight: 45 }}
          />
        );
        break;
      default:
        Preview = (
          <Icon
            key={'barcode-icon'}
            size="massive"
            name="barcode"
            className="designerHoverItem"
            style={{ color: color }}
          />
        );
        break;
    }

    return [
      Preview,
      <div key={'alt-text'} style={{ color: 'black', fontSize: 12 }}>
        {altText}
      </div>,
    ];
  }
}
