import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Button, Modal, Icon } from 'semantic-ui-react';
import FormTextAreaJson from '../../common/FormTextAreaJSON';
import FormDropdownProject from '../../common/FormDropdownProject';
import FormDropdownCertificate from '../../common/FormDropdownCertificate';
import Edit from '../../common/Edit';
import FormDropdownLanguages from '../../common/FormDropdownLanguages';

const jsonTemplate = {
  labelColor: {
    type: 'string',
    value: 'rgb(255,255,255)',
  },
  description: {
    type: 'string',
    value: 'Description',
  },
  backgroundColor: {
    type: 'string',
    value: 'rgb(0,0,0)',
  },
  foregroundColor: {
    type: 'string',
    value: 'rgb(255,255,255)',
  },
  organizationName: {
    type: 'string',
    value: 'Organization name',
  },
  storeCard: {
    type: 'object',
    properties: {},
  },
};

const dynamicDataJsonSchema = {
  type: ['object', 'null'],
  title: 'DynamicData',
  properties: {},
};

class NewModal extends Component {
  static propTypes = {
    itemCreate: PropTypes.func.isRequired,
    itemSet: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
  };

  handleChange = (e, p) => {
    const { itemSet, data } = this.props;
    data[p.name] = p.value;
    itemSet(data);
  };

  handleLanguageChange = (e, p) => {
    const { itemSet, data } = this.props;
    data['languages'] = p.value;
    itemSet(data);
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.itemCreate(this.props.data);
  };

  render() {
    const { data } = this.props;
    return (
      <div>
        <Modal
          trigger={
            <Button floated="right" size="small" primary>
              <Icon name="plus" />New
            </Button>
          }
        >
          <Modal.Header>New Template</Modal.Header>
          <Modal.Content image>
            <Modal.Description>
              <Form onSubmit={this.handleSubmit}>
                <FormDropdownProject
                  name="projectId"
                  label="Project"
                  placeholder="Project"
                  onChange={this.handleChange}
                  defaultValue={data.projectId || []}
                />
                <FormDropdownCertificate
                  name="certificateId"
                  onChange={this.handleChange}
                  defaultValue={data.certificateId || []}
                />
                <Form.Input
                  name="name"
                  label="Name"
                  onChange={this.handleChange}
                  defaultValue={data.name}
                />
                <FormDropdownLanguages
                  name="languages"
                  value={data.languages || []}
                  onChange={this.handleLanguageChange}
                  multiple={true}
                />

                <FormTextAreaJson
                  name="dynamicDataJsonSchema"
                  label="Dynamic data JSON Schema"
                  defaultValue={dynamicDataJsonSchema}
                  onChange={this.handleChange}
                />
                <Button
                  disabled={
                    !data.languages || !data.languages.length || !data.projectId
                  }
                  primary
                  type="submit"
                >
                  Create
                </Button>
              </Form>
            </Modal.Description>
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}

export default class TemplateNewModal extends Component {
  render() {
    return (
      <Edit
        resource={'template'}
        resourceId={'new'}
        defaultValue={{
          dynamicDataJsonSchema,
          jsonTemplate,
        }}
        component={NewModal}
      />
    );
  }
}
