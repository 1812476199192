import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FormInputDateTime from '../FormInputDateTime';
import { connect } from 'react-redux';
import { filterSet } from '../../../actions/index';

class FilterDate extends Component {
  static propTypes = {
    defaultValue: PropTypes.object,
    filterSet: PropTypes.func.isRequired,
    onChange: PropTypes.func,
  };

  handleChange = (e, p) => {
    this.props.filterSet({ name: p.name, value: p.value });
    if (this.props.onChange) {
      this.props.onChange(e, {
        name: p.name,
        value: p.value,
      });
    }
  };

  render() {
    const { filter, defaultValue, name } = this.props;
    let value = filter[name].value || defaultValue;
    return (
      <FormInputDateTime
        name={this.props.name}
        label={this.props.label}
        defaultValue={value}
        onChange={this.handleChange}
      />
    );
  }
}

export default connect(
  (state, props) => ({
    filter: state.filter,
  }),
  { filterSet },
)(FilterDate);
