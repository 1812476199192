import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ResourceDataGrid from '../../common/ResourceDataGrid';
import { Link } from 'react-router';
import { Button } from 'semantic-ui-react';
import Filter from '../../common/filter/Filter';
import JsonModal from '../../common/JsonModal';
import LabelProject from '../../common/LabelProject';
import ImageCreateModal from './ImageCreateModal';
import ResourceListPagedDecorator from '../../common/ResourceListPagedDecorator';

class Bytes extends React.Component {
  formatBytes = () => {
    const i = Math.floor(Math.log(this.props.bytes) / Math.log(1024));
    return (
      (!this.props.bytes && '0 Bytes') ||
      (this.props.bytes / Math.pow(1024, i)).toFixed(2) +
        ' ' +
        ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][i]
    );
  };
  render() {
    return <span>{this.formatBytes()}</span>;
  }
}

let fields = {
  name: {
    title: 'NAME',
  },
  type: {
    title: 'IMAGE_TYPE',
  },
  fileSize: {
    title: 'SIZE',
    orderBy: 'fileSize',
    convertToValue: function(id, item) {
      return <Bytes bytes={item.fileSize} />;
    },
  },
  dimension: {
    title: 'DIMENSION',
    orderBy: 'width',
    convertToValue: function(id, item) {
      return (
        <span>
          {item.width}x{item.height}
        </span>
      );
    },
  },
  projectId: {
    title: 'PROJECT',
    convertToValue: function(id, item) {
      return <LabelProject id={item.projectId} />;
    },
  },
  updatedAt: {
    title: 'UPDATED_AT',
  },
  actions: {
    convertToValue: function(id, item) {
      return <Actions item={item} />;
    },
  },
};

const resource = 'image';

const filterProps = [
  {
    name: 'project',
    type: 'inUuid',
    columnName: 'projectId',
  },
  {
    name: 'imageType',
    type: 'in',
    columnName: 'type',
  },
  {
    name: 'name',
    type: 'like',
    columnName: 'name',
  },
];

class _Actions extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    viewer: PropTypes.object.isRequired,
  };

  render() {
    return (
      <Button.Group>
        <JsonModal
          title={`Image ${this.props.item.id}`}
          data={this.props.item}
        />

        <Button as="a" icon="world" href={this.props.item.url} target="blank" />

        {this.props.viewer.isAdmin && (
          <Button
            as={Link}
            to={'/passes/' + resource + '/' + this.props.item.id}
            icon="edit"
          />
        )}
      </Button.Group>
    );
  }
}

const Actions = connect((state, props) => ({
  viewer: state.viewer.data,
}))(_Actions);

class ImageListDataGrid extends Component {
  render() {
    return (
      <ResourceDataGrid
        {...this.props}
        newButton={
          <ImageCreateModal
            buttonProps={{ floated: 'right', size: 'small', primary: true }}
            onClose={() => {
              this.props.listLoad();
            }}
          />
        }
      />
    );
  }
}

export default class ImageList extends Component {
  render() {
    const filter = <Filter project={true} name={true} imageType={true} />;

    const componentProps = {
      filterProps: filterProps,
      filter: filter,
      fields: fields,
      cols: [
        'projectId',
        'name',
        'type',
        'fileSize',
        'dimension',
        'updatedAt',
        'actions',
      ],
    };
    return (
      <ResourceListPagedDecorator
        resource={resource}
        componentProps={componentProps}
        component={ImageListDataGrid}
      />
    );
  }
}
