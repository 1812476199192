import React, { Component } from 'react';
import { List } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

export default class Help extends Component {
  render() {
    return (
      <div>
        <div style={{ marginTop: 20 }}>
          <FormattedMessage id="SHORT_CODES" default="SHORT_CODES" />
        </div>
        <List bulleted>
          <List.Item>{'%@ - Displays the lockscreen message'}</List.Item>
          <List.Item>{'#{pid}- Pass ID'}</List.Item>
          <List.Item>{'#{tid}- Template ID)'}</List.Item>
        </List>
      </div>
    );
  }
}
