import React, { Component } from 'react';
import ResourceDataGrid from '../../common/ResourceDataGrid';
import { Link } from 'react-router';
import { Button } from 'semantic-ui-react';
import JsonModal from '../../common/JsonModal';
import ResourceListPagedDecorator from '../../common/ResourceListPagedDecorator';
import LabelTicketEshop from '../../common/LabelTicketEshop';
import Filter from '../../common/filter/Filter';
import PropTypes from 'prop-types';

const filterProps = [
  {
    name: 'eshopId',
    type: 'inUuid',
    columnName: 'eshopId',
  },
];

let fields = {
  name: {
    title: 'Name',
    input: 'text',
    readOnly: true,
  },

  eshopId: {
    title: 'Eshop',
    input: 'text',
    readOnly: true,
    convertToValue: function(id) {
      return <LabelTicketEshop id={id} />;
    },
  },
  active: {
    title: 'Active',
    input: 'text',
    readOnly: true,
  },
  order: {
    title: 'Order',
    input: 'text',
    readOnly: true,
  },
  actions: {
    convertToValue: function(id, item) {
      return <Actions item={item} />;
    },
  },
};

const resource = 'ticketProduct';

class Actions extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
  };

  render() {
    return (
      <Button.Group>
        <JsonModal
          title={`Product ${this.props.item.id}`}
          data={this.props.item}
        />
        <Button
          as={Link}
          to={'/ticket/' + resource + '/' + this.props.item.id}
          icon="edit"
        />
      </Button.Group>
    );
  }
}

export default class ProductList extends Component {
  render() {
    const filter = <Filter eshopId={true} />;
    return (
      <ResourceListPagedDecorator
        resource={'ticketProduct'}
        component={ResourceDataGrid}
        componentProps={{
          filter: filter,
          filterProps: filterProps,
          resource: resource,
          fields: fields,
          newButton: (
            <Button
              floated="right"
              size="small"
              primary
              as={Link}
              to={'/ticket/' + resource + '/new'}
              icon="plus"
              title="New"
            />
          ),
          cols: ['name', 'eshopId', 'active', 'order', 'actions'],
        }}
      />
    );
  }
}
