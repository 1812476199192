import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Popup } from 'semantic-ui-react';

export default class Label extends Component {
  static propTypes = {
    label: PropTypes.object,
    tooltip: PropTypes.string,
  };

  render() {
    const { label, tooltip } = this.props;
    return <Popup trigger={label} content={tooltip} />;
  }
}
