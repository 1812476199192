import { applyMiddleware, createStore, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import reducers from '../reducers/index.js';
import saga from '../saga/index.js';

import { browserHistory } from 'react-router';
import { routerMiddleware } from 'react-router-redux';
const routerMiddlewareImpl = routerMiddleware(browserHistory);

const sagaMiddleware = createSagaMiddleware();
let middlewares = [routerMiddlewareImpl, sagaMiddleware];

let composeEnhancers = compose;

if (process.env.NODE_ENV !== 'production') {
  // https://github.com/zalmoxisus/redux-devtools-extension
  composeEnhancers =
    typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
        })
      : compose;
}

const enhancer = composeEnhancers(applyMiddleware.apply(null, middlewares));

export let store = null;
export function newStore(initialState) {
  store = createStore(reducers, initialState, enhancer);
  sagaMiddleware.run(saga);
  return store;
}
