import { call, put } from 'redux-saga/effects';
import client, { CORE_API_URL } from '../services/api';

import {
  TEMPLATE_UPDATE_PASESS_BY_PUSH_SUCCESS,
  TEMPLATE_UPDATE_PASESS_BY_PUSH_FAILURE,
  TEMPLATE_UPDATE_SAVE_TO_GOOLE_PAY_FAILURE,
  TEMPLATE_UPDATE_SAVE_TO_GOOLE_PAY_SUCCESS,
} from '../actions/template';

import { addNotification } from '../actions/notification';

import { AUTO_DISMISS } from '../constants/toast.js';

export function* templateUpdatePassesByPush(action) {
  let resourceQuery = `${CORE_API_URL}/v1/template/${action.templateId}/push`;
  try {
    let data = yield call(client, 'GET', resourceQuery, {});
    yield put({
      type: TEMPLATE_UPDATE_PASESS_BY_PUSH_SUCCESS,
      data,
      serviceType: action.serviceType,
    });
    yield put(
      addNotification({
        title: 'Template push success',
        message: `Passes for template ${
          action.templateId
        } was notified to check updates. (by push notification)`,
        level: 'success',
        autoDismiss: AUTO_DISMISS,
      }),
    );
  } catch (e) {
    yield put({
      type: TEMPLATE_UPDATE_PASESS_BY_PUSH_FAILURE,
      message: e.message,
      serviceType: action.serviceType,
    });
    yield put(
      addNotification({
        title: 'Template push failure',
        message: e.message,
        level: 'error',
        autoDismiss: AUTO_DISMISS,
      }),
    );
  }
}

export function* templateUpdateSaveToGooglePay(action) {
  let resourceQuery = `${CORE_API_URL}/v1/template/${
    action.templateId
  }/savetogooglepay`;
  try {
    let data = yield call(client, 'GET', resourceQuery, {});
    yield put({
      type: TEMPLATE_UPDATE_SAVE_TO_GOOLE_PAY_SUCCESS,
      data,
      serviceType: action.serviceType,
    });
    yield put(
      addNotification({
        title: 'Template update Save to Google Pay success',
        message: `Passes for template ${
          action.templateId
        } updates save to google pay objects`,
        level: 'success',
        autoDismiss: AUTO_DISMISS,
      }),
    );
  } catch (e) {
    yield put({
      type: TEMPLATE_UPDATE_SAVE_TO_GOOLE_PAY_FAILURE,
      message: e.message,
      serviceType: action.serviceType,
    });
    yield put(
      addNotification({
        title: 'Template update Save to Google Pay failure',
        message: e.message,
        level: 'error',
        autoDismiss: AUTO_DISMISS,
      }),
    );
  }
}
