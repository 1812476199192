import React, { Component } from 'react';
import ResourceFormDropdown from './ResourceFormDropdown';
import LabelProject from './LabelProject';

export default class FormDropdownTemplate extends Component {
  render() {
    const filterProps = [
      /* TODOn otTemplateIds
      {
        name: 'templateIds',
        type: 'inUuid',
        columnName: 'id'
      }, */
      {
        name: 'project',
        type: 'inUuid',
        columnName: 'projectId',
      },
    ];
    return (
      <ResourceFormDropdown
        {...this.props}
        resource="template"
        label="Template"
        filterProps={filterProps}
        renderContent={data => {
          return (
            <span>
              {data.name}
              <small>
                <LabelProject disableLink={true} id={data.projectId} />
              </small>
            </span>
          );
        }}
      />
    );
  }
}
