import {
  LIST_COMPLETE_MERGE_PROPS,
  LIST_COMPLETE_LOADING,
  LIST_COMPLETE_FAILURE,
  LIST_COMPLETE_SUCCESS,
  LIST_COMPLETE_DESTROY,
} from '../actions/resourceListComplete';
import { Record, Map } from 'immutable';

const ResourceListCompleteStruct = Record({
  resource: null,
  requestId: null,
  data: null,
  loading: false,
  totalCount: 0,
  order: 'desc',
  orderBy: 'id',
  timestamp: null,
  filter: {},
  filterProps: [],
  message: null,
});

export default function(state = new Map(), action) {
  const { data, message, requestId } = action;
  let newState = state;
  switch (action.type) {
    case LIST_COMPLETE_MERGE_PROPS:
      if (!state.get(requestId)) {
        newState = state.setIn(
          [requestId],
          new ResourceListCompleteStruct(action),
        );
      }
      Object.keys(action.value).map((key, index) => {
        newState = newState.setIn([requestId, key], action.value[key]);
        return newState;
      });
      return newState;

    case LIST_COMPLETE_LOADING:
      if (!state.get(requestId)) {
        newState = state.setIn(
          [requestId],
          new ResourceListCompleteStruct(action),
        );
      }
      return newState.setIn([requestId, 'loading'], true);
    case LIST_COMPLETE_SUCCESS:
      return state
        .setIn([requestId, 'loading'], false)
        .setIn([requestId, 'data'], !!data.data ? data.data : [])
        .setIn(
          [requestId, 'totalCount'],
          !!data.totalCount ? data.totalCount : 0,
        );
    case LIST_COMPLETE_FAILURE:
      return state
        .setIn([requestId, 'loading'], false)
        .setIn([requestId, 'data'], null)
        .setIn([requestId, 'message'], message);

    case LIST_COMPLETE_DESTROY:
      return state.delete(requestId);
    default:
      return state;
  }
}
