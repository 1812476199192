import React from 'react';
import {
  Modal,
  Form,
  Button,
  Input,
  Image,
  Icon,
  List,
} from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

export default class AppStoreAddModal extends React.Component {
  static propTypes = {
    onAdd: PropTypes.func.isRequired,
  };

  componentWillMount() {
    this.setState({
      isFetching: false,
      searchQuery: '',
      result: null,
      modalOpen: false,
    });
  }

  handleOpen = () => this.setState({ modalOpen: true });

  handleClose = () => this.setState({ modalOpen: false });

  add = id => {
    this.props.onAdd(id);
    this.handleClose();
    this.setState({
      searchQuery: '',
      result: null,
    });
  };

  handleSearchTextChange = (e, { value }) => {
    this.setState({ searchQuery: value });
  };

  search = (e, value) => {
    this.setState({ isFetching: true });
    fetch(
      `https://itunes.apple.com/search?entity=software&limit=10&media=software&term=${
        this.state.searchQuery
      }`,
    ).then(result => {
      result.json().then(data => {
        this.setState({ isFetching: false, result: data });
      });
    });
  };

  render() {
    const { isFetching, searchQuery } = this.state;

    return (
      <Modal
        trigger={
          <Button
            primary
            onClick={this.handleOpen}
            size={'small'}
            style={{
              marginLeft: 110,
              marginTop: 10,
            }}
          >
            <FormattedMessage id="ADD_APPLICATION" default="ADD_APPLICATION" />
          </Button>
        }
        closeIcon={true}
        open={this.state.modalOpen}
        onClose={this.handleClose}
      >
        <Modal.Header>Find and Select a App</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Form
              onSubmit={e => {
                e.preventDefault();
                e.stopPropagation();
                this.search();
                return false;
              }}
            >
              <Input
                value={searchQuery}
                placeholder="write app name"
                onChange={this.handleSearchTextChange}
                disabled={isFetching}
                loading={isFetching}
                fluid
                icon={
                  <Icon
                    name="search"
                    inverted
                    circular
                    link
                    onClick={() => {
                      this.search();
                    }}
                  />
                }
              />
            </Form>
            <List divided selection animated size={`small`}>
              {this.state.result &&
                this.state.result.results &&
                this.state.result.results.map((app, index) => {
                  return (
                    <List.Item key={index}>
                      <List.Content
                        floated="right"
                        onClick={() => {
                          this.add(app.trackId);
                        }}
                      >
                        <Button size={`small`} primary>
                          Add
                        </Button>
                      </List.Content>
                      <Image avatar src={app.artworkUrl512} />
                      <List.Content>
                        <List.Header>{app.trackName}</List.Header>
                        <List.Description>{app.sellerName}</List.Description>
                      </List.Content>
                    </List.Item>
                  );
                })}
            </List>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}
