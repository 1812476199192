import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { filterSet, filterSetData } from '../../../actions/index';
import FormDropdownTicketEshop from '../FormDropdownTicketEshop';

class FilterEshop extends Component {
  static propTypes = {
    filterSet: PropTypes.func.isRequired,
    onChange: PropTypes.func,
  };

  handleChange = (e, p) => {
    this.props.filterSet({ name: 'eshopId', value: p.value });
    if (this.props.onChange) {
      this.props.onChange(e, p);
    }
  };

  render() {
    return (
      <FormDropdownTicketEshop
        multiple={true}
        name="eshopId"
        onChange={this.handleChange}
      />
    );
  }
}

export default connect((state, props) => ({}), { filterSet, filterSetData })(
  FilterEshop,
);
