import React, { Component } from 'react';
import BasePassRequestByTagsOverview from './BasePassRequestByTagsOverview';

export default class PassPreviewRequestByTagsOverview extends Component {
  render() {
    return (
      <BasePassRequestByTagsOverview
        serviceType="preview"
        description="Shows statistic about pass previews by tags."
      />
    );
  }
}
