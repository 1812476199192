import React, { Component } from 'react';
import Form from './Form';
import Edit from '../../common/Edit';

export default class ConfigNew extends Component {
  render() {
    const defaultValue = {
      active: true,
      orderPrefix: '90',
      locales: ['cs'],
      currency: 'CZK',
      currencyCode: 203,
      maxItems: 10,
      maxPriceWithVat: 1000,
      email: {
        template: {
          cs: null,
        },
      },
      eshopVersion: 'latest',
      headOverrides: '',
      bodyOverrides: '',
      messages: {},
      styles: {
        backgroundColor: '#f44336',
        backgroundImg: null,
        brandPrimary: 'rgb(183,14,11)',
        brandSecondary: 'rgb(255,241,0)',
        contentBtnColor: '#ffffff',
        contentColor: '#000000',
        contentErrorColor: 'red',
        contentWarningColor: 'grey',
        fontFamily: '"PT Sans", sans-serif',
        footerColor: '#ffffff',
        footerHeight: '3em',
        footerImg: null,
        headerColor: '#ffffff',
        headerHeight: '3em',
        headerImg: null,
      },
      name: 'Nový eshop',
    };

    return (
      <Edit
        resource={'ticketEshop'}
        resourceId={'new'}
        defaultValue={defaultValue}
        component={Form}
      />
    );
  }
}
