import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Button,
  Table,
  Modal,
  Confirm,
} from 'semantic-ui-react/dist/commonjs';
import AccessRights from '../../../constants/accessRights';
import { FormattedMessage } from 'react-intl';
import LabelProject from '../../common/LabelProject';
import LabelUser from '../../common/LabelUser';

export default class ProjectUserRightsEdit extends Component {
  static propTypes = {
    accessRights: PropTypes.number,
    projectId: PropTypes.string,
    userId: PropTypes.string,
  };

  open = () => this.setState({ open: true });
  close = () => this.setState({ open: false });

  componentWillMount() {
    const { accessRights } = this.props;
    this.setState({
      openModal: false,
      accessRights: accessRights,
      loading: false,
      open: false,
    });
  }

  onChangeAccessForProject(value, checked) {
    let { accessRights } = this.state;
    const operator = checked ? -1 : 1;

    if (accessRights === undefined) {
      accessRights = 0;
    }

    const shifted = accessRights + operator * value;
    this.setState({ accessRights: parseInt(shifted, 10) });
  }

  onChangeAccessNumberProject(value) {
    this.setState({ accessRights: parseInt(value, 10) });
  }

  doSave = (projectId, userId, accessRights) => {
    const { saveRights } = this.props;
    this.setState({ loading: true });
    saveRights(projectId, userId, accessRights).finally(e => {
      this.setState({ loading: false });
    });
  };

  submitData = () => {
    const { projectId, userId } = this.props;
    this.doSave(projectId, userId, this.state.accessRights);
  };

  deleteData = () => {
    const { projectId, userId } = this.props;
    this.doSave(projectId, userId, null);
  };

  openModal = (e) => {
    e.preventDefault();
    this.setState({
      openModal: true,
      preModalOpenAccessRights: this.state.accessRights,
    });
  };

  closeModal = e => {
    e.preventDefault();
    this.setState({
      openModal: false,
    });
  };

  cancelModal = e => {
    e.preventDefault();
    this.setState({
      openModal: false,
      accessRights: this.state.preModalOpenAccessRights,
    });
  };

  render() {
    let { projectId, userId } = this.props;
    const { openModal, accessRights } = this.state;
    return (
      <div>
        <Modal open={openModal}>
          <Modal.Header>
            <LabelProject id={projectId} /> <LabelUser id={userId} />
          </Modal.Header>
          <Table
            basic="very"
            style={{
              paddingLeft: 20,
              paddingRight: 20,
            }}
          >
            <Table.Body>
              {Object.keys(AccessRights).map((key, index) => {
                const value = AccessRights[key];
                const shifted = accessRights & value;
                const checked = shifted === value && accessRights !== undefined;
                return (
                  <Table.Row key={key}>
                    <Table.Cell>
                      <Form.Checkbox
                        key={index}
                        name={key}
                        value={key}
                        onChange={(e, p) =>
                          this.onChangeAccessForProject(value, checked)
                        }
                        control="input"
                        type="checkbox"
                        checked={checked}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <FormattedMessage
                        id={`${key}_RIGHT_NAME`}
                        defaultMessage={`${key}_RIGHT_NAME`}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <FormattedMessage
                        id={`${key}_RIGHT_DESCRIPTION`}
                        defaultMessage={`${key}_RIGHT_DESCRIPTION`}
                      />
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
          <Modal.Actions>
            <Button onClick={this.closeModal}>Ok</Button>
            <Button onClick={e => this.cancelModal(e, this.state.accessRights)}>
              Cancel
            </Button>
          </Modal.Actions>
        </Modal>

        <Form loading={this.state.loading}>
          <Form.Group inline>
            <Form.Button color="red" onClick={this.open} icon="trash" />
            <Confirm
              header="Delete user access from project"
              open={this.state.open}
              onCancel={this.close}
              onConfirm={this.deleteData}
            />
            <Form.Input
              name="accessRightsNumber"
              type="number"
              value={accessRights !== undefined ? accessRights : ''}
              onChange={(e, p) => this.onChangeAccessNumberProject(p.value)}
              action={{
                icon: 'privacy',
                onClick: this.openModal,
              }}
            />
            <Form.Button
              disabled={this.props.accessRights === this.state.accessRights}
              primary
              onClick={this.submitData}
              icon="save"
            />
          </Form.Group>
        </Form>
      </div>
    );
  }
}
