import React, { Component } from 'react';

export default class CouponTop extends Component {
  render() {
    return (
      <div
        style={{
          top: `0px`,
          left: `0px`,
          background: `linear-gradient(225deg, rgb(0, 0, 0) 3px, transparent 0px),  linear-gradient(135deg, rgb(0, 0, 0) 3px, transparent 0px)`,
          display: `block`,
          position: `absolute`,
          width: `100%`,
          height: `4px`,
          backgroundRepeat: `repeat-x`,
          backgroundSize: `8px 4px`,
        }}
      />
    );
  }
}
