import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  uiSetDragAndDropProperty,
  uiSetTemplateForceOpenField,
} from '../../../../../../../actions/ui';
import Errors from '../../../../../../../models/Errors';
import Template from '../../../../../../../models/template';
import { ObjectType } from '../../../../../../../models/property';
import FieldPreview from './preview/FieldPreview';
import FieldForm from './edit/FieldForm';
import { Popup, Modal, Button, Form } from 'semantic-ui-react';
import { FormattedHTMLMessage } from 'react-intl';
import { RESPONSIVE_MENU_BREAKPOINT } from '../../../../../../../containers/Layout';
import FieldTypeDropdown from './edit/FieldTypeDropdown';
import Help from '../../Help';

const DRAG_FIELD_TYPE = 'DRAG_FIELD_TYPE';
const POPUP_VS_MODAL_BREAK_POINT = RESPONSIVE_MENU_BREAKPOINT;

class Field extends Component {
  static propTypes = {
    uiSetDragAndDropProperty: PropTypes.func.isRequired,
    previewLanguage: PropTypes.string.isRequired,
    field: PropTypes.instanceOf(ObjectType).isRequired,
    errors: PropTypes.instanceOf(Errors).isRequired,
    data: PropTypes.instanceOf(Template).isRequired,
    itemSet: PropTypes.func.isRequired,
    fieldIndex: PropTypes.number.isRequired,
    fieldsType: PropTypes.string.isRequired,
    dragAndDrop: PropTypes.object,
  };

  getKey = () => {
    const { field } = this.props;
    const key = field.getIn(['properties', 'key', 'value']);
    return key;
  };

  handleClose = () => {
    const { uiSetTemplateForceOpenField } = this.props;
    uiSetTemplateForceOpenField(null);
  };

  isOpen = () => {
    const { forceOpen } = this.props;
    const isOpen = forceOpen === this.getKey();
    return isOpen;
  };

  handleOpen = () => {
    const { uiSetTemplateForceOpenField } = this.props;
    uiSetTemplateForceOpenField(this.getKey());
  };

  emulateOpen() {
    setTimeout(() => {
      document.getElementById(`${this.getKey()}_field_trigger`).click();
    });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.windowWidth <= POPUP_VS_MODAL_BREAK_POINT &&
      this.props.windowWidth > POPUP_VS_MODAL_BREAK_POINT &&
      this.isOpen()
    ) {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.emulateOpen();
        this.timeout = null;
      }, 300);
    }
  }

  componentDidMount = () => {
    if (this.isOpen()) {
      this.emulateOpen();
    }
  };

  getFieldType() {
    const { field } = this.props;
    if (field.getIn(['properties', 'numberStyle', 'value'])) {
      return 'number';
    }
    if (
      field.getIn(['properties', 'dateStyle', 'value']) ||
      field.getIn(['properties', 'timeStyle', 'value'])
    ) {
      return 'date';
    }
    if (field.getIn(['properties', 'currencyCode', 'value'])) {
      return 'currency';
    }

    return 'text';
  }

  onDrag = event => {
    event.preventDefault();
  };

  onDragOver = event => {
    const { fieldsType, dragAndDrop } = this.props;
    event.preventDefault();
    if (dragAndDrop[DRAG_FIELD_TYPE] !== fieldsType) {
      event.dataTransfer.effectAllowed = 'none';
      event.dataTransfer.dropEffect = 'none';
    }
  };

  onDragEnd = event => {
    const { uiSetDragAndDropProperty } = this.props;
    event.preventDefault();
    uiSetDragAndDropProperty(DRAG_FIELD_TYPE, undefined);
  };

  onDragStart = event => {
    const { fieldsType, fieldIndex, uiSetDragAndDropProperty } = this.props;
    const data = {
      droppedIndex: fieldIndex,
      droppedFieldsType: fieldsType,
    };

    event.dataTransfer.setData('draggedField', JSON.stringify(data));
    uiSetDragAndDropProperty(DRAG_FIELD_TYPE, fieldsType);
  };

  onDrop = event => {
    const { fieldsType, fieldIndex, itemSet, data } = this.props;
    event.preventDefault();
    try {
      itemSet(data.fieldSwitch(fieldsType, fieldIndex, event));
    } catch (e) {
      return;
    }
  };

  handleRemove = () => {
    const { data, itemSet, fieldsType, fieldIndex } = this.props;
    itemSet(data.removeField(fieldsType, fieldIndex));
  };

  render() {
    const {
      data,
      fieldsType,
      fieldIndex,
      errors,
      field,
      previewLanguage,
      itemSet,
      windowWidth,
    } = this.props;

    const path = [
      `jsonTemplate`,
      data.getPassStyle(),
      `properties`,
      fieldsType,
      'items',
      fieldIndex,
      'properties',
    ];

    const fieldType = this.getFieldType();

    if (windowWidth > POPUP_VS_MODAL_BREAK_POINT) {
      return (
        <div
          key={`${this.getKey()}_popup`}
          onDragOver={this.onDragOver}
          onDrag={this.onDrag}
          onDragEnd={this.onDragEnd}
          onDrop={this.onDrop}
          draggable="true"
          onDragStart={this.onDragStart}
          style={{
            display: fieldsType !== 'backFields' ? 'table-cell' : `table-row`,
            width: '100%',
          }}
        >
          <Popup
            style={{
              padding: 0,
            }}
            trigger={
              <div id={`${this.getKey()}_field_trigger`}>
                <FieldPreview
                  previewLanguage={previewLanguage}
                  fieldIndex={fieldIndex}
                  fieldsType={fieldsType}
                  fieldType={fieldType}
                  data={data}
                  errors={errors}
                  field={field}
                  itemSet={itemSet}
                  path={path}
                />
              </div>
            }
            position={'right center'}
            on="click"
            onOpen={this.handleOpen}
            onClose={this.handleClose}
          >
            <div style={{ minWidth: 350, minHeight: 550, margin: 20 }}>
              <Form>
                <Form.Group widths="equal">
                  <Form.Field key={'deleteBtn'}>
                    <Form.Button
                      floated="left"
                      icon="trash"
                      type="button"
                      color="red"
                      onClick={this.handleRemove}
                    />
                  </Form.Field>

                  <Form.Field
                    style={{ textAlign: 'right' }}
                    key={'passTypeDropdown'}
                  >
                    <FieldTypeDropdown
                      itemSet={itemSet}
                      fieldIndex={fieldIndex}
                      data={data}
                      fieldType={fieldType}
                      fieldsType={fieldsType}
                    />
                  </Form.Field>
                </Form.Group>
              </Form>
              <FieldForm
                showDeleteAndType={true}
                path={path}
                previewLanguage={previewLanguage}
                fieldIndex={fieldIndex}
                fieldsType={fieldsType}
                fieldType={fieldType}
                data={data}
                errors={errors}
                field={field}
                itemSet={itemSet}
              />
              <Help />
            </div>
          </Popup>
        </div>
      );
    } else {
      return (
        <div
          key={`${this.getKey()}_modal`}
          onDragOver={this.onDragOver}
          onDrag={this.onDrag}
          onDragEnd={this.onDragEnd}
          onDrop={this.onDrop}
          draggable="true"
          onDragStart={this.onDragStart}
          style={{
            display: fieldsType !== 'backFields' ? 'table-cell' : `table-row`,
            width: '100%',
          }}
        >
          <Modal
            trigger={
              <div id={`${this.getKey()}_field_trigger`}>
                <FieldPreview
                  previewLanguage={previewLanguage}
                  fieldIndex={fieldIndex}
                  fieldsType={fieldsType}
                  fieldType={fieldType}
                  data={data}
                  errors={errors}
                  field={field}
                  itemSet={itemSet}
                  path={path}
                />
              </div>
            }
            open={this.isOpen()}
            onOpen={this.handleOpen}
            onClose={this.handleClose}
            size={'fullscreen'}
            closeIcon
          >
            <Modal.Header>
              <Form inline>
                <FieldTypeDropdown
                  itemSet={itemSet}
                  fieldIndex={fieldIndex}
                  data={data}
                  fieldType={fieldType}
                  fieldsType={fieldsType}
                />
              </Form>
            </Modal.Header>
            <Modal.Content scrolling>
              <Modal.Description>
                <FieldForm
                  showDeleteAndType={false}
                  path={path}
                  previewLanguage={previewLanguage}
                  fieldIndex={fieldIndex}
                  fieldsType={fieldsType}
                  fieldType={fieldType}
                  data={data}
                  errors={errors}
                  field={field}
                  itemSet={itemSet}
                />
                <Help />
              </Modal.Description>
            </Modal.Content>

            <Modal.Actions>
              <Button
                floated="left"
                icon="trash"
                type="button"
                color="red"
                onClick={this.handleRemove}
              />
              <Button primary onClick={this.handleClose}>
                <FormattedHTMLMessage id={'CLOSE'} />
              </Button>
            </Modal.Actions>
          </Modal>
        </div>
      );
    }
  }
}

export default connect(
  state => ({
    forceOpen: state.ui.templateForceOpen,
    dragAndDrop: state.ui.dragAndDrop,
    windowWidth: state.ui.windowWidth,
  }),
  { uiSetDragAndDropProperty, uiSetTemplateForceOpenField },
)(Field);
