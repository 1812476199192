export const LIST_PAGED_MERGE_PROPS = 'LIST_PAGED_MERGE_PROPS';
export const LIST_PAGED_LOADING = 'LIST_PAGED_LOADING';
export const LIST_PAGED_FAILURE = 'LIST_PAGED_FAILURE';
export const LIST_PAGED_SUCCESS = 'LIST_PAGED_SUCCESS';
export const LIST_PAGED_DESTROY = 'LIST_PAGED_DESTROY';
export const LIST_PAGED_INIT = 'LIST_PAGED_INIT';
export const LIST_PAGED_EXPORT_LOADING = 'LIST_PAGED_EXPORT_LOADING';
export const LIST_PAGED_EXPORT_SUCCESS = 'LIST_PAGED_EXPORT_SUCCESS';
export const LIST_PAGED_EXPORT_FAILURE = 'LIST_PAGED_EXPORT_FAILURE';
export function listLoad(resource, requestId = 'list', success, failure) {
  return {
    type: LIST_PAGED_LOADING,
    resource,
    requestId,
    success,
    failure,
  };
}
export function listExport(
  resource,
  filterProps = [],
  list,
  format,
  csvKeys,
  csvLabels,
  requestId,
) {
  return {
    type: LIST_PAGED_EXPORT_LOADING,
    resource,
    list,
    format,
    csvKeys,
    csvLabels,
    requestId,
  };
}

export function listMergeProps(resource, requestId = 'list', value) {
  return {
    type: LIST_PAGED_MERGE_PROPS,
    resource,
    value,
    requestId,
  };
}

export function listDestroy(resource, requestId) {
  return {
    type: LIST_PAGED_DESTROY,
    resource,
    requestId,
  };
}
export function listInit(resource, requestId, limit) {
  return {
    type: LIST_PAGED_INIT,
    resource,
    requestId,
    limit,
  };
}
