import React from 'react';
import { useDispatch } from 'react-redux';
import { LOGIN_URL } from '../services/api';
import Logo from './../components/loginlogo.svg';
import {
  Button,
  Form,
  Grid,
  Header,
  Message,
  Segment,
} from 'semantic-ui-react';
import { setToken } from '../actions/token';
import { push } from '../actions/routing';

export default function Login() {
  const [email, setEmail] = React.useState('');
  const handleEmailChange = event => {
    setEmail(event.target.value);
  };

  const [password, setPassword] = React.useState('');
  const handlePasswordChange = event => {
    setPassword(event.target.value);
  };

  const [errorMessage, setErrorMessage] = React.useState('');

  const dispatch = useDispatch();

  const handleSubmit = e => {
    e.preventDefault();
    const xhr = new XMLHttpRequest();
    xhr.onload = () => {
      switch (xhr.status) {
        case 200:
          const jsonResponse = JSON.parse(xhr.responseText);
          dispatch(setToken(jsonResponse));

          let redirect = '/';
          if (typeof URLSearchParams === 'function') {
            const urlParams = new URLSearchParams(window.location.search);
            redirect = urlParams.get('redirect') || '/';
          }
          dispatch(push(redirect));
          return;
        case 401:
          setErrorMessage('Invalid credentials');
          return;
        default:
          return console.error(xhr.responseText);
      }
    };
    xhr.open('POST', '/auth/login');
    xhr.setRequestHeader('Content-Type', 'application/json; charset=utf-8');
    xhr.send(JSON.stringify({ email: email, password: password }));
  };

  const messages = errorMessage ? (
    <Message negative>{errorMessage}</Message>
  ) : (
    undefined
  );

  return (
    <Segment vertical style={{ height: '100vh', padding: 0 }}>
      <Grid
        textAlign="center"
        style={{ height: '100%', alignItems: 'flex-end', margin: 0 }}
        verticalAlign="middle"
      >
        <Grid.Row>
          <Grid.Column>
            <Segment vertical style={{ maxWidth: 450, margin: 'auto' }}>
              <img
                className="logo"
                src={Logo}
                alt="yourpass"
                style={{
                  width: 150,
                }}
              />
              <Header as="h2" textAlign="center">
                Log In
              </Header>
              <p>
                Need a Yourpass account?
                <a href={`${LOGIN_URL}?signup&redirect=${window.location}`}>
                  {' '}
                  Create an account
                </a>
              </p>
              <Form onSubmit={handleSubmit}>
                <Form.Input
                  fluid
                  name="email"
                  icon="envelope"
                  iconPosition="left"
                  placeholder="E-mail address"
                  onChange={handleEmailChange}
                />
                <Form.Input
                  fluid
                  name="password"
                  icon="lock"
                  iconPosition="left"
                  placeholder="Password"
                  type="password"
                  onChange={handlePasswordChange}
                />
                {messages}
                <Button color="blue" fluid>
                  Login
                </Button>
                <p>
                  <a href={`${LOGIN_URL}?reset&redirect=${window.location}`}>
                    Forgot password?
                  </a>
                </p>
              </Form>
              <p style={{ marginTop: '2em', marginBottom: 0 }}>
                Get more information about{' '}
                <a
                  href="http://yourpass.eu"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Yourpass
                </a>
                .
              </p>
              <p>
                Need help?{' '}
                <a
                  href="http://yourpass.eu/#contacts"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Contact us
                </a>
                .
              </p>
            </Segment>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row
          style={{
            backgroundColor: 'black',
            color: 'white',
            margin: 0,
            fontSize: 12,
          }}
        >
          ©2018&nbsp;All&nbsp;Rights&nbsp;Reserved. Yourpass is a registered
          trademark of YOUR&nbsp;PASS,&nbsp;s.r.o. Terms.
        </Grid.Row>
      </Grid>
    </Segment>
  );
}
