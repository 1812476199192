import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';

export default class FormTextAreaJson extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    defaultValue: PropTypes.object,
  };

  componentWillMount() {
    this.setState({ validJSONData: true });
  }

  handleChangeJSON = (e, p) => {
    try {
      const value = JSON.parse(p.value);
      this.setState({ validJSONData: true });
      this.props.onChange(e, { name: p.name, value: value });
    } catch (ex) {
      this.setState({ validJSONData: false });
    }
  };

  render() {
    const dataStr = JSON.stringify(this.props.defaultValue || {}, null, 10);
    return (
      <Form.TextArea
        disabled={this.props.disabled}
        autoHeight={true}
        name={this.props.name}
        error={!this.state.validJSONData}
        label={this.props.label}
        readOnly={this.props.readOnly}
        defaultValue={dataStr}
        onChange={this.handleChangeJSON}
      />
    );
  }
}
