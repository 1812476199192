import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import { connect } from 'react-redux';
import ColorInput from '../../common/ColorInput';
import { injectIntl } from 'react-intl';

class EshopForm extends Component {
  static propTypes = {
    data: PropTypes.object,
    intl: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
  };

  setColor = (e, d) => {
    const { handleChange } = this.props;
    handleChange(null, { name: `styles.${d.name}`, value: d.value });
  };

  render() {
    let { data, handleChange, intl } = this.props;

    const colors = [
      'backgroundColor',
      'brandPrimary',
      'brandSecondary',
      'contentBtnColor',
      'contentColor',
      'contentErrorColor',
      'contentWarningColor',
      'footerColor',
      'headerColor',
    ];

    return (
      <div>
        {colors.map(colorItem => {
          return (
            <ColorInput
              key={colorItem}
              onChange={this.setColor}
              label={intl.formatHTMLMessage({
                id: colorItem.toUpperCase(),
              })}
              name={colorItem}
              id={colorItem}
              value={data.styles[colorItem]}
            />
          );
        })}
        <Form.Input
          name="styles.fontFamily"
          label={intl.formatHTMLMessage({ id: 'FONTFAMILY' })}
          onChange={handleChange}
          defaultValue={data.styles.fontFamily}
        />
        <Form.Input
          name="styles.footerHeight"
          label={intl.formatHTMLMessage({ id: 'FOOTERHEIGHT' })}
          onChange={handleChange}
          defaultValue={data.styles.footerHeight}
        />
        <Form.Input
          name="styles.headerHeight"
          label={intl.formatHTMLMessage({ id: 'HEADERHEIGHT' })}
          onChange={handleChange}
          defaultValue={data.styles.headerHeight}
        />
        <Form.Input
          name="styles.backgroundImg"
          label={intl.formatHTMLMessage({ id: 'BACKGROUNDIMG' })}
          onChange={handleChange}
          defaultValue={data.styles.backgroundImg}
        />
        <Form.Input
          name="styles.footerImg"
          label={intl.formatHTMLMessage({ id: 'FOOTERIMG' })}
          onChange={handleChange}
          defaultValue={data.styles.footerImg}
        />
        <Form.Input
          name="styles.headerImg"
          label={intl.formatHTMLMessage({ id: 'HEADERIMG' })}
          onChange={handleChange}
          defaultValue={data.styles.headerImg}
        />
        <Form.TextArea
          label={intl.formatHTMLMessage({ id: 'HEADOVERRIDES' })}
          name="headOverrides"
          onChange={handleChange}
          defaultValue={data.headOverrides}
          autoHeight
        />
        <Form.TextArea
          label={intl.formatHTMLMessage({ id: 'BODYOVERRIDES' })}
          name="bodyOverrides"
          onChange={handleChange}
          defaultValue={data.bodyOverrides}
          autoHeight
        />
      </div>
    );
  }
}

// This removes warnings for "undefined" react props
export default connect(
  (state, props) => ({
    viewer: state.viewer.data,
  }),
  {},
)(injectIntl(EshopForm));
