export default class Errors {
  constructor(valid, errors) {
    this.valid = !!valid;
    this.errors = errors || [];
  }

  hasErrors = path => {
    return this.getErrors(path).length > 0;
  };

  pathToString = arr => {
    let str = '';

    arr.forEach(i => {
      const isNumber = !isNaN(i);
      if (isNumber) {
        i = `[${i}]`;
      } else if (str !== ``) {
        str = `${str}.`;
      }
      str = `${str}${i}`;
    });

    return str;
  };

  getErrors = path => {
    if (path instanceof Array) {
      path = this.pathToString(path);
    }
    let results = [];
    this.errors.map((e, i) => {
      if (e.dataPath.indexOf(`.${path}`) !== -1) {
        results.push(e);
      }
      return i;
    });
    return results;
  };

  hasMissingPropertyOnPath = (path, property) => {
    if (path instanceof Array) {
      path = this.pathToString(path);
    }
    let results = [];
    this.errors.map((e, i) => {
      if (
        e.dataPath === `.${path}` &&
        e.keyword === 'required' &&
        e.params &&
        e.params.missingProperty === property
      ) {
        results.push(e);
      }
      return i;
    });
    return results.length > 0;
  };
}
