import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';

export default class Preview extends Component {
  static propTypes = {
    passStyle: PropTypes.string,
    size: PropTypes.object,
    type: PropTypes.string.isRequired,
    onRemove: PropTypes.func.isRequired,
    data: PropTypes.object,
    showDeleteIcon: PropTypes.bool,
  };

  render() {
    const { type, data, size, onClick, showDeleteIcon } = this.props;

    if (type === 'logo') {
      if (data) {
        size.width = parseInt(data.width / 2, 10);
        size.height = parseInt(data.height / 2, 10);
      } else {
        size.width = 160;
        size.height = 50;
      }
      size.maxWidth = 160;
      size.maxHeight = 50;
    }

    const style = {
      position: 'relative',
      backgroundImage: `url(${data.url})`,
      backgroundRepeat: 'no-repeat',
      margin: '0 auto',
      ...size,
    };

    return (
      <div className="designerHoverItem" style={style} onClick={onClick}>
        <Button
          color="red"
          size="small"
          style={{
            position: 'absolute',
            right: -14,
            top: -14,
            cursor: 'pointer',
            padding: '3px',
          }}
          icon="remove"
          disabled={!showDeleteIcon}
          onClick={this.props.onRemove}
        />
      </div>
    );
  }
}
