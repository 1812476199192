export const TEMPLATE_UPDATE_PASESS_BY_PUSH_LOADING =
  'TEMPLATE_UPDATE_PASESS_BY_PUSH_LOADING';
export const TEMPLATE_UPDATE_PASESS_BY_PUSH_SUCCESS =
  'TEMPLATE_UPDATE_PASESS_BY_PUSH_SUCCESS';
export const TEMPLATE_UPDATE_PASESS_BY_PUSH_FAILURE =
  'TEMPLATE_UPDATE_PASESS_BY_PUSH_FAILURE';

export const TEMPLATE_UPDATE_SAVE_TO_GOOLE_PAY_LOADING =
  'TEMPLATE_UPDATE_SAVE_TO_GOOLE_PAY_LOADING';
export const TEMPLATE_UPDATE_SAVE_TO_GOOLE_PAY_SUCCESS =
  'TEMPLATE_UPDATE_SAVE_TO_GOOLE_PAY_SUCCESS';
export const TEMPLATE_UPDATE_SAVE_TO_GOOLE_PAY_FAILURE =
  'TEMPLATE_UPDATE_SAVE_TO_GOOLE_PAY_FAILURE';

export function templateUpdatePassesByPush(templateId) {
  return {
    type: TEMPLATE_UPDATE_PASESS_BY_PUSH_LOADING,
    templateId,
  };
}

export function templateUpdateSaveToGooglePay(templateId) {
  return {
    type: TEMPLATE_UPDATE_SAVE_TO_GOOLE_PAY_LOADING,
    templateId,
  };
}
