import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
class OrderEdit extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
  };

  render() {
    const { data, onChange, intl } = this.props;
    return (
      <Form.Input
        name="email"
        label={intl.formatHTMLMessage({ id: 'EMAIL' })}
        onChange={onChange}
        defaultValue={data.email}
      />
    );
  }
}
export default injectIntl(OrderEdit);
