import React, { Component } from 'react';
import ResourceItemDecorator from './ResourceItemDecorator';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from '../../actions/routing';
class Edit extends Component {
  static propTypes = {
    resourceId: PropTypes.string.isRequired,
    resource: PropTypes.string.isRequired,
    component: PropTypes.func.isRequired,
  };

  failure = e => {
    this.props.push(`/error/${e.status || 500}`);
  };

  render() {
    return <ResourceItemDecorator {...this.props} failure={this.failure} />;
  }
}

export default connect(() => ({}), { push })(Edit);
