export default {
  pass: {
    csvKeys: [],
    csvLabels: [],
  },
  project: {
    csvKeys: [],
    csvLabels: [],
  },
  file: {
    csvKeys: [],
    csvLabels: [],
  },
  image: {
    csvKeys: [],
    csvLabels: [],
  },
  userProject: {
    csvKeys: [],
    csvLabels: [],
  },
  user: {
    csvKeys: [],
    csvLabels: [],
  },
  template: {
    csvKeys: [],
    csvLabels: [],
  },
  certificate: {
    csvKeys: [],
    csvLabels: [],
  },
  ticketEshop: {
    csvKeys: [],
    csvLabels: [],
  },
  ticketProduct: {
    csvKeys: [],
    csvLabels: [],
  },
  ticketOrder: {
    csvKeys: [
      'id',
      'paymentMethod',
      'orderNumber',
      'yearSerial',
      'locale',
      // "paymentUrl",
      // "paymentAttrs",
      'paymentServiceBaseUrl',
      'state',
      'email',
      'paymentMethodDiscount',
      'paymentCheckCount',
      'totalWithoutVat',
      'totalWithVat',
      'orderItemsCreated',
      'orderPaymentError',
      'orderPaymentRequested',
      'orderPaymentSuccess',
      'orderItemNumberGenerateRequested',
      'orderEmailRequested',
      'orderEmailError',
      'orderEmailSuccess',
      'orderDelivered',
      'createdAt',
      'updatedAt',
      'deletedAt',
      'configId',
      'paymentState'
    ],
    csvLabels: [],
  },
  ticketOrderItem: {
    csvKeys: [
      'id',
      'data',
      'product',
      'entryCount',
      'state',
      'validTo',
      'validFrom',
      'itemNumber',
      'projectSerial',
      'yearSerial',
      'monthSerial',
      'daySerial',
      'issued',
      'orderItemNumberGenerateRequested',
      'orderItemNumberGenerateSuccess',
      'orderItemNumberGenerateError',
      'orderItemPassRequested',
      'orderItemPassSuccess',
      'orderItemPassError',
      'orderItemPassInvalidateRequested',
      'orderItemPassInvalidateSuccess',
      'orderItemManualChange',
      'orderItemUsed',
      'firstEntry',
      'lastEntry',
      'createdAt',
      'updatedAt',
      'deletedAt',
      'orderId',
      'configId',
      'templateId',
      'passId',
    ],
    csvLabels: [],
  },
  ticketTransaction: {
    csvKeys: [
      'eshopId',
      'timestamp',
      'orderNumber',
      'code',
      'type',
      'price',
      'discountPercentage',
      'discountAmount',
      'discountedPrice',
    ],
    csvLabels: [
      'Eshop',
      'Datum',
      'Číslo objednávky',
      'Kód',
      'Typ vstupenky',
      'Cena [Kč]',
      'Sleva [%]',
      'Sleva [Kč]',
      'Cena po slevě [Kč]',
    ],
  },
  ticketSale: {
    csvKeys: ['eshopId', 'type', 'count', 'price', 'discountedPrice'],
    csvLabels: [
      'Eshop',
      'Typ vstupenky',
      'Počet prodaných kusů',
      'Cena celkem bez slev [Kč]',
      'Cena celkem po uplatnění slev [Kč]',
    ],
  },
};
