import React, { Component } from 'react';

import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { GOOGLE_MAP_API_KEY } from '../../services/api';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const libraries = ['places'];

const containerStyle = {
  width: '50vw',
  height: '400px',
};

function MyMapComponent(props) {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAP_API_KEY,
    nonce: document.querySelector('script[nonce]')?.nonce,
    libraries: libraries,
  });

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      options={{ streetViewControl: false }}
      center={{ lat: props.lat, lng: props.lng }}
      lat={props.lat}
      lng={props.lng}
      zoom={15}
      onClick={props.onClickMap}
    >
      {props.search}
      <Marker position={{ lat: props.lat, lng: props.lng }} />
    </GoogleMap>
  ) : (
    <></>
  );
}

export default class MapInput extends Component {
  static propTypes = {
    close: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
  };

  componentWillMount() {
    this.setState({
      address: '',
    });
  }

  onClickMap = value => {
    const { onChange } = this.props;
    onChange(value.latLng.lat(), value.latLng.lng());
  };

  onChange = address => {
    this.setState({ address });
  };

  handleFormSubmit = event => {
    event.preventDefault();
    const { address } = this.state;
    const { onChange } = this.props;
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        onChange(latLng.lat, latLng.lng);
      });
  };

  render() {
    const { lat, lng, close } = this.props;
    const { address, stateLat, stateLng } = this.state;
    const DEFAULT_LAT = 50.0369421;
    const DEFAULT_LNG = 14.493124599999987;
    const search = (
      <div>
        <div style={{ position: 'absolute', top: 5, left: 5, width: 350 }}>
          <PlacesAutocomplete
            style={{ width: 350 }}
            inputProps={{
              value: address,
              onChange: this.onChange,
            }}
          />
          <Button onClick={this.handleFormSubmit} style={{ marginTop: 5 }}>
            Submit
          </Button>
        </div>
        <Button
          onClick={close}
          style={{ position: 'absolute', top: 5, right: 5 }}
        >
          Close
        </Button>
      </div>
    );
    return (
      <MyMapComponent
        onClickMap={this.onClickMap}
        lat={stateLat || lat || DEFAULT_LAT}
        lng={stateLng || lng || DEFAULT_LNG}
        search={search}
      />
    );
  }
}
