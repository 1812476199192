import React from 'react';
import PropTypes from 'prop-types';
import { Button, Segment } from 'semantic-ui-react';
import LatLngInput from '../../../../common/LatLngInput';
import { NumberProperty } from '../../../../../models/property';
import { connect } from 'react-redux';
import { addNotification } from '../../../../../actions/notification';
import Template from '../../../../../models/template';
import Errors from '../../../../../models/Errors';
import InputRenderComponent from '../../inputs/InputRenderComponent';

class Locations extends InputRenderComponent {
  static propTypes = {
    itemSet: PropTypes.func,
    data: PropTypes.instanceOf(Template).isRequired,
    errors: PropTypes.instanceOf(Errors).isRequired,
  };

  add = () => {
    try {
      const { itemSet, data } = this.props;
      const newData = data.locationAdd();
      itemSet(newData);
    } catch (e) {
      this.props.addNotification({
        title: 'Error',
        message: e.message,
        level: 'error',
      });
    }
  };

  remove = index => {
    try {
      const { itemSet, data } = this.props;
      const newData = data.locationRemove(index);
      itemSet(newData);
    } catch (e) {
      this.props.addNotification({
        title: 'Error',
        message: e.message,
        level: 'error',
      });
    }
  };

  handleLatLng = (lat, lng, index) => {
    try {
      const { itemSet, data } = this.props;

      const path = ['jsonTemplate', 'locations', 'items', index, 'properties'];

      let newData = data;
      newData = newData.setIn(
        path.concat(['latitude']),
        new NumberProperty({ value: Number(lat) }),
      );
      newData = newData.setIn(
        path.concat(['longitude']),
        new NumberProperty({ value: Number(lng) }),
      );

      itemSet(newData);
    } catch (e) {
      this.props.addNotification({
        title: 'Error',
        message: e.message,
        level: 'error',
      });
    }
  };

  render() {
    const { data } = this.props;
    const path = ['jsonTemplate', 'locations', 'items'];
    const locations = data.getIn(path);
    return (
      <div>
        <h2>Locations</h2>
        {locations &&
          locations.map((location, index) => {
            const path = [
              'jsonTemplate',
              'locations',
              'items',
              index,
              'properties',
            ];

            return (
              <Segment key={index}>
                <Button
                  icon="trash"
                  color="red"
                  floated="right"
                  onClick={() => {
                    this.remove(index);
                  }}
                />
                {this.renderNumberInput(path, 'latitude')}
                {this.renderNumberInput(path, 'longitude')}
                <LatLngInput
                  lat={parseFloat(
                    location.getIn(['properties', 'latitude', 'value']),
                  )}
                  lng={parseFloat(
                    location.getIn(['properties', 'longitude', 'value']),
                  )}
                  onChange={(lat, lng) => this.handleLatLng(lat, lng, index)}
                />
                {this.renderNumberInput(path, 'altitude')}
                {this.renderStringInput(path, 'relevantText')}
              </Segment>
            );
          })}
        <Button primary icon="plus" onClick={this.add} />
      </div>
    );
  }
}

export default connect(state => ({}), {
  addNotification,
})(Locations);
