import React, { Component } from 'react';
import { Segment, Label } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

class ErrorPage extends Component {
  static propTypes = {
    navigation: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
  };

  render() {
    const router = this.props.navigation;

    const statusCode =
      (router && router.params && router.params.statusCode) || 500;

    return (
      <div>
        <Segment padded>
          <Label color={'red'} attached="top">
            {`${statusCode}`} -{' '}
            <FormattedMessage id={`ERROR_${statusCode}_HEADER`} />
          </Label>
          <p>
            <FormattedMessage id={`ERROR_${statusCode}_TEXT`} />
          </p>
        </Segment>
      </div>
    );
  }
}

export default connect(
  (state, props) => ({ config: state.config, navigation: state.navigation }),
  {},
)(injectIntl(ErrorPage));
