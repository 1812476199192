export const TICKET_ESHOP_VERSIONS_LOADING = 'TICKET_ESHOP_VERSIONS_LOADING';
export const TICKET_ESHOP_VERSIONS_SUCCESS = 'TICKET_ESHOP_VERSIONS_SUCCESS';
export const TICKET_ESHOP_VERSIONS_FAILURE = 'TICKET_ESHOP_VERSIONS_FAILURE';

export const TICKET_ESHOP_VERSION_MESSAGES_LOADING =
  'TICKET_ESHOP_VERSION_MESSAGES_LOADING';
export const TICKET_ESHOP_VERSION_MESSAGES_SUCCESS =
  'TICKET_ESHOP_VERSION_MESSAGES_SUCCESS';
export const TICKET_ESHOP_VERSION_MESSAGES_FAILURE =
  'TICKET_ESHOP_VERSION_MESSAGES_FAILURE';

export function fetchTicketEshopVersions() {
  return {
    type: TICKET_ESHOP_VERSIONS_LOADING,
  };
}

export function fetchTicketEshopMessages(version) {
  return {
    type: TICKET_ESHOP_VERSION_MESSAGES_LOADING,
    version,
  };
}
