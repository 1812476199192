export default function(value) {
  let isAllLinksValid = true;
  if (value) {
    const links = value.match(/(<a.*?<\/a>)/gi);
    if (links && links.length > 0) {
      links.forEach(link => {
        let isLinkValid = false;
        const regexpArr = [
          /(^([^<]*)$|(<a[\s]+[^>]*?href="(.*?)">([^<]+|.*?)?<\/a>))/,
          /(^([^<]*)$|(<a[\s]+[^>]*?href='(.*?)'>([^<]+|.*?)?<\/a>))/,
        ];
        regexpArr.forEach(regexp => {
          if (regexp.test(link)) {
            isLinkValid = true;
          }
        });
        if (!isLinkValid) {
          isAllLinksValid = false;
        }
      });
    }
  }
  return isAllLinksValid;
}
