import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import languageCodes from '../../../constants/languageCodes';

import { injectIntl } from 'react-intl';

class FormEmail extends Component {
  static propTypes = {
    data: PropTypes.object,
    intl: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
  };

  render() {
    let { data, handleChange } = this.props;
    return (
      <div>
        <h1>YS Mail Gun</h1>
        {data.locales &&
          data.locales.map((lang, index) => {
            return (
              <div key={index}>
                <Form.Input
                  name={`email.template.${lang}`}
                  label={languageCodes[lang]}
                  onChange={handleChange}
                  defaultValue={
                    (data.email &&
                      data.email.template &&
                      data.email.template[lang]) ||
                    ''
                  }
                />
              </div>
            );
          })}
      </div>
    );
  }
}

export default injectIntl(FormEmail);
