import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  List,
  Button,
  Segment,
  Header,
  Message,
  Grid,
} from 'semantic-ui-react';
import { login } from '../actions/viewer';
import { Link } from 'react-router';
import { push } from '../actions/routing';
import { DOC_API_URL } from '../services/api';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import './home.css';

class Home extends Component {
  login = () => {
    this.props.push('/');
    this.props.login();
  };

  render() {
    let viewer = this.props.viewer ? this.props.viewer.data : null;
    const accessRights = viewer ? viewer.accessRights : null;
    const showPassList = viewer && (viewer.isAdmin || accessRights.PASS_READ);
    const showTemplateList =
      viewer && (viewer.isAdmin || accessRights.TEMPLATE_READ);
    const sectionHeaderStyle = { marginTop: 15 };
    return (
      <div>
        {!viewer && (
          <Segment>
            <h2>Login</h2>
            <p>
              For access to your passes please{' '}
              <button onClick={this.login}>login</button>.
            </p>
            <Button primary onClick={this.login} floated="right">
              Login
            </Button>
            <div
              style={{
                clear: 'both',
              }}
            />
          </Segment>
        )}
        <Header as="h1">
          <FormattedMessage
            defaultMessage="Yourpass Console"
            id="home.header"
          />
        </Header>
        <p>
          <FormattedMessage
            defaultMessage="This application allows you to view, manage and report passes. You can also find API documentation for yourpass services."
            id="home.perex"
          />
        </p>
        <Segment>
          <h2>
            <FormattedMessage defaultMessage="Yourpass" id="home.core.title" />
          </h2>
          {viewer && !viewer.hasAnyProject() && (
            <Message>
              <FormattedHTMLMessage
                id="home.core.no.project.message"
                defaultMessage={`You currently have no project available, please contact technical support <a href="mailto:info@yourpass.eu">info@yourpass.eu</a>.`}
              />
            </Message>
          )}
          {viewer && (
            <Grid>
              <Grid.Row>
                {viewer.hasAnyProject() && (
                  <Grid.Column mobile={16} tablet={8} computer={6}>
                    <h3 style={sectionHeaderStyle}>
                      <FormattedMessage
                        defaultMessage="Passes"
                        id="home.core.passes.title"
                      />
                    </h3>
                    <List>
                      <List.Item disabled={!showPassList}>
                        <List.Icon name="ticket" />
                        <List.Content>
                          <List.Header>
                            <Link to="/passes/pass">
                              <FormattedMessage
                                defaultMessage="Passes"
                                id="home.core.pass.link"
                              />
                            </Link>
                          </List.Header>
                          <List.Description>
                            <FormattedMessage
                              defaultMessage="Browse, create, edit your passes"
                              id="home.core.pass.description"
                            />
                          </List.Description>
                        </List.Content>
                      </List.Item>
                      <List.Item disabled={!showTemplateList}>
                        <List.Icon name="wordpress forms" />
                        <List.Content>
                          <List.Header>
                            <Link to="/passes/template">
                              <FormattedMessage
                                defaultMessage="Templates"
                                id="home.core.template.link"
                              />
                            </Link>
                          </List.Header>
                          <List.Description>
                            <FormattedMessage
                              defaultMessage=" Manage your templates"
                              id="home.core.template.description"
                            />
                          </List.Description>
                        </List.Content>
                      </List.Item>
                      {viewer.isAdmin && (
                        <List.Item>
                          <List.Icon name="image" />
                          <List.Content>
                            <List.Header>
                              <Link to="passes/image">
                                <FormattedMessage
                                  defaultMessage="Images"
                                  id="home.core.image.link"
                                />
                              </Link>
                            </List.Header>
                            <List.Description>
                              <FormattedMessage
                                defaultMessage="Manage your images"
                                id="home.core.image.description"
                              />
                            </List.Description>
                          </List.Content>
                        </List.Item>
                      )}
                      {viewer.isAdmin && (
                        <List.Item>
                          <List.Icon name="certificate" />
                          <List.Content>
                            <List.Header>
                              <Link to="passes/certificate">
                                <FormattedMessage
                                  defaultMessage="Certificates"
                                  id="home.core.certificate.link"
                                />
                              </Link>
                            </List.Header>
                            <List.Description>
                              <FormattedMessage
                                defaultMessage="Manage certificates"
                                id="home.core.certificate.description"
                              />
                            </List.Description>
                          </List.Content>
                        </List.Item>
                      )}
                    </List>
                  </Grid.Column>
                )}
                {viewer.hasAnyProject() && (
                  <Grid.Column mobile={16} tablet={8} computer={6}>
                    <h3 style={sectionHeaderStyle}>
                      <FormattedMessage
                        defaultMessage="Reports"
                        id="home.core.report.title"
                      />
                    </h3>
                    <List>
                      <List.Item>
                        <List.Icon name="bar chart" />
                        <List.Content>
                          <Link to="/report/passInWalletOverview">
                            <FormattedMessage
                              defaultMessage="Passes registered in wallet"
                              id="home.report.passes.registered.in.wallet.link"
                            />
                          </Link>
                        </List.Content>
                      </List.Item>
                      <List.Item>
                        <List.Icon name="line chart" />
                        <List.Content>
                          <Link to="/report/passAtLeastOnceRegisterInWalletOverview">
                            <FormattedMessage
                              defaultMessage="Passes at least once registered in wallet"
                              id="home.report.passes.at.least.once.registered.in.wallet.link"
                            />
                          </Link>
                        </List.Content>
                      </List.Item>
                      <List.Item>
                        <List.Icon name="pie chart" />
                        <List.Content>
                          <Link to="/report/passDownloadRequestByTagsOverview">
                            <FormattedMessage
                              defaultMessage="Downloads of pass by tags"
                              id="home.report.download.by.tags.link"
                            />
                          </Link>
                        </List.Content>
                      </List.Item>
                      <List.Item>
                        <List.Icon name="area chart" />
                        <List.Content>
                          <Link to="/report/passPreviewRequestByTagsOverview">
                            <FormattedMessage
                              defaultMessage="Previews of pass by tags"
                              id="home.report.preview.by.tags.link"
                            />
                          </Link>
                        </List.Content>
                      </List.Item>
                    </List>
                  </Grid.Column>
                )}
                <Grid.Column mobile={16} tablet={8} computer={4}>
                  <h3 style={sectionHeaderStyle}>
                    <FormattedMessage
                      id="home.documentation.header"
                      defaultMessage="Documentation"
                    />
                  </h3>
                  <List>
                    <List.Item>
                      <List.Icon name="file text" />
                      <List.Content>
                        <List.Header href={DOC_API_URL}>
                          <FormattedMessage
                            id="home.documentation.link"
                            defaultMessage="API"
                          />
                        </List.Header>
                      </List.Content>
                    </List.Item>
                  </List>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          )}
        </Segment>

        {viewer && viewer.isAdmin && (
          <Segment>
            <h2>
              <FormattedMessage
                defaultMessage="Identity management"
                id="home.idm.header"
              />
            </h2>
            <List>
              <List.Item>
                <List.Icon name="archive" />
                <List.Content>
                  <List.Header>
                    <Link to="/identity/project">
                      <FormattedMessage
                        defaultMessage="Projects"
                        id="home.idm.project.link"
                      />
                    </Link>
                  </List.Header>
                  <List.Description>
                    <FormattedMessage
                      defaultMessage="Manage projects"
                      id="home.idm.project.description"
                    />
                  </List.Description>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Icon name="users" />
                <List.Content>
                  <List.Header>
                    <Link to="/identity/iuser">
                      <FormattedMessage
                        defaultMessage="Users"
                        id="home.idm.user.link"
                      />
                    </Link>
                  </List.Header>
                  <List.Description>
                    <FormattedMessage
                      defaultMessage="Manage users"
                      id="home.idm.user.description"
                    />
                  </List.Description>
                </List.Content>
              </List.Item>
            </List>
          </Segment>
        )}
      </div>
    );
  }
}

export default connect((state, props) => ({ viewer: state.viewer }), {
  login,
  push,
})(Home);
