export const SEND_EMAIL_LOADING = 'SEND_EMAIL_SUCCESS_LOADING';
export const SEND_EMAIL_SUCCESS = 'SEND_EMAIL_SUCCESS';
export const SEND_EMAIL_FAILURE = 'SEND_EMAIL_FAILURE';

export function sendEmail(orderId, orderNumber) {
  return {
    type: SEND_EMAIL_LOADING,
    orderId,
    orderNumber,
  };
}
