import { call, put } from 'redux-saga/effects';
import client from '../services/api';
import { REGISTER_FAILURE, REGISTER_SUCCESS } from '../actions/register';

import { getApiForResource, getApiResourceAlias } from './resource';

export function* registerLoad(action) {
  let resource = action.resource;
  const allData = [];
  const limit = 1000;
  try {
    let hasNext = true;
    let page = 1;
    while (hasNext) {
      let resourceQuery = `${getApiForResource(
        resource,
      )}/v1/${getApiResourceAlias(resource)}?&page=${page}&limit=${limit}&suppressCount`;

      let data = yield call(client, 'GET', resourceQuery, {});

      if (data && data.data) {
        data.data.forEach(i => {
          allData.push(i);
        });
      }

      page = page + 1;
      if (data.data === null || data.data.length < limit) {
        hasNext = false;
      }
    }
    const response = {
      data: allData,
      totalCount: allData.length,
    };
    yield put({
      type: REGISTER_SUCCESS,
      data: response,
      resource,
    });
  } catch (e) {
    yield put({
      type: REGISTER_FAILURE,
      error: e,
      resource,
    });
  }
}
