import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { filterSet, filterSetData } from '../../../actions/index';
import { Form, Dropdown } from 'semantic-ui-react';
import paymentStates from '../../../constants/paymentStates';
import { injectIntl } from 'react-intl';
class FilterPaymentState extends Component {
  static propTypes = {
    filterSet: PropTypes.func.isRequired,
    onChange: PropTypes.func,
    intl: PropTypes.object.isRequired,
  };

  handleChange = (e, p) => {
    this.props.filterSet({ name: 'paymentState', value: p.value });
    if (this.props.onChange) {
      this.props.onChange(e, p);
    }
  };

  render() {
    const { intl } = this.props;
    const options = Object.keys(paymentStates).map(key => {
      return {
        text: intl.formatHTMLMessage({ id: key }),
        value: paymentStates[key],
        id: key,
      };
    });
    return (
      <Form.Field>
        <label>{intl.formatHTMLMessage({ id: 'PAYMENT_STATE' })}</label>
        <Dropdown
          name="PAYMENT_STATE"
          selection={true}
          multiple={true}
          search={true}
          options={options}
          placeholder={intl.formatHTMLMessage({
            id: 'PAYMENT_STATE_PLACEHOLDER',
          })}
          onChange={this.handleChange}
        />
      </Form.Field>
    );
  }
}

export default connect((state, props) => ({}), { filterSet, filterSetData })(
  injectIntl(FilterPaymentState),
);
