import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FormGroupTimestamps from './FormGroupTimestamps';

export default class FormReadOnly extends Component {
  static propTypes = {
    data: PropTypes.object,
  };

  render() {
    return (
      <div>
        <pre>{JSON.stringify(this.props.data, null, 2)}</pre>
        <FormGroupTimestamps data={this.props.data} />
      </div>
    );
  }
}
