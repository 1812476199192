import PropTypes from 'prop-types';
import InputRenderComponent from '../../../../../../inputs/InputRenderComponent';

const opts = [
  {
    key: 'PKDateStyleNone',
    value: 'PKDateStyleNone',
    text: 'PKDateStyleNone',
  },
  {
    key: 'PKDateStyleShort',
    value: 'PKDateStyleShort',
    text: 'PKDateStyleShort',
  },
  {
    key: 'PKDateStyleMedium',
    value: 'PKDateStyleMedium',
    text: 'PKDateStyleMedium',
  },
  {
    key: 'PKDateStyleLong',
    value: 'PKDateStyleLong',
    text: 'PKDateStyleLong',
  },
  {
    key: 'PKDateStyleFull',
    value: 'PKDateStyleFull',
    text: 'PKDateStyleFull',
  },
];

export default class FieldTypeDate extends InputRenderComponent {
  static propTypes = {
    ...InputRenderComponent.propTypes,
    path: PropTypes.array.isRequired,
  };

  render() {
    const { path } = this.props;
    return [
      this.renderDateInput(path, 'value'),
      this.renderBooleanInput(path, 'ignoresTimeZone', 'time'),
      this.renderDropDownInput(path, 'timeStyle', opts),
      this.renderDropDownInput(path, 'dateStyle', opts),
    ];
  }
}
