import React, { Component } from 'react';
import Fields from './Fields';
import PropTypes from 'prop-types';
import Errors from '../../../../../../models/Errors';
import Template from '../../../../../../models/template';

export default class BackFields extends Component {
  static propTypes = {
    previewLanguage: PropTypes.string.isRequired,
    errors: PropTypes.instanceOf(Errors).isRequired,
    data: PropTypes.instanceOf(Template).isRequired,
    itemSet: PropTypes.func.isRequired,
  };

  render() {
    const { data, previewLanguage, errors, itemSet } = this.props;
    return (
      <Fields
        data={data}
        previewLanguage={previewLanguage}
        errors={errors}
        itemSet={itemSet}
        fieldsType="backFields"
      />
    );
  }
}
