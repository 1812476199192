import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ResourceDataGrid from '../../common/ResourceDataGrid';
import { Link } from 'react-router';
import { Button } from 'semantic-ui-react';
import JsonModal from '../../common/JsonModal';
import ResourceListPagedDecorator from '../../common/ResourceListPagedDecorator';
import LabelTicketEshop from '../../common/LabelTicketEshop';
import Filter from '../../common/filter/Filter';
import { listExport } from '../../../actions/resourceListPaged';
import { connect } from 'react-redux';

const filterProps = [
  {
    name: 'eshopId',
    type: 'inUuid',
    columnName: 'eshopId',
  },
  {
    name: 'paymentState',
    type: 'inUuid',
    columnName: 'paymentState',
  },
  {
    name: 'itemNumber',
    type: 'like',
    columnName: 'itemNumber',
  },

  {
    name: 'orderNumber',
    type: 'like',
    columnName: 'orderNumber',
  },
];

let fields = {
  name: {
    title: 'Name',
    input: 'text',
    convertToValue: function(id, item) {
      return `${item.product.name}`;
    },
  },
  serials: {
    title: 'Serial Day/Month/Year',
    input: 'text',
    convertToValue: function(id, item) {
      return `${item.daySerial}/${item.monthSerial}/${item.yearSerial}`;
    },
  },
  createdAt: {
    title: 'Created',
    type: 'date',
  },
  validFrom: {
    title: 'Valid from',
    type: 'date',
  },
  validTo: {
    title: 'Valid to',
    type: 'date',
  },
  itemNumber: {
    title: 'ITEM_NUMBER',
    type: 'text',
  },

  orderNumber: {
    title: 'ORDER_NUMBER',
    type: 'text',
    orderBy: 'orderNumber',
    convertToValue: function(id, item) {
      return (
        <Link to={'/ticket/ticketorder/' + item.orderId}>
          {item.orderNumber}
        </Link>
      );
    },
  },

  eshopId: {
    title: 'Eshop',
    input: 'text',
    readOnly: true,
    convertToValue: function(id) {
      return <LabelTicketEshop id={id} />;
    },
  },
  entryCount: {
    title: 'TICKETORDERITEM_ENTRY_COUNT',
    convertToValue: function(id, item) {
      return <span>{`${item.entryCount}/${item.product.maxEntries}`}</span>;
    },
  },
  actions: {
    convertToValue: function(id, item) {
      return <Actions item={item} />;
    },
  },
};

const resource = 'ticketOrderItem';

class Actions extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
  };

  render() {
    return (
      <Button.Group>
        <JsonModal
          title={`OrderItem ${this.props.item.id}`}
          data={this.props.item}
        />
        <Button
          as={Link}
          to={'/ticket/' + resource + '/' + this.props.item.id}
          icon="edit"
        />
      </Button.Group>
    );
  }
}

class OrderItemList extends Component {
  render() {
    const filter = (
      <Filter
        itemNumber={true}
        orderNumber={true}
        eshopId={true}
        paymentState={true}
      />
    );
    return (
      <ResourceListPagedDecorator
        resource={'ticketOrderItem'}
        component={ResourceDataGrid}
        componentProps={{
          filter: filter,
          filterProps: filterProps,
          resource: resource,
          fields: fields,
          cols: [
            'entryCount',
            'itemNumber',
            'eshopId',
            'orderNumber',
            'name',
            'createdAt',
            'validFrom',
            'validTo',
            'actions',
          ],
          csv: true,
        }}
      />
    );
  }
}

export default connect((state, props) => ({}), { listExport })(OrderItemList);
