import React, { Component } from 'react';
import ResourceFormDropdown from './ResourceFormDropdown';
import LabelProject from './LabelProject';

export default class FormDropdownProject extends Component {
  render() {
    return (
      <ResourceFormDropdown
        resource="project"
        label="Project"
        {...this.props}
        renderContent={data => {
          return (
            <span>
              {data.name}
              {data.parentId && (
                <small>
                  <LabelProject disableLink={true} id={data.parentId} />
                </small>
              )}
            </span>
          );
        }}
      />
    );
  }
}
