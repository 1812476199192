import React, { Component } from 'react';
import FormInputCheckbox from '../../common/FormInputCheckbox';
import FormTextInputFieldWithErrors from '../../common/form/FormTextInputFieldWithErrors';
import FormSectionTitle from '../../common/form/FormSectionTitle';

export default class FormTabLocalization extends Component {
  handleChangeWarningEnabled = (e, p) => {
    const { data, onChange } = this.props;
    onChange(e, p);
    if (p.value) {
      if (!data.warningTitle) {
        // TODO - add default values for each language
        onChange(e, { name: 'warningTitle', value: {} });
      }
    }
  };

  render() {
    const { data, onChange, ticketEshop, errors } = this.props;

    return (
      <div>
        <FormSectionTitle translationKey="TITLE" path="title" errors={errors} />

        {ticketEshop.locales.map((locale, i) => {
          const name = `title.${locale}`;
          return (
            <FormTextInputFieldWithErrors
              key={name}
              errors={errors}
              name={name}
              label={locale}
              type="text"
              onChange={onChange}
              defaultValue={data.title[locale]}
            />
          );
        })}

        <FormSectionTitle
          translationKey="WARNING_TITLE"
          path="warningTitle"
          errors={errors}
        />

        <FormInputCheckbox
          name="warningEnabled"
          label="Enabled"
          onChange={this.handleChangeWarningEnabled}
          defaultChecked={data.warningEnabled}
          toggle={true}
        />
        {data.warningEnabled &&
          ticketEshop.locales.map((locale, i) => {
            const name = `warningTitle.${locale}`;
            return (
              <FormTextInputFieldWithErrors
                key={name}
                errors={errors}
                name={name}
                label={locale}
                type="text"
                onChange={onChange}
                defaultValue={
                  (data.warningTitle && data.warningTitle[locale]) || ''
                }
              />
            );
          })}
      </div>
    );
  }
}
