import React, { Component } from 'react';
import { Label, List, Accordion } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

export default class ErrorsMessage extends Component {
  state = { active: false };

  handleClick = () => {
    this.setState({ active: !this.state.active });
  };

  render() {
    const { active } = this.state;

    const { errors } = this.props;
    if (errors.length <= 0) {
      return null;
    }
    return (
      <Label
        basic
        color="red"
        pointing={'above'}
        style={{ marginTop: 0, marginBottom: '1em' }}
      >
        <Accordion color="red">
          <Accordion.Title
            active={active}
            index={0}
            onClick={this.handleClick}
            style={{ color: '#DB2828' }}
          >
            <FormattedMessage
              id="PLEASE_ENTER_A_VALUE"
              defaultMessage="PLEASE_ENTER_A_VALUE"
            />
          </Accordion.Title>
          <Accordion.Content active={active}>
            <List>
              {errors.map((e, i) => {
                return <List.Item key={i}>{e.message}</List.Item>;
              })}
            </List>
          </Accordion.Content>
        </Accordion>
      </Label>
    );
  }
}
