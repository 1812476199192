export default {
  PAID: 'paid',
  CANCELED: 'canceled',
  TIMEOUT: 'timeout',
  DECLINED_3D: 'declined3D',
  DECLINED_AC: 'declinedAC',
  ERROR: 'error',
  UNKNOWN: 'unknown',
  PENDING: 'pending',
};
