import React, { Component } from 'react';
import { Button, Icon, Confirm } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

export default class DeleteButton extends Component {
  state = { open: false };

  show = () => this.setState({ open: true });

  handleConfirm = () => {
    this.setState({ open: false });
    this.props.onDelete();
  };

  handleCancel = () => this.setState({ open: false });

  render() {
    if (!this.state.open) {
      return (
        <Button onClick={this.show} color="red" type="button">
          <Icon name="trash" />
          <FormattedMessage id="BUTTON_DELETE" defaultMessage="BUTTON_DELETE" />
        </Button>
      );
    } else {
      return (
        <Confirm
          content="Resource will be permanetly deleted. Are you sure?"
          open={this.state.open}
          onCancel={this.handleCancel}
          onConfirm={this.handleConfirm}
        />
      );
    }
  }
}
