import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  Button,
  Icon,
  Menu,
  Dropdown,
  Loader,
  Message,
  Popup,
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import { listExport } from '../../actions/resourceListPaged';
import csv from '../../constants/csv';
import { FormattedDate, FormattedMessage } from 'react-intl';
import ResourceDataGridObjectAccordion from './ResourceDataGridObjectAccordion';
import { injectIntl } from 'react-intl';
const exportSize = 1000;

class ResourceDataGrid extends Component {
  static propTypes = {
    filter: PropTypes.object,
    newButton: PropTypes.object,
    listLoad: PropTypes.func.isRequired,
    listSetLimit: PropTypes.func.isRequired,
    listSetPage: PropTypes.func.isRequired,
    listSetOrder: PropTypes.func.isRequired,
    fields: PropTypes.object,
    cols: PropTypes.array,
    data: PropTypes.array,
  };

  csv = () => {
    let {
      resource,
      listExport,
      filterProps,
      list,
      csvKeys,
      csvLabels,
      requestId,
    } = this.props;
    listExport(
      resource,
      filterProps,
      { filter: list.filter, limit: exportSize },
      'csv',
      csvKeys,
      csvLabels,
      requestId,
    );
  };

  handleLimitChange(e, values) {
    const { value } = values;
    let { listSetLimit } = this.props;
    listSetLimit(value);
  }

  handlePageChange(values) {
    const { value } = values;
    let { listSetPage } = this.props;
    listSetPage(value);
  }

  handleSort(value, direction) {
    let { listSetOrder } = this.props;
    listSetOrder(value, direction === 'ascending' ? 'desc' : 'asc');
  }

  renderCell(key, col) {
    let {listLoad, list: { data }, fields } = this.props;
    if (!!fields[col].convertToValue) {
      return fields[col].convertToValue(data[key][col], data[key], listLoad);
    }
    if (data[key][col] == null) return '';

    if (fields[col].link)
      return <a href={fields[col].link(data[key].id)}>{data[key][col]}</a>;

    const dataType = typeof data[key][col];
    if (dataType === 'boolean' || fields[col].type === 'boolean') {
      return data[key][col] ? <Icon name="check" /> : null;
    }

    if (!isNaN(data[key][col])) {
      return '' + data[key][col];
    }

    if (dataType === 'object')
      return <ResourceDataGridObjectAccordion data={data[key][col]} />;

    if (dataType === 'date' || fields[col].type === 'date') {
      return (
        <FormattedDate
          value={new Date(data[key][col])}
          year="numeric"
          month="2-digit"
          day="2-digit"
          hour="2-digit"
          minute="2-digit"
          second="2-digit"
          timeZoneName="short"
          hour12={false}
        />
      );
    }

    return data[key][col];
  }

  renderTable() {
    const { list, fields, cols, csv, intl } = this.props;

    if (list == null) {
      return <Loader active inline="centered" />;
    }
    const {
      orderBy,
      order,
      data,
      page,
      limit,
      totalCount,
      loading,
      message,
    } = list;
    const selectedColumn = orderBy;
    const selectedDirection = order === 'asc' ? 'ascending' : 'descending';
    if (loading) {
      return <Loader active inline="centered" />;
    }
    if (data == null) {
      return null;
    }
    const pageCount =
      parseInt(totalCount / limit, 10) + (totalCount % limit ? 1 : 0);
    const nextPage = page < pageCount;
    const prevPage = page > 1;
    const NewButton = this.props.newButton;
    const hasNewButton = !!NewButton;
    const colsLength = Object.keys(cols).length;

    return (
      <div>
        {message && (
          <Message negative>
            <Message.Header>
              We are sorry but something is broken
            </Message.Header>
            <p>{message}</p>
          </Message>
        )}
        <Table celled sortable>
          <Table.Header>
            <Table.Row>
              {cols &&
                cols.map(col => {
                  const sortable =
                    !fields[col].convertToValue ||
                    (fields[col].convertToValue && fields[col].orderBy);

                  // Prepare props for header
                  const headerProps = {
                    key: col,
                    onClick: () => {
                      sortable &&
                        this.handleSort(
                          fields[col].orderBy || col,
                          selectedDirection,
                        );
                    },
                  };
                  if (
                    (col === selectedColumn ||
                      selectedColumn === fields[col].orderBy) &&
                    sortable
                  ) {
                    headerProps.sorted = selectedDirection;
                  }

                  const Title =
                    typeof fields[col].title === 'string' ? (
                      <FormattedMessage
                        id={fields[col].title}
                        defaultMessage={fields[col].title}
                      />
                    ) : (
                      fields[col].title
                    );

                  return (
                    <Table.HeaderCell {...headerProps}>
                      {fields[col].title && !sortable && Title}
                      {fields[col].title && sortable && <span style={{    cursor: 'pointer', color: '#4183c4'}}>{Title}</span>}
                    </Table.HeaderCell>
                  );
                })}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data &&
              Object.keys(data).map(key => (
                <Table.Row key={key}>
                  {cols &&
                    cols.map(col => (
                      <Table.Cell key={col}>
                        {this.renderCell(key, col)}
                      </Table.Cell>
                    ))}
                </Table.Row>
              ))}
          </Table.Body>
          <Table.Footer fullWidth>
            <Table.Row>
              <Table.HeaderCell colSpan={colsLength}>
                <Menu pagination>
                  <Menu.Item
                    as={Button}
                    disabled={!prevPage}
                    icon
                    onClick={() => {
                      this.handlePageChange({
                        name: 'page',
                        value: page - 1,
                      });
                    }}
                  >
                    <Icon name="chevron left" />
                  </Menu.Item>
                  <Menu.Item
                    style={{
                      minWidth: '80px',
                    }}
                  >
                    {page}/{pageCount}
                  </Menu.Item>
                  <Menu.Item
                    as={Button}
                    disabled={!nextPage}
                    icon
                    onClick={() => {
                      this.handlePageChange({
                        name: 'page',
                        value: page + 1,
                      });
                    }}
                  >
                    <Icon name="chevron right" />
                  </Menu.Item>
                </Menu>
                <Dropdown
                  style={{
                    marginLeft: '10px',
                  }}
                  name="limit"
                  compact
                  selection={true}
                  onChange={(e, values) => this.handleLimitChange(e, values)}
                  options={[
                    {
                      text: '5',
                      value: 5,
                    },
                    {
                      text: '10',
                      value: 10,
                    },
                    {
                      text: '20',
                      value: 20,
                    },
                    {
                      text: '50',
                      value: 50,
                    },
                    {
                      text: '100',
                      value: 100,
                    },
                  ]}
                  value={limit}
                />
                <label
                  style={{
                    marginLeft: '10px',
                  }}
                >
                  Total: {totalCount}
                </label>
                {hasNewButton && NewButton}
                {csv && (
                  <Popup
                    trigger={
                      <Button
                        disabled={!totalCount}
                        secondary
                        size="small"
                        floated="right"
                        onClick={this.csv}
                      >
                        Export
                      </Button>
                    }
                    content={intl.formatHTMLMessage({
                      id: 'MORE_THAN_1000_WARNING',
                    })}
                  />
                )}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </div>
    );
  }

  render() {
    const Filter = this.props.filter
      ? React.cloneElement(this.props.filter, {
          onChange: this.props.onFilterChange,
          onFilter: this.props.onFilterSubmit,
        })
      : null;
    return (
      <div>
        {Filter}
        {this.renderTable()}
      </div>
    );
  }
}

export default connect(
  (state, props) => ({
    csvKeys: csv[props.resource].csvKeys,
    csvLabels: csv[props.resource].csvLabels,
  }),
  { listExport },
)(injectIntl(ResourceDataGrid));
