import React, { Component } from 'react';
import BasePassRequestByTagsOverview from './BasePassRequestByTagsOverview';

export default class PassDownloadRequestByTagsOverview extends Component {
  render() {
    return (
      <BasePassRequestByTagsOverview
        serviceType="download"
        description="Shows statistic eabout pass downloads by tags."
      />
    );
  }
}
