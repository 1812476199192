import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Grid, Icon } from 'semantic-ui-react';
import { Checkboard } from 'react-color/lib/components/common';
import FormGroupTimestamps from '../../common/FormGroupTimestamps';
import BackButton from '../../common/buttons/BackButton';
import FormFieldProject from '../../common/FormFieldProject';
import { FormattedMessage, injectIntl } from 'react-intl';
import messages from '../../../messagesDefine.js';
import DeleteButton from '../../common/buttons/DeleteButton';

class ImageForm extends Component {
  static propTypes = {
    data: PropTypes.object,
    itemSet: PropTypes.func.isRequired,
    itemSave: PropTypes.func.isRequired,
  };

  onChange(e) {
    const { itemSet, data } = this.props;
    data[e.target.name] = e.target.value;
    itemSet(data);
  }

  handleSave = e => {
    e.preventDefault();
    const { itemSave, data } = this.props;
    itemSave(data);
  };

  handleApply = e => {
    e.preventDefault();
    const { itemApply, data } = this.props;
    itemApply(data);
  };

  handleDelete = () => {
    //e.preventDefault();
    const { itemDelete } = this.props;
    itemDelete();
  };

  render() {
    const { data, intl, changed } = this.props;

    const previewImageStyle = {
      left: 0,
      right: 0,
      bottom: 0,
      top: 0,
      margin: 'auto',
      position: 'absolute',
      maxWidth: '320px',
      maxHeight: '320px',
      border: '1px dotted grey',
      zIndex: 1000,
    };
    const previewContainerStyle = {
      position: 'relative',
      width: '320px',
      height: '320px',
    };
    return (
      <Form>
        <Grid columns={2}>
          <Grid.Row>
            <Grid.Column>
              <Form.Input
                name="id"
                label={intl.formatMessage(messages.ID)}
                disabled={true}
                defaultValue={data.id}
                readOnly
              />
              <FormFieldProject projectId={data.projectId} />

              <Form.Input
                name="name"
                label={intl.formatMessage(messages.NAME)}
                onChange={(e, p) => this.onChange(e, p)}
                defaultValue={data.name}
              />
              <Form.Field>
                <label>Size</label>
                {data.width} x {data.height}
              </Form.Field>
              <FormGroupTimestamps data={data} />
            </Grid.Column>
            <Grid.Column>
              <div style={previewContainerStyle}>
                <img alt="preview" src={data.url} style={previewImageStyle} />
                <Checkboard />
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Button disabled={!changed} primary onClick={this.handleSave}>
              <Icon name="save" />
              <FormattedMessage id="BUTTON_SAVE" />
            </Button>
            <Button disabled={!changed} secondary onClick={this.handleApply}>
              <Icon name="save" />
              <FormattedMessage id="BUTTON_APPLY" />
            </Button>
            <BackButton />
            <DeleteButton onDelete={this.handleDelete} />
          </Grid.Row>
        </Grid>
      </Form>
    );
  }
}

export default injectIntl(ImageForm);
