import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ResourceDataGrid from '../../common/ResourceDataGrid';
import { Link } from 'react-router';
import { Button } from 'semantic-ui-react';
import { DISTRIBUTION_API_URL } from '../../../services/api';
import ResourceListPagedDecorator from '../../common/ResourceListPagedDecorator';
import { injectIntl } from 'react-intl';

let fields = {
  name: {
    title: 'Name',
    input: 'text',
    convertToValue: function(id, item) {
      return `${item.product.name}`;
    },
  },
  createdAt: {
    title: 'Created',
    type: 'date',
  },
  itemNumber: {
    title: 'ITEM_NUMBER',
    type: 'text',
  },
  passActions: {
    title: 'PASS',
    convertToValue: function(id, item) {
      return <PassActions {...this.props} item={item} />;
    },
  },
  orderItemActions: {
    title: 'ORDER_ITEM',
    convertToValue: function(id, item) {
      return <OrderItemActions item={item} />;
    },
  },
};

class _OrderItemActions extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
  };

  render() {
    const { item, viewer } = this.props;
    const { accessRights, isAdmin } = viewer.data;
    const canRead =
      isAdmin ||
      (item &&
        accessRights[item.projectId] &&
        accessRights[item.projectId].PASS_READ);
    const canUpdate =
      isAdmin ||
      (item &&
        accessRights[item.projectId] &&
        accessRights[item.projectId].PASS_UPDATE);

    return (
      <div>
        <Button.Group widths="2">
          <Button
            as={Link}
            href={`/ticket/ticketOrderItem/${item.id}`}
            icon="edit"
            disabled={!(canRead || canUpdate)}
          />
        </Button.Group>
      </div>
    );
  }
}

const OrderItemActions = connect(
  (state, props) => ({
    viewer: state.viewer,
  }),
  {},
)(_OrderItemActions);

class _PassActions extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
  };

  render() {
    const { item, viewer } = this.props;
    const { accessRights, isAdmin } = viewer.data;
    const canRead =
      isAdmin ||
      (item &&
        accessRights[item.projectId] &&
        accessRights[item.projectId].PASS_READ);
    const canUpdate =
      isAdmin ||
      (item &&
        accessRights[item.projectId] &&
        accessRights[item.projectId].PASS_UPDATE);

    return (
      <div>
        {item.passId && (
          <Button.Group widths="3">
            <Button
              as="a"
              href={DISTRIBUTION_API_URL + '/' + item.passId + '?tag=console'}
              icon="unhide"
              target="_blank"
            />
            <Button
              as="a"
              href={
                DISTRIBUTION_API_URL +
                '/' +
                item.passId +
                '?format=pdf&tag=console'
              }
              icon="print"
              target="_blank"
            />
            <Button
              as={Link}
              href={`/passes/pass/${item.passId}`}
              icon="edit"
              disabled={!(canRead || canUpdate)}
            />
          </Button.Group>
        )}
      </div>
    );
  }
}

const PassActions = connect(
  (state, props) => ({
    viewer: state.viewer,
  }),
  {},
)(_PassActions);

class OrderEdit extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
  };

  render() {
    const { data } = this.props;
    const filterProps = [
      {
        name: 'orderId',
        type: 'eq',
        columnName: 'orderId',
      },
    ];
    const filter = {
      orderId: { value: data.id },
    };
    return (
      <ResourceListPagedDecorator
        filterProps={filterProps}
        filter={filter}
        resource={'ticketOrderItem'}
        component={ResourceDataGrid}
        componentProps={{
          resource: 'ticketOrderItem',
          fields: fields,
          cols: [
            'itemNumber',
            'name',
            'createdAt',
            'passActions',
            'orderItemActions',
          ],
          csv: true,
        }}
      />
    );
  }
}

export default injectIntl(OrderEdit);
