import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Button, Icon } from 'semantic-ui-react';
import JsonSchemaForm from '../../common/JsonSchemaForm';
import Edit from '../../common/Edit';
import RegisterItemDecorator from '../../common/RegisterItemDecorator';
import FormSelectTemplate from '../../common/FormDropdownTemplate';
import BackButton from '../../common/buttons/BackButton';

class PassNewForm extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    formData: PropTypes.object.isRequired,
  };

  handleJsonChange = data => {
    this.props.itemSet(data);
  };

  handleDynamicDataChange = dynamicData => {
    const { itemSet, data } = this.props;
    data.dynamicData = dynamicData;
    itemSet(data);
  };

  handleSubmit = e => {
    this.props.itemCreate(this.props.data);
  };

  render() {
    const { data, template } = this.props;
    let schema;
    const uiSchema = {};
    schema = template.dynamicDataJsonSchema || {};
    schema.type = 'object';
    if (!schema.properties) {
      schema.properties = {};
    }

    /*
    */
    return (
      <div>
        <JsonSchemaForm
          schema={schema}
          onChange={this.handleDynamicDataChange}
          formData={data.dynamicData}
          uiSchema={uiSchema}
        />

        <Button primary type="submit" onClick={this.handleSubmit}>
          <Icon name="save" />Save
        </Button>
        <BackButton />
      </div>
    );
  }
}

class PassNewWithDecorator extends Component {
  render() {
    return (
      <Edit
        resource={'pass'}
        resourceId={'new'}
        defaultValue={{ templateId: this.props.data.id, dynamicData: {} }}
        component={PassNewForm}
        componentProps={{ template: this.props.data }}
      />
    );
  }
}

export default class PassNewTemplateSelect extends Component {
  componentWillMount() {
    this.setState({
      templateId: '',
    });
  }

  handleTemplateChange = (e, p) => {
    this.setState({
      templateId: p.value,
    });
  };

  render() {
    return (
      <div>
        <Form key={0}>
          <FormSelectTemplate
            onChange={this.handleTemplateChange}
            defaultValue={this.state.templateId}
          />
        </Form>
        {this.state.templateId && (
          <RegisterItemDecorator
            resource={'template'}
            resourceId={this.state.templateId}
            component={PassNewWithDecorator}
          />
        )}
        {!this.state.templateId && <BackButton />}
      </div>
    );
  }
}
