import React, { Component } from 'react';
import { FormattedDate, FormattedTime, IntlProvider } from 'react-intl';
import PropTypes from 'prop-types';

export default class FieldPrevieValuewDate extends Component {
  static propTypes = {
    value: PropTypes.string.isRequired,
    dateStyle: PropTypes.string.isRequired,
    timeStyle: PropTypes.string.isRequired,
    previewLanguage: PropTypes.string.isRequired,
  };

  getTimeOptions = style => {
    let opts = {};
    opts.hour = '2-digit';
    opts.minute = '2-digit';
    opts.hour12 = false;
    switch (style) {
      case 'PKDateStyleShort':
        break;
      case 'PKDateStyleMedium':
        opts.second = '2-digit';
        break;
      case 'PKDateStyleLong':
        opts.second = '2-digit';
        opts.timeZoneName = 'short';
        break;
      case 'PKDateStyleFull':
        opts.second = '2-digit';
        opts.timeZoneName = 'long';
        break;
      default:
        break;
    }
    return opts;
  };

  getDateOptions = style => {
    let opts = {};
    opts.year = 'numeric';
    switch (style) {
      case 'PKDateStyleShort':
        opts.day = '2-digit';
        opts.month = '2-digit';
        break;
      case 'PKDateStyleMedium':
        opts.day = 'numeric';
        opts.month = 'short';
        break;
      case 'PKDateStyleLong':
        opts.day = 'numeric';
        opts.month = 'long';
        break;
      case 'PKDateStyleFull':
        opts.day = 'numeric';
        opts.month = 'long';
        opts.weekday = 'long';
        break;
      default:
        break;
    }
    return opts;
  };

  render() {
    const { value, dateStyle, timeStyle, previewLanguage } = this.props;
    const isDate = dateStyle !== 'PKDateStyleNone';
    const isTime = timeStyle !== 'PKDateStyleNone';
    let content = <span />;

    if (isDate && !isTime) {
      content = (
        <FormattedDate
          value={new Date(value)}
          {...this.getDateOptions(dateStyle)}
        />
      );
    }
    if (!isDate && isTime) {
      content = (
        <FormattedTime
          value={new Date(value)}
          {...this.getTimeOptions(timeStyle)}
        />
      );
    }
    if (isDate && isTime) {
      content = (
        <span>
          <FormattedDate
            value={new Date(value)}
            {...this.getDateOptions(dateStyle)}
          />{' '}
          <FormattedTime
            value={new Date(value)}
            {...this.getTimeOptions(timeStyle)}
          />
        </span>
      );
    }

    return <IntlProvider locale={previewLanguage}>{content}</IntlProvider>;
  }
}
