import { combineReducers } from 'redux';

import viewer from './viewer';
import ui from './ui';
import config from './config';
import report from './report';
import filter from './filter';
import resource from './resource';
import resourceListComplete from './resourceListComplete';
import resourceListPaged from './resourceListPaged';
import intl from './intl';
import notification from './notification';
import navigation from './navigation';
import ticket from './ticket';
import token from './token';
import register from './register';
import { routerReducer } from 'react-router-redux';

export default combineReducers({
  config,
  ui,
  intl,
  report,
  viewer,
  register,
  resource,
  resourceListComplete,
  resourceListPaged,
  filter,
  notification,
  navigation,
  ticket,
  token,
  routing: routerReducer,
});
