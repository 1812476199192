import { SET_TOKEN, UNSET_TOKEN } from '../actions/token';
import _ from 'lodash';

export default function(state = {}, action) {
  switch (action.type) {
    case SET_TOKEN:
      state = action.token;
      return _.merge({}, state);
    case UNSET_TOKEN:
      return {};
    default:
      return state;
  }
}
