import React, { Component } from 'react';
import { Router, Route, IndexRoute } from 'react-router';
import Layout from './Layout';
import Home from '../components/Home';
import Login from '../components/Login';
import User from '../components/user/User';
import CertificateList from '../components/core/certificate/CertificateList';
import CertificateEdit from '../components/core/certificate/CertificateEdit';
import CertificateCreate from '../components/core/certificate/CertificateCreate';
import PassList from '../components/core/pass/PassList';
import PassEdit from '../components/core/pass/PassEdit';
import PassNew from '../components/core/pass/PassNew';
import TemplateList from '../components/core/template/TemplateList';
import TemplateEdit from '../components/core/template/TemplateEdit';
import ProjectList from '../components/idm/project/ProjectList';
import ProjectEdit from '../components/idm/project/ProjectEdit';
import TicketAccounting from '../components/ticket/accounting/Accounting';
import TicketEshopList from '../components/ticket/eshop/List';
import TicketEshopEdit from '../components/ticket/eshop/Edit';
import TicketEshopNew from '../components/ticket/eshop/New';
import TicketProductList from '../components/ticket/product/List';
import TicketProductEdit from '../components/ticket/product/Edit';
import TicketProductNew from '../components/ticket/product/New';
import TicketOrderList from '../components/ticket/order/List';
import TicketOrderEdit from '../components/ticket/order/Edit';
import TicketOrderItemList from '../components/ticket/orderItem/List';
import TicketOrderItemEdit from '../components/ticket/orderItem/Edit';
import UserList from '../components/idm/user/UserList';
import UserEdit from '../components/idm/user/UserEdit';
import ImageList from '../components/core/image/ImageList';
import ImageEdit from '../components/core/image/ImageEdit';
import PassInWalletOverview from '../components/report/PassInWalletOverview';
import PassAtLeastOnceRegisterInWalletOverview from '../components/report/PassAtLeastOnceRegisterInWalletOverview';
import PassPreviewRequestByTagsOverview from '../components/report/PassPreviewRequestByTagsOverview';
import PassDownloadRequestByTagsOverview from '../components/report/PassDownloadRequestByTagsOverview';
import ActiveCardsOverview from '../components/report/ActiveCardsOverview';
import Links from '../components/report/Links';
import { connect } from 'react-redux';
import About from '../components/About';
import ApiEndpoints from '../components/ApiEndpoints';
import ErrorPage from '../components/ErrorPage';
import { IntlProvider } from 'react-intl';

const routes = (
  <Route path="/" component={Layout}>
    <IndexRoute component={Home} />;
    <Route path="user/detail" component={User} />
    <Route path="about" component={About} />
    <Route path="api/endpoints" component={ApiEndpoints} />
    <Route path="error/:statusCode" component={ErrorPage} />
    <Route path="passes/pass" component={PassList} />
    <Route path="passes/pass/new" component={PassNew} />
    <Route path="passes/pass/:id" component={PassEdit} />
    <Route path="identity/project" component={ProjectList} />
    <Route path="identity/project/:id" component={ProjectEdit} />
    <Route path="identity/user" component={UserList} />
    <Route path="identity/user/:id" component={UserEdit} />
    <Route path="passes/image" component={ImageList} />
    <Route path="passes/image/:id" component={ImageEdit} />
    <Route path="passes/template" component={TemplateList} />
    <Route path="passes/template/:id" component={TemplateEdit} />
    <Route
      path="report/passPreviewRequestByTagsOverview"
      component={PassPreviewRequestByTagsOverview}
    />
    <Route
      path="report/passDownloadRequestByTagsOverview"
      component={PassDownloadRequestByTagsOverview}
    />
    <Route
      path="report/passInWalletOverview"
      component={PassInWalletOverview}
    />
    <Route
      path="report/passAtLeastOnceRegisterInWalletOverview"
      component={PassAtLeastOnceRegisterInWalletOverview}
    />
    <Route path="report/activeCardsOverview" component={ActiveCardsOverview} />
    <Route path="report/links" component={Links} />
    <Route path="passes/certificate" component={CertificateList} />
    <Route path="passes/certificate/new" component={CertificateCreate} />
    <Route path="passes/certificate/:id" component={CertificateEdit} />
    <Route path="ticket/ticketAccounting" component={TicketAccounting} />
    <Route path="ticket/ticketEshop" component={TicketEshopList} />
    <Route path="ticket/ticketEshop/new" component={TicketEshopNew} />
    <Route path="ticket/ticketEshop/:id" component={TicketEshopEdit} />
    <Route path="ticket/ticketProduct" component={TicketProductList} />
    <Route path="ticket/ticketProduct/new" component={TicketProductNew} />
    <Route path="ticket/ticketProduct/:id" component={TicketProductEdit} />
    <Route path="ticket/ticketOrder" component={TicketOrderList} />
    <Route path="ticket/ticketOrder/:id" component={TicketOrderEdit} />
    <Route path="ticket/ticketOrderItem" component={TicketOrderItemList} />
    <Route path="ticket/ticketOrderItem/:id" component={TicketOrderItemEdit} />
  </Route>
);

class Main extends Component {
  render() {
    const locale = this.props.intl.currentLocale;
    //const locale = "cs";
    const messages = this.props.intl.messages[this.props.intl.currentLocale];

    return (
      <IntlProvider locale={locale} messages={messages}>
        <Router history={this.props.history}>
          <Route path="/login" component={Login} />
          {routes}
        </Router>
      </IntlProvider>
    );
  }
}

export default connect((state, props) => ({ intl: state.intl }), {})(Main);
