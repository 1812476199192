import { delay } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { setToken } from '../actions/token';
import { login } from '../actions/viewer';
import client from '../services/api';

export function* tokenRefresh(action) {
  try {
    if (action && action.token && action.token.expiresIn) {
      yield call(delay, (action.token.expiresIn - 30) * 1000);
    }
    let newToken = yield call(client, 'POST', '/auth/refresh', {});
    yield put(setToken(newToken));
  } catch (e) {
    yield put(login());
  }
}
