import {
  TICKET_ESHOP_VERSIONS_FAILURE,
  TICKET_ESHOP_VERSIONS_LOADING,
  TICKET_ESHOP_VERSIONS_SUCCESS,
  TICKET_ESHOP_VERSION_MESSAGES_FAILURE,
  TICKET_ESHOP_VERSION_MESSAGES_SUCCESS,
  TICKET_ESHOP_VERSION_MESSAGES_LOADING,
} from '../actions/ticket';

import { Record } from 'immutable';

const InitialState = Record({
  eshopVersions: [],
  eshopVersionLoading: false,
  eshopVersionError: null,

  eshopMessages: {},
  eshopMessagesLoading: false,
  eshopMessagesError: null,
});

export default function viewer(state = new InitialState(), action) {
  switch (action.type) {
    case TICKET_ESHOP_VERSIONS_LOADING:
      return state
        .set('eshopVersionLoading', true)
        .set('eshopVersionError', null);
    case TICKET_ESHOP_VERSIONS_SUCCESS:
      return state
        .set('eshopVersionLoading', false)
        .set('eshopVersions', action.data);
    case TICKET_ESHOP_VERSIONS_FAILURE:
      return state
        .set('eshopVersionLoading', false)
        .set('eshopVersionError', action.error);

    case TICKET_ESHOP_VERSION_MESSAGES_LOADING:
      return state
        .set('eshopMessagesLoading', true)
        .set('eshopMessagesError', null);
    case TICKET_ESHOP_VERSION_MESSAGES_SUCCESS:
      const eshopMessages = state.get('eshopMessages');
      eshopMessages[action.version] = action.data;
      return state
        .set('eshopMessagesLoading', false)
        .set('eshopMessages', eshopMessages);
    case TICKET_ESHOP_VERSION_MESSAGES_FAILURE:
      return state
        .set('eshopMessagesLoading', false)
        .set('eshopMessagesError', action.error);

    default:
      return state;
  }
}
