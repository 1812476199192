import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Button, Icon } from 'semantic-ui-react';
import FormReadOnly from '../../common/FormReadOnly';
import FormTextAreaJson from '../../common/FormTextAreaJSON';
import { connect } from 'react-redux';
import BackButton from '../../common/buttons/BackButton';
import { injectIntl } from 'react-intl';
import FormTab from '../../common/FormTab';
import { FormattedMessage } from 'react-intl';

const FORM_NAME = 'ticketOrderItem';

class OrderItemForm extends Component {
  static propTypes = {
    data: PropTypes.object,
    intl: PropTypes.object.isRequired,
    itemSet: PropTypes.func.isRequired,
    itemSetPropertyByPath: PropTypes.func.isRequired,
    itemSave: PropTypes.func.isRequired,
    itemCreate: PropTypes.func.isRequired,
  };

  handleChange = (e, { name, value }) => {
    const { itemSetPropertyByPath, data } = this.props;
    itemSetPropertyByPath(name, value, data);
  };

  handleSave = e => {
    e.preventDefault();
    const { itemSave, data } = this.props;
    itemSave(data);
  };

  handleApply = e => {
    e.preventDefault();
    const { itemApply, data } = this.props;
    itemApply(data);
  };

  handleChangeJSON = json => {
    this.props.itemSet(json);
  };

  render() {
    let { data, changed, intl, viewer } = this.props;

    const options = [];

    for (let i = 0; i <= data.product.maxEntries; i++) {
      options.push({
        text: `${i}`,
        value: i,
      });
    }

    const tabOptions = {
      used: {
        name: intl.formatMessage({ id: 'TICKETORDERITEM_ENTRY_COUNT' }),
        icon: 'check',
        content: (
          <Form.Group widths="equal">
            <Form.Dropdown
              onChange={this.handleChange}
              name="entryCount"
              fluid
              selection
              value={data.entryCount}
              options={options}
              label={`${intl.formatHTMLMessage({
                id: 'TICKETORDERITEM_ENTRY_COUNT',
              })}`}
            />
            <Form.Input
              label={`${intl.formatHTMLMessage({
                id: 'TICKETORDERITEM_MAX_ENTRIES',
              })}`}
              value={data.product.maxEntries}
              disabled
            />
          </Form.Group>
        ),
      },
      form: {
        name: intl.formatMessage({ id: 'TICKETORDERITEM_EDIT' }),
        icon: 'edit',
        content: <FormReadOnly data={data} />,
      },
    };

    if (viewer.isAdmin) {
      tabOptions.json = {
        icon: 'file text',
        name: 'Json',
        content: (
          <FormTextAreaJson
            onChange={this.handleChangeJSON}
            label="JSON"
            name="json"
            defaultValue={data}
          />
        ),
      };
    }

    return (
      <Form>
        <FormTab
          tabOptions={tabOptions}
          formName={FORM_NAME}
          defaultTab="used"
        />
        <Button disabled={!changed} primary onClick={this.handleSave}>
          <Icon name="save" />
          <FormattedMessage id="BUTTON_SAVE" />
        </Button>
        <Button disabled={!changed} secondary onClick={this.handleApply}>
          <Icon name="save" />
          <FormattedMessage id="BUTTON_APPLY" />
        </Button>
        <BackButton />
      </Form>
    );
  }
}

// This removes warnings for "undefined" react props
export default connect((state, props) => ({ viewer: state.viewer.data }), {})(
  injectIntl(OrderItemForm),
);
