export default {
  PROJECT_ACCESS: 0,
  PASS_CREATE: 1,
  PASS_READ: 2,
  PASS_UPDATE: 4,
  PASS_DELETE: 8,
  TEMPLATE_CREATE: 16,
  TEMPLATE_READ: 32,
  TEMPLATE_UPDATE: 64,
  TEMPLATE_DELETE: 128,
  TICKET_ACCOUNTING: 256,
  TICKET_ENTRY: 512,
  TICKET_ESHOP_READ: 1024,
  IMAGE_MANAGE: 2048,
  STAMP_MANAGE: 4096,
  USER_UPDATE: 8192,
  PROJECT_CREATE: 16384,
  PROJECT_UPDATE: 32768,
  TICKET_PRODUCT_MANAGE: 65536,
};
