import React, { Component } from 'react';
import { Form, Dropdown } from 'semantic-ui-react';

const options = [
  { key: 'days', value: 'days', text: 'Days' },
  { key: 'interval', value: 'interval', text: 'Interval' },
];

export default class FormDropdownPeriod extends Component {
  render() {
    return (
      <Form.Field>
        <label>Type</label>
        <Dropdown
          minCharacters={0}
          name={this.props.name}
          selection={true}
          search={true}
          multiple={false}
          options={options}
          value={this.props.value}
          onChange={this.props.onChange}
        />
      </Form.Field>
    );
  }
}
