import React, { Component } from 'react';
import { Form } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import InputDateTime from './InputDateTime';

export default class FormInputDateTime extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  };

  render() {
    return (
      <Form.Field>
        <label>{this.props.label}</label>
        <InputDateTime {...this.props} />
      </Form.Field>
    );
  }
}
