import hljs from 'highlight.js/lib/highlight';
import json from 'highlight.js/lib/languages/json';
import React from 'react';
import ReactDOM from 'react-dom';
import DOM from 'react-dom-factories';

hljs.registerLanguage('json', json);

// TODO use https://github.com/akiran/react-highlight if will be ready for react-16

class Highlight extends React.Component {
  componentDidMount() {
    this.highlightCode();
  }

  componentDidUpdate() {
    this.highlightCode();
  }

  highlightCode() {
    const domNode = ReactDOM.findDOMNode(this);
    const nodes = domNode.querySelectorAll('pre code');

    let i;
    for (i = 0; i < nodes.length; i++) {
      hljs.highlightBlock(nodes[i]);
    }
  }

  render() {
    const { children, className, element, innerHTML } = this.props;
    let Element = element ? DOM[element] : null;

    if (innerHTML) {
      if (!Element) {
        Element = DOM.div;
      }

      return Element(
        {
          dangerouslySetInnerHTML: { __html: children },
          className: className || null,
        },
        null,
      );
    } else {
      if (Element) {
        return Element({ className }, children);
      } else {
        return (
          <pre>
            <code className={className}>{children}</code>
          </pre>
        );
      }
    }
  }
}

Highlight.defaultProps = {
  innerHTML: false,
  className: null,
  element: null,
};

export default Highlight;
