import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { uiSetTemplatePreviewLanguage } from '../../../../../actions/ui';
import Image from '../../../../common/image/Image';

class TemplateImage extends Component {
  static propTypes = {
    itemSet: PropTypes.func.isRequired,
    uiSetTemplatePreviewLanguage: PropTypes.func.isRequired,
    previewLanguage: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired,
  };

  setImage = (key, value) => {
    const { itemSet, data } = this.props;
    itemSet(data.setImage(key, value));
  };

  onLanguageChange = (e, { value }) => {
    const { uiSetTemplatePreviewLanguage } = this.props;
    uiSetTemplatePreviewLanguage(value);
  };

  render() {
    const { data, previewLanguage } = this.props;
    const languages = data.get('languages');
    const images = data.get('images');
    const projectId = data.get('projectId');
    const passStyle = data.getPassStyle();
    return (
      <Image
        passStyle={passStyle}
        onChange={this.setImage}
        style={{ cursor: 'pointer' }}
        images={images}
        type={this.props.type}
        projectId={projectId}
        languages={languages}
        previewLanguage={previewLanguage}
        onChangeLanguage={this.onLanguageChange}
      />
    );
  }
}

export default connect((state, props) => ({}), {
  uiSetTemplatePreviewLanguage,
})(injectIntl(TemplateImage));
