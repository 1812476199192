import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Template from '../../../../../../../../models/template/index';
import { Icon } from 'semantic-ui-react';
import FieldPreviewBase from './FieldPreviewBase';

class FieldPreviewLabel extends FieldPreviewBase {
  static propTypes = {
    ...FieldPreviewBase.propTypes,
    data: PropTypes.instanceOf(Template).isRequired,
  };

  render() {
    const { data } = this.props;
    const labelColor = data.getLabelColor();

    return (
      <div
        style={
          this.isBackfield()
            ? {}
            : {
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }
        }
      >
        <label
          style={
            this.isBackfield()
              ? {
                  fontWeight: 'bold',
                  cursor: 'pointer',
                  color: 'black',
                  display: 'block',
                  whiteSpace: 'pre',
                }
              : {
                  fontWeight: 'normal',
                  cursor: 'pointer',
                  color: labelColor,
                  display: 'block',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  height: '1.3em',
                  textTransform: 'uppercase',
                  fontSize: '10px',
                }
          }
        >
          {this.isLocalizedLabel() && <Icon name="globe" />}
          {this.getLabelValue() || <span>&nbsp;</span>}
        </label>
      </div>
    );
  }
}

export default connect(
  state => ({
    dragAndDrop: state.ui.dragAndDrop,
  }),
  {},
)(FieldPreviewLabel);
