import React from 'react';
import { ColorProperty } from '../../../../models/property';
import PropTypes from 'prop-types';
import Template from '../../../../models/template';
import Errors from '../../../../models/Errors';
import ColorInput from '../../../common/ColorInput';
import BaseInput from './BaseInput';
import { FormattedHTMLMessage } from 'react-intl';

export default class ColorPropertyInput extends BaseInput {
  static propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    data: PropTypes.instanceOf(Template).isRequired,
    errors: PropTypes.instanceOf(Errors).isRequired,
    path: PropTypes.array.isRequired,
    itemSet: PropTypes.func.isRequired,
  };

  handleChange = (date, d) => {
    const { data, path, itemSet } = this.props;

    let v = new ColorProperty({
      value: d.value,
      fallback: this.getFallback(),
    });

    // Reset fallback if is not placeholder
    if (!v.isPlaceholder('color')) {
      v = v.deleteIn(['fallback']);
    }

    itemSet(data.setIn(path, v));
  };

  handleDateFallbackChange = (e, p) => {
    const { data, path, itemSet } = this.props;
    const color = this.getValue();
    itemSet(
      data.setIn(
        path,
        new ColorProperty({
          value: color,
          fallback: p.value ? p.value : undefined,
        }),
      ),
    );
  };

  render() {
    const { label, errors } = this.props;
    const { data, path } = this.props;
    let value = data.getIn(path);
    const isPlaceholder = value ? value.isPlaceholder('color') : false;

    let valueError = null;

    let basePath = path.concat([]);
    basePath.pop();

    if (
      (errors.hasErrors(path.concat('value')) && !isPlaceholder) ||
      errors.hasMissingPropertyOnPath(basePath, path[path.length - 1]) ||
      errors.hasMissingPropertyOnPath(path, 'value')
    ) {
      valueError = (
        <FormattedHTMLMessage
          id={'COLOR_INPUT_VALUE_ERROR'}
          values={{ examplePlaceholder: '#{color}' }}
        />
      );
    }

    const out = [
      <ColorInput
        key={`${this.pathToString()}.value`}
        name={'value'}
        label={label}
        error={!!valueError}
        value={this.getValue()}
        onChange={this.handleChange}
      />,
    ];

    if (valueError) {
      out.push(
        this.renderErrorLabel(valueError, `${this.pathToString()}.value.error`),
      );
    }

    if (isPlaceholder) {
      let fallbackError = null;

      if (errors.hasErrors(path.concat('fallback'))) {
        fallbackError = (
          <FormattedHTMLMessage id={'COLOR_INPUT_FALLBACK_ERROR'} />
        );
      } else if (!this.getFallback()) {
        fallbackError = (
          <FormattedHTMLMessage id={'COLOR_INPUT_FALLBACK_MISSING'} />
        );
      }

      out.push(
        <ColorInput
          key={`${this.pathToString()}.fallback`}
          name={'fallback'}
          label={this.getFallbackLabel()}
          error={!!fallbackError}
          onChange={this.handleDateFallbackChange}
          value={this.getFallback()}
        />,
      );

      if (fallbackError) {
        out.push(
          this.renderErrorLabel(
            fallbackError,
            `${this.pathToString()}.fallback.error`,
          ),
        );
      }
    }

    return out;
  }
}
