import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table, Loader, Grid } from 'semantic-ui-react';
import { connect } from 'react-redux';
import Filter from '../common/filter/Filter';
import { inQueryBuilder } from '../../services/api';
import { fetchReport } from '../../actions/index';
import { injectIntl } from 'react-intl';
import { FormattedHTMLMessage } from 'react-intl';
import LabelProject from '../common/LabelProject';
import NoData from './NoData';

const serviceType = 'activity';

class ActiveCardsOverview extends Component {
  static propTypes = {
    filter: PropTypes.object.isRequired,
  };

  componentWillMount() {
    this.setState({ data: [], loading: false });
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    const { filter, fetchReport } = this.props;
    let where = {};
    if (filter.project && filter.project.value) {
      where = inQueryBuilder(where, filter.project.value, 'projectId');
    }

    const query = {
      from: filter.from.value,
      to: filter.to.value,
      where: where,
    };
    fetchReport(serviceType, query);
  };

  render() {
    const { report } = this.props;
    if (!report || report.loading) {
      return <Loader active inline="centered" />;
    }

    return (
      <div>
        <p>
          <FormattedHTMLMessage id="ACTIVE_CARDS_REPORT_DESCRIPTION" />
        </p>
        <Filter
          project={true}
          from={true}
          to={true}
          onFilter={this.fetchData}
        />
        <Grid>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <div>
                {!report.data && <NoData />}
                {report.data && (
                  <Table celled>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>
                          <FormattedHTMLMessage id="PROJECT" />
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          <FormattedHTMLMessage id="NUMBER_OF_ACTIVE_CARDS" />
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          <FormattedHTMLMessage id="NUMBER_OF_CARD_REGISTRATIONS" />
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          <FormattedHTMLMessage id="NUMBER_OF_CARD_UPDATES" />
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {report.data.map((item, i) => (
                        <Table.Row key={i}>
                          <Table.Cell>
                            <LabelProject id={item.projectId} />
                          </Table.Cell>
                          <Table.Cell>{item.active}</Table.Cell>
                          <Table.Cell>{item.registrations}</Table.Cell>
                          <Table.Cell>{item.updates}</Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                )}
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

export default injectIntl(
  connect(
    (state, props) => ({
      filter: state.filter,
      report: state.report[serviceType],
    }),
    { fetchReport },
  )(ActiveCardsOverview),
);
