import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Table, Label } from 'semantic-ui-react/dist/commonjs';
import client, { CORE_API_URL } from '../../../services/api';
import ProjectUserRightsRow from './ProjectUserRightsRow';
import Edit from '../../common/Edit';
import { addNotification } from '../../../actions/notification';
import { AUTO_DISMISS } from '../../../constants/toast';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';

class ProjectUserRights extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    addNotification: PropTypes.func.isRequired,
  };

  refreshUsers = users => {
    const { itemSet, data } = this.props;
    data.users = users;
    itemSet(data);
  };

  componentWillMount() {
    this.setState({
      email: null,
      error: null,
      loading: false,
    });
  }

  setUser = (e, d) => {
    this.setState({
      email: d.value,
      error: null,
    });
  };

  showError = e => {
    this.props.addNotification({
      title: `Error ${e.status}`,
      message: e.message,
      level: 'error',
      autoDismiss: AUTO_DISMISS,
    });
  };

  // TODO - may be moved in to redux(-saga)
  addUser = e => {
    this.setState({ loading: true });
    client('GET', `${CORE_API_URL}/v1/user/byEmail/${this.state.email}`)
      .then(json => {
        this.setState({ loading: false, email: null });
        this.saveRights(this.props.data.id, json.id, 0);
      })
      .catch(e => {
        if (e.status === 404) {
          return this.setState({ error: e.status, loading: false });
        }
        this.showError(e);
      });
  };

  // TODO - may be moved in to redux(-saga)
  saveRights = (projectId, userId, accessRights) => {
    const payload = { accessRights: accessRights };
    return client(
      'POST',
      `${CORE_API_URL}/v1/project/${projectId}/user/${userId}/rights`,
      payload,
    )
      .then(json => {
        const users = this.props.data.users || {};
        if (json.accessRights === null) {
          delete users[userId];
        } else {
          users[userId] = json.accessRights;
        }
        this.refreshUsers(users);
      })
      .catch(e => {
        this.showError(e);
      });
  };

  render() {
    const { data } = this.props;
    return (
      <div>
        <h2>
          <FormattedMessage id="PROJECT_USER_RIGHTS_HEADER" />
        </h2>
        <Form loading={this.state.loading}>
          <Form.Input
            error={this.state.error === 404}
            name="email"
            label={
              <FormattedMessage
                id="ADD_USER_RIGHTS_LABEL"
                values={{
                  email: this.state.email,
                }}
              />
            }
            placeholder={this.props.intl.formatMessage({
              id: 'ADD_USER_RIGHTS_PLACEHOLDER',
            })}
            onChange={this.setUser}
            value={this.state.email || ''}
            action={{
              icon: 'plus',
              primary: true,
              onClick: this.addUser,
            }}
          />
          {this.state.error === 404 && (
            <Label
              basic
              color="red"
              pointing={'above'}
              style={{ marginTop: 0, marginBottom: '1em' }}
            >
              <FormattedMessage
                id="USER_NOT_FOUND"
                values={{
                  email: this.state.email,
                }}
              />
            </Label>
          )}
        </Form>
        {data &&
          data.users && (
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>
                    <FormattedMessage id="USER" />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <FormattedMessage id="RIGHTS" />
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {Object.keys(data.users).map(i => {
                  return (
                    <ProjectUserRightsRow
                      key={i}
                      projectId={data.id}
                      accessRights={data.users[i]}
                      userId={i}
                      saveRights={this.saveRights}
                    />
                  );
                })}
              </Table.Body>
            </Table>
          )}
      </div>
    );
  }
}

const ProjectUserRightsWithNotification = injectIntl(
  connect((state, props) => ({}), {
    addNotification,
  })(ProjectUserRights),
);

export default class PUR extends Component {
  render() {
    return (
      <Edit
        resource={'project'}
        resourceId={this.props.projectId}
        component={ProjectUserRightsWithNotification}
      />
    );
  }
}
