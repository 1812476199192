import shortid from 'shortid';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';

export function addNotification(notification) {
  return {
    type: ADD_NOTIFICATION,
    notification,
    id: shortid.generate(),
  };
}
export function removeNotification(id) {
  return {
    type: REMOVE_NOTIFICATION,
    id,
  };
}
