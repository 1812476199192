import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { filterSet } from '../../../actions/index';

const intervalOptions = [
  {
    text: 'Daily',
    value: 'daily',
  },
  {
    text: 'Weekly',
    value: 'weekly',
  },
  {
    text: 'Monthly',
    value: 'monthly',
  },
];

class FilterInterval extends Component {
  static propTypes = {
    value: PropTypes.string.isRequired,
    filterSet: PropTypes.func.isRequired,
    onChange: PropTypes.func,
  };

  handleChange = (e, p) => {
    this.props.filterSet({ name: p.name, value: p.value });
    if (this.props.onChange) {
      this.props.onChange(e, {
        name: p.name,
        value: p.value,
      });
    }
  };

  render() {
    return (
      <Form.Select
        name={this.props.name}
        label="Interval"
        options={intervalOptions}
        value={this.props.value}
        onChange={this.handleChange}
      />
    );
  }
}

export default connect(
  (state, props) => ({
    value: state.filter[props.name]
      ? state.filter[props.name].value
      : intervalOptions[0].value,
  }),
  { filterSet },
)(FilterInterval);
