import React from 'react';
import { Input } from 'semantic-ui-react';
import { NumberProperty, numberRegexp } from '../../../../models/property';
import { Form } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import Template from '../../../../models/template';
import Errors from '../../../../models/Errors';
import BaseInput from './BaseInput';
import { FormattedHTMLMessage } from 'react-intl';

export default class NumberInput extends BaseInput {
  static propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    readOnly: PropTypes.bool,
    data: PropTypes.instanceOf(Template).isRequired,
    errors: PropTypes.instanceOf(Errors).isRequired,
    path: PropTypes.array.isRequired,
    itemSet: PropTypes.func.isRequired,
  };

  handleChange = (e, p) => {
    const { itemSet, data, path } = this.props;
    let value = data.getIn(path);

    if (p.value !== '') {
      let allowedChars = '' + p.value.replace(/,/g, '.');
      const isNumber = !!allowedChars.match(numberRegexp);
      let fallback = value && !isNumber ? value.get('fallback') : undefined;

      if (isNumber && allowedChars.slice(-1) !== '.') {
        allowedChars = Number(allowedChars);
      }
      itemSet(
        data.setIn(path, new NumberProperty({ value: allowedChars, fallback })),
      );
    } else {
      itemSet(data.deleteIn(path));
    }
  };

  handleFallbackChange = (e, p) => {
    const { itemSet, data, path } = this.props;

    if (p.value !== '') {
      let allowedChars = '' + p.value.replace(/,/g, '.');
      const isNumber = !!allowedChars.match(numberRegexp);

      if (isNumber && allowedChars.slice(-1) !== '.') {
        allowedChars = Number(allowedChars);
      }
      itemSet(
        data.setIn(
          path,
          new NumberProperty({
            value: this.getValue(),
            fallback: allowedChars,
          }),
        ),
      );
    } else {
      let value = data.getIn(path);
      value = value.deleteIn(['fallback']);
      itemSet(data.setIn(path, value));
    }
  };

  valueToText = () => {
    const { data, path } = this.props;
    let value = data.getIn(path);
    if (value) {
      let out = value.get('value');
      if (out === 0) {
        out = '0';
      }
      return out || '';
    } else {
      return '';
    }
  };

  render() {
    const { label, readOnly, errors } = this.props;
    const { data, path } = this.props;
    let value = data.getIn(path);
    let text = this.valueToText();

    const isPlaceholder = value ? value.isPlaceholder('number') : false;

    let basePath = path.concat([]);
    basePath.pop();

    let valueError = null;
    if (
      (errors.hasErrors(path.concat('value')) && !isPlaceholder) ||
      errors.hasMissingPropertyOnPath(basePath, path[path.length - 1]) ||
      errors.hasMissingPropertyOnPath(path, 'value')
    ) {
      valueError = (
        <FormattedHTMLMessage
          id={'NUMBER_INPUT_VALUE_ERROR'}
          values={{ examplePlaceholder: '#{number}' }}
        />
      );
    }

    const out = [
      <Form.Field error={!!valueError} key={`${this.pathToString()}.value`}>
        <label>{label}</label>
        {readOnly && <p>{text}</p>}
        {!readOnly && (
          <Input
            error={!!valueError}
            style={{ width: '100%' }}
            value={text}
            type="string"
            onChange={this.handleChange}
          />
        )}
      </Form.Field>,
    ];

    if (valueError) {
      out.push(
        this.renderErrorLabel(valueError, `${this.pathToString()}.value.error`),
      );
    }

    if (isPlaceholder && !readOnly) {
      let fallbackError = null;

      if (errors.hasErrors(path.concat('fallback'))) {
        fallbackError = (
          <FormattedHTMLMessage id={'NUMBER_INPUT_FALLBACK_ERROR'} />
        );
      } else if (this.getFallback() === undefined) {
        fallbackError = (
          <FormattedHTMLMessage id={'NUMBER_INPUT_FALLBACK_MISSING'} />
        );
      }

      out.push(
        <Form.Field
          key={`${this.pathToString()}.fallback`}
          error={!!fallbackError}
        >
          <label>{this.getFallbackLabel()}</label>
          <Form.Input
            error={!!fallbackError}
            onChange={this.handleFallbackChange}
            value={this.getFallback()}
          />
        </Form.Field>,
      );
      if (fallbackError) {
        out.push(
          this.renderErrorLabel(
            fallbackError,
            `${this.pathToString()}.fallback.error`,
          ),
        );
      }
    }

    return out;
  }
}
