import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { imageTypes } from '../../../constants/Image.config';
import {
  Grid,
  Dropdown,
  Checkbox,
  Modal,
  Button,
  Icon,
} from 'semantic-ui-react';
import LanguageCodes from '../../../constants/languageCodes';
import ImageSelection from './ImageSelection';
import PreviewResource from './PreviewResource';
import './Image.css';
import {
  FormattedHTMLMessage,
  defineMessages,
  injectIntl,
  FormattedMessage,
} from 'react-intl';
import ImageCreateModal from '../../core/image/ImageCreateModal';

const messages = defineMessages({
  imageEnableTranslations: {
    defaultMessage: 'Enable translations',
    id: 'image.enable.translation.label',
  },
});

class Image extends Component {
  static propTypes = {
    type: PropTypes.oneOf(imageTypes),
    id: PropTypes.string,
    previewColor: PropTypes.string,
    projectId: PropTypes.string.isRequired,
    images: PropTypes.object,
    previewStyle: PropTypes.object,
    previewLanguage: PropTypes.string,
    languages: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    onChangeLanguage: PropTypes.func,
  };

  componentWillMount() {
    this.setState({
      modalOpen: false,
      selectedLanguage:
        this.props.previewLanguage || this.props.languages.get(0),
      isTranslated: this.isTranslatedImage(),
      renderImageSelection: true,
    });
  }

  handleSelectedLanguageChange = (e, data) => {
    this.setState({ selectedLanguage: data.value });
    const { onChangeLanguage } = this.props;
    if (onChangeLanguage) {
      onChangeLanguage(e, data);
    }
  };

  handleRemoveImage = () => {
    const { selectedLanguage } = this.state;
    let value = undefined;
    if (this.state.isTranslated && this.canBeTranslated()) {
      value = this.getImage();
      delete value[selectedLanguage];
      const keys = Object.keys(value);
      if (keys.length === 0) {
        value = undefined;
      }
    }
    this.handleImageChange(value);
  };

  handleImageChange = value => {
    const { type, onChange } = this.props;
    this.setState({ isTranslated: typeof value === 'object' });
    // TODO remove this with JSON hack (is not immutable in template, so template will not re-render)
    onChange(type, value ? JSON.parse(JSON.stringify(value)) : undefined);
  };

  handleChange = data => {
    const { selectedLanguage } = this.state;
    let value = data.id;
    if (this.state.isTranslated && this.canBeTranslated()) {
      value = this.getImage();
      if (!value || typeof value === 'string') {
        value = {};
      }
      value[selectedLanguage] = data.id;
    }
    this.handleImageChange(value);
  };

  handleOpen = () => {
    this.setState({ modalOpen: true });
  };

  handleClose = () => {
    this.setState({ modalOpen: false });
  };

  isTranslatedImage = () => {
    const image = this.getImage();
    return !(typeof image === 'undefined' || typeof image === 'string');
  };

  getImage = () => {
    const { images, type } = this.props;
    if (images) {
      return images[type];
    } else {
      return undefined;
    }
  };

  handleSwitchTranslate = (e, p) => {
    const { languages } = this.props;
    const image = this.getImage();
    this.setState({ isTranslated: p.checked });

    if (p.checked) {
      //this.handleImageChange(image);
    } else {
      if (image && languages[0]) {
        this.handleImageChange(image[languages[0]]);
      } else {
        this.handleImageChange(undefined);
      }
    }
  };

  getPreviewImageId = language => {
    const { isTranslated } = this.state;

    let id = null;
    const image = this.getImage();
    if (!isTranslated && typeof image === 'string') {
      id = image;
    }

    if (isTranslated && image) {
      // editor is open select realvalue
      if (this.state.modalOpen) {
        id = image[language];
      } else {
        // use english or alphabet first fot preview if is not defined for current language
        if (image[language]) {
          id = image[language];
        } else if (image && image['en']) {
          id = image['en'];
        } else if (image) {
          const keys = Object.keys(image).sort((a, b) => {
            return a.localeCompare(b);
          });
          if (keys.length > 0) {
            id = image[keys[0]];
          }
        }
      }
    }

    return id;
  };

  canBeTranslated = () => {
    return this.props.type !== 'icon';
  };

  render() {
    const {
      intl,
      passStyle,
      type,
      previewLanguage,
      languages,
      projectId,
      previewColor,
    } = this.props;
    const id = this.getPreviewImageId(
      previewLanguage || this.state.selectedLanguage,
    );

    const showDeleteIcon = !this.state.isTranslated || this.state.modalOpen;
    return (
      <Modal
        trigger={
          <PreviewResource
            onRemove={this.handleRemoveImage}
            type={type}
            passStyle={passStyle}
            previewColor={previewColor}
            style={{ cursor: 'pointer' }}
            id={id}
            onClick={this.handleOpen}
            showDeleteIcon={showDeleteIcon}
          />
        }
        open={this.state.modalOpen}
        onClose={this.handleClose}
        onOpen={this.handleOpen}
        closeIcon="close"
      >
        <Modal.Header>
          <Grid columns={2}>
            <Grid.Row>
              <Grid.Column>
                <FormattedMessage
                  id="image.dialog.header"
                  defaultMessage="Select the {type} image"
                  values={{ type: type }}
                />
              </Grid.Column>
              {(this.canBeTranslated() || this.state.isTranslated) && (
                <Grid.Column style={{ textAlign: 'right' }}>
                  <Dropdown
                    value={this.state.selectedLanguage}
                    onChange={this.handleSelectedLanguageChange}
                    disabled={!this.state.isTranslated}
                    style={{ marginRight: 30 }}
                    options={languages.toArray().map(lng => ({
                      text: LanguageCodes[lng],
                      value: lng,
                    }))}
                  />

                  <Checkbox
                    disabled={!this.canBeTranslated()}
                    toggle
                    label={intl.formatMessage(messages.imageEnableTranslations)}
                    checked={this.state.isTranslated}
                    onChange={this.handleSwitchTranslate}
                    style={{ marginRight: 20 }}
                  />
                </Grid.Column>
              )}
            </Grid.Row>
          </Grid>
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            {this.state.renderImageSelection && (
              <ImageSelection
                type={type}
                projectId={projectId}
                selectedId={id}
                handleChange={this.handleChange}
              />
            )}
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            floated={'left'}
            type="button"
            onClick={this.handleRemoveImage}
          >
            <Icon name={'erase'} />
            <FormattedHTMLMessage id="image.modal.ok" defaultMessage="Reset" />
          </Button>

          <ImageCreateModal
            buttonProps={{ primary: true }}
            onClose={() => {
              this.setState({ renderImageSelection: true });
            }}
            onOpen={() => {
              this.setState({ renderImageSelection: false });
            }}
            projectId={projectId}
            type={type}
            onCreateSuccess={data => {
              this.handleChange(data);
            }}
          />

          <Button primary type="button" onClick={this.handleClose}>
            <FormattedHTMLMessage id="image.modal.ok" defaultMessage="Ok" />
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default injectIntl(Image);
