import React from 'react';
import AppStoreAddModal from './AppStoreAddModal';
import AppStoreAppDetail from './AppStoreAppDetail';
import PropTypes from 'prop-types';
import { List } from 'semantic-ui-react';
import { addNotification } from '../../../../../../actions/notification';
import { connect } from 'react-redux';
import Template from '../../../../../../models/template/index';

class AppStore extends React.Component {
  static propTypes = {
    data: PropTypes.instanceOf(Template).isRequired,
    itemSet: PropTypes.func.isRequired,
  };

  handleAdd = id => {
    try {
      const { itemSet, data } = this.props;
      const newData = data.associatedIdentifierAdd(id);
      itemSet(newData);
    } catch (e) {
      this.props.addNotification({
        title: 'Error',
        message: e.message,
        level: 'error',
      });
    }
  };

  handleRemove = id => {
    try {
      const { itemSet, data } = this.props;
      const newData = data.associatedIdentifierRemove(id);
      itemSet(newData);
    } catch (e) {
      this.props.addNotification({
        title: 'Error',
        message: e.message,
        level: 'error',
      });
    }
  };

  render() {
    const { data } = this.props;
    const items = data.getIn([
      'jsonTemplate',
      'associatedStoreIdentifiers',
      'items',
    ]);
    return (
      <div>
        <List>
          {items &&
            items.map(appId => {
              return (
                <AppStoreAppDetail
                  key={appId.get('value')}
                  id={appId.get('value')}
                  onRemove={this.handleRemove}
                />
              );
            })}
        </List>
        <AppStoreAddModal onAdd={this.handleAdd} />
      </div>
    );
  }
}

export default connect(state => ({}), {
  addNotification,
})(AppStore);
