import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Template from '../../../../models/template';
import { FormattedMessage } from 'react-intl';
import StringInput from './StringInput';
import NumberInput from './NumberInput';
import DatePropertyInput from './DateInput';
import ColorPropertyInput from './ColorInput';
import BooleanInput from './BooleanInput';
import DropDownInput from './DropDownInput';
import Errors from '../../../../models/Errors';

export default class InputRenderComponent extends Component {
  static propTypes = {
    data: PropTypes.instanceOf(Template).isRequired,
    errors: PropTypes.instanceOf(Errors).isRequired,
    itemSet: PropTypes.func.isRequired,
  };

  getLabel = value => {
    return <FormattedMessage id={value.toUpperCase()} defaultValue={value} />;
  };

  pathToString(path) {
    return path.join();
  }

  renderBooleanInput = (path, value, icon) => {
    const { data, errors, itemSet } = this.props;
    return (
      <BooleanInput
        key={`${this.pathToString(path)}.${value}`}
        icon={icon}
        itemSet={itemSet}
        data={data}
        errors={errors}
        path={[...path].concat([value])}
        label={this.getLabel(value)}
      />
    );
  };

  renderDropDownInput = (path, value, options, opts = {}) => {
    const { data, itemSet, errors } = this.props;
    return (
      <DropDownInput
        key={`${this.pathToString(path)}.${value}`}
        errors={errors}
        itemSet={itemSet}
        data={data}
        placeholder={this.getLabel(`${value}_placeholder`)}
        options={options}
        path={[...path].concat([value])}
        label={this.getLabel(value)}
        {...opts}
      />
    );
  };

  renderStringInput = (path, value, options = {}) => {
    const { data, errors, itemSet } = this.props;
    return (
      <StringInput
        key={options.key || `${this.pathToString(path)}.${value}`}
        keyBase={options.key || `${this.pathToString(path)}.${value}`}
        itemSet={itemSet}
        data={data}
        errors={errors}
        path={[...path].concat([value])}
        label={this.getLabel(value)}
        {...options}
      />
    );
  };

  renderDateInput = (path, value, opts = {}) => {
    const { data, errors, itemSet } = this.props;
    return (
      <DatePropertyInput
        key={`${this.pathToString(path)}.${value}`}
        itemSet={itemSet}
        data={data}
        errors={errors}
        path={[...path].concat([value])}
        label={this.getLabel(value)}
        {...opts}
      />
    );
  };

  renderNumberInput = (path, value, opts = {}) => {
    const { data, errors, itemSet } = this.props;
    return (
      <NumberInput
        key={`${this.pathToString(path)}.${value}`}
        itemSet={itemSet}
        data={data}
        errors={errors}
        path={[...path].concat([value])}
        label={this.getLabel(value)}
        {...opts}
      />
    );
  };

  renderColorInput = (path, value) => {
    const { data, errors, itemSet } = this.props;
    return (
      <ColorPropertyInput
        key={`${this.pathToString(path)}.${value}`}
        itemSet={itemSet}
        data={data}
        errors={errors}
        path={[...path].concat([value])}
        label={this.getLabel(value)}
      />
    );
  };
}
