import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react/dist/commonjs';
import LabelUser from '../../common/LabelUser';
import ProjectUserRightsEdit from './ProjectUserRightsEdit';

export default class ProjectUserRightsRow extends Component {
  static propTypes = {
    projectId: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    accessRights: PropTypes.number.isRequired,
    saveRights: PropTypes.func.isRequired,
  };

  render() {
    const { projectId, accessRights, userId, saveRights } = this.props;
    return (
      <Table.Row>
        <Table.Cell>
          <LabelUser id={userId} />
        </Table.Cell>
        <Table.Cell>
          <ProjectUserRightsEdit
            projectId={projectId}
            accessRights={accessRights}
            userId={userId}
            saveRights={saveRights}
          />
        </Table.Cell>
      </Table.Row>
    );
  }
}
