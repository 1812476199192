import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Form, Checkbox, Icon } from 'semantic-ui-react';
import { BooleanProperty } from '../../../../models/property';
import Template from '../../../../models/template';
import Errors from '../../../../models/Errors';
import BaseInput from './BaseInput';

class BooleanPropertyInput extends BaseInput {
  static propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    data: PropTypes.instanceOf(Template).isRequired,
    errors: PropTypes.instanceOf(Errors).isRequired,
    path: PropTypes.array.isRequired,
    itemSet: PropTypes.func.isRequired,
    icon: PropTypes.string,
  };

  checkboxChange = (e, p) => {
    const { data, path, itemSet } = this.props;
    itemSet(
      data.setIn(
        path,
        new BooleanProperty({ value: p.checked ? true : false }),
      ),
    );
  };

  render() {
    const { label, icon } = this.props;
    const { data, path } = this.props;
    let value = data.getIn(path);
    const w = value ? value.get('value') : false;
    return (
      <Form.Field key={`${this.pathToString()}`}>
        <label>{label}</label>
        <Checkbox
          onChange={this.checkboxChange}
          toggle
          defaultChecked={w === true}
          label="&nbsp;"
        />
        {icon && <Icon disabled={w !== true} name={icon} />}
      </Form.Field>
    );
  }
}

export default injectIntl(BooleanPropertyInput);
