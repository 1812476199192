import React from 'react';
import PropTypes from 'prop-types';
import Locations from './Locations';
import Beacons from './Beacons';
import { Form } from 'semantic-ui-react';
import InputRenderComponent from '../../inputs/InputRenderComponent';
import Template from '../../../../../models/template';
import Errors from '../../../../../models/Errors';

export default class RelevanceKeys extends InputRenderComponent {
  static propTypes = {
    data: PropTypes.instanceOf(Template).isRequired,
    errors: PropTypes.instanceOf(Errors).isRequired,
    itemSet: PropTypes.func.isRequired,
  };

  render() {
    const { data, errors, itemSet } = this.props;
    return (
      <Form>
        {this.renderNumberInput(['jsonTemplate'], 'maxDistance')}
        <div style={{ marginBottom: 20 }}>
          <Locations errors={errors} data={data} itemSet={itemSet} />
        </div>
        <div style={{ marginBottom: 20 }}>
          <Beacons errors={errors} data={data} itemSet={itemSet} />
        </div>
      </Form>
    );
  }
}
