export const PROJECT_BATCH_SAVE_LOADING = 'PROJECT_BATCH_SAVE_LOADING';
export const PROJECT_BATCH_SAVE_SUCCESS = 'PROJECT_BATCH_SAVE_SUCCESS';
export const PROJECT_BATCH_SAVE_FAILURE = 'PROJECT_BATCH_SAVE_FAILURE';

export function projectSaveBatch(data) {
  return {
    type: PROJECT_BATCH_SAVE_LOADING,
    data,
  };
}
