import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Popup } from 'semantic-ui-react';
import Field from '../fields/field/Field';
import BoardingPassIcon from './BoardingPassIcon';
import AuxiliaryFields from '../fields/AuxiliaryFields';
import SecondaryFields from '../fields/SecondaryFields';
import { injectIntl } from 'react-intl';
import shortid from 'shortid';
import { List } from 'immutable';
import Barcodes from '../barcode/Barcodes';
import LogoLogoTextHeaderFields from './LogoTextHeaderFields';
import Errors from '../../../../../../models/Errors';
import Template from '../../../../../../models/template';
import { uiSetTemplateForceOpenField } from '../../../../../../actions/ui';
import { connect } from 'react-redux';
import passStyleBoardingPass from './passStyleBoardingPass.svg';

class BoardingPass extends Component {
  static propTypes = {
    intl: PropTypes.object.isRequired,
    previewLanguage: PropTypes.string.isRequired,
    errors: PropTypes.instanceOf(Errors).isRequired,
    data: PropTypes.instanceOf(Template).isRequired,
    itemSet: PropTypes.func.isRequired,
    uiSetTemplateForceOpenField: PropTypes.func.isRequired,
  };

  addField = () => {
    const { itemSet, data, uiSetTemplateForceOpenField } = this.props;
    const key = shortid.generate();
    uiSetTemplateForceOpenField(key);
    itemSet(data.fieldAdd('primaryFields', key));
  };

  render() {
    const { data, previewLanguage, intl, itemSet, errors } = this.props;
    let primaryFields = data.getFields('primaryFields');
    if (!primaryFields) {
      primaryFields = new List();
    }
    const primaryFieldsLength = (primaryFields && primaryFields.size) || 0;

    return (
      <div>
        <LogoLogoTextHeaderFields
          data={data}
          previewLanguage={previewLanguage}
          errors={errors}
          itemSet={itemSet}
        />

        <img
          src={passStyleBoardingPass}
          style={{ width: '100%', position: 'absolute', top: 60, zIndex: -1 }}
          alt={``}
        />

        <div
          style={{
            display: 'table',
            tableLayout: 'fixed',
            width: '100%',
            height: 70,
          }}
        >
          <div
            style={{
              display: 'table-cell',
              width: '100%',
              verticalAlign: 'top',
              paddingLeft: 12,
              paddingRight: 12,
            }}
          >
            {primaryFieldsLength > 0 && (
              <Field
                errors={errors}
                data={data}
                itemSet={itemSet}
                previewLanguage={previewLanguage}
                field={primaryFields.get(0)}
                fieldsType={'primaryFields'}
                keyName={'boardingPass.properties.primaryFields'}
                fieldIndex={0}
                key={primaryFields.get(0).getIn(['properties', 'key', 'value'])}
              />
            )}
          </div>
          <div
            style={{
              display: 'table-cell',
              width: '100%',
              textAlign: 'center',
              verticalAlign: 'middle',
            }}
          >
            <BoardingPassIcon itemSet={itemSet} data={data} />
          </div>
          <div
            style={{
              display: 'table-cell',
              width: '100%',
              verticalAlign: 'top',
            }}
          >
            {primaryFieldsLength > 1 && (
              <Field
                key={primaryFields.get(1).getIn(['properties', 'key', 'value'])}
                errors={errors}
                data={data}
                itemSet={itemSet}
                previewLanguage={previewLanguage}
                field={primaryFields.get(1)}
                fieldsType={'primaryFields'}
                keyName={'boardingPass.properties.primaryFields'}
                fieldIndex={1}
                style={{ textAlign: 'right' }}
              />
            )}
          </div>
          {primaryFieldsLength < 2 && (
            <Popup
              content={intl.formatHTMLMessage({
                id: 'primaryFields'.toUpperCase(),
              })}
              trigger={
                <Button
                  icon="plus"
                  color="green"
                  onClick={this.addField}
                  size="mini"
                  style={{
                    position: 'absolute',
                    height: 20,
                    padding: 3,
                    right: -25,
                    margin: 10,
                  }}
                />
              }
            />
          )}
        </div>

        <div
          style={{
            paddingLeft: 12,
            paddingRight: 12,
          }}
        >
          <AuxiliaryFields
            data={data}
            previewLanguage={previewLanguage}
            errors={errors}
            itemSet={itemSet}
          />

          <SecondaryFields
            data={data}
            previewLanguage={previewLanguage}
            errors={errors}
            itemSet={itemSet}
          />
        </div>

        <Barcodes
          data={data}
          errors={errors}
          itemSet={itemSet}
          previewLanguage={previewLanguage}
        />
      </div>
    );
  }
}

export default connect((state, props) => ({}), { uiSetTemplateForceOpenField })(
  injectIntl(BoardingPass),
);
