import React from 'react';
import PropTypes from 'prop-types';
import FieldPreviewCurrency from './FieldPreviewValueCurrency';
import FieldPreviewNumber from './FieldPreviewValueNumber';
import FieldPreviewDate from './FieldPreviewValueDate';
import { Icon } from 'semantic-ui-react';
import { List } from 'immutable';
import { ObjectType } from '../../../../../../../../models/property';
import FieldPreviewBase from './FieldPreviewBase';

export default class FieldPreviewValue extends FieldPreviewBase {
  static propTypes = {
    ...FieldPreviewBase.propTypes,
    fieldType: PropTypes.string.isRequired,
    field: PropTypes.instanceOf(ObjectType).isRequired,
    fieldsType: PropTypes.string.isRequired,
    previewLanguage: PropTypes.string.isRequired,
  };

  // TODO - don't use chars but real width of rendered element.
  getFontSize() {
    const { data, fieldsType, previewLanguage } = this.props;
    const passStyle = data.getPassStyle();

    const MAX_FONT_SIZE = 125;
    const MIN_FONT_SIZE = 75;
    const MAX_CHARS_IN_FIELD = 8;

    if (passStyle === 'coupon' || passStyle === 'storeCard') {
      const MAX_COUNT = 4;

      const secondaryFields = data.getIn([
        'jsonTemplate',
        passStyle,
        'properties',
        'secondaryFields',
        'items',
      ])
        ? data.getIn([
            'jsonTemplate',
            passStyle,
            'properties',
            'secondaryFields',
            'items',
          ])
        : new List();

      const auxiliaryFields = !!data.getIn([
        'jsonTemplate',
        passStyle,
        'properties',
        'auxiliaryFields',
        'items',
      ])
        ? data.getIn([
            'jsonTemplate',
            passStyle,
            'properties',
            'auxiliaryFields',
            'items',
          ])
        : new List();

      const totalCount = auxiliaryFields.size + secondaryFields.size;
      let valueCount = 0;
      if (totalCount) {
        secondaryFields.forEach(w => {
          if (w.getIn(['properties', 'value', 'value'])) {
            const value = w.getIn(['properties', 'value', 'value']).get
              ? w.getIn(['properties', 'value', 'value']).get(previewLanguage)
              : w.getIn(['properties', 'value', 'value']);

            if (value) {
              valueCount += value.toString().length;
            }
          }
        });
        auxiliaryFields.forEach(w => {
          if (w.getIn(['properties', 'value', 'value'])) {
            const value = w.getIn(['properties', 'value', 'value']).get
              ? w.getIn(['properties', 'value', 'value']).get(previewLanguage)
              : w.getIn(['properties', 'value', 'value']);
            if (value) {
              valueCount += value.toString().length;
            }
          }
        });
      }
      const fontSize = Math.min(
        MAX_FONT_SIZE,
        Math.max(
          MAX_CHARS_IN_FIELD * MAX_COUNT / valueCount * 100,
          MIN_FONT_SIZE,
        ),
      );
      return fontSize;
    } else {
      const fields = data.getIn([
        'jsonTemplate',
        passStyle,
        'properties',
        fieldsType,
        'items',
      ])
        ? data.getIn([
            'jsonTemplate',
            passStyle,
            'properties',
            fieldsType,
            'items',
          ])
        : new List();

      const fieldSize = fields.size || 0;
      let valueCount = 0;
      if (fieldSize) {
        fields.forEach(w => {
          if (w.getIn(['properties', 'value', 'value'])) {
            const value = w.getIn(['properties', 'value', 'value']).get
              ? w.getIn(['properties', 'value', 'value']).get(previewLanguage)
              : w.getIn(['properties', 'value', 'value']);
            if (value) {
              valueCount += value.toString().length;
            }
          }
        });
      }
      const fontSize = Math.min(
        MAX_FONT_SIZE,
        Math.max(
          MAX_CHARS_IN_FIELD * fields.size / valueCount * 100,
          MIN_FONT_SIZE,
        ),
      );

      return fontSize;
    }
  }

  getStyle = () => {
    const { data } = this.props;
    const color = this.isBackfield() ? 'black' : data.getForegroundColor();
    const fontSize = this.getFontSize();
    if (this.isBackfield())
      return {
        color: color,
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
      };
    else
      return {
        color: color,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        fontSize: `${fontSize}%`,
        height: '26px',
        lineHeight: '22px',
      };
  };

  render() {
    const { previewLanguage, field, fieldType } = this.props;

    let content = this.tidyHtml(this.getValueValue());
    const valueProperty = field.getIn(['properties', 'value']);
    const isPlaceholder = valueProperty && valueProperty.isPlaceholder();

    if (!isPlaceholder) {
      if (fieldType === 'date') {
        content = (
          <FieldPreviewDate
            previewLanguage={previewLanguage}
            value={this.getValueValue()}
            dateStyle={field.getIn(['properties', 'dateStyle', 'value'])}
            timeStyle={field.getIn(['properties', 'timeStyle', 'value'])}
          />
        );
      }

      if (fieldType === 'number') {
        content = (
          <FieldPreviewNumber
            previewLanguage={previewLanguage}
            value={this.getValueValue()}
            style={field.getIn(['properties', 'numberStyle', 'value'])}
          />
        );
      }

      if (fieldType === 'currency') {
        content = (
          <FieldPreviewCurrency
            previewLanguage={previewLanguage}
            value={this.getValueValue()}
            currency={field.getIn(['properties', 'currencyCode', 'value'])}
          />
        );
      }
    }

    return (
      <div className="field-preview" style={this.getStyle()}>
        {this.isLocalizedValue() && <Icon name="globe" />}
        {content}
      </div>
    );
  }
}
