import React, { Component } from 'react';
import Form from './Form';
import Edit from '../../common/Edit';
export default class OrderEdit extends Component {
  render() {
    return (
      <Edit
        resource={'ticketOrder'}
        resourceId={this.props.params.id}
        component={Form}
      />
    );
  }
}
