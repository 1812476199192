import { call, put } from 'redux-saga/effects';
import client, { CORE_API_URL } from '../services/api';
import {
  FETCH_VIEWER_SUCCESS,
  FETCH_VIEWER_FAILURE,
  AUTH_CHANGE_PASSWORD_SUCCESS,
  AUTH_CHANGE_PASSWORD_FAILURE,
} from '../actions/viewer';
import { tokenRefresh } from '../saga/token';
import { unsetToken } from '../actions/token';
import { login as loginAction } from '../actions/viewer';
import { store } from '../store/index';

export function* fetchViewer(action) {
  try {
    // uggly hack
    const state = store.getState();
    if (!state.token || !state.token.token) {
      yield tokenRefresh();
    }

    let data = yield call(
      client,
      'GET',
      CORE_API_URL + '/v1/user/me',
      {},
      true,
    );
    yield put({ type: FETCH_VIEWER_SUCCESS, data });
  } catch (e) {
    yield put({ type: FETCH_VIEWER_FAILURE, message: e.message });
  }
}
export function* logout(action) {
  yield call(client, 'POST', '/auth/logout', {});
  yield put(unsetToken());
  // TODO remove access from state via event to other tabs
  yield put(loginAction());
}

export function login(action) {
  const redirect =
    window.location.pathname === '/'
      ? ''
      : `?redirect=${window.location.pathname}`;
  window.location = `/login${redirect}`;
}

export function* changePassword(action) {
  try {
    let data = yield call(
      client,
      'POST',
      CORE_API_URL + '/v1/user/me/changePassword',
      {
        currentPassword: action.currentPassword,
        newPassword: action.newPassword,
      },
      true,
    );
    yield put({ type: AUTH_CHANGE_PASSWORD_SUCCESS, data });
    if (action.onSuccess) {
      action.onSuccess(data);
    }
  } catch (e) {
    yield put({ type: AUTH_CHANGE_PASSWORD_FAILURE, message: e.message });
    if (action.onFailure) {
      action.onFailure(e);
    }
  }
  // TODO call logout ?
}
