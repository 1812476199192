import React, { Component } from 'react';
import ResourceFormDropdown from './ResourceFormDropdown';
import LabelProject from './LabelProject';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
class FormDropdownTicketEshop extends Component {
  static propsTypes = {
    intl: PropTypes.object.isRequired,
  };

  render() {
    const { intl } = this.props;
    return (
      <ResourceFormDropdown
        {...this.props}
        placeholder={intl.formatHTMLMessage({
          id: 'ESHOP_FILTER_PLACEHOLDER',
        })}
        selection={true}
        multiple={this.props.multiple}
        resource="ticketEshop"
        label="Ticket eshop"
        renderContent={data => {
          return (
            <span>
              {data.name}
              <small>
                <LabelProject disableLink={true} id={data.projectId} />
              </small>
            </span>
          );
        }}
      />
    );
  }
}
export default injectIntl(FormDropdownTicketEshop);
