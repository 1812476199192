import { select, put } from 'redux-saga/effects';
import pjson from '../../package.json';
import { UI_LOAD_FORM_STORE_SUCCESS } from '../actions/ui';

export function* storageLoad() {
  const viewer = yield select(state => state.viewer.get('data'));
  const key = `${viewer.id}|${pjson.version}|ui`;
  const ui = localStorage.getItem(key);
  if (ui) {
    yield put({ type: UI_LOAD_FORM_STORE_SUCCESS, data: JSON.parse(ui) });
  }
}

export function* storageSave() {
  const ui = yield select(state => state.ui);
  const viewer = yield select(state => state.viewer.get('data'));
  const key = `${viewer.id}|${pjson.version}|ui`;
  localStorage.setItem(key, JSON.stringify(ui));
}
