import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Icon, Label, Segment } from 'semantic-ui-react';
import DesignerTab from './tabs/designer/DesignerTab';
import RelevanceKeys from './tabs/relevance/RelevanceKeys';
import FormTextAreaJson from '../../common/FormTextAreaJSON';
import FormTab from '../../common/FormTab';
import { templateUpdatePassesByPush } from '../../../actions/template';
import { connect } from 'react-redux';
import BackButton from '../../common/buttons/BackButton';
import { FormattedHTMLMessage, FormattedMessage, injectIntl } from 'react-intl';
import Template from '../../../models/template/index';
import { addNotification } from '../../../actions/notification';
import { AUTO_DISMISS } from '../../../constants/toast.js';
import GeneralForm from './tabs/general/GeneralForm';
import SaveToGooglePay from './tabs/saveToGooglePay/SaveToGooglePay';
import DeleteButton from '../../common/buttons/DeleteButton';

export const FORM_NAME = 'template';

class TemplateEditForm extends Component {
  static propTypes = {
    intl: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    itemSet: PropTypes.func,
    itemSetMetadata: PropTypes.func,
    itemSave: PropTypes.func,
    itemSetPropertyByPath: PropTypes.func,
    uiSetFormTab: PropTypes.func,
  };

  componentWillMount() {
    this.valid(null, 'TEMPLATE_IS_INVALID_TITLE', 'TEMPLATE_NOT_SAVED_MESSAGE');
  }

  handleChangeJSON = (e, data) => {
    const { itemSet } = this.props;
    itemSet(new Template(data));
  };

  valid = (action, messageId, titleId) => {
    const { data, addNotification, intl, itemSetMetadata } = this.props;
    const errors = data.validate();
    if (!errors.valid) {
      itemSetMetadata('touched', true);
      addNotification({
        title: intl.formatMessage({ id: titleId }),
        message: intl.formatMessage({ id: messageId }),
        level: 'error',
        autoDismiss: AUTO_DISMISS,
      });
    } else {
      if (action) {
        action(data.toJSON());
      }
    }
  };

  handleAction = action => {
    this.valid(
      action,
      'TEMPLATE_NOT_SAVED_TITLE',
      'TEMPLATE_NOT_SAVED_MESSAGE',
    );
  };

  handleSave = () => {
    const { itemSave } = this.props;
    this.handleAction(itemSave);
  };

  handleApply = () => {
    const { itemApply } = this.props;
    this.handleAction(itemApply);
  };

  handleDuplicate = e => {
    const data = JSON.parse(JSON.stringify(this.props.data));
    data.name = `${data.name} - copy`;
    delete data.id;
    delete data.updatedAt;
    delete data.createdAt;
    delete data.deletedAt;
    this.props.itemCreate(data);
  };

  handleDelete = e => {
    const { itemDelete } = this.props;
    itemDelete();
  };

  handlePush = () => {
    const { data, templateUpdatePassesByPush } = this.props;
    templateUpdatePassesByPush(data.id);
  };

  render() {
    let { data, changed, viewer, intl, touched } = this.props;
    const { jsonTemplate } = data;
    const errors = data.validate();
    const isValid = touched && !!errors;

    if (data === null) {
      return null;
    }
    const accessRights = viewer.accessRights;
    const isAdmin = viewer.isAdmin;
    const canCreate =
      isAdmin ||
      (data &&
        accessRights[data.projectId] &&
        accessRights[data.projectId].TEMPLATE_CREATE);
    const canUpdate =
      isAdmin ||
      (data &&
        accessRights[data.projectId] &&
        accessRights[data.projectId].TEMPLATE_UPDATE);
    const canSendPush =
      isAdmin ||
      (data &&
        accessRights[data.projectId] &&
        accessRights[data.projectId].TEMPLATE_UPDATE);

    const canDelete =
      isAdmin ||
      (data &&
        accessRights[data.projectId] &&
        accessRights[data.projectId].TEMPLATE_DELETE);

    const jsonContent = (
      <Form>
        <FormTextAreaJson
          onChange={(e, obj) => this.handleChangeJSON(e, obj.value)}
          name="json"
          defaultValue={data.toJSON()}
        />
      </Form>
    );

    if (!jsonTemplate) {
      if (viewer.isAdmin) {
        return (
          <div>
            {jsonContent}
            <Button secondary onClick={this.handleApply} disabled={!changed}>
              <Icon name="save" />
              <FormattedMessage id="BUTTON_APPLY" />
            </Button>
            <BackButton />
          </div>
        );
      } else {
        return (
          <div>
            <Segment>
              <Label color={'red'} attached="top">
                <FormattedHTMLMessage id="NOT_SUPPORTED_TEMPLATE" />
              </Label>
            </Segment>
            <BackButton />
          </div>
        );
      }
    }

    const relevanceKeysError =
      errors.hasErrors(['jsonTemplate', 'relevantDate']) ||
      errors.hasErrors(['jsonTemplate', 'maxDistance']) ||
      errors.hasErrors(['jsonTemplate', 'locations']) ||
      errors.hasErrors(['jsonTemplate', 'beacons']);

    // TODO - create function
    const hasBackFieldError = errors.hasErrors([
      `jsonTemplate`,
      data.getPassStyle(),
      'properties',
      'backFields',
      'items',
    ]);

    // TODO - create function, add barcode errors
    const hasFrontFieldError =
      errors.hasErrors([
        `jsonTemplate`,
        data.getPassStyle(),
        'properties',
        'primaryFields',
        'items',
      ]) ||
      errors.hasErrors([
        `jsonTemplate`,
        data.getPassStyle(),
        'properties',
        'secondaryFields',
        'items',
      ]) ||
      errors.hasErrors([
        `jsonTemplate`,
        data.getPassStyle(),
        'properties',
        'auxiliaryFields',
        'items',
      ]) ||
      errors.hasErrors([
        `jsonTemplate`,
        data.getPassStyle(),
        'properties',
        'headerFields',
        'items',
      ]) ||
      errors.hasErrors([
        `jsonTemplate`,
        data.getPassStyle(),
        'properties',
        'logoText',
        'items',
      ]);

    const hasBarcodeError = errors.hasErrors(['jsonTemplate', 'barcodes']);

    const designerError =
      errors.hasErrors(['jsonTemplate', 'description']) ||
      errors.hasErrors(['jsonTemplate', 'organizationName']) ||
      errors.hasErrors(['jsonTemplate', 'groupingIdentifier']) ||
      errors.hasErrors(['jsonTemplate', 'backgroundColor']) ||
      errors.hasErrors(['jsonTemplate', 'labelColor']) ||
      errors.hasErrors(['jsonTemplate', 'foregroundColor']) ||
      errors.hasErrors(['jsonTemplate', 'expirationDate']) ||
      hasBackFieldError ||
      hasFrontFieldError ||
      hasBarcodeError;

    // TODO - get errors
    const generalForm = false;

    const tabOptions = {
      template: {
        error: generalForm,
        name: intl.formatMessage({ id: 'GENERAL' }),
        icon: 'edit',
        content: (
          <GeneralForm
            data={data}
            itemSet={this.props.itemSet}
            changed={changed}
            isAdmin={isAdmin}
            canUpdate={canUpdate}
            errors={errors}
          />
        ),
      },
      designer: {
        error: designerError,
        icon: 'paint brush',
        name: intl.formatMessage({ id: 'DESIGNER' }),
        content: (
          <DesignerTab
            data={data}
            errors={errors}
            itemSet={this.props.itemSet}
            requestId={this.props.requestId}
            hasBackFieldError={hasBackFieldError}
            hasFrontFieldError={hasFrontFieldError}
            hasBarcodeError={hasBarcodeError}
          />
        ),
      },
      proximity: {
        error: relevanceKeysError,
        icon: 'map',
        name: intl.formatMessage({ id: 'RELEVANCE_KEYS' }),
        content: (
          <RelevanceKeys
            errors={errors}
            data={data}
            itemSet={this.props.itemSet}
          />
        ),
      },
    };

    if (viewer.isAdmin) {
      tabOptions.json = {
        error: errors.errors.length > 0,
        icon: 'file text',
        name: 'Json',
        content: jsonContent,
      };

      tabOptions.errors = {
        error: errors.errors.length > 0,
        icon: 'warning circle',
        name: 'Error',
        content: <pre>{JSON.stringify(errors, null, 2)}</pre>,
      };

      tabOptions.saveToGooglePay = {
        error: false,
        icon: 'google',
        name: 'Save to Google Pay',
        content: (
          <SaveToGooglePay
            data={data}
            itemSet={this.props.itemSet}
            changed={changed}
            isAdmin={isAdmin}
            canUpdate={canUpdate}
            errors={errors}
          />
        ),
      };
    }

    return (
      <div>
        <FormTab
          tabOptions={tabOptions}
          formName={FORM_NAME}
          defaultTab="designer"
        />
        <br />
        <br />
        <br />
        {canUpdate && (
          <Button
            primary
            onClick={this.handleSave}
            disabled={!changed || (touched && !isValid)}
          >
            <Icon name="save" />Save
          </Button>
        )}
        {canUpdate && (
          <Button
            secondary
            onClick={this.handleApply}
            disabled={!changed || (touched && !isValid)}
          >
            <Icon name="save" />Apply
          </Button>
        )}
        {canCreate && (
          <Button onClick={this.handleDuplicate}>
            <Icon name="copy" />Duplicate
          </Button>
        )}
        {canSendPush && (
          <Button onClick={this.handlePush} disabled={changed}>
            Push
          </Button>
        )}

        {canDelete && <DeleteButton onDelete={this.handleDelete} />}

        <BackButton />
      </div>
    );
  }
}

export default injectIntl(
  connect(state => ({ form: state.ui.forms[FORM_NAME] }), {
    templateUpdatePassesByPush,
    addNotification,
  })(injectIntl(TemplateEditForm)),
);
