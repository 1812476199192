import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from '../../../actions/routing';
import { Button, Icon } from 'semantic-ui-react';

class BackButton extends Component {
  render() {
    const { navigation, push } = this.props;
    const prevUrl = navigation.path.split('/');
    prevUrl.pop();
    return (
      <Button
        type="button"
        onClick={() => {
          push(prevUrl.join('/'));
        }}
      >
        <Icon name="list layout" />Back
      </Button>
    );
  }
}

export default connect(
  state => ({
    config: state.config,
    navigation: state.navigation,
  }),
  { push },
)(BackButton);
