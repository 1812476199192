import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Label from './Label';
import { Link } from 'react-router';

export default class LabelWithLink extends Component {
  static propTypes = {
    data: PropTypes.object,
  };

  render() {
    const { data: { name, id }, link } = this.props;
    if (link) {
      return (
        <div>
          <Link to={link}>
            <Label label={<span>{name}</span>} tooltip={id} />
          </Link>
        </div>
      );
    } else {
      return (
        <div>
          <Label label={<span>{name}</span>} tooltip={id} />
        </div>
      );
    }
  }
}
