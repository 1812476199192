import React from 'react';
import TextWidget from './TextWidget';
import moment from 'moment';
import InputDateTime from '../InputDateTime';
import ReactJsonSchemaForm from 'react-jsonschema-form';
import { Segment, Form } from 'semantic-ui-react';
import ObjectField from './ObjectField';

function CustomDateTimeWidget(props) {
  return (
    <InputDateTime
      defaultValue={moment(props.value)}
      onChange={(e, p) => props.onChange(p.value)}
    />
  );
}
function CustomDateWidget(props) {
  return (
    <InputDateTime
      defaultValue={moment(props.value)}
      onChange={(e, p) => props.onChange(p.value)}
    />
  );
}

function FieldTemplate(props) {
  const {
    id,
    rawErrors,
    label,
    help,
    required,
    description,
    errors,
    children,
  } = props;

  return (
    <Form.Field error={!!rawErrors}>
      <label htmlFor={id}>
        {label}
        {required ? '*' : null}
      </label>
      <small>
        <i>{description}</i>
      </small>
      {children}
      {errors}
      {help}
    </Form.Field>
  );
}

function ObjectFieldTemplate(props) {
  return (
    <Segment>
      {props.title}
      {props.description}
      {props.properties.map(element => (
        <div className="property-wrapper">{element.children}</div>
      ))}
    </Segment>
  );
}

const CustomDescriptionField = ({ id, description }) => {
  return <div id={id}>{description}</div>;
};

const CustomTitleField = ({ title, required }) => {
  //const legend = required ? title + '*' : title;
  //return <div id="customA">{legend}</div>;
  return null;
};

// https://github.com/mozilla-services/react-jsonschema-form/tree/master/src/components/widgets
const widgets = {
  DateTimeWidget: CustomDateTimeWidget,
  DateWidget: CustomDateWidget,
  TextWidget: TextWidget,
};

// https://github.com/mozilla-services/react-jsonschema-form/tree/master/src/components/fields
const fields = {
  TitleField: CustomTitleField,
  DescriptionField: CustomDescriptionField,
  ObjectField: ObjectField,
};

const SemanticUIReactJsonSchemaForm = props => {
  return (
    <ReactJsonSchemaForm
      {...props}
      widgets={widgets}
      fields={fields}
      FieldTemplate={FieldTemplate}
      ObjectFieldTemplate={ObjectFieldTemplate}
    >
      <div>
        <button type="submit" style={{ display: 'none' }}>
          Submit
        </button>
      </div>
    </ReactJsonSchemaForm>
  );
};

export default SemanticUIReactJsonSchemaForm;
