import React from 'react';
import { Image, Button, List, Loader } from 'semantic-ui-react';
import PropTypes from 'prop-types';

export default class AppStoreAppDetail extends React.Component {
  static propTypes = {
    id: PropTypes.number.isRequired,
    onRemove: PropTypes.func.isRequired,
  };

  componentWillMount() {
    this.setState({
      isFetching: true,
      result: null,
    });

    const url = `https://itunes.apple.com/lookup?id=${this.props.id}`;

    function jsonp(url, callback) {
      var callbackName = 'jsonp_callback_' + Math.round(100000 * Math.random());
      window[callbackName] = function(data) {
        delete window[callbackName];
        document.body.removeChild(script);
        callback(data);
      };

      var script = document.createElement('script');
      script.src =
        url + (url.indexOf('?') >= 0 ? '&' : '?') + 'callback=' + callbackName;
      script.nonce = document.querySelector('script[nonce]')?.nonce;
      document.body.appendChild(script);
    }

    jsonp(url, data => {
      this.setState({ isFetching: false, result: data });
    });
  }

  remove = id => {
    this.props.onRemove(id);
  };

  render() {
    const { isFetching, result } = this.state;

    if (isFetching) {
      return (
        <List.Item>
          <Loader>Loading</Loader>
          <List.Content>
            <List.Header>{this.props.id}</List.Header>
          </List.Content>
        </List.Item>
      );
    }

    if (result && result.results && result.results.length > 0) {
      const app = result.results[0];
      return (
        <List.Item
          style={{
            paddingTop: 10,
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          <List.Content
            floated="right"
            onClick={() => {
              this.remove(app.trackId);
            }}
          >
            <Button icon="trash" size={'small'} />
          </List.Content>
          <Image avatar src={app.artworkUrl512} />
          <List.Content>
            <List.Header>{app.trackName}</List.Header>
            <List.Description>{app.sellerName}</List.Description>
          </List.Content>
        </List.Item>
      );
    } else {
      return (
        <List.Item>{`error fetching app with id ${this.props.id}`}</List.Item>
      );
    }
  }
}
