import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LabelWithLink from './LabelWithLink';
import { connect } from 'react-redux';
import { Loader } from 'semantic-ui-react';

class LabelTemplate extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
  };

  render() {
    const { register, id, disableLink } = this.props;
    if (register.get('loading')) {
      return (
        <div>
          <Loader active inline="centered" />
        </div>
      );
    }

    let name = id;
    let project = register.get('data').get(id);
    if (project) {
      name = project.name;
    }
    const d = { id, name };
    if (disableLink) {
      return <LabelWithLink data={d} />;
    }
    return (
      <LabelWithLink data={d} link={`/passes/template/${this.props.id}`} />
    );
  }
}

export default connect(
  (state, props) => ({
    register: state.register.get('template'),
  }),
  {},
)(LabelTemplate);
