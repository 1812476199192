import PropTypes from 'prop-types';
import Currencies from '../../../../../../../../../constants/currencyCodes';
import InputRenderComponent from '../../../../../../inputs/InputRenderComponent';

const currencyOptions = Object.keys(Currencies).map(c => ({
  key: c,
  value: c,
  text: c,
}));

export default class CurrencyField extends InputRenderComponent {
  static propTypes = {
    ...InputRenderComponent.propTypes,
    path: PropTypes.array.isRequired,
  };

  render() {
    const { path } = this.props;
    return [
      this.renderNumberInput(path, 'value'),
      this.renderDropDownInput(path, 'currencyCode', currencyOptions),
    ];
  }
}
