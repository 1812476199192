import { Record, List, OrderedMap } from 'immutable';

export const placeHolderRegexp = /^#\{.+\}$/;
export const placeHolderRegexpIgnorePIDAndTID = /#\{[^pt][^\\}]*\}|#\{[^}][^i][^}]*\}|#\{[^}]{2}[^d]\}|#\{[^}]{4,}\}/;
export const numberRegexp = /^-?\d*\.?\d*$/;

const BaseProperty = props => {
  return class extends Record(props) {
    constructor(data) {
      if (data && typeof data.value === 'object') {
        data.value = new OrderedMap(data.value);
      }
      super(data);
    }

    isPlaceholder(type) {
      if (this.value && typeof this.value === 'string') {
        // if is string type, it can contains #{pid} and #{tid} as verified values
        // TODO - be care-full date is also string type
        if (this.type !== 'string') {
          return !!this.value.match(placeHolderRegexp);
        } else {
          if (type === 'date' || type === 'color') {
            return !!this.value.match(placeHolderRegexp);
          } else {
            return !!this.value.match(placeHolderRegexpIgnorePIDAndTID);
          }
        }
      }
      return false;
    }

    isNumber() {
      if (this.value && typeof this.value === 'string') {
        return !!this.value.match(numberRegexp);
      }
      if (this.value && typeof this.value === 'number') {
        return true;
      }

      return false;
    }
  };
};

export class StringProperty extends BaseProperty({
  type: 'string',
  value: undefined,
  fallback: undefined,
}) {}

export class NumberProperty extends BaseProperty({
  type: 'number',
  value: undefined,
  fallback: undefined,
}) {}

export class ColorProperty extends BaseProperty({
  type: 'string',
  value: undefined,
  fallback: undefined,
}) {}

export function createStructure(obj) {
  if (!obj) return undefined;

  switch (obj.type) {
    case 'array':
      return new ArrayType(obj);
    case 'object':
      return new ObjectType(obj);
    case 'string':
      return new StringProperty(obj);
    case 'number':
      return new NumberProperty(obj);
    case 'boolean':
      return new BooleanProperty(obj);

    default:
      // check if is object
      if (typeof obj === 'object') {
        let map = new OrderedMap();
        const keys = Object.keys(obj);
        keys.map((key, i) => {
          map = map.set(key, createStructure(obj[key]));
          return i;
        });
        return map;
      } else {
        return obj;
      }
  }
}

export class BooleanProperty extends Record({
  type: 'boolean',
  value: undefined,
}) {}

export class ArrayType extends Record({
  type: 'array',
  items: undefined,
}) {
  constructor(data) {
    let items = new List();
    if (data && data.items) {
      data.items.map((item, index) => {
        items = items.push(createStructure(item));
        return index;
      });
    }
    super({ items });
  }
}

export class ObjectType extends Record({
  type: 'object',
  properties: undefined,
}) {
  constructor(data) {
    let properties = new OrderedMap();
    Object.keys(data.properties).map((key, index) => {
      properties = properties.set(key, createStructure(data.properties[key]));
      return index;
    });
    super({ properties });
  }
}
