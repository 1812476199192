import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { Button } from 'semantic-ui-react';
import JsonModal from '../../common/JsonModal';
import { push } from '../../../actions/routing';
import { uiSetFormTab } from '../../../actions/ui';

const resource = 'template';

class TemplateListActions extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    viewer: PropTypes.object.isRequired,
  };

  render() {
    const { item, viewer } = this.props;
    const accessRights = viewer.accessRights;
    const isAdmin = viewer.isAdmin;
    const canRead =
      isAdmin ||
      (item &&
        accessRights[item.projectId] &&
        accessRights[item.projectId].TEMPLATE_READ);
    //  const canDelete = isAdmin || (item && accessRights[item.projectId] && accessRights[item.projectId].TEMPLATE_DELETE);
    const canUpdate =
      isAdmin ||
      (item &&
        accessRights[item.projectId] &&
        accessRights[item.projectId].TEMPLATE_UPDATE);
    return (
      <Button.Group>
        <JsonModal title={`Template ${item.id}`} data={item} />
        <Button
          as={Link}
          to={'/passes/' + resource + '/' + item.id}
          icon="edit"
          disabled={!(canRead || canUpdate)}
        />
      </Button.Group>
    );
  }
}

export default connect(
  state => ({
    viewer: state.viewer.data,
  }),
  { push, uiSetFormTab },
)(TemplateListActions);
