export const REGISTER_LOADING = 'REGISTER_LOADING';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_DESTROY = 'REGISTER_DESTROY';
export const REGISTER_INIT = 'REGISTER_INIT';

export function loadRegister(resource) {
  return {
    type: REGISTER_LOADING,
    resource,
  };
}
