import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ObjectType } from '../../../../../../../../models/property';

export default class FieldPreviewBase extends Component {
  static propTypes = {
    previewLanguage: PropTypes.string.isRequired,
    fieldsType: PropTypes.string.isRequired,
    field: PropTypes.instanceOf(ObjectType).isRequired,
  };

  isBackfield() {
    const { fieldsType } = this.props;
    return fieldsType === 'backFields';
  }

  tidyHtml = html => {
    let d = document.createElement('div');
    d.innerHTML = html;
    return (
      <span
        dangerouslySetInnerHTML={{
          __html: d.innerHTML ? d.innerHTML : '',
        }}
      />
    );
  };

  getLabel = () => {
    const { field } = this.props;
    return field.getIn(['properties', 'label', 'value']);
  };

  getLabelValue = () => {
    const { previewLanguage } = this.props;
    let label = this.getLabel();

    if (this.isLocalizedLabel()) {
      label = label.get(previewLanguage);
    }

    return label;
  };

  isLocalizedLabel = () => {
    let label = this.getLabel();
    return label && !!label.get;
  };

  getValue = () => {
    const { field } = this.props;
    let value =
      field.getIn(['properties', 'attributedValue', 'value']) ||
      field.getIn(['properties', 'value', 'value']);
    return value;
  };

  isLocalizedValue = () => {
    let value = this.getValue();
    return value && !!value.get;
  };

  getValueValue = () => {
    const { previewLanguage } = this.props;
    let label = this.getValue();
    if (this.isLocalizedValue()) {
      label = label.get(previewLanguage);
    }
    return label;
  };

  hasLabel = () => {
    return !!this.getLabelValue();
  };

  hasValue = () => {
    return !!this.getValueValue();
  };
}
