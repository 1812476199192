import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class CouponTop extends Component {
  static propTypes = {
    backgroundColor: PropTypes.string.isRequired,
  };

  render() {
    const { backgroundColor } = this.props;
    return (
      <div
        className="couponBottom"
        style={{
          display: 'block',
          position: 'absolute',
          bottom: 0,
          left: '0px',
          width: '100%',
          height: '4px',
          background: `linear-gradient(225deg, ${backgroundColor} 3px, transparent 0),  linear-gradient(135deg, ${backgroundColor} 3px, transparent 0)`,
          backgroundRepeat: 'repeat-x',
          backgroundSize: '8px 4px',
        }}
      />
    );
  }
}
