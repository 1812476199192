import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Image from '../../common/image/Image';
import { Grid } from 'semantic-ui-react';

export default class PassImage extends Component {
  static propTypes = {
    imageType: PropTypes.string.isRequired,
    languages: PropTypes.object.isRequired,
    images: PropTypes.object,
    projectId: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  render() {
    const { imageType } = this.props;
    return (
      <Grid.Row>
        <Grid.Column>
          <FormattedMessage id={imageType.toUpperCase()} />:
        </Grid.Column>
        <Grid.Column>
          <Image {...this.props} type={imageType} />
        </Grid.Column>
      </Grid.Row>
    );
  }
}
