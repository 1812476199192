import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form } from 'semantic-ui-react';
import FilterDynamicData from './FilterDynamicData';
import FilterDate from './FilterDate';
import FilterInterval from './FilterInterval';
import FilterId from './FilterId';
import FilterItemNumber from './FilterItemNumber';
import FilterEshop from './FilterEshop';
import FilterPaymentState from './FilterPaymentState';
import FilterOrderNumber from './FilterOrderNumber';
import FilterEmail from './FilterEmail';
import FilterImageType from './FilterImageType';
import FilterName from './FilterName';
import FilterDisplayName from './FilterDisplayName';
import FilterDownloads from './FilterDownloads';
import FilterDevices from './FilterDevices';
import { injectIntl } from 'react-intl';
import './Filter.css';
import FormDropdownProject from '../FormDropdownProject';
import { filterSet, filterSetData } from '../../../actions';
import FormDropdownTemplate from '../FormDropdownTemplate';
import { RESPONSIVE_MENU_BREAKPOINT } from '../../../containers/Layout';

class Filter extends Component {
  static propTypes = {
    onFilter: PropTypes.func,
    onChange: PropTypes.func,
    project: PropTypes.bool,
    projectProps: PropTypes.object,
    template: PropTypes.bool,
    dynamicData: PropTypes.bool,
    id: PropTypes.bool,
    from: PropTypes.bool,
    to: PropTypes.bool,
    ticketAccountingFrom: PropTypes.bool,
    ticketAccountingTo: PropTypes.bool,
    interval: PropTypes.bool,
    itemNumber: PropTypes.bool,
    eshopId: PropTypes.bool,
    name: PropTypes.bool,
    passTypeId: PropTypes.bool,
    imageType: PropTypes.bool,
    email: PropTypes.bool,
    displayName: PropTypes.bool,
  };

  handleChangeProject = (e, p) => {
    this.props.filterSet({ name: 'project', value: p.value });
    this.props.filterSet({ name: 'template', value: [] });
    if (this.props.onChange) {
      this.props.onChange(this.props.filter);
    }

    // TODO - this is hack on filter(by toggle property render new component, which fetch templates by project filter)
    this.setState({ showTemplate: !this.state.showTemplate });
  };

  handleChangeTemplate = (e, p) => {
    this.props.filterSet({ name: 'template', value: p.value });
    if (this.props.onChange) {
      this.props.onChange(this.props.filter);
    }
  };

  componentWillMount() {
    // TODO - this is hack on filter(by toggle property render new component, which fetch templates by project filter)
    this.setState({ showTemplate: true });
  }

  onFilter = e => {
    if (e) e.preventDefault();
    if (this.props.onFilter) {
      this.props.onFilter();
    }
  };

  onChange = (e, p) => {
    if (this.props.onChange) {
      this.props.onChange(this.props.filter);
    }
  };

  render() {
    const { intl, filter } = this.props;

    const components = [];

    if (this.props.project) {
      components.push(
        <FormDropdownProject
          {...this.props.projectProps}
          key={`project${this.state.showTemplate}`}
          selection={true}
          multiple={true}
          onChange={this.handleChangeProject}
          defaultValue={filter.project ? filter.project.value : []}
        />,
      );
    }

    if (this.props.template) {
      const pfilter = {
        project: filter.project ? filter.project : [],
      };

      components.push(
        <FormDropdownTemplate
          key={`template${this.state.showTemplate}`}
          selection={true}
          multiple={true}
          onChange={this.handleChangeTemplate}
          defaultValue={filter.template ? filter.template.value : []}
          filter={pfilter}
        />,
      );
    }

    if (this.props.eshopId) {
      components.push(<FilterEshop key={`eshopId`} onChange={this.onChange} />);
    }

    if (this.props.from) {
      components.push(
        <FilterDate
          name="from"
          key={`from`}
          label="From"
          onChange={this.onChange}
        />,
      );
    }
    if (this.props.to) {
      components.push(
        <FilterDate key={`to`} name="to" label="To" onChange={this.onChange} />,
      );
    }

    if (this.props.createdFrom) {
      components.push(
        <FilterDate
          key={`createdFrom`}
          name="createdAtFrom"
          label={intl.formatHTMLMessage({ id: 'CREATED_FROM' })}
          onChange={this.onChange}
        />,
      );
    }
    if (this.props.createdTo) {
      components.push(
        <FilterDate
          key={`createdTo`}
          name="createdAtTo"
          label={intl.formatHTMLMessage({ id: 'CREATED_TO' })}
          onChange={this.onChange}
        />,
      );
    }

    if (this.props.dynamicData) {
      components.push(
        <FilterDynamicData
          key={`dynamicData`}
          name="dynamicData"
          onChange={this.onChange}
          label="Dynamic data"
        />,
      );
    }

    if (this.props.ticketAccountingFrom) {
      components.push(
        <FilterDate
          key={`ticketAccountingFrom`}
          name="ticketAccountingFrom"
          label="From"
          onChange={this.onChange}
        />,
      );
    }
    if (this.props.ticketAccountingTo) {
      components.push(
        <FilterDate
          key={`ticketAccountingTo`}
          name="ticketAccountingTo"
          label="To"
          onChange={this.onChange}
        />,
      );
    }

    if (this.props.interval) {
      components.push(
        <FilterInterval
          key={`interval`}
          name="interval"
          label="Interval"
          onChange={this.onChange}
        />,
      );
    }

    if (this.props.date) {
      components.push(
        <FilterDate
          name="date"
          label="Date"
          key={`date`}
          onChange={this.onChange}
        />,
      );
    }

    if (this.props.id) {
      components.push(
        <FilterId name="id" label="ID" key={`id`} onChange={this.onChange} />,
      );
    }

    if (this.props.paymentState) {
      components.push(
        <FilterPaymentState key={`paymentState`} onChange={this.onChange} />,
      );
    }

    if (this.props.itemNumber) {
      components.push(
        <FilterItemNumber
          key={`itemNumber`}
          name="itemNumber"
          label={intl.formatHTMLMessage({ id: 'ITEM_NUMBER' })}
          onChange={this.onChange}
        />,
      );
    }

    if (this.props.orderNumber) {
      components.push(
        <FilterOrderNumber
          key={`orderNumber`}
          name="orderNumber"
          label={intl.formatHTMLMessage({ id: 'ORDER_NUMBER' })}
          onChange={this.onChange}
        />,
      );
    }

    if (this.props.displayName) {
      components.push(
        <FilterDisplayName
          key={`displayName`}
          name="displayName"
          label={intl.formatHTMLMessage({ id: 'DISPLAY_NAME' })}
          onChange={this.onChange}
        />,
      );
    }

    if (this.props.email) {
      components.push(
        <FilterEmail
          key={`email`}
          name="email"
          label={intl.formatHTMLMessage({ id: 'EMAIL' })}
          onChange={this.onChange}
        />,
      );
    }

    if (this.props.name) {
      components.push(
        <FilterName
          key={`name`}
          name="name"
          label={intl.formatHTMLMessage({ id: 'NAME' })}
          onChange={this.onChange}
        />,
      );
    }

    if (this.props.passTypeId) {
      components.push(
        <FilterName
          key={`passTypeId`}
          name="passTypeId"
          label={intl.formatHTMLMessage({ id: 'PASS_TYPE_ID' })}
          onChange={this.onChange}
        />,
      );
    }

    if (this.props.imageType) {
      components.push(
        <FilterImageType
          key={`imageType`}
          name="imageType"
          label={intl.formatHTMLMessage({ id: 'IMAGE_TYPE' })}
          onChange={this.onChange}
        />,
      );
    }

    if (this.props.downloads) {
      components.push(
        <FilterDownloads
          key={`downloads`}
          name="downloads"
          label={intl.formatHTMLMessage({ id: 'DOWNLOADS' })}
          onChange={this.onChange}
        />,
      );
    }

    if (this.props.devices) {
      components.push(
        <FilterDevices
          key={`devices`}
          name="devices"
          label={intl.formatHTMLMessage({ id: 'DEVICES' })}
          onChange={this.onChange}
        />,
      );
    }

    let contentWidth =
      this.props.windowWidth > RESPONSIVE_MENU_BREAKPOINT
        ? this.props.windowWidth - 200
        : this.props.windowWidth;

    const size = Math.max(Math.floor(contentWidth / 315), 1);
    const groups = [];

    while (components.length) {
      groups.push(components.splice(0, size));
    }

    if (groups.length > 0) {
      // Add search button into last group
      groups[groups.length - 1].push(
        <div className="field" key={1}>
          <label>&nbsp;</label>
          <Form.Button onClick={this.onFilter} icon="filter" />
        </div>,
      );
    }

    return (
      <Form id={'filter-form'} className={'filter-form'}>
        {groups.map((partial, index) => (
          <Form.Group key={index}>
            {partial.map(component => component)}
          </Form.Group>
        ))}
      </Form>
    );
  }
}
export default connect(
  (state, props) => ({
    windowWidth: state.ui.windowWidth,
    filter: state.filter,
  }),
  { filterSet, filterSetData },
)(injectIntl(Filter));
