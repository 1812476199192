export default {
  MENU_LOGIN: 'Login',
  MENU_USER: 'Users',
  MENU_USER_DETAIL: 'Profile settings',
  MENU_USER_LOGOUT: 'Logout',
  MENU_PASSES: 'Pass',
  MENU_PASSES_PASS: 'Passes',
  MENU_PASSES_TEMPLATE: 'Templates',
  MENU_PASSES_IMAGE: 'Images',
  MENU_PASSES_CERTIFICATE: 'Certificates',
  MENU_REPORT: 'Reports',
  MENU_REPORT_PASSINWALLETOVERVIEW: 'Passes registered in wallet',
  MENU_REPORT_PASSATLEASTONCEREGISTERINWALLETOVERVIEW:
    'Passes at least once registered in wallet',
  MENU_REPORT_PASSDOWNLOADREQUESTBYTAGSOVERVIEW: 'Downloads of pass by tags',
  MENU_REPORT_PASSPREVIEWREQUESTBYTAGSOVERVIEW: 'Previews of pass by tags',
  MENU_REPORT_ACTIVECARDSOVERVIEW: 'Active cards overview',
  ACTIVE_CARDS_REPORT_DESCRIPTION: 'Active cards overview',
  NUMBER_OF_ACTIVE_CARDS: 'Number of active cards',
  NUMBER_OF_CARD_UPDATES: 'Number of card updates',
  NUMBER_OF_CARD_REGISTRATIONS: 'Number of card registrations',
  MENU_REPORT_LINKS: 'Links',
  MENU_IDENTITY: 'Identity Managment',
  MENU_IDENTITY_PROJECT: 'Projects',
  MENU_IDENTITY_USER: 'Users',
  MENU_TICKET: 'Ticket',
  MENU_TICKET_TICKETACCOUNTING: 'Accounting',
  MENU_TICKET_TICKETORDER: 'Order',
  MENU_TICKET_TICKETORDERITEM: 'Order item',
  MENU_TICKET_TICKETESHOP: 'Eshop',
  MENU_TICKET_TICKETPRODUCT: 'Product',
  MENU_API: 'API',
  MENU_API_ENDPOINTS: 'Endpoints',
  MENU_API_DOCUMENTATION: 'Documentation',
  MENU_NEW: 'New',
  PROJECT_ACCESS_RIGHT_NAME: 'Project access',
  PASS_CREATE_RIGHT_NAME: 'Pass create',
  PASS_READ_RIGHT_NAME: 'Pass read',
  PASS_UPDATE_RIGHT_NAME: 'Pass update',
  PASS_DELETE_RIGHT_NAME: 'Pass delete',
  TEMPLATE_CREATE_RIGHT_NAME: 'Template create',
  TEMPLATE_READ_RIGHT_NAME: 'Template read',
  TEMPLATE_UPDATE_RIGHT_NAME: 'Template update',
  TEMPLATE_DELETE_RIGHT_NAME: 'Template delete',
  TICKET_ACCOUNTING_RIGHT_NAME: 'Ticket accounting',
  PROJECT_ACCESS_RIGHT_DESCRIPTION: 'Project access description',
  PASS_CREATE_RIGHT_DESCRIPTION: 'Pass create description',
  PASS_READ_RIGHT_DESCRIPTION: 'Pass read description',
  PASS_UPDATE_RIGHT_DESCRIPTION: 'Pass update description',
  PASS_DELETE_RIGHT_DESCRIPTION: 'Pass delete description',
  TEMPLATE_CREATE_RIGHT_DESCRIPTION: 'Template create description',
  TEMPLATE_READ_RIGHT_DESCRIPTION: 'Template read description',
  TEMPLATE_UPDATE_RIGHT_DESCRIPTION: 'Template update description',
  TEMPLATE_DELETE_RIGHT_DESCRIPTION: 'Template delete description',
  TICKET_ACCOUNTING_RIGHT_DESCRIPTION: 'Ticket accounting description',
  TICKET_ENTRY_RIGHT_NAME: 'Ticket entry',
  TICKET_ENTRY_RIGHT_DESCRIPTION: 'Ticket entry description',
  TICKET_ESHOP_READ_RIGHT_NAME: 'Ticket read',
  TICKET_ESHOP_READ_RIGHT_DESCRIPTION: 'Ticket read description',
  LABEL_PROJECT: 'Project',
  BUTTON_DELETE: 'Delete',
  BUTTON_DOWNLOAD: 'Download',
  BUTTON_PRINT: 'Print',
  BUTTON_QR: 'QR Code',
  BUTTON_SAVE: 'Save',
  BUTTON_APPLY: 'Apply',
  BUTTON_VIEW: 'Preview',
  TICKETORDERITEM_USED: 'Used',
  TICKETORDERITEM_ENTRY_COUNT: 'Entry count',
  TICKETORDERITEM_MAX_ENTRIES: 'Max entries',
  ITEM_NUMBER: 'Item number',
  BRANDPRIMARY: 'Brand primary',
  BRANDSECONDARY: 'Brand secondary',
  CONTENTBTNCOLOR: 'Content button color',
  CONTENTCOLOR: 'Content color',
  CONTENTERRORCOLOR: 'Content error color',
  CONTENTWARNINGCOLOR: 'Content warning color',
  FOOTERCOLOR: 'Footer Color',
  HEADERCOLOR: 'Header Color',
  FONTFAMILY: 'Font family',
  FOOTERHEIGHT: 'Footer height',
  HEADERHEIGHT: 'Header height',
  BACKGROUNDIMG: 'Background image',
  FOOTERIMG: 'Footer image',
  HEADERIMG: 'Header image',
  HEADOVERRIDES: 'Head overrides',
  BODYOVERRIDES: 'Body overrides',
  ESHOP_ID: 'Eshop',
  TICKET_ESHOP: 'Ticket eshop',
  PAID: 'Paid',
  CANCELED: 'Canceled',
  TIMEOUT: 'Timeout',
  DECLINED_3D: 'Declined 3D',
  DECLINED_AC: 'Declined AC',
  ERROR: 'Error',
  PAYMENT_STATE: 'Payment state',
  PAYMENT_STATE_PLACEHOLDER: 'Please select state',
  ESHOP_FILTER_PLACEHOLDER: 'Please select eshop',
  MORE_THAN_1000_WARNING:
    'If the filtered list contains a large number of items, the operation may take quite a long time and in the case of a really large number, it may also end up crashing your browser.',
  NEW_USER: 'New user',
  ORDER_NUMBER: 'Order number',
  EMAIL: 'Email',
  RESEND_MAIL: 'Resend order',
  EDIT: 'Edit',
  ORDER_ITEM_LIST: 'Order items',
  PASS: 'Pass',
  ORDER_ITEM: 'Order item',
  DESIGNER: 'Designer',
  BOARDING_PASS: 'Boarding pass',
  COUPON: 'Coupon',
  EVENT_TICKET: 'Event ticket',
  GENERIC: 'Generic',
  STORE_CARD: 'Store card',
  FORMAT: 'Barcode format',
  MESSAGE: 'Barcode message',
  MESSAGE_PLACEHOLDER: 'Message',
  ALTTEXT: 'Barcode alt text',
  MESSAGEENCODING: 'Message encoding',
  BACKFIELDS: 'Back Fields ',
  ATTRIBUTEDVALUE: 'Attributed value',
  CHANGEMESSAGE: 'Change message',
  DESCRIPTION: 'Description',
  ORGANIZATIONNAME: 'Organization name',
  FOREGROUNDCOLOR: 'Foreground color',
  BACKGROUNDCOLOR: 'Background color',
  LABELCOLOR: 'Label color',
  HEADERFIELDS: 'Header fieldsType',
  AUXILIARYFIELDS: 'Auxiliary fieldsType',
  SECONDARYFIELDS: 'Secondary fieldsType',
  LOGOTEXT: 'Logo text',
  FOOTER: 'Footer',
  LOGO: 'Logo',
  BACKGROUND: 'Background',
  STRIP: 'Strip',
  BARCODE: 'Barcode',
  THUMBNAIL: 'Thumbnail',
  STRIPIMAGE: 'Strip image',
  BACKGROUNDIMAGE: 'Background image',
  RECTANGULARBARCODE: 'Rectangular barcode',
  SQUAREBARCODE: 'Square barcode',
  PRIMARYFIELD: 'Primary field',
  PRIMARYFIELDS: 'Primary fieldsType',
  TEXTALIGNMENT: 'Text alignment',
  FIELDTYPE: 'Field type',
  BARCODEFALLBACK: 'Fallback barcode for iOS 8 or earlier',
  KEY: 'Key',
  LABEL: 'Label',
  VALUE: 'Value',
  LOCATIONS: 'Locations',
  LATITUDE: 'Latitude',
  LONGITUDE: 'Longitude',
  ALTITUDE: 'Altitude',
  BEACONS: 'Beacons',
  PROXIMITYUUID: 'Proximity UUID',
  MAJOR: 'Major',
  MINOR: 'Minor',
  RELEVANTTEXT: 'Relevant text',
  MAXDISTANCE: 'Max distance (In meters)',
  BARCODES: 'Barcodes',
  DATESTYLE: 'Date style',
  TIMESTYLE: 'Time style',
  CURRENCYCODE: 'Currency code',
  NUMBERSTYLE: 'Number style',
  RELEVANCE_KEYS: 'Relevance keys',
  RELEVANTDATE: 'Relevant date',
  ERROR_400_HEADER: 'Bad request',
  ERROR_400_TEXT:
    'Probably you do not have enough rights. Please contact the administrator.',

  ERROR_403_HEADER: 'Forbidden: Access is denied',
  ERROR_403_TEXT:
    'Probably you do not have enough rights. Please contact the administrator.',
  ERROR_404_HEADER: 'Not found',
  ERROR_404_TEXT:
    'Page or resource was not found. Please check your url or contact administrartor',

  ERROR_500_HEADER: 'Internal error',
  ERROR_500_TEXT: 'If the error is repeated, please contact the administrator.',

  PLEASE_FILL_FIELD: 'Please, fill this mandatory field:',
  PLEASE_FILL_FIELDS: 'Please, fill these mandatory fieldsType:',
  SOME_FIELDS_ARE_REQUIRED: 'Some fieldsType are required!',
  PLEASE_ENTER_A_VALUE: 'Please enter a correct value',

  IMAGE_VALIDATION_WIDTH_EXPECTED_LESS_OR_EQUAL:
    'Image width is {current}, but it must be less or equal {expected}.',
  IMAGE_VALIDATION_HEIGHT_EXPECTED_LESS_OR_EQUAL:
    'Image height is {current}, but it must be less or equal {expected}.',
  IMAGE_VALIDATION_HEIGHT_EXPECTED_MORE_OR_EQUAL:
    'Image height is {current}, but it must be more or equal {expected}.',

  IMAGE_VALIDATION_WIDTH_EXPECTED:
    'Image width is {current}, but {expected} is expected.',
  IMAGE_VALIDATION_HEIGHT_EXPECTED:
    'Image height is {current}, but {expected} is expected.',
  IMAGE_VALIDATION_THUMBNAIL:
    'Image dimensions ({width}x{height} px) do not satisfy these requirements: the longer side must have {max}px, while the shorter side must range from {min} to {max}px',

  IMAGE_VALIDATION_UNSUPORTED_FILE:
    'Unsuported file format {mimeType} of file {name}, please use png.',

  IMAGE_TYPE: 'Image type',
  PROJECT: 'Project',

  NAME: 'Name',
  UPLOAD_NEW_IMAGE: 'Upload new image',

  DRAG_IMAGE:
    'Please drag & drop image to this area, or click here to open upload dialog.',
  MIME_TYPE: 'Mime-Type',
  DIMENSION: 'Dimension',
  SIZE: 'Size',
  UPDATED_AT: 'Last update',
  SAVE: 'Save',
  ID: 'Id',

  PKBARCODEFORMATQR: 'QR code',
  PKBARCODEFORMATPDF417: 'PDF 417',
  PKBARCODEFORMATCODE128: 'Code 128',
  PKBARCODEFORMATAZTEC: 'Aztec',

  FRONT: 'Front',
  BACK: 'Back',
  EXPIRATIONDATE: 'Expiration date',

  FORM_DROPDOWN_LANGUAGES: 'Languages',
  ADD_APPLICATION: 'Add App',
  DONE: 'Done',
  SHORT_CODES: 'Shortcodes',
  ICON: 'Icon',
  PKPASS_DOCUMENTATION: 'PKPASS Official documentation',
  DISPLAY_NAME: 'Name',
  OK: 'Ok',
  EXAMPLE_INPUT_LABEL: 'Dynamic preview',
  PREVIEW_LANGUAGE: 'Preview language',
  TEMPLATE_NAME: 'Template name',
  CERT_NAME: 'Cert name',
  TEMPLATE_LABEL_TRANSLATION: 'Label',
  TEMPLATE_VALUE_TRANSLATION: 'Value',
  TEMPLATE_ATTRIBUTED_VALUE_TRANSLATION: 'Attributed value',
  EXPIRATION_DATE: 'Expiration date',
  IMAGE_MANAGE_RIGHT_NAME: 'Manage images',
  IMAGE_MANAGE_RIGHT_DESCRIPTION:
    'Manage images (can list/upload images to project or in template editor)',
  STAMP_MANAGE_RIGHT_NAME: 'Stampcard',
  STAMP_MANAGE_RIGHT_DESCRIPTION: 'Manage stampcards (see overview, call api)',
  TEMPLATE_NOT_SAVED_TITLE: 'Template not saved',
  TEMPLATE_IS_INVALID_TITLE: 'Template is invalid ♿',
  TEMPLATE_NOT_SAVED_MESSAGE: 'Please correct the highlighted field',
  NO_DATA_TITLE: 'No data',
  NO_DATA_MESSAGE: 'There are no relevant data for the specified parameters',
  FALLBACK: 'Fallback',
  GENERAL: 'General',
  DYNAMIC_DATA: 'Dynamic data',
  DYNAMIC_IMAGES: 'Dynamic images',
  JSON: 'Json',
  IGNORESTIMEZONE: 'Ignores timezone',
  CREATE: 'Create',
  PERIOD: 'Period',
  COUNT: 'Count',
  INCREASE_PER_DAY: 'Daily difference',
  INCREASE_PER_WEEK: 'Weekly difference',
  INCREASE_PER_MONTH: 'Monthly difference',
  TABLE: 'Table',
  COLLAPSE_ALL: 'Collapse all',
  EXPAND_ALL: 'Expand all',
  USER_UPDATE_RIGHT_NAME: 'User update',
  PROJECT_CREATE_RIGHT_NAME: 'Project create',
  PROJECT_UPDATE_RIGHT_NAME: 'Project update',
  USER_UPDATE_RIGHT_DESCRIPTION: 'Can edit users rights on projects',
  PROJECT_CREATE_RIGHT_DESCRIPTION: 'Can create sub project',
  PROJECT_UPDATE_RIGHT_DESCRIPTION: 'Can update project',
  TICKET_PRODUCT_MANAGE_RIGHT_NAME: 'Manage eshop products',
  TICKET_PRODUCT_MANAGE_RIGHT_DESCRIPTION:
    'Can create/update product for eshop. Need also template/eshop read access rights',

  DEVICES: 'In wallet',
  DOWNLOADS: 'Downloaded',
  SOME_DEVICES: 'In wallet',
  NONE_DEVICES: 'Not in wallet',
  SOME_DOWNLOADS: 'Downloaded',
  NONE_DOWNLOADS: 'Not downloaded',
  CREATED_FROM: 'Created from',
  CREATED_TO: 'Created to',
  OOPS:
    'Oops That did not go as expected. This product currently requires a minimum device width of 500px to display properly.',
  NOT_SUPPORTED_TEMPLATE:
    'Template format is not supported and cannot be edited.',
  TITLE: 'Title',
  WARNING_TITLE: 'Warning',
  CERTIFICATE_UPLAOAD_FAILURE_TITLE: `Failure upload certificate`,
  CERTIFICATE_UPLAOAD_SUCCESS_TITLE: `Success`,
  CERTIFICATE_UPLAOAD_SUCCESS_MESSAGE: `Success upload certificate`,
  GROUPINGIDENTIFIER: `Grouping identifier`,
  GRAPH_HAS_BEEN_UPDATED: `Graph has been updated`,
  VALID: 'Valid',
  TEXT_ALIGNMENT: 'Text alignment',
  TEXT_ALIGNMENT_PLACEHOLDER: 'Select text alignment',
  CURRENCY_CODE: 'Currency code',
  CURRENCY_CODE_PLACEHOLDER: 'Select currency code',

  COLOR_INPUT_VALUE_ERROR:
    'Value is not valid, it can be in rgb(1,2,3) format or placeholder {examplePlaceholder}',
  COLOR_INPUT_FALLBACK_MISSING: 'Fallback value cannot be empty',
  COLOR_INPUT_FALLBACK_ERROR: 'Fallback is not valid color in rgb format',

  DATE_INPUT_VALUE_ERROR:
    'Value is not valid, it can be date format or placeholder {examplePlaceholder}',
  DATE_INPUT_FALLBACK_MISSING: 'Fallback value cannot be empty',
  DATE_INPUT_FALLBACK_ERROR: 'Fallback is not valid date',

  NUMBER_INPUT_VALUE_ERROR:
    'Value is not valid, it can be number or placeholder {examplePlaceholder}',
  NUMBER_INPUT_FALLBACK_MISSING: 'Fallback value cannot be empty',
  NUMBER_INPUT_FALLBACK_ERROR: 'Fallback is not valid number',

  STRING_INPUT_TRANSLATIONS_VALUE_ERROR: 'Missing translation for {langLabel}',
  STRING_INPUT_VALUE_ERROR: 'Please fill value',
  STRING_INPUT_FALLBACK_ERROR: 'Fallback is not valid',
  STRING_INPUT_FALLBACK_MISSING: 'Fallback value cannot be empty',

  TEMPLATE_LIST_ENABLE_VALIDATION: 'Enable validation',

  TEXTALIGNMENT_PLACEHOLDER: 'Text alignment',
  CONTAINS_NON_VALID_LINK: 'Text contains invalid link.',

  CLEAR: 'Clear',
  RESET: 'Reset',
  CLOSE: 'Close',
  SAVE_TO_GOOGLE_PAY_OBJECT_TEMPLATE:
    'Save to Google Pay template (mapping pass to object)',

  SAVE_TO_GOOGLE_PAY_UPDATE_ALL_PASSES: 'UPDATE_ALL_PASSES',

  PASS_TYPE_ID: 'Pass Type Identifier',

  USER_NOT_FOUND: 'User with email: {email} was not found.',
  ADD_USER_RIGHTS_LABEL: 'Add user to project',
  ADD_USER_RIGHTS_PLACEHOLDER: 'Fill user email',
  PROJECT_USER_RIGHTS_HEADER: 'User with access to project',
  USER: 'User ',
  RIGHTS: 'Rights',
};
