import { call, put, select } from 'redux-saga/effects';
import client, { CORE_API_URL } from '../services/api';

import { FETCH_REPORT_SUCCESS, FETCH_REPORT_FAILURE } from '../actions';
import { addNotification } from '../actions/notification';
import { AUTO_DISMISS } from '../constants/toast.js';

export function* fetchReport(action) {
  const query = action.query;
  //const headers = action.headers;
  //toJSON remove timezone. We must use timezone fix

  let from = query.from instanceof Date ? query.from.toJSON() : query.from;
  let to = query.to instanceof Date ? query.to.toJSON() : query.to;

  let resourceQuery = `${CORE_API_URL}/v1/report/${
    action.serviceType
  }?where=${encodeURIComponent(
    JSON.stringify(query.where),
  )}&from=${encodeURIComponent(from)}&to=${encodeURIComponent(to)}&interval=${
    query.interval
  }`;

  try {
    const previousData = yield select(state => {
      return state.report[action.serviceType].data;
    });

    const messages = yield select(state => {
      return state.intl;
    });

    let data = yield call(client, 'GET', resourceQuery, {});

    if (
      data &&
      (('total' in data && data.total && data.period) || !('total' in data))
    ) {
      yield put({
        type: FETCH_REPORT_SUCCESS,
        data,
        serviceType: action.serviceType,
        query: action.query,
      });

      let firstTime = previousData === null;

      if (!firstTime) {
        yield put(
          addNotification({
            message:
              messages.messages[messages.currentLocale].GRAPH_HAS_BEEN_UPDATED,
            level: 'success',
            autoDismiss: AUTO_DISMISS,
          }),
        );
      }
    } else {
      yield put(
        addNotification({
          title: messages.messages[messages.currentLocale].NO_DATA_TITLE,
          message: messages.messages[messages.currentLocale].NO_DATA_MESSAGE,
          level: 'error',
          autoDismiss: AUTO_DISMISS,
        }),
      );

      yield put({
        type: FETCH_REPORT_FAILURE,
        data,
        serviceType: action.serviceType,
        query: action.query,
      });
    }
  } catch (e) {
    yield put({
      type: FETCH_REPORT_FAILURE,
      message: e.message,
      serviceType: action.serviceType,
      query: action.query,
    });
  }
}
