import React, { Component } from 'react';
import ResourceItemDecorator from '../../common/ResourceItemDecorator';
import { Icon } from 'semantic-ui-react';

class IsValid extends Component {
  render() {
    const errors = this.props.data.validate();
    if (errors.valid) {
      return <Icon name="check" color={'green'} />;
    } else {
      return <Icon name="close" color={'red'} />;
    }
  }
}

export default class TemplateEdit extends Component {
  render() {
    return (
      <ResourceItemDecorator
        resource={'template'}
        resourceId={this.props.id}
        component={IsValid}
      />
    );
  }
}
