import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Button, Modal } from 'semantic-ui-react';
import Edit from '../../common/Edit';
import { FormattedMessage } from 'react-intl';
import LabelProject from '../../common/LabelProject';

const defaultValue = {
  active: true,
  parentId: null,
};

class NewForm extends Component {
  static propTypes = {
    itemCreate: PropTypes.func.isRequired,
    itemSet: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    parentId: PropTypes.string,
  };

  handleChange = (e, p) => {
    const { itemSet, data } = this.props;
    data[p.name] = p.value;
    itemSet(data);
  };
  handleChangeCheckbox(e, p) {
    const { itemSet, data } = this.props;
    data[e.target.name] = !data[e.target.name];
    itemSet(data);
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.itemCreate(this.props.data);
  };

  componentDidMount() {
    const { itemSet, data, parentId } = this.props;
    data.parentId = parentId;
    itemSet(data);
  }

  render() {
    const { data } = this.props;
    return (
      <Form onSubmit={this.handleSubmit}>
        <Form.Input
          key={`form_name`}
          name="name"
          label="Name"
          onChange={this.handleChange}
          defaultValue={data.name}
        />
        <Form.TextArea
          key={`form_desc`}
          name="description"
          label="Description"
          onChange={this.handleChange}
          defaultValue={data.description}
        />
        <Form.Input
          key={`form_active`}
          name="active"
          label="Active"
          type="checkbox"
          onChange={this.handleChangeCheckbox}
          checked={data.active}
        />

        {!!this.props.parentId && (
          <Form.Field>
            <label>Parent project</label>
            <LabelProject id={this.props.parentId} />
          </Form.Field>
        )}

        {/*
        <FormDropdownProject
          name={'parentId'}
          disabled={!!this.props.parentId}
          defaultValue={this.props.parentId}
          label={'Parent project'}
          onChange={this.handleChange}
        />*/}

        <Button disabled={!data.name} primary type="submit">
          <FormattedMessage id="CREATE" default="CREATE" />
        </Button>
      </Form>
    );
  }
}

export default class ProjectNewModal extends Component {
  handleOpen = () => {
    this.setState({ modalOpen: true });
  };

  handleClose = () => {
    this.setState({ modalOpen: false });
  };

  componentWillMount() {
    this.setState({ modalOpen: false });
  }

  render() {
    return (
      <Modal
        open={this.state.modalOpen}
        onClose={this.handleClose}
        onOpen={this.handleOpen}
        as="span"
        trigger={
          <Button
            size="small"
            primary
            icon="plus"
            disabled={this.props.disabled}
          />
        }
      >
        <Modal.Header>New project</Modal.Header>
        <Modal.Content image>
          <Modal.Description>
            {this.state.modalOpen && (
              <Edit
                resource={'project'}
                resourceId={'new'}
                defaultValue={defaultValue}
                component={NewForm}
                componentProps={{ ...this.props }}
              />
            )}
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}
