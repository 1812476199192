import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ErrorsMessage from './ErrorsMessage';
import Errors from '../../../models/Errors';

export default class FormFieldWithErrors extends Component {
  static propTypes = {
    errors: PropTypes.instanceOf(Errors),
    name: PropTypes.string,
    children: PropTypes.element.isRequired,
  };

  render() {
    const { children, errors, name } = this.props;
    if (errors) {
      const fieldErrors = errors.getErrors(name || children.props.name);
      const error = fieldErrors.length > 0;
      return [
        React.cloneElement(children, { error: error, key: '0' }),
        <ErrorsMessage errors={fieldErrors} key={'1'} />,
      ];
    } else {
      return children;
    }
  }
}
