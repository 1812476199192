import React, { Component } from 'react';
import PrimaryFields from '../fields/PrimaryFields';
import PropTypes from 'prop-types';
import SecondaryAndAuxiliaryFields from '../fields/SecondaryAndAuxiliaryFields';
import TemplateImage from '../TemplateImage';
import Errors from '../../../../../../models/Errors';
import Template from '../../../../../../models/template';
import Barcodes from '../barcode/Barcodes';
import LogoLogoTextHeaderFields from './LogoTextHeaderFields';
import CouponBottom from './CouponBottom';
import CouponTop from './CouponTop';

export default class Coupon extends Component {
  static propTypes = {
    previewLanguage: PropTypes.string.isRequired,
    errors: PropTypes.instanceOf(Errors).isRequired,
    data: PropTypes.instanceOf(Template).isRequired,
    itemSet: PropTypes.func.isRequired,
  };

  render() {
    const { data, previewLanguage, itemSet, errors } = this.props;
    const backgroundColor = data.getBackgroundColor();
    return (
      <div>
        <CouponTop backgroundColor={backgroundColor} />

        <LogoLogoTextHeaderFields
          data={data}
          previewLanguage={previewLanguage}
          errors={errors}
          itemSet={itemSet}
        />
        <TemplateImage
          itemSet={itemSet}
          data={data}
          type={'strip'}
          previewLanguage={previewLanguage}
        />

        <div
          style={{
            paddingLeft: 12,
            paddingRight: 12,
            position: 'absolute',
            top: 60,
            width: '100%',
            pointerEvents: 'none',
          }}
        >
          <PrimaryFields
            data={data}
            previewLanguage={previewLanguage}
            errors={errors}
            itemSet={itemSet}
          />
        </div>

        <div
          style={{
            paddingLeft: 12,
            paddingRight: 12,
          }}
        >
          <SecondaryAndAuxiliaryFields
            data={data}
            previewLanguage={previewLanguage}
            errors={errors}
            itemSet={itemSet}
          />
        </div>

        <Barcodes
          data={data}
          errors={errors}
          itemSet={itemSet}
          previewLanguage={previewLanguage}
        />
        <CouponBottom backgroundColor={backgroundColor} />
      </div>
    );
  }
}
