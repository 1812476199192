import messages from '../messagesDefine.js';

export const images = {
  background: {
    expected: `360 x 440`,
    validation: function(width, height) {
      const errors = [];
      if (width > 360)
        errors.push({
          code: messages.IMAGE_VALIDATION_WIDTH_EXPECTED_LESS_OR_EQUAL,
          params: { expected: 360, current: width },
        });
      if (height > 440)
        errors.push({
          code: messages.IMAGE_VALIDATION_HEIGHT_EXPECTED_LESS_OR_EQUAL,
          params: { expected: 440, current: height },
        });
      return errors;
    },
    //  help: `The background image (background.png) is displayed behind the entire front of the pass. The expected dimensions are 180 x 220 points. The image is cropped slightly on all sides and blurred. Depending on the image, you can often provide an image at a smaller size and let it be scaled up, because the blur effect hides details. This lets you reduce the file size without a noticeable difference in the pass.`
  },
  footer: {
    expected: `572 x 30`,
    validation: function(width, height) {
      const errors = [];
      if (width !== 572)
        errors.push({
          code: messages.IMAGE_VALIDATION_WIDTH_EXPECTED,
          params: { expected: 572, current: width },
        });
      if (height !== 30)
        errors.push({
          code: messages.IMAGE_VALIDATION_HEIGHT_EXPECTED,
          params: { expected: 30, current: height },
        });
      return errors;
    },
    //  help: `The footer image (footer.png) is displayed near the barcode. The allotted space is 286 x 15 points.`
  },
  icon: {
    expected: `58 x 58`,
    validation: function(width, height) {
      const errors = [];
      if (width !== 58)
        errors.push({
          code: messages.IMAGE_VALIDATION_WIDTH_EXPECTED,
          params: { expected: 58, current: width },
        });
      if (height !== 58)
        errors.push({
          code: messages.IMAGE_VALIDATION_HEIGHT_EXPECTED,
          params: { expected: 58, current: height },
        });
      return errors;
    },
    // help: `The icon (icon.png) is displayed when a pass is shown on the lock screen and by apps such as Mail when showing a pass attached to an email. The icon should measure 29 x 29 points.`
  },
  logo: {
    expected: `0-320 x 100`,
    validation: function(width, height) {
      const errors = [];
      if (width > 320)
        errors.push({
          code: messages.IMAGE_VALIDATION_WIDTH_EXPECTED_LESS_OR_EQUAL,
          params: { expected: 320, current: width },
        });
      if (height !== 100)
        errors.push({
          code: messages.IMAGE_VALIDATION_HEIGHT_EXPECTED,
          params: { expected: 100, current: height },
        });
      return errors;
    },
    // help: `The logo image (logo.png) is displayed in the top left corner of the pass, next to the logo text. The allotted space is 160 x 50 points; in most cases it should be narrower.`
  },

  strip: {
    expected: `640 x 168-246`,
    validation: function(width, height) {
      const errors = [];

      if (width !== 640)
        errors.push({
          code: messages.IMAGE_VALIDATION_WIDTH_EXPECTED,
          params: { expected: 640, current: width },
        });

      if (height > 246)
        errors.push({
          code: messages.IMAGE_VALIDATION_HEIGHT_EXPECTED_LESS_OR_EQUAL,
          params: { expected: 246, current: height },
        });

      if (height < 168)
        errors.push({
          code: messages.IMAGE_VALIDATION_HEIGHT_EXPECTED_MORE_OR_EQUAL,
          params: { expected: 168, current: height },
        });

      return errors;
    },
    //     help: `The strip image (strip.png) is displayed behind the primary fields.
    // On iPhone 6 and 6 Plus The allotted space is 375 x 98 points for event tickets, 375 x 144 points for gift cards and coupons, and 375 x 123 in all other cases.
    // On prior hardware The allotted space is 320 x 84 points for event tickets, 320 x 110 points for other pass styles with a square barcode on devices with 3.5 inch screens, and 320 x 123 in all other cases.`
  },

  thumbnail: {
    expected: `120-180 x 120-180, 2:3 - 3:2`,
    validation: function(width, height) {
      const errors = [];
      const max = Math.max(width, height);
      const min = Math.min(width, height);
      if (max !== 180 || min < 120) {
        errors.push({
          code: messages.IMAGE_VALIDATION_THUMBNAIL,
          params: { min: 120, max: 180, height, width },
        });
      }
      return errors;
    },
    // help: `The thumbnail image (thumbnail.png) displayed next to the fields on the front of the pass. The allotted space is 90 x 90 points. The aspect ratio should be in the range of 2:3 to 3:2, otherwise the image is cropped.`
  },
};

export const imageTypes = Object.keys(images);

export const imageTypesOptions = Object.keys(images).map(key => ({
  key: key,
  value: key,
  text: key + ` (${images[key].expected})`,
}));
