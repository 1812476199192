import React, { Component } from 'react';
import { Label, Table } from 'semantic-ui-react';
import { connect } from 'react-redux';

class About extends Component {
  render() {
    return (
      <Table celled>
        <Table.Body>
          <Table.Row>
            <Table.Cell colSpan="2">
              <Label ribbon>Console</Label>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Version</Table.Cell>
            <Table.Cell>{`${this.props.config.console.version}`}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Build</Table.Cell>
            <Table.Cell>
              {`${this.props.config.console.buildTimestamp}`}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Enviroment</Table.Cell>
            <Table.Cell>{`${this.props.config.console.env}`}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Enviroment color</Table.Cell>
            <Table.Cell>{`${this.props.config.console.envColor}`}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    );
  }
}

export default connect((state, props) => ({ config: state.config }), {})(About);
