import React from 'react';
import { Breadcrumb } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import ResourceLabel from './common/ResourceLabel';
import LabelTicketEshop from './common/LabelTicketEshop';
import LabelUser from './common/LabelUser';
import LabelTicketProduct from './common/LabelTicketProduct';
import { injectIntl } from 'react-intl';

class BreadcrumbImpl extends React.Component {
  isId() {
    const lastPartOfUrl = this.getLastPartOfUrl();
    return lastPartOfUrl.length === 36 && lastPartOfUrl.split('-').length === 5;
  }

  getLastPartOfUrl() {
    const { navigation } = this.props;
    return navigation.path.split('/').pop();
  }

  getTypeFromUrl() {
    const { navigation } = this.props;
    return navigation.path.split('/').slice(-2)[0];
  }

  getBreadcrumpParts() {
    const { navigation } = this.props;
    const breadcrumpParts = navigation.path.split('/');
    if (this.isId()) {
      breadcrumpParts.pop();
    }
    breadcrumpParts.shift();
    return breadcrumpParts;
  }

  getIdName() {
    const { navigation } = this.props;
    const type = this.getTypeFromUrl();
    const id = this.getLastPartOfUrl();
    if (type === 'ticketEshop') {
      return <LabelTicketEshop id={id} />;
    }
    if (type === 'user') {
      return <LabelUser id={id} />;
    }
    if (type === 'ticketProduct') {
      return <LabelTicketProduct id={id} />;
    }
    const resources = ['template', 'file', 'certificate', 'project'];
    return !resources.includes(type) ? (
      id
    ) : (
      <ResourceLabel resource={type} id={id} link={navigation.path} />
    );
  }

  getLabel(label) {
    const { intl } = this.props;
    if (label.includes('_new')) {
      return intl.formatHTMLMessage({ id: `MENU_NEW` });
    }
    return intl.formatHTMLMessage({ id: `MENU${label.toUpperCase()}` });
  }

  render() {
    let link = '';
    let label = '';
    const breadcrumpParts = this.getBreadcrumpParts();
    return (
      <div>
        {breadcrumpParts[0] !== 'error' &&
          breadcrumpParts.length > 1 && (
            <Breadcrumb
              key="breadCrump"
              size="massive"
              style={{
                marginBottom: 30,
              }}
              className="hide-print"
            >
              <Breadcrumb.Section key="home">
                <Link to="/">Home</Link>
              </Breadcrumb.Section>
              {breadcrumpParts.map((state, key) => {
                link += `/${state}`;
                label += `_${state}`;
                return (
                  <span key={key}>
                    <Breadcrumb.Divider icon="right chevron" />
                    <Breadcrumb.Section>
                      {!!key && <Link to={link}>{this.getLabel(label)}</Link>}
                      {!key && this.getLabel(label)}
                    </Breadcrumb.Section>
                  </span>
                );
              })}
              {this.isId() && (
                <span>
                  <Breadcrumb.Divider icon="right chevron" />
                  <Breadcrumb.Section key="id">
                    {this.getIdName()}
                  </Breadcrumb.Section>
                </span>
              )}
            </Breadcrumb>
          )}
      </div>
    );
  }
}

export default connect(state => ({
  config: state.config,
  viewer: state.viewer,
  navigation: state.navigation,
}))(injectIntl(BreadcrumbImpl));
