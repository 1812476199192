import { takeEvery, put } from 'redux-saga/effects';
import {
  CREATE_LOADING,
  CREATE_SUCCESS,
  READ_LOADING,
  DELETE_LOADING,
  UPDATE_LOADING,
} from '../actions/resource';

import { LIST_COMPLETE_LOADING } from '../actions/resourceListComplete';
import {
  LIST_PAGED_LOADING,
  LIST_PAGED_EXPORT_LOADING,
} from '../actions/resourceListPaged';

import { FETCH_REPORT_LOADING } from '../actions/index';
import {
  TEMPLATE_UPDATE_PASESS_BY_PUSH_LOADING,
  TEMPLATE_UPDATE_SAVE_TO_GOOLE_PAY_LOADING,
} from '../actions/template';
import { SEND_EMAIL_LOADING } from '../actions/sendEmail';

import { loadRegister, REGISTER_LOADING } from '../actions/register';
import { registerLoad } from './register';

import {
  AUTH_LOGOUT,
  AUTH_LOGIN,
  AUTH_CHANGE_PASSWORD,
  FETCH_VIEWER_LOADING,
  FETCH_VIEWER_SUCCESS,
} from '../actions/viewer';
import { create, read, update, remove } from './resource';
import { listComplete } from './resourceListComplete';
import { listPaged, listPagedExport } from './resourceListPaged';
import { fetchViewer, logout, login, changePassword } from './viewer';
import { fetchReport } from './report';
import {
  templateUpdatePassesByPush,
  templateUpdateSaveToGooglePay,
} from './template';
import { sendEmail } from './sendEmail';

import {
  TICKET_ESHOP_VERSIONS_LOADING,
  TICKET_ESHOP_VERSION_MESSAGES_LOADING,
} from '../actions/ticket';
import { ticketEshopFetchVersions, ticketEshopFetchMessage } from './ticket';
import { projectSaveBatch } from './project';
import { PROJECT_BATCH_SAVE_LOADING } from '../actions/project';
import { storageLoad, storageSave } from './localStorage';

import { SET_TOKEN } from '../actions/token';
import { tokenRefresh } from './token';

export default function*() {
  yield* [
    takeEvery(FETCH_VIEWER_SUCCESS, function*() {
      yield put(loadRegister('project'));
      yield put(loadRegister('template'));
      yield storageLoad();
    }),
    takeEvery(REGISTER_LOADING, registerLoad),

    takeEvery(TICKET_ESHOP_VERSIONS_LOADING, ticketEshopFetchVersions),
    takeEvery(TICKET_ESHOP_VERSION_MESSAGES_LOADING, ticketEshopFetchMessage),

    takeEvery(CREATE_LOADING, create),
    // When created project/template is necesary update rights
    takeEvery(CREATE_SUCCESS, function*(action) {
      if (action.resource === 'project' || action.resource === 'template') {
        yield fetchViewer();
      }
    }),

    takeEvery(LIST_PAGED_LOADING, listPaged),
    takeEvery(LIST_COMPLETE_LOADING, listComplete),
    takeEvery(LIST_PAGED_EXPORT_LOADING, listPagedExport),
    takeEvery(READ_LOADING, read),
    takeEvery(UPDATE_LOADING, update),
    takeEvery(DELETE_LOADING, remove),
    takeEvery(FETCH_VIEWER_LOADING, fetchViewer),
    takeEvery(FETCH_REPORT_LOADING, fetchReport),
    takeEvery(AUTH_CHANGE_PASSWORD, changePassword),
    takeEvery(AUTH_LOGOUT, logout),
    takeEvery(AUTH_LOGIN, login),
    takeEvery(SEND_EMAIL_LOADING, sendEmail),
    takeEvery(PROJECT_BATCH_SAVE_LOADING, projectSaveBatch),
    takeEvery(
      TEMPLATE_UPDATE_PASESS_BY_PUSH_LOADING,
      templateUpdatePassesByPush,
    ),
    takeEvery(
      TEMPLATE_UPDATE_SAVE_TO_GOOLE_PAY_LOADING,
      templateUpdateSaveToGooglePay,
    ),

    // This store ui settings in to local storage
    takeEvery(action => /UI_*/.test(action.type), storageSave),
    takeEvery(SET_TOKEN, tokenRefresh),
  ];
}
