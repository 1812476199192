import {
  FETCH_VIEWER_LOADING,
  FETCH_VIEWER_SUCCESS,
  FETCH_VIEWER_FAILURE,
  AUTH_LOGOUT,
} from '../actions/viewer';

import { Record } from 'immutable';
import Viewer from '../models/viewer';

const InitialState = Record({
  data: null,
  loading: false,
  error: null,
  fetched: false,
});

export default function viewer(state = new InitialState(), action) {
  switch (action.type) {
    case FETCH_VIEWER_LOADING:
      return state.set('loading', true);
    case FETCH_VIEWER_SUCCESS:
      return state
        .set('loading', false)
        .set('data', new Viewer(action.data))
        .set('fetched', true);
    case FETCH_VIEWER_FAILURE:
      return state.set('loading', false).set('fetched', true);
    case AUTH_LOGOUT:
      return state
        .set('loading', false)
        .set('data', null)
        .set('fetched', false);
    default:
      return state;
  }
}
