import React, { Component } from 'react';
import ResourceDataGrid from '../../common/ResourceDataGrid';
import { Menu, Segment } from 'semantic-ui-react';
import ResourceListPagedDecorator from '../../common/ResourceListPagedDecorator';
import Filter from '../../common/filter/Filter';
import LabelTicketEshop from '../../common/LabelTicketEshop';
import { connect } from 'react-redux';
import { uiSetFormTab } from '../../../actions/ui';
import { Link } from 'react-router';

const FORM_NAME = 'ticketAccounting';

let fields = {
  eshopId: {
    title: 'Eshop',
    input: 'text',
    readOnly: true,
    convertToValue: function(id) {
      return <LabelTicketEshop id={id}/>;
    },
  },
  timestamp: {
    title: 'Datum',
    type: 'date',
  },
  orderNumber: {
    title: 'Číslo objednávky',
    convertToValue: function(id, item) {
      if (item.orderId) {
        return <Link to={'/ticket/ticketorder/' + item.orderId}>
          {item.orderNumber}
        </Link>;
      } else {
        return item.orderNumber;
      }
    },
  },
  code: {
    title: 'Kód',
  },
  type: {
    title: 'Typ vstupenky',
  },
  price: {
    title: 'Cena [Kč]',
  },
  discountPercentage: {
    title: 'Sleva [%]',
  },
  discountAmount: {
    title: 'Sleva [Kč]',
  },
  discountedPrice: {
    title: 'Cena po slevě [Kč]',
  },
};

let fieldsSales = {
  eshopId: {
    title: 'Eshop',
    input: 'text',
    readOnly: true,
    convertToValue: function(id) {
      return <LabelTicketEshop id={id}/>;
    },
  },
  type: { title: 'Typ vstupenky' },
  count: { title: 'Počet prodaných kusů' },
  price: { title: 'Cena celkem bez slev [Kč]' },
  discountedPrice: {
    title: 'Cena celkem po uplatnění slev [Kč]',
  },
};

const filterProps = [
  {
    name: 'project',
    type: 'inUuid',
    columnName: 'projectId',
  },
  {
    name: 'eshopId',
    type: 'inUuid',
    columnName: 'eshopId',
  },
  {
    name: 'orderNumber',
    type: 'like',
    columnName: 'orderNumber',
  },
  {
    name: 'ticketAccountingFrom',
    type: 'gt',
    columnName: 'timestamp',
  },
  {
    name: 'ticketAccountingTo',
    type: 'lt',
    columnName: 'timestamp',
  },
];

class Accounting extends Component {
  componentWillMount() {
    this.setState({ activeItem: 'discounts' });
  }

  handleItemClick = (e, { value }) => this.props.uiSetFormTab(FORM_NAME, value);

  render() {
    const { form } = this.props;
    //TODO - print one page both statistics, but it must be solved as filter wrapper which wrap filter into nested wrappers

    let activeItem = 'discounts';
    if (form && form.tab) {
      activeItem = form.tab;
    }
    const filter = (
      <Filter
        project={true}
        eshopId={true}
        ticketAccountingFrom={true}
        ticketAccountingTo={true}
        orderNumber={activeItem === 'transactions'}
      />
    );

    return (
      <div>
        <Menu attached="top" tabular>
          <Menu.Item
            name="Sales"
            value="discounts"
            active={activeItem === 'discounts'}
            onClick={this.handleItemClick}
          />
          <Menu.Item
            name="Transactions"
            value="transactions"
            active={activeItem === 'transactions'}
            onClick={this.handleItemClick}
          />
        </Menu>
        {activeItem === 'discounts' && (
          <Segment attached="bottom">
            <h2>Sales</h2>
            <ResourceListPagedDecorator
              resource={'ticketSale'}
              component={ResourceDataGrid}
              componentProps={{
                filter: filter,
                filterProps: filterProps,
                resource: 'ticketSale',
                fields: fieldsSales,
                cols: ['eshopId', 'type', 'count', 'price', 'discountedPrice'],
                csv: true,
              }}
            />
          </Segment>
        )}
        {activeItem === 'transactions' && (
          <Segment attached="bottom">
            <h2>Transactions</h2>
            <ResourceListPagedDecorator
              resource={'ticketTransaction'}
              component={ResourceDataGrid}
              componentProps={{
                filter: filter,
                filterProps: filterProps,
                resource: 'ticketTransaction',
                fields: fields,
                cols: [
                  'eshopId',
                  'timestamp',
                  'orderNumber',
                  'code',
                  'type',
                  'price',
                  'discountPercentage',
                  'discountAmount',
                  'discountedPrice',
                ],
                csv: true,
              }}
            />
          </Segment>
        )}
      </div>
    );
  }
}

// This removes warnings for "undefined" react props
export default connect(
  (state, props) => ({ form: state.ui.forms[FORM_NAME] }),
  { uiSetFormTab },
)(Accounting);
