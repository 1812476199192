import { Form } from 'semantic-ui-react';
import React, { Component } from 'react';

export default class FormInputCheckbox extends Component {
  handleNumberChange = (e, p) => {
    const { onChange } = this.props;
    onChange(e, { name: p.name, value: p.checked });
  };

  render() {
    return (
      <Form.Checkbox
        name={this.props.name}
        label={this.props.label}
        value={this.props.value}
        defaultChecked={this.props.defaultChecked || this.props.defaultValue}
        toggle={this.props.toggle}
        onChange={this.handleNumberChange}
      />
    );
  }
}
