import React, { Component } from 'react';
import { Form, Button, Icon } from 'semantic-ui-react';
import FormGroupTimestamps from '../../common/FormGroupTimestamps';
import FormTextAreaJson from '../../common/FormTextAreaJSON';
import FormTab from '../../common/FormTab';
import ResourceItemDecorator from '../../common/ResourceItemDecorator';
import BackButton from '../../common/buttons/BackButton';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import FormTabPeriod from './FormTabPeriod';
import FormTabLocalization from './FormTabLocalization';
import FormTabGenaral from './FormTabGenaral';
import FormTabTicketService from './FormTabTicketService';
import jsonSchema from './jsonSchema';

const FORM_NAME = 'ticketProduct';

class ProductForm extends Component {
  static propTypes = {
    data: PropTypes.object,
    itemSet: PropTypes.func.isRequired,
    itemSetPropertyByPath: PropTypes.func.isRequired,
    itemSave: PropTypes.func.isRequired,
    itemCreate: PropTypes.func.isRequired,
  };

  componentWillMount() {
    this.validate();
  }

  handleChange = (e, p) => {
    const { itemSetPropertyByPath, data } = this.props;
    itemSetPropertyByPath(p.name, p.value, data);
    if (this.props.touched) {
      this.validate();
    }
  };

  validate = () => {
    // probably not the best way to get rid of Ajv
    return true
  };

  setTouched = () => {
    this.props.itemSetMetadata('touched', true);
  };

  handleSubmit = e => {
    this.setTouched();
    e.preventDefault();
    if (this.validate()) {
      if (this.props.data.id) {
        this.props.itemSave(this.props.data);
      } else {
        this.props.itemCreate(this.props.data);
      }
    }
  };

  handleApply = () => {
    this.setTouched();
    const { itemApply, data } = this.props;
    if (this.validate()) {
      itemApply(data);
    }
  };

  handleChangeJSON = (event, json) => {
    this.props.itemSet(json.value);
    if (this.props.touched) {
      this.validate();
    }
  };

  render() {
    let { data, changed, viewer, intl, ticketEshop } = this.props;
    const { errors } = this.state;
    const tabOptions = {
      form: {
        name: intl.formatMessage({ id: 'EDIT' }),
        icon: 'edit',
        content: (
          <FormTabGenaral
            onChange={this.handleChange}
            data={data}
            ticketEshop={ticketEshop}
            errors={errors}
          />
        ),
      },
      localization: {
        icon: 'globe',
        name: 'Localization',
        content: (
          <FormTabLocalization
            onChange={this.handleChange}
            data={data}
            ticketEshop={ticketEshop}
            errors={errors}
          />
        ),
      },
      period: {
        name: 'ValiditySpec',
        icon: 'time',
        content: <FormTabPeriod onChange={this.handleChange} data={data} />,
      },
    };

    if (viewer.isAdmin) {
      tabOptions.ticketService = {
        name: 'Ticket service',
        icon: 'ticket',
        content: (
          <FormTabTicketService onChange={this.handleChange} data={data} />
        ),
      };

      tabOptions.json = {
        icon: 'file text',
        name: 'Json',
        content: (
          <div>
            <FormTextAreaJson
              onChange={this.handleChangeJSON}
              label="JSON"
              name="json"
              defaultValue={data}
            />
            <FormTextAreaJson
              onChange={() => {}}
              label="JSON Schema"
              name="json"
              defaultValue={jsonSchema(this.props.ticketEshop.locales)}
            />
          </div>
        ),
      };
    }

    return (
      <Form>
        <FormTab
          tabOptions={tabOptions}
          formName={FORM_NAME}
          defaultTab="form"
        />
        <FormGroupTimestamps data={data} />

        <Button
          type="button"
          primary
          disabled={!changed}
          onClick={this.handleSubmit}
        >
          <Icon name="save" />
          <FormattedMessage id="BUTTON_SAVE" />
        </Button>

        {!!this.props.data.id && (
          <Button
            type="button"
            secondary
            onClick={this.handleApply}
            disabled={!changed}
          >
            <Icon name="save" />
            <FormattedMessage id="BUTTON_APPLY" />
          </Button>
        )}

        <BackButton />
      </Form>
    );
  }
}

const ProductFormWithIntl = injectIntl(ProductForm);

class ProductFormImpl extends Component {
  render() {
    return (
      <ProductFormWithIntl
        {...this.props.parentProps}
        ticketEshop={this.props.data}
      />
    );
  }
}

export default class ProductFormTicketEshopDecorator extends Component {
  render() {
    return (
      <ResourceItemDecorator
        resource={'ticketEshop'}
        resourceId={this.props.data.eshopId}
        parentProps={this.props}
        component={ProductFormImpl}
      />
    );
  }
}
