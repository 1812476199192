import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon, Accordion, Popup } from 'semantic-ui-react';

export default class ResourceDataGridObjectAccordion extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
  };

  state = { active: false };

  handleClick = e => {
    this.setState({ active: !this.state.active });
  };

  render() {
    const { data } = this.props;
    const { active } = this.state;
    return (
      <Accordion>
        <Accordion.Title active={active} onClick={this.handleClick}>
          <Icon name="dropdown" />
          <Popup
            inverted
            style={{ padding: 10 }}
            trigger={<span>Object</span>}
            content={
              <pre style={{ margin: 0, padding: 0 }}>
                {JSON.stringify(data, null, 2)}
              </pre>
            }
          />
        </Accordion.Title>
        <Accordion.Content active={active}>
          <pre className="json hljs">{JSON.stringify(data, null, 2)}</pre>
        </Accordion.Content>
      </Accordion>
    );
  }
}
