import React, { Component } from 'react';
import ResourceFormDropdown from './ResourceFormDropdown';

export default class FormDropdownCertificate extends Component {
  render() {
    return (
      <ResourceFormDropdown
        {...this.props}
        resource="certificate"
        label="Certificate"
        valueKey="id"
        textKey="passTypeId"
      />
    );
  }
}
