import { call, put, select } from 'redux-saga/effects';
import client from '../services/api';
import {
  LIST_COMPLETE_FAILURE,
  LIST_COMPLETE_SUCCESS,
} from '../actions/resourceListComplete';

import {
  getApiForResource,
  getApiResourceAlias,
  prepareFilter,
} from './resource';

export function* listComplete(action) {
  let resource = action.resource;
  let requestId = action.requestId;
  const success = action.success;
  const failure = action.failure;

  const list = yield select(state => {
    return state.resourceListComplete.get(requestId);
  });
  let order = list.get('order') || 'asc';
  let orderBy = list.get('orderBy') || 'id';
  let filterProps = list.get('filterProps');
  let filter = list.get('filter');
  let where = prepareFilter(filter, filterProps);
  const allData = [];
  const limit = 1000;
  try {
    let hasNext = true;
    let page = 1;

    while (hasNext) {
      let resourceQuery = `${getApiForResource(
        resource,
      )}/v1/${getApiResourceAlias(resource)}?where=${encodeURIComponent(
        JSON.stringify(where),
      )}&page=${page}&limit=${limit}&order=${order}&orderBy=${orderBy}&suppressCount`;

      let data = yield call(client, 'GET', resourceQuery, {});

      if (data && data.data) {
        data.data.forEach(i => {
          allData.push(i);
        });
      }

      page = page + 1;
      if (data.data === null || data.data.length < limit) {
        hasNext = false;
      }
    }
    const response = {
      data: allData,
      totalCount: allData.length,
    };

    if (success) {
      success(response, resource, requestId);
    }
    yield put({
      type: LIST_COMPLETE_SUCCESS,
      data: response,
      resource,
      requestId,
      filter,
    });
  } catch (e) {
    if (failure) failure(e, resource, requestId);
    yield put({
      type: LIST_COMPLETE_FAILURE,
      message: e.message,
      resource: resource,
      requestId,
      filter,
    });
  }
}
