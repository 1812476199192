import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ResourceDataGrid from '../../common/ResourceDataGrid';
import ResourceListPagedDecorator from '../../common/ResourceListPagedDecorator';
import ProjectNewModal from './ProjectNewModal';
import LabelProject from '../../common/LabelProject';
import { connect } from 'react-redux';
import Actions from './ProjectActions';
import Filter from '../../common/filter/Filter';

let fields = {
  name: {
    title: 'Name',
    input: 'text',
    readOnly: true,
  },
  parentId: {
    title: 'Parent',
    convertToValue: function(id, item) {
      return item.parentId ? <LabelProject id={item.parentId} /> : null;
    },
  },
  active: {
    title: 'Active',
    input: 'text',
    readOnly: true,
  },
  actions: {
    convertToValue: function(id, item) {
      return <Actions item={item} />;
    },
  },
};

const filterProps = [
  {
    name: 'name',
    type: 'like',
    columnName: 'name',
  },
  /*{
    name: 'project',
    type: 'in',
    columnName: 'parentId',
  }, */
];

const resource = 'project';

class ProjectList extends Component {
  static propTypes = {
    viewer: PropTypes.object.isRequired,
  };
  render() {
    const { viewer } = this.props;
    // const filter = <Filter name={true} project={true} projectProps={{ label:"Parent" }} />;
    const filter = <Filter name={true} />;

    return (
      <ResourceListPagedDecorator
        resource={'project'}
        component={ResourceDataGrid}
        componentProps={{
          resource: resource,
          filterProps: filterProps,
          filter: filter,
          fields: fields,
          newButton: viewer.isAdmin ? (
            <div style={{ float: 'right' }}>
              <ProjectNewModal />
            </div>
          ) : null,
          cols: ['name', 'parentId', 'active', 'actions'],
        }}
      />
    );
  }
}

export default connect((state, props) => ({ viewer: state.viewer.data }))(
  ProjectList,
);
