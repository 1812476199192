import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ResourceDataGrid from '../../common/ResourceDataGrid';
import { Link } from 'react-router';
import { Button, Icon, Dropdown, Popup } from 'semantic-ui-react';
import { listExport } from '../../../actions/resourceListPaged';
import Filter from '../../common/filter/Filter';
import { DISTRIBUTION_API_URL } from '../../../services/api';
import LabelProject from '../../common/LabelProject';
import LabelTemplate from '../../common/LabelTemplate';
import ResourceListPagedDecorator from '../../common/ResourceListPagedDecorator';
import { push } from '../../../actions/routing';

let fields = {
  dynamicData: {
    title: 'DynamicData',
  },
  devices: {
    title: 'Devices',
    orderBy: 'devices.total',
    convertToValue: function(id, item) {
      if (!item.devices) return null;

      return (
        <Popup
          on={['click', 'hover']}
          position="top center"
          trigger={<span>{item.devices.total}</span>}
          inverted
          style={{ padding: 10 }}
          content={
            <pre style={{ margin: 0, padding: 0 }}>
              {JSON.stringify(item.devices, null, 2)}
            </pre>
          }
        />
      );
    },
  },
  voided: {
    title: 'Voided',
  },
  templateId: {
    title: 'Template',
    convertToValue: function(id, item) {
      return <LabelTemplate id={id} />;
    },
  },
  projectId: {
    title: 'Project',
    convertToValue: function(id, item) {
      return <LabelProject id={item.projectId} />;
    },
  },
  createdAt: {
    title: 'CreatedAt',
    type: 'date',
  },
  updatedAt: {
    title: 'UpdatedAt',
    type: 'date',
  },
  actions: {
    convertToValue: function(id, item) {
      return <Actions item={item} />;
    },
  },
};

const resource = 'pass';

class _Actions extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
  };

  componentWillMount() {
    const { item } = this.props;
    const deleted = {};
    deleted[item.id] = false;
    this.setState({ deleted: deleted });
  }

  render() {
    const { viewer, item } = this.props;
    const { accessRights, isAdmin } = viewer.data;
    const canRead =
      isAdmin ||
      (item &&
        accessRights[item.projectId] &&
        accessRights[item.projectId].PASS_READ);
    const canUpdate =
      isAdmin ||
      (item &&
        accessRights[item.projectId] &&
        accessRights[item.projectId].PASS_UPDATE);

    return (
      <Button.Group widths="2">
        <Button
          as="a"
          href={DISTRIBUTION_API_URL + '/' + item.id + '?tag=console'}
          icon="unhide"
          target="_blank"
        />
        <Dropdown icon="content" button className="icon">
          <Dropdown.Menu style={{ width: 30 }}>
            <Dropdown.Item
              style={{ marginLeft: -5 }}
              as={Link}
              to={`/passes/${resource}/${item.id}`}
              icon="edit"
              disabled={!(canRead || canUpdate)}
            />
            <Dropdown.Item
              style={{ marginLeft: -5 }}
              as={Link}
              to={`${DISTRIBUTION_API_URL}/${item.id}?format=pdf&tag=console`}
              icon="print"
              target="_blank"
            />
            <Dropdown.Item
              style={{ marginLeft: -5 }}
              as={Link}
              to={`${DISTRIBUTION_API_URL}/${
                item.id
              }?format=qrcode&tag=console`}
              icon="qrcode"
              target="_blank"
            />
            <Dropdown.Item
              style={{ marginLeft: -5 }}
              as={Link}
              to={`${DISTRIBUTION_API_URL}/${
                item.id
              }?format=pkpass&tag=console`}
              icon="download"
              target="_blank"
            />
          </Dropdown.Menu>
        </Dropdown>
      </Button.Group>
    );
  }
}

const Actions = connect((state, props) => ({
  viewer: state.viewer,
}))(_Actions);

const filterProps = [
  {
    name: 'project',
    type: 'inUuid',
    columnName: 'projectId',
  },
  {
    name: 'template',
    type: 'inUuid',
    columnName: 'templateId',
  },
  {
    name: 'id',
    type: 'like',
    columnName: 'id',
  },
  {
    columnName: 'dynamicData',
    name: 'dynamicData',
    type: 'like',
  },
  {
    columnName: 'createdAt',
    name: 'createdAtFrom',
    type: 'gt',
  },
  {
    columnName: 'createdAt',
    name: 'createdAtTo',
    type: 'lt',
  },
  {
    columnName: 'devices.total',
    name: 'devices',
    type: 'gtOrEq',
  },
];

class NewButton extends Component {
  goToNew = e => {
    e.preventDefault();
    this.props.push(`/passes/${resource}/new`);
  };

  render() {
    return (
      <Button floated="right" size="small" primary onClick={this.goToNew}>
        <Icon name="plus" />New
      </Button>
    );
  }
}

const NewButtonImplementation = connect((state, props) => ({}), { push })(
  NewButton,
);

class PassList extends Component {
  render() {
    const { viewer } = this.props;
    const canCreate = viewer.accessRights.PASS_CREATE || viewer.isAdmin;
    const newButton = canCreate ? <NewButtonImplementation /> : null;
    const filter = (
      <Filter
        project={true}
        template={true}
        dynamicData={true}
        id={true}
        twoLine={true}
        createdFrom={true}
        createdTo={true}
        downloads={false}
        devices={true}
      />
    );

    const componentProps = {
      newButton: newButton,
      filterProps: filterProps,
      filter: filter,
      fields: fields,
      cols: [
        'projectId',
        'templateId',
        'dynamicData',
        'devices',
        'voided',
        'createdAt',
        'updatedAt',
        'actions',
      ],
      csv: true,
    };

    return (
      <ResourceListPagedDecorator
        resource={'pass'}
        component={ResourceDataGrid}
        componentProps={componentProps}
      />
    );
  }
}

export default connect(
  (state, props) => ({
    viewer: state.viewer.data,
  }),
  { listExport },
)(PassList);
