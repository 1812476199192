import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Button } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { addNotification } from '../../../../../actions/notification';
import { injectIntl } from 'react-intl';
import { templateUpdateSaveToGooglePay } from '../../../../../actions/template';

class SaveToGooglePayTab extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    itemSet: PropTypes.func.isRequired,
    canUpdate: PropTypes.bool,
    changed: PropTypes.bool,
  };

  handleChange = (e, p) => {
    const { itemSet, data } = this.props;
    itemSet(data.set(p.name, p.value));
  };

  handleSaveToGooglePay = () => {
    const { data, templateUpdateSaveToGooglePay } = this.props;
    templateUpdateSaveToGooglePay(data.id);
  };

  render() {
    let { data, intl, changed } = this.props;

    return (
      <Form>
        <Form.TextArea
          autoHeight={true}
          label={intl.formatMessage({
            id: 'SAVE_TO_GOOGLE_PAY_OBJECT_TEMPLATE',
          })}
          onChange={this.handleChange}
          value={data.get('saveToGooglePayObjectTemplate')}
          name="saveToGooglePayObjectTemplate"
        />
        <Button onClick={this.handleSaveToGooglePay} disabled={changed}>
          {intl.formatMessage({ id: 'SAVE_TO_GOOGLE_PAY_UPDATE_ALL_PASSES' })}
        </Button>
      </Form>
    );
  }
}

export default connect(state => ({}), {
  addNotification,
  templateUpdateSaveToGooglePay,
})(injectIntl(SaveToGooglePayTab));
