import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ResourceDataGrid from '../../common/ResourceDataGrid';
import { Link } from 'react-router';
import { Button, Icon } from 'semantic-ui-react';
import JsonModal from '../../common/JsonModal';
import ResourceListPagedDecorator from '../../common/ResourceListPagedDecorator';
import { FormattedHTMLMessage } from 'react-intl';
import LabelProject from '../../common/LabelProject';
import Filter from '../../common/filter/Filter';

let fields = {
  name: {
    title: 'Name',
  },
  passTypeId: {
    title: 'Pass Type Identifier',
  },
  expiration: {
    title: 'Expiration',
  },
  projectId: {
    title: <FormattedHTMLMessage id="PROJECT" />,
    input: 'text',
    readOnly: true,
    convertToValue: function(id, item) {
      return <LabelProject id={item.projectId} />;
    },
  },
  actions: {
    convertToValue: function(id, item) {
      return <Actions item={item} />;
    },
  },
};
const resource = 'certificate';

class Actions extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
  };

  render() {
    return (
      <Button.Group>
        <JsonModal
          title={`Certificate ${this.props.item.id}`}
          data={this.props.item}
        />
        <Button
          as={Link}
          to={'/passes/' + resource + '/' + this.props.item.id}
          icon="edit"
        />
      </Button.Group>
    );
  }
}

class NewButton extends Component {
  render() {
    return (
      <Button
        as={Link}
        to={'/passes/' + resource + '/new'}
        floated="right"
        primary
        size="small"
      >
        <Icon name="plus" />New
      </Button>
    );
  }
}

const filterProps = [
  {
    name: 'project',
    type: 'inUuid',
    columnName: 'projectId',
  },
  {
    name: 'name',
    type: 'like',
    columnName: 'name',
  },
  {
    name: 'passTypeId',
    type: 'like',
    columnName: 'passTypeId',
  },
];

export default class CertificateList extends Component {
  render() {
    const filter = <Filter project={true} name={true} passTypeId={true} />;
    return (
      <ResourceListPagedDecorator
        resource={resource}
        component={ResourceDataGrid}
        componentProps={{
          newButton: <NewButton />,
          filterProps: filterProps,
          filter: filter,
          resource: resource,
          fields: fields,
          cols: ['projectId', 'name', 'passTypeId', 'expiration', 'actions'],
        }}
      />
    );
  }
}
