import {
  REGISTER_LOADING,
  REGISTER_FAILURE,
  REGISTER_SUCCESS,
} from '../actions/register';

import {
  READ_SUCCESS,
  CREATE_SUCCESS,
  UPDATE_SUCCESS,
} from '../actions/resource';

import { LIST_COMPLETE_SUCCESS } from '../actions/resourceListComplete';
import { LIST_PAGED_SUCCESS } from '../actions/resourceListPaged';

import { Record, Map } from 'immutable';

const RegisterStruct = Record({
  resource: null,
  data: new Map(),
  loading: false,
  error: null,
});

export default function(state = new Map(), action) {
  const { data, error, resource } = action;
  let newState = state;
  switch (action.type) {
    case REGISTER_LOADING:
      if (!state.get(resource)) {
        newState = state.set(
          resource,
          new RegisterStruct({
            resource,
            data: new Map(),
            loading: true,
          }),
        );
      }
      return newState.setIn([resource, 'loading'], true);
    case REGISTER_SUCCESS:
      let map = newState.getIn([resource, 'data']);
      const list = !!data.data ? data.data : [];
      list.map((i, index) => {
        map = map.set(i.id, i);
        return map;
      });
      return state
        .setIn([resource, 'loading'], false)
        .setIn([resource, 'data'], map);
    case REGISTER_FAILURE:
      return state
        .setIn([resource, 'loading'], false)
        .setIn([resource, 'data'], null)
        .setIn([resource, 'error'], error);

    // TODO - may be not only project in future
    case READ_SUCCESS:
    case CREATE_SUCCESS:
    case UPDATE_SUCCESS:
      if (resource === 'project' || resource === 'template') {
        let project = newState.get(resource);
        let mapI = project.data;
        mapI = mapI.set(data.id, data);
        newState = newState.setIn([resource, 'data'], mapI);
      }
      return newState;

    // TODO - may be not only project in future
    case LIST_PAGED_SUCCESS:
    case LIST_COMPLETE_SUCCESS: {
      if (resource === 'project' || resource === 'template') {
        if (!state.get(resource)) {
          newState = state.set(
            resource,
            new RegisterStruct({
              resource,
              data: new Map(),
              loading: true,
            }),
          );
        }

        let map = newState.getIn([resource, 'data']);
        const list = !!data.data ? data.data : [];
        list.map((i, index) => {
          map = map.set(i.id, i);
          return map;
        });
        return newState.setIn([resource, 'data'], map);
      }
      return state;
    }

    default:
      return state;
  }
}
