import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Grid } from 'semantic-ui-react';
import DesignerTabRightForm from './DesignerTabRightForm';
import { injectIntl } from 'react-intl';
import FormDropdownLanguages from './../../../../common/FormDropdownLanguages';
import { uiSetTemplatePreviewLanguage } from '../../../../../actions/ui';
import { connect } from 'react-redux';
import Designer from './Designer';
import Errors from '../../../../../models/Errors';
import Template from '../../../../../models/template';
import { FormattedHTMLMessage } from 'react-intl';
import { Segment, Label } from 'semantic-ui-react';

class DesignerTab extends Component {
  static propTypes = {
    hasFrontFieldError: PropTypes.bool.isRequired,
    hasBackFieldError: PropTypes.bool.isRequired,
    hasBarcodeError: PropTypes.bool.isRequired,
    errors: PropTypes.instanceOf(Errors).isRequired,
    data: PropTypes.instanceOf(Template).isRequired,
    itemSet: PropTypes.func.isRequired,
    uiSetTemplatePreviewLanguage: PropTypes.func.isRequired,
    windowWidth: PropTypes.number.isRequired,
    templatePreviewLanguage: PropTypes.string,
    intl: PropTypes.object.isRequired,
  };

  componentWillMount() {
    const { uiSetTemplatePreviewLanguage, data } = this.props;
    // fix contains template language
    const languages = data.get('languages');
    uiSetTemplatePreviewLanguage(languages.get(0));
  }

  languageChange = (e, { value }) => {
    const { uiSetTemplatePreviewLanguage } = this.props;
    uiSetTemplatePreviewLanguage(value);
  };

  render() {
    const {
      data,
      intl,
      templatePreviewLanguage,
      itemSet,
      windowWidth,
      errors,
      hasBackFieldError,
      hasFrontFieldError,
      hasBarcodeError,
    } = this.props;
    const languages = data.get('languages');

    const previewLanguage = templatePreviewLanguage || languages.get(0);

    if (windowWidth < 500) {
      return (
        <Segment padded>
          <Label color={'red'} attached="top">
            <FormattedHTMLMessage id="OOPS" />
          </Label>
        </Segment>
      );
    }

    return (
      <div style={{ minHeight: 1000 }}>
        <Grid doubling columns={2}>
          <Grid.Column width={8}>
            <Designer
              data={data}
              itemSet={itemSet}
              errors={errors}
              windowWidth={windowWidth}
              previewLanguage={previewLanguage}
              hasBackFieldError={hasBackFieldError}
              hasFrontFieldError={hasFrontFieldError}
              hasBarcodesError={hasBarcodeError}
            />

            <Form>
              <FormDropdownLanguages
                onChange={this.languageChange}
                selection={true}
                label={`${intl.formatHTMLMessage({
                  id: 'PREVIEW_LANGUAGE',
                })}`}
                options={languages}
                name="previewLanguage"
                value={previewLanguage}
                multiple={false}
              />
            </Form>
          </Grid.Column>
          <Grid.Column width={8}>
            <DesignerTabRightForm
              data={data}
              itemSet={itemSet}
              errors={errors}
              previewLanguage={previewLanguage}
            />
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

export default connect(
  (state, props) => ({
    windowWidth: state.ui.windowWidth,
    templatePreviewLanguage: state.ui.templatePreviewLanguage,
  }),
  { uiSetTemplatePreviewLanguage },
)(injectIntl(DesignerTab));
