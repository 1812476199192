import React, { Component } from 'react';
import ProductForm from './Form';
import Edit from '../../common/Edit';
import ResourceListCompleteDecorator from '../../common/ResourceListCompleteDecorator';

const defaultValue = {
  name: 'Dětská',
  title: {
    cs: 'Dětská',
    en: 'Child',
  },
  order: 1,
  units: 'CZK',
  priceWithoutVat: 150,
  vat: 0,
  priceWithVat: 150,
  period: {
    daysMaximalValidity: 30,
    periodType: 'days',
  },
  numberServiceConfig: {},
  numberServiceUrl: 'simple',
  maxItems: 5,
  maxEntries: 1,
  warningEnabled: true,
  warningTitle: {
    cs: 'Při vstupu je potřeba prokázat věk dítěte.',
    en: 'On entry you will ask for proof age of children.',
  },
  active: false,
};

class ProductEdit extends Component {
  render() {
    if (this.props.list.loading) {
      // TODO return loading component
      return null;
    }

    if (this.props.list.data.length <= 0) {
      // TODO return no esho avalable
      return null;
    }

    defaultValue.eshopId = this.props.list.data[0].id;

    return (
      <Edit
        resource={'ticketProduct'}
        resourceId={'new'}
        component={ProductForm}
        defaultValue={defaultValue}
      />
    );
  }
}

export default class ProductNewTicketeshopIdSelect extends Component {
  render() {
    return (
      <ResourceListCompleteDecorator
        resource={'ticketEshop'}
        component={ProductEdit}
      />
    );
  }
}
