import {
  UI_SET_JSON_EDIT_FORMAT,
  UI_FORM_SET_TAB,
  UI_SET_SCROLL_HEIGHT,
  UI_SET_WINDOW_HEIGHT,
  UI_SET_DRAG_AND_DROP_PROPERTY,
  UI_SET_TEMPLATE_PREVIEV_LANGUAGE,
  UI_LOAD_FORM_STORE_SUCCESS,
  UI_SET_TEMPLATE_LIST_VALIDATION,
  UI_SET_TEMPLATE_FORCE_OPEN,
} from '../actions/ui';
import _ from 'lodash';
import uiJsonEditFormats from '../constants/uiJsonEditFormats';

export default function(
  state = {
    jsonEditFormat: uiJsonEditFormats[0],
    forms: {},
    scrollHeight: 0,
    windowWidth: window.innerWidth,
    dragAndDrop: {},
    templatePreviewLanguage: null,
    templateListValidation: false,
    templateForceOpen: null,
  },
  action,
) {
  switch (action.type) {
    case UI_SET_WINDOW_HEIGHT:
      state.windowWidth = action.value;
      return _.merge({}, state);
    case UI_SET_SCROLL_HEIGHT:
      state.scrollHeight = action.value;
      return _.merge({}, state);
    case UI_SET_JSON_EDIT_FORMAT:
      state.jsonEditFormat = action.format;
      return _.merge({}, state);

    case UI_FORM_SET_TAB:
      if (!state.forms[action.form]) {
        state.forms[action.form] = {};
      }
      state.forms[action.form].tab = action.tab;
      return _.merge({}, state);

    case UI_SET_DRAG_AND_DROP_PROPERTY: {
      state.dragAndDrop[action.property] = action.value;
      return _.merge({}, state);
    }
    case UI_SET_TEMPLATE_PREVIEV_LANGUAGE: {
      state.templatePreviewLanguage = action.value;
      return _.merge({}, state);
    }

    case UI_LOAD_FORM_STORE_SUCCESS: {
      state.forms = action.data.forms;
      state.templateListValidation = action.data.templateListValidation;
      return _.merge({}, state);
    }

    case UI_SET_TEMPLATE_LIST_VALIDATION: {
      state.templateListValidation = action.value;
      return _.merge({}, state);
    }

    case UI_SET_TEMPLATE_FORCE_OPEN: {
      state.templateForceOpen = action.value;
      return _.merge({}, state);
    }

    default:
      return state;
  }
}
