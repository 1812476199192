import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  listInit,
  listLoad,
  listDestroy,
  listMergeProps,
} from '../../actions/resourceListPaged';

import { push } from '../../actions/routing';
import { AUTO_DISMISS } from '../../constants/toast.js';
import { addNotification } from '../../actions/notification';
import WithRequestIdClass from './WithRequestIdComponent';

class ResourceListPagedDecorator extends Component {
  static propTypes = {
    list: PropTypes.object,
    resource: PropTypes.string.isRequired,
    push: PropTypes.func.isRequired,
    listInit: PropTypes.func.isRequired,
    listLoad: PropTypes.func.isRequired,
    listMergeProps: PropTypes.func.isRequired,
    listDestroy: PropTypes.func.isRequired,
    addNotification: PropTypes.func.isRequired,
    component: PropTypes.func.isRequired,
    componentProps: PropTypes.object,
  };

  componentWillMount() {
    this.listMergeProps({
      limit: this.props.limit || 20,
      page: this.props.page || 1,
      filter: this.props.filter || this.props.globalFilter || {},
      filterProps:
        (this.props.componentProps
          ? this.props.componentProps.filterProps
          : null) ||
        this.props.filterProps ||
        [],
      order: this.props.order || 'desc',
      orderBy: this.props.orderBy || 'updatedAt',
    });
    this.listLoad();
  }

  componentWillUnmount() {
    this.props.listDestroy(this.props.resource, this.props.requestId);
  }

  listMergeProps = value => {
    this.props.listMergeProps(this.props.resource, this.props.requestId, value);
  };

  listSetLimit = limit => {
    this.listMergeProps({ limit: limit, page: 1 });
    this.listLoad();
  };

  listSetOrder = (orderBy, order) => {
    this.listMergeProps({ orderBy: orderBy, order: order });
    this.listLoad();
  };

  listSetPage = page => {
    this.listMergeProps({ page: page });
    this.listLoad();
  };

  listLoadSuccess = (data, resource, requestId) => {};

  listLoadFailure = (e, resource) => {
    this.props.push(`/error/${e.status || 500}`);
    this.props.addNotification({
      title: `List of "${resource}" fetch failure.`,
      message: e.message,
      level: 'error',
      autoDismiss: AUTO_DISMISS,
    });
  };

  listLoad = (
    success = this.listLoadSuccess,
    failure = this.listLoadFailure,
  ) => {
    let { resource } = this.props;
    this.props.listLoad(resource, this.props.requestId, success, failure);
  };

  onFilterSubmit = () => {
    this.listMergeProps({ page: 1 });
    this.listLoad();
  };

  onFilterChange = filter => {
    this.listMergeProps({ filter: filter });
  };

  render() {
    const { list, viewer, resource, componentProps } = this.props;
    const childrenProps = {
      resource: resource,
      list: list,
      viewer: viewer,
      listMergeProps: this.listMergeProps,
      listLoad: this.listLoad,
      listSetLimit: this.listSetLimit,
      listSetPage: this.listSetPage,
      listSetOrder: this.listSetOrder,
      onFilterSubmit: this.onFilterSubmit,
      onFilterChange: this.onFilterChange,
      filterProps: (componentProps ? componentProps.filterProps : null) || [],
      requestId: this.props.requestId,
      parentProps: this.props.parentProps,
    };

    if (list) {
      const ChildComponent = this.props.component;
      return <ChildComponent {...childrenProps} {...componentProps} />;
    } else return null;
  }
}
const ResourceListPagedDecoratorWithConnect = connect(
  (state, props) => ({
    list: state.resourceListPaged.get(props.requestId),
    viewer: state.viewer.data,
    globalFilter: state.filter,
  }),
  { push, listLoad, listDestroy, listInit, listMergeProps, addNotification },
)(ResourceListPagedDecorator);

export default class ResourceItemDecoratorClass extends Component {
  render() {
    return (
      <WithRequestIdClass>
        <ResourceListPagedDecoratorWithConnect {...this.props} />
      </WithRequestIdClass>
    );
  }
}
