export const SET_TOKEN = 'SET_TOKEN';
export const UNSET_TOKEN = 'UNSET_TOKEN';

export function setToken(token) {
  return {
    type: SET_TOKEN,
    token,
  };
}

export function unsetToken() {
  return {
    type: UNSET_TOKEN,
  };
}
