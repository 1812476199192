import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Button, Icon } from 'semantic-ui-react';
import JsonSchemaForm from '../../common/JsonSchemaForm';
import FormSelectTemplate from '../../common/FormDropdownTemplate';
import moment from 'moment';
import { FormattedMessage, injectIntl } from 'react-intl';
import BackButton from '../../common/buttons/BackButton';
import DeleteButton from './../../../components/common/buttons/DeleteButton';
import { DISTRIBUTION_API_URL } from '../../../services/api';
import { itemDelete } from '../../../actions/resource';
import FormInputDateTime from '../../common/FormInputDateTime';
import FormTab from '../../common/FormTab';
import FormTextAreaJson from '../../common/FormTextAreaJSON';
import PassImages from './PassImages';
import SaveToGooglePay from './PassSaveToGooglePay';

class PassEdit extends Component {
  static propTypes = {
    data: PropTypes.object,
    parentProps: PropTypes.object,
  };

  constructor(props) {
    super(props);
    moment.locale(moment.locale(this.props.intl.currentLocale));
  }

  componentWillMount() {
    this.setState({ deleted: false });
  }

  handleSubmit = () => {
    const { parentProps: { itemSave, data } } = this.props;
    itemSave(data);
  };

  handleApply = () => {
    const { parentProps: { itemApply, data } } = this.props;
    itemApply(data);
  };

  handleDelete = id => {
    const { parentProps: { itemDelete } } = this.props;
    itemDelete();
  };

  handleDynamicDataChange = dynamicData => {
    const { parentProps: { itemSet, data } } = this.props;
    data.dynamicData = dynamicData;
    itemSet(data);
  };

  handleVoided = (e, p) => {
    const { parentProps: { itemSet, data } } = this.props;
    data.voided = p.checked;
    itemSet(data);
  };

  handleTemplateChange = (e, p) => {
    const { parentProps: { itemSet, data } } = this.props;
    data.templateId = p.value;
    // TODO - may be clear dynamicData when template is changed
    itemSet(data);
  };

  handleExpirationDateChange = (e, { name, value }) => {
    const { parentProps: { itemSet, data } } = this.props;
    if (typeof value !== 'undefined' && value !== null) {
      data[name] = value;
    } else {
      delete data[name];
    }
    itemSet(data);
  };

  handleChangeJSON = (e, p) => {
    let { parentProps: { itemSet } } = this.props;
    itemSet(p.value);
  };

  getValue = () => {
    const { parentProps: { data } } = this.props;
    return data.expirationDate ? moment(data.expirationDate) : null;
  };

  setImage = (key, value) => {
    const { parentProps: { data, itemSet } } = this.props;
    if (!data.dynamicImages) {
      data.dynamicImages = {};
    }
    data.dynamicImages[key] = value;
    itemSet(data);
  };

  render() {
    const { parentProps, data, viewer, intl } = this.props;
    const template = data;
    const pass = parentProps.data;
    const changed = parentProps.changed;
    const accessRights = viewer.accessRights;
    const canUpdate =
      viewer.isAdmin ||
      (template &&
        accessRights[template.projectId] &&
        accessRights[template.projectId].PASS_UPDATE);
    const canDelete =
      !pass.deletedAt &&
      (viewer.isAdmin ||
        (template &&
          accessRights[template.projectId] &&
          accessRights[template.projectId].PASS_DELETE));

    const schema = template.dynamicDataJsonSchema;
    schema.type = 'object';

    const tabOptions = {
      general: {
        name: intl.formatMessage({ id: 'GENERAL' }),
        icon: 'edit',
        content: (
          <Form key={0}>
            <FormSelectTemplate
              onChange={this.handleTemplateChange}
              defaultValue={pass.templateId}
            />

            <FormInputDateTime
              name={'expirationDate'}
              label={intl.formatMessage({ id: 'EXPIRATION_DATE' })}
              defaultValue={pass.expirationDate}
              onChange={this.handleExpirationDateChange}
            />

            <Form.Checkbox
              style={{ marginTop: 20 }}
              label="Voided"
              defaultChecked={pass.voided}
              onChange={this.handleVoided}
            />
          </Form>
        ),
      },
      dynamicData: {
        name: intl.formatMessage({ id: 'DYNAMIC_DATA' }),
        content: (
          <JsonSchemaForm
            schema={schema}
            onChange={this.handleDynamicDataChange}
            formData={pass.dynamicData}
            uiSchema={{}}
          />
        ),
      },
    };

    if (data.jsonTemplate) {
      tabOptions.dynamicImages = {
        name: intl.formatMessage({ id: 'DYNAMIC_IMAGES' }),
        icon: 'image',
        content: (
          <PassImages
            pass={pass}
            languages={data.languages}
            images={pass.dynamicImages}
            projectId={data.projectId}
            onChange={this.setImage}
          />
        ),
      };
    }

    if (viewer.isAdmin) {
      tabOptions.json = {
        name: intl.formatMessage({ id: 'JSON' }),
        icon: 'file text',
        content: (
          <div>
            <Form>
              <FormTextAreaJson
                onChange={this.handleChangeJSON}
                label="JSON"
                name="json"
                defaultValue={pass}
              />
            </Form>
          </div>
        ),
      };
    }

    if (data.saveToGooglePayObjectTemplate) {
      tabOptions.saveToGooglePay = {
        error: false,
        icon: 'google',
        name: 'Save to Google Pay',
        content: (
          <SaveToGooglePay template={data} pass={pass} changed={changed} />
        ),
      };
    }

    return (
      <div>
        <FormTab tabOptions={tabOptions} formName="pass" defaultTab="general" />
        <br />
        <br />
        {canUpdate && (
          <Button
            primary
            type="button"
            onClick={this.handleSubmit}
            disabled={!changed}
          >
            <Icon name="save" />
            <FormattedMessage id="BUTTON_SAVE" />
          </Button>
        )}
        {canUpdate && (
          <Button secondary onClick={this.handleApply} disabled={!changed}>
            <Icon name="save" />
            <FormattedMessage id="BUTTON_APPLY" />
          </Button>
        )}
        <Button
          as="a"
          href={DISTRIBUTION_API_URL + '/' + pass.id + '?tag=console'}
          target="_blank"
        >
          <Icon name="unhide" />
          <FormattedMessage id="BUTTON_VIEW" defaultMessage="BUTTON_VIEW" />
        </Button>
        <Button
          as="a"
          target="_blank"
          href={
            DISTRIBUTION_API_URL + '/' + pass.id + '?format=pkpass&tag=console'
          }
        >
          <Icon name="download" />
          <FormattedMessage
            id="BUTTON_DOWNLOAD"
            defaultMessage="BUTTON_DOWNLOAD"
          />
        </Button>
        <Button
          as="a"
          href={
            DISTRIBUTION_API_URL + '/' + pass.id + '?format=pdf&tag=console'
          }
          target="_blank"
        >
          <Icon name="print" />
          <FormattedMessage id="BUTTON_PRINT" defaultMessage="BUTTON_PRINT" />
        </Button>
        <Button
          as="a"
          href={
            DISTRIBUTION_API_URL + '/' + pass.id + '?format=qrcode&tag=console'
          }
          target="_blank"
        >
          <Icon name="qrcode" />
          <FormattedMessage id="BUTTON_QR" defaultMessage="BUTTON_QR" />
        </Button>

        {canDelete && (
          <DeleteButton onDelete={() => this.handleDelete(pass.id)} />
        )}
        <BackButton />
      </div>
    );
  }
}

export default injectIntl(
  connect((state, props) => ({ intl: state.intl }), {
    itemDelete,
  })(injectIntl(PassEdit)),
);
