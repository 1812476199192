import React, { Component } from 'react';
import RegisterItemDecorator from '../RegisterItemDecorator';
import { DISTRIBUTION_API_URL } from '../../../services/api';
import Preview from './Preview';
import { Icon } from 'semantic-ui-react';

export default class PreviewResource extends Component {
  getPreviewSize = () => {
    const { passStyle, type, data, previewColor } = this.props;
    let size = {
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      color: previewColor,
    };

    if (type === 'strip') {
      size.width = 320;
      size.height =
        passStyle === 'coupon' || passStyle === 'storeCard' ? 122 : 84;
      size.backgroundSize = 'cover';
      size.backgroundPosition = 'center';
    }

    if (type === 'icon') {
      size.width = 29;
      size.height = 29;
    }

    if (type === 'logo') {
      if (data) {
        size.width = parseInt(data.width / 2, 10);
        size.height = parseInt(data.height / 2, 10);
      } else {
        size.width = 160;
        size.height = 50;
      }
      size.maxWidth = 160;
      size.maxHeight = 50;
      size.float = 'left';
    }

    if (type === 'footer') {
      size.width = 300;
      size.height = 20;
      size.backgroundPosition = 'center';
      size.backgroundSize = 'cover';
    }

    if (type === 'thumbnail') {
      size.width = 90;
      size.height = 90;
    }

    if (type === 'background') {
      size.width = 360;
      size.height = 440;
    }
    return size;
  };

  renderPreview = () => {
    const { id, onClick, type, style } = this.props;

    const size = this.getPreviewSize();

    if (!id) {
      if (type === 'icon') {
        return (
          <img
            className="designerHoverItem"
            alt="presentation"
            src={`${DISTRIBUTION_API_URL}/icon.png`}
            style={style}
            onClick={onClick}
            width={29}
            height={29}
          />
        );
      }

      return (
        <div
          onClick={onClick}
          className="designerHoverItem emptyPreview"
          style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            cursor: 'pointer',
            ...size,
          }}
        >
          <Icon name="image" />
        </div>
      );
    }

    return (
      <RegisterItemDecorator
        resourceId={id}
        resource="image"
        component={Preview}
        componentProps={{
          ...this.props,
          onClick: onClick,
          size: size,
        }}
      />
    );
  };

  render() {
    const { previewContainerStyle } = this.props;
    return <div style={previewContainerStyle}>{this.renderPreview()}</div>;
  }
}
