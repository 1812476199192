import React, { Component } from 'react';
import FormDropdownNumberService from './partials/FormDropdownNumberService';
import FormTextAreaJSON from '../../common/FormTextAreaJSON';

export default class FormTabTicketService extends Component {
  render() {
    const { data, onChange } = this.props;
    return (
      <div>
        <FormDropdownNumberService
          onChange={onChange}
          name="numberServiceUrl"
          value={data.numberServiceUrl}
        />

        {/* TODO implement form for each service number type*/}
        {data.numberServiceUrl !== 'simple' && (
          <FormTextAreaJSON
            onChange={onChange}
            label="Number service config"
            name="numberServiceConfig"
            defaultValue={data.numberServiceConfig}
          />
        )}
      </div>
    );
  }
}
