import { Form } from 'semantic-ui-react';
import React, { Component } from 'react';

export default class FormInputNumber extends Component {
  handleNumberChange = (e, p) => {
    const { onChange } = this.props;
    onChange(e, { name: p.name, value: Number(p.value) });
  };

  render() {
    return (
      <Form.Input
        name={this.props.name}
        label={this.props.label}
        value={this.props.value}
        disabled={this.props.disabled}
        defaultValue={this.props.defaultValue}
        type="number"
        onChange={this.handleNumberChange}
      />
    );
  }
}
