import React, { Component } from 'react';
import PassForm from './PassForm';
import ResourceItemDecorator from '../../common/ResourceItemDecorator';
export default class PassEditTemplate extends Component {
  render() {
    return (
      <ResourceItemDecorator
        resource={'template'}
        resourceId={this.props.data.templateId}
        parentProps={this.props}
        component={PassForm}
      />
    );
  }
}
