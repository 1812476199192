import React, { Component } from 'react';
import shortid from 'shortid';

export default class WithRequestIdClass extends Component {
  constructor(props) {
    super(props);
    this.requestId = shortid.generate();
  }

  render() {
    return React.cloneElement(this.props.children, {
      requestId: this.requestId,
    });
  }
}
