import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ErrorsMessage from './ErrorsMessage';
import Errors from '../../../models/Errors';
import { Header } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

export default class FormTextInputFieldWithErrors extends Component {
  static propTypes = {
    errors: PropTypes.instanceOf(Errors),
    path: PropTypes.string.isRequired,
    translationKey: PropTypes.string.isRequired,
  };

  render() {
    const { translationKey, path, errors } = this.props;
    return (
      <div>
        <Header as="h2">
          <FormattedMessage id={translationKey} />
        </Header>
        <ErrorsMessage errors={errors.getErrors(path)} />
      </div>
    );
  }
}
