import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Form } from 'semantic-ui-react';
import FieldPreviewNumber from '../preview/FieldPreviewValueNumber';
import FieldPreviewValueCurrency from '../preview/FieldPreviewValueCurrency';
import FieldPreviewValueDate from '../preview/FieldPreviewValueDate';

class ExampleInput extends Component {
  renderValue = () => {
    const { fieldType, field, previewLanguage } = this.props;
    switch (fieldType) {
      case 'number':
        return (
          <FieldPreviewNumber
            previewLanguage={previewLanguage}
            value={field.getIn(['properties', 'value', 'value']) || 100.05}
            style={field.getIn(['properties', 'numberStyle', 'value'])}
          />
        );
      case 'currency':
        return (
          <FieldPreviewValueCurrency
            previewLanguage={previewLanguage}
            value={field.getIn(['properties', 'value', 'value']) || 100}
            currency={field.getIn(['properties', 'currencyCode', 'value'])}
          />
        );
      case 'date':
        return (
          <FieldPreviewValueDate
            previewLanguage={previewLanguage}
            value={
              field.getIn(['properties', 'value']) &&
              !field.getIn(['properties', 'value']).isPlaceholder()
                ? field.getIn(['properties', 'value', 'value'])
                : '1987-08-18T22:00:00.000Z'
            }
            dateStyle={field.getIn(['properties', 'dateStyle', 'value'])}
            timeStyle={field.getIn(['properties', 'timeStyle', 'value'])}
          />
        );
      default:
        return null;
    }
  };

  render() {
    const { intl, fieldType } = this.props;

    if (fieldType === 'text') {
      return null;
    }

    return (
      <Form.Field>
        <label>
          {intl.formatHTMLMessage({
            id: 'EXAMPLE_INPUT_LABEL',
          })}:
        </label>
        <span style={{ marginLeft: 10 }}>{this.renderValue()}</span>
      </Form.Field>
    );
  }
}
export default injectIntl(ExampleInput);
