import React, { Component } from 'react';
import PrimaryFields from '../fields/PrimaryFields';
import PropTypes from 'prop-types';
import AuxiliaryFields from '../fields/AuxiliaryFields';
import SecondaryFields from '../fields/SecondaryFields';
import TemplateImage from '../TemplateImage';
import Errors from '../../../../../../models/Errors';
import Template from '../../../../../../models/template';
import Barcodes from '../barcode/Barcodes';
import LogoLogoTextHeaderFields from './LogoTextHeaderFields';
export default class Generic extends Component {
  static propTypes = {
    previewLanguage: PropTypes.string.isRequired,
    errors: PropTypes.instanceOf(Errors).isRequired,
    data: PropTypes.instanceOf(Template).isRequired,
    itemSet: PropTypes.func.isRequired,
  };

  render() {
    const { data, previewLanguage, itemSet, errors } = this.props;

    const fieldsStyle = {
      display: 'table',
      width: '100%',
      paddingLeft: 12,
      paddingRight: 12,
    };
    return (
      <div>
        <LogoLogoTextHeaderFields
          data={data}
          previewLanguage={previewLanguage}
          errors={errors}
          itemSet={itemSet}
        />

        <div className="primaryFields" style={fieldsStyle}>
          <div
            style={{ display: 'table-cell', width: 210, verticalAlign: 'top' }}
          >
            <PrimaryFields
              data={data}
              previewLanguage={previewLanguage}
              errors={errors}
              itemSet={itemSet}
            />
          </div>
          <div style={{ display: 'table-cell' }}>
            <TemplateImage
              data={data}
              previewLanguage={previewLanguage}
              errors={errors}
              itemSet={itemSet}
              type={'thumbnail'}
            />
          </div>
        </div>

        <div style={{ paddingLeft: 12, paddingRight: 12 }}>
          <SecondaryFields
            data={data}
            previewLanguage={previewLanguage}
            errors={errors}
            itemSet={itemSet}
          />
          <AuxiliaryFields
            data={data}
            previewLanguage={previewLanguage}
            errors={errors}
            itemSet={itemSet}
          />
        </div>

        <Barcodes
          data={data}
          errors={errors}
          itemSet={itemSet}
          previewLanguage={previewLanguage}
        />
      </div>
    );
  }
}
