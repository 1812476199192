import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Button } from 'semantic-ui-react';
import MapInput from './MapInput';
import PropTypes from 'prop-types';

class LatLngInput extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
  };

  componentWillMount() {
    this.setState({ displayMap: false });
  }

  close = () => {
    this.setState({
      displayMap: !this.state.displayMap,
    });
  };

  handleKeyDown = e => {
    if (e.keyCode === 27) {
      this.setState({ displayMap: false });
    }
  };

  handleClick = e => {
    this.setState({
      displayMap: !this.state.displayMap,
    });
  };

  render() {
    const { displayMap } = this.state;
    const { onChange, lat, lng } = this.props;
    return (
      <div
        style={{ width: '100%', marginBottom: 10, float: 'left' }}
        onKeyDown={this.handleKeyDown}
      >
        <Button
          icon="map"
          floated="left"
          onClick={() => {
            this.handleClick();
          }}
        />
        {displayMap ? (
          <div
            style={{
              position: 'absolute',
              zIndex: 1000,
              width: '50%',
              height: '50%',
            }}
          >
            <MapInput
              lat={lat}
              lng={lng}
              onChange={onChange}
              close={this.close}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

export default injectIntl(LatLngInput);
