import PropTypes from 'prop-types';
import InputRenderComponent from '../../../../../../inputs/InputRenderComponent';

const options = [
  {
    key: 'PKNumberStyleDecimal',
    value: 'PKNumberStyleDecimal',
    text: 'PKNumberStyleDecimal',
  },
  {
    key: 'PKNumberStylePercent',
    value: 'PKNumberStylePercent',
    text: 'PKNumberStylePercent',
  },
  {
    key: 'PKNumberStyleScientific',
    value: 'PKNumberStyleScientific',
    text: 'PKNumberStyleScientific',
  },
  {
    key: 'PKNumberStyleSpellOut',
    value: 'PKNumberStyleSpellOut',
    text: 'PKNumberStyleSpellOut',
  },
];

export default class FieldTypeNumber extends InputRenderComponent {
  static propTypes = {
    ...InputRenderComponent.propTypes,
    path: PropTypes.array.isRequired,
  };

  render() {
    const { path } = this.props;
    return [
      this.renderNumberInput(path, 'value'),
      this.renderDropDownInput(path, 'numberStyle', options),
    ];
  }
}
