import React, { Component } from 'react';
import { Form, Dropdown } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import Template from '../../../../../../../../models/template';

export default class FieldTypeDropdown extends Component {
  static propTypes = {
    itemSet: PropTypes.func.isRequired,
    fieldIndex: PropTypes.number.isRequired,
    fieldsType: PropTypes.string.isRequired,
    fieldType: PropTypes.string.isRequired,
    data: PropTypes.instanceOf(Template).isRequired,
  };

  onChange = (e, { value }) => {
    const { data, itemSet, fieldsType, fieldIndex } = this.props;
    itemSet(data.fieldChangeType(fieldsType, fieldIndex, value));
  };

  render() {
    const { fieldType } = this.props;
    return (
      <Form.Field>
        <label>{`Field type`}</label>
        <Dropdown
          style={{ fontSize: 16 }}
          onChange={this.onChange}
          placeholder={`Select field type`}
          defaultValue={fieldType}
          options={[
            {
              key: 'text',
              value: 'text',
              text: 'Text',
            },
            {
              key: 'date',
              value: 'date',
              text: 'Date',
            },
            {
              key: 'number',
              value: 'number',
              text: 'Number',
            },
            {
              key: 'currency',
              value: 'currency',
              text: 'Currency',
            },
          ]}
        />
      </Form.Field>
    );
  }
}
