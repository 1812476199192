import React from 'react';
import PropTypes from 'prop-types';
import { Menu, Table, Loader, Grid, Segment } from 'semantic-ui-react';
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  PieChart,
  Pie,
  Cell,
} from 'recharts';
import { connect } from 'react-redux';
import Filter from '../common/filter/Filter';
import { inUuidQueryBuilder } from '../../services/api';
import { fetchReport } from '../../actions/index';
import { injectIntl } from 'react-intl';
import COLORS from '../../constants/colors';
import BaseReport from './BaseReport';
import NoData from './NoData';
import shortid from 'shortid';

class PassRequestByTagsOverviewBase extends BaseReport {
  static propTypes = {
    filter: PropTypes.object.isRequired,
  };

  componentWillMount() {
    this.setState({
      activeItem: 'period',
    });
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    const { filter, fetchReport, serviceType } = this.props;

    let where = {};
    if (filter.template && filter.template.value) {
      where = inUuidQueryBuilder(where, filter.template.value, 'templateId');
    }
    if (filter.project && filter.project.value) {
      where = inUuidQueryBuilder(where, filter.project.value, 'projectId');
    }
    const query = {
      from: this.props.filter.from.value,
      to: this.props.filter.to.value,
      interval: this.props.filter.interval.value,
      where: where,
    };
    fetchReport(serviceType, query);
  };

  handleTabClick = (e, { name }) => this.setState({ activeItem: name });

  getTagName = tag => {
    // TODO - get string from intl
    const noTagLabel = 'without tag';
    return tag === '' ? noTagLabel : tag;
  };

  renderNoData = () => {
    return <NoData />;
  };

  render() {
    if (!this.props.report || this.props.report.loading) {
      return <Loader active inline="centered" />;
    }

    const data = this.props.report.data;

    const interval = this.props.report.query.interval;
    let tags = [];
    let sorted = [];
    let period = [];

    const labelKey = 'formatedTimestampLabelKey' + shortid.generate();

    if (data) {
      period = data.period;

      if (this.state.activeItem === 'period') {
        tags = period.map(period => {
          return period.tag;
        });

        for (let i = 0; i < data.data.length; i++) {
          for (let j = 0; j < tags.length; j++) {
            if (typeof data.data[i].tags[tags[j]] === 'undefined') {
              data.data[i].tags[tags[j]] = 0;
            }
          }
        }

        sorted = data.data.sort(function(a, b) {
          var c = new Date(a.from);
          var d = new Date(b.from);
          return c.getTime() - d.getTime();
        });

        sorted = sorted.map(d => {
          const out = d.tags;
          out[labelKey] = this.getLabel(d.from, interval);
          return out;
        });
      } else {
        tags = data.total.map(total => {
          return total.tag;
        });
      }
    }

    const self = this;

    return (
      <div>
        <p>{this.props.description}</p>

        <Menu attached="top" tabular>
          <Menu.Item
            name="period"
            active={this.state.activeItem === 'period'}
            onClick={this.handleTabClick}
          />
          <Menu.Item
            name="total"
            active={this.state.activeItem === 'total'}
            onClick={this.handleTabClick}
          />
        </Menu>

        {this.state.activeItem === 'period' && (
          <Segment attached="bottom">
            <Filter
              project={true}
              template={true}
              from={true}
              to={true}
              interval={true}
              onFilter={() => this.fetchData()}
            />

            <Grid>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={12} computer={10}>
                  <div>
                    <h3>Interval graph</h3>
                    {!data && this.renderNoData()}
                    {data && (
                      <ResponsiveContainer minWidth={468} minHeight={300}>
                        <LineChart data={sorted}>
                          <XAxis dataKey={labelKey} />
                          <YAxis />
                          <CartesianGrid strokeDasharray="3 3" />
                          <Tooltip />
                          <Legend />
                          {tags.map((tag, j) => (
                            <Line
                              type="monotone"
                              key={j}
                              name={self.getTagName(tag)}
                              dataKey={tag}
                              strokeWidth={2}
                              stroke={COLORS[j % COLORS.length]}
                            />
                          ))}
                        </LineChart>
                      </ResponsiveContainer>
                    )}
                  </div>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={4} computer={6}>
                  <div>
                    <h3>Period summary</h3>
                    {!data && this.renderNoData()}
                    {data && (
                      <ResponsiveContainer minWidth={180} minHeight={300}>
                        <PieChart>
                          <Pie
                            data={period}
                            label
                            nameKey="tag"
                            dataKey="count"
                            innerRadius="0%"
                            outerRadius="60%"
                          >
                            {period.map((entry, index) => (
                              <Cell
                                key={index}
                                fill={COLORS[index % COLORS.length]}
                              />
                            ))}
                          </Pie>
                          <Legend
                            payload={period.map((entry, index) => {
                              return {
                                value: self.getTagName(entry.tag),
                                type: 'square',
                                id: index,
                                color: COLORS[index % COLORS.length],
                              };
                            })}
                          />
                        </PieChart>
                      </ResponsiveContainer>
                    )}
                  </div>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={16}>
                  <h3>Tag by interval data table</h3>
                  {!data && this.renderNoData()}
                  {data && (
                    <Table>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>interval</Table.HeaderCell>
                          {tags.map((tag, j) => (
                            <Table.HeaderCell key={j}>
                              {self.getTagName(tag)}
                            </Table.HeaderCell>
                          ))}
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {sorted
                          .slice(0)
                          .reverse()
                          .map((v, i) => (
                            <Table.Row key={i}>
                              <Table.Cell>{v[labelKey]}</Table.Cell>
                              {tags.map((tag, j) => (
                                <Table.Cell key={j}>{'' + v[tag]}</Table.Cell>
                              ))}
                            </Table.Row>
                          ))}
                      </Table.Body>
                    </Table>
                  )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        )}
        {this.state.activeItem === 'total' && (
          <Segment attached="bottom">
            <Filter project={true} template={true} onFilter={this.fetchData} />
            <Grid>
              <Grid.Row>
                <Grid.Column mobile={8} tablet={8} computer={8}>
                  <div>
                    <h3>Total tags count</h3>
                    {!data && this.renderNoData()}
                    {data && (
                      <ResponsiveContainer minWidth={300} minHeight={300}>
                        <PieChart>
                          <Pie
                            data={data.total}
                            label
                            nameKey="tag"
                            dataKey="count"
                            innerRadius="0%"
                            outerRadius="60%"
                          >
                            {data.total.map((entry, index) => (
                              <Cell
                                key={index}
                                fill={COLORS[index % COLORS.length]}
                              />
                            ))}
                          </Pie>
                          <Legend
                            payload={data.total.map((entry, index) => {
                              return {
                                value: self.getTagName(entry.tag),
                                type: 'square',
                                id: index,
                                color: COLORS[index % COLORS.length],
                              };
                            })}
                          />
                        </PieChart>
                      </ResponsiveContainer>
                    )}
                  </div>
                </Grid.Column>
                <Grid.Column mobile={8} tablet={8} computer={8}>
                  <h3>Total tags count data table</h3>
                  {!data && this.renderNoData()}
                  {data && (
                    <Table>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>Tag</Table.HeaderCell>
                          <Table.HeaderCell>Count</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {data.total.map((v, i) => (
                          <Table.Row key={i}>
                            <Table.Cell>{self.getTagName(v.tag)}</Table.Cell>
                            <Table.Cell>{v.count}</Table.Cell>
                          </Table.Row>
                        ))}
                      </Table.Body>
                    </Table>
                  )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        )}
      </div>
    );
  }
}

export default injectIntl(
  connect(
    (state, props) => ({
      filter: state.filter,
      report: state.report[props.serviceType],
    }),
    { fetchReport },
  )(PassRequestByTagsOverviewBase),
);
