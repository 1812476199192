import { call, put } from 'redux-saga/effects';
import client, { TICKET_API_URL } from '../services/api';

import { SEND_EMAIL_SUCCESS, SEND_EMAIL_FAILURE } from '../actions/sendEmail';

import { addNotification } from '../actions/notification';

import { AUTO_DISMISS } from '../constants/toast.js';

export function* sendEmail(action) {
  let resourceQuery = `${TICKET_API_URL}/v1/order/${action.orderId}/sendMail`;
  try {
    let data = yield call(client, 'GET', resourceQuery, {});
    yield put({
      type: SEND_EMAIL_SUCCESS,
      data,
    });
    yield put(
      addNotification({
        title: 'Send email success',
        message: `Email for order ${action.orderNumber} has been send.`,
        level: 'success',
        autoDismiss: AUTO_DISMISS,
      }),
    );
  } catch (e) {
    yield put({
      type: SEND_EMAIL_FAILURE,
      message: e.message,
    });
    yield put(
      addNotification({
        title: 'Send email failure',
        message: e.message,
        level: 'error',
        autoDismiss: AUTO_DISMISS,
      }),
    );
  }
}
