import React, { Component } from 'react';
import ProjectListTable from './ProjectListTable';
import { injectIntl } from 'react-intl';

class ProjectList extends Component {
  render() {
    return <ProjectListTable />;
  }
}

export default injectIntl(ProjectList);
