import { call, put } from 'redux-saga/effects';
import { fetchNoCredentials } from '../services/api';

import {
  TICKET_ESHOP_VERSIONS_SUCCESS,
  TICKET_ESHOP_VERSIONS_FAILURE,
  TICKET_ESHOP_VERSION_MESSAGES_SUCCESS,
  TICKET_ESHOP_VERSION_MESSAGES_FAILURE,
} from '../actions/ticket';

export function* ticketEshopFetchVersions(action) {
  let resourceQuery = `https://yourpass.s3.amazonaws.com/eshop/versions.json`;
  try {
    let data = yield call(fetchNoCredentials, 'GET', resourceQuery, {});
    yield put({
      type: TICKET_ESHOP_VERSIONS_SUCCESS,
      data,
    });
  } catch (e) {
    yield put({
      type: TICKET_ESHOP_VERSIONS_FAILURE,
      error: e,
    });
  }
}

export function* ticketEshopFetchMessage(action) {
  let resourceQuery = `https://yourpass.s3.amazonaws.com/eshop/messages/${
    action.version
  }.json`;
  try {
    let data = yield call(fetchNoCredentials, 'GET', resourceQuery, {});
    yield put({
      type: TICKET_ESHOP_VERSION_MESSAGES_SUCCESS,
      data,
      version: action.version,
    });
  } catch (e) {
    yield put({
      type: TICKET_ESHOP_VERSION_MESSAGES_FAILURE,
      error: e,
      version: action.version,
    });
  }
}
