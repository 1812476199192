import React, { Component } from 'react';
import { Dropdown, Input, Icon } from 'semantic-ui-react';
import DateTime from 'react-datetime';
import moment from 'moment-timezone';
import 'react-datetime/css/react-datetime.css';
import PropTypes from 'prop-types';

export default class InputDateTime extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string,
    disableTimeZone: PropTypes.bool,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  };

  componentWillMount() {
    const detectedOffset = moment()
      .tz(moment.tz.guess())
      .format('Z');

    let defaultValue = this.props.defaultValue;

    if (defaultValue instanceof Date) {
      defaultValue = moment(defaultValue).format();
    }

    const isValidDate =
      defaultValue &&
      defaultValue.slice &&
      moment(defaultValue.slice(0, 19) + '+00:00').isValid();

    const value = isValidDate
      ? moment(defaultValue.slice(0, 19) + '+00:00')
      : defaultValue;

    let timezone = stateOptions[0].value;

    const offset = isValidDate
      ? defaultValue.slice(19, defaultValue.length)
      : detectedOffset;

    stateOptions.map(o => {
      if (offset === o.offset) {
        timezone = o.value;
      }
      return 0;
    });

    this.setState({
      timezone: timezone,
      value: value ? value : undefined,
    });
  }

  setDate = (date, timezone) => {
    const { onChange, name } = this.props;
    const myTime =
      date !== ''
        ? moment.tz(moment(date).format('YYYY-MM-DD HH:mm:ss'), timezone)
        : null;

    onChange(date, {
      value: myTime !== null && myTime.isValid() ? myTime.format() : null,
      name: name,
    });
  };

  handleChange = date => {
    const { timezone } = this.state;
    this.setState({ value: date });
    this.setDate(date, timezone);
  };

  handleChangeTimezone = (e, d) => {
    this.setState({ timezone: d.value });
    const { value } = this.state;
    this.setDate(value, d.value);
  };

  renderInput = (props, openCalendar) => {
    const label = this.props.disableTimeZone ? (
      undefined
    ) : (
      <Dropdown
        value={this.state.timezone}
        options={stateOptions}
        onChange={this.handleChangeTimezone}
        disabled={this.props.disableTimeZone}
      />
    );

    const labelPosition = this.props.disableTimeZone ? undefined : 'right';

    return (
      <Input
        labelPosition={labelPosition}
        type="text"
        placeholder="Select date"
        iconPosition="left"
        icon={<Icon name="calendar" onClick={openCalendar} />}
        label={label}
        {...props}
      />
    );
  };

  render() {
    const { value } = this.state;
    return (
      <DateTime
        defaultValue={value}
        type="text"
        onChange={this.handleChange}
        utc={true}
        renderInput={this.renderInput}
      />
    );
  }
}

const stateOptions = [
  { key: 'Etc/GMT', value: 'Etc/GMT', text: 'UTC', offset: '+00:00' },
  { key: 'Etc/GMT-1', value: 'Etc/GMT-1', text: 'UTC+1', offset: '+01:00' },
  { key: 'Etc/GMT-2', value: 'Etc/GMT-2', text: 'UTC+2', offset: '+02:00' },
  { key: 'Etc/GMT-3', value: 'Etc/GMT-3', text: 'UTC+3', offset: '+03:00' },
  { key: 'Etc/GMT-4', value: 'Etc/GMT-4', text: 'UTC+4', offset: '+04:00' },
  { key: 'Etc/GMT-5', value: 'Etc/GMT-5', text: 'UTC+5', offset: '+05:00' },
  { key: 'Etc/GMT-6', value: 'Etc/GMT-6', text: 'UTC+6', offset: '+06:00' },
  { key: 'Etc/GMT-7', value: 'Etc/GMT-7', text: 'UTC+7', offset: '+07:00' },
  { key: 'Etc/GMT-8', value: 'Etc/GMT-8', text: 'UTC+8', offset: '+08:00' },
  { key: 'Etc/GMT-9', value: 'Etc/GMT-9', text: 'UTC+9', offset: '+09:00' },
  {
    key: 'Etc/GMT-10',
    value: 'Etc/GMT-10',
    text: 'UTC+10',
    offset: '+10:00',
  },
  {
    key: 'Etc/GMT-11',
    value: 'Etc/GMT-11',
    text: 'UTC+11',
    offset: '+11:00',
  },
  {
    key: 'Etc/GMT-12',
    value: 'Etc/GMT-12',
    text: 'UTC+12',
    offset: '+12:00',
  },
  {
    key: 'Etc/GMT-13',
    value: 'Etc/GMT-13',
    text: 'UTC+13',
    offset: '+13:00',
  },
  {
    key: 'Etc/GMT-14',
    value: 'Etc/GMT-14',
    text: 'UTC+14',
    offset: '+14:00',
  },

  { key: 'Etc/GMT+1', value: 'Etc/GMT+1', text: 'UTC-1', offset: '-01:00' },
  { key: 'Etc/GMT+2', value: 'Etc/GMT+2', text: 'UTC-2', offset: '-02:00' },
  { key: 'Etc/GMT+3', value: 'Etc/GMT+3', text: 'UTC-3', offset: '-03:00' },
  { key: 'Etc/GMT+4', value: 'Etc/GMT+4', text: 'UTC-4', offset: '-04:00' },
  { key: 'Etc/GMT+5', value: 'Etc/GMT+5', text: 'UTC-5', offset: '-05:00' },
  { key: 'Etc/GMT+6', value: 'Etc/GMT+6', text: 'UTC-6', offset: '-06:00' },
  { key: 'Etc/GMT+7', value: 'Etc/GMT+7', text: 'UTC-7', offset: '-07:00' },
  { key: 'Etc/GMT+8', value: 'Etc/GMT+8', text: 'UTC-8', offset: '-08:00' },
  { key: 'Etc/GMT+9', value: 'Etc/GMT+9', text: 'UTC-9', offset: '-09:00' },
  {
    key: 'Etc/GMT+10',
    value: 'Etc/GMT+10',
    text: 'UTC-10',
    offset: '-10:00',
  },
  {
    key: 'Etc/GMT+11',
    value: 'Etc/GMT+11',
    text: 'UTC-11',
    offset: '-11:00',
  },
  {
    key: 'Etc/GMT+12',
    value: 'Etc/GMT+12',
    text: 'UTC-12',
    offset: '-12:00',
  },
];
