import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from 'semantic-ui-react';
import client, { TICKET_API_URL } from '../../../services/api';
import connect from 'react-redux/es/connect/connect';
import { addNotification } from '../../../actions/notification';
import { injectIntl, defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'ticket.check.order.payment.state.title',
    defaultMessage: 'Payment state updated',
  },
  message: {
    id: 'ticket.check.order.payment.state.message',
    defaultMessage: 'Payment state of order {orderNumber} was update.',
  },
  failureTitle: {
    id: 'ticket.check.order.payment.state.failure.title',
    defaultMessage: 'Couldn\'t check payment state.',
  },
});

class CheckPaymentState extends Component {
  static propTypes = {
    order: PropTypes.object.isRequired,
    refresh: PropTypes.func.isRequired,
    addNotification: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.checkPaymentState = this.checkPaymentState.bind(this);
    this.state = { loading: false };
  }

  async checkPaymentState() {
    const { order, refresh, addNotification, intl } = this.props;
    this.setState({
      loading: true,
    });
    const url = `${TICKET_API_URL}/v1/order/${order.id}/updatePaymentState`;
    try {
      await client('POST', url, {});
      addNotification({
        title: intl.formatMessage(messages.title),
        message: intl.formatMessage(messages.message, order),
        level: 'success',
      });
    } catch (e) {
      addNotification({
        title: intl.formatMessage(messages.failureTitle),
        message: e.message,
        level: 'error',
      });
    }
    this.setState({
      loading: false,
    });
    refresh();
  }

  render() {
    return (
      <Button
        icon
        onClick={this.checkPaymentState}
        disabled={this.state.loading}
      >
        <Icon name="refresh" loading={this.state.loading}/>
      </Button>
    );
  }
}

export default connect(undefined, {
  addNotification,
})(injectIntl(CheckPaymentState));
