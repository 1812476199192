import React, { Component } from 'react';
import { FormattedNumber, IntlProvider } from 'react-intl';
import { toWords } from 'number-to-words';
import PropTypes from 'prop-types';

export default class FieldPreviewNumber extends Component {
  static propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    style: PropTypes.string.isRequired,
    previewLanguage: PropTypes.string.isRequired,
  };

  getStyle(style) {
    switch (style) {
      case 'PKNumberStylePercent':
        return 'percent';
      case 'PKNumberStyleDecimal':
      default:
        return 'decimal';
    }
  }

  render() {
    const { value, style, previewLanguage } = this.props;
    const options = {
      style: this.getStyle(style),
      value: Number(value),
    };
    if (style === 'PKNumberStyleSpellOut') {
      return <span>{toWords(value)}</span>;
    }
    if (style === 'PKNumberStyleScientific') {
      return (
        <span>
          {Number(value)
            .toExponential()
            .replace(/\+/g, '')
            .toUpperCase()}
        </span>
      );
    }

    return (
      <IntlProvider locale={previewLanguage}>
        <FormattedNumber {...options} />
      </IntlProvider>
    );
  }
}
