import React, { Component } from 'react';
import { Form, Dropdown } from 'semantic-ui-react';

const options = [
  { key: 'simple', value: 'simple', text: 'simple' },
  { key: 'elvis', value: 'elvis', text: 'elvis' },
  { key: 'originsoft', value: 'originsoft', text: 'originsoft' },
  { key: 'ntree', value: 'ntree', text: 'ntree' },
  { key: 'stack', value: 'stack', text: 'stack' },
  { key: 'other', value: 'other', text: 'other' },
];

export default class FormDropdownNumberService extends Component {
  render() {
    return (
      <Form.Field>
        <label>Number service</label>
        <Dropdown
          minCharacters={0}
          name={this.props.name}
          selection={true}
          search={true}
          multiple={false}
          options={options}
          value={this.props.value}
          onChange={this.props.onChange}
        />
      </Form.Field>
    );
  }
}
