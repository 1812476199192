import React from 'react';
import { Provider } from 'react-redux';
import { newStore } from './store/index';
import './index.css';
import 'semantic-ui-css/semantic.css';
import 'highlight.js/styles/atom-one-dark.css';
import { browserHistory } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';
import Main from './containers/Main';
import initialState from './initialState';
import './messagesDefine';

// App locales are defined in src/server/config.js
const { addLocaleData } = require('react-intl');
const cs = require('react-intl/locale-data/cs');
const de = require('react-intl/locale-data/de');
const en = require('react-intl/locale-data/en');
const es = require('react-intl/locale-data/es');
const fr = require('react-intl/locale-data/fr');
const pt = require('react-intl/locale-data/pt');
const ro = require('react-intl/locale-data/ro');

[cs, de, en, es, fr, pt, ro].forEach(locale => addLocaleData(locale));
const store = newStore(initialState);
const history = syncHistoryWithStore(browserHistory, store);

export default class App extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <Main history={history} store={store} />
      </Provider>
    );
  }
}
