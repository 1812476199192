import React, { Component } from 'react';
import ProjectForm from './ProjectForm';
import Edit from '../../common/Edit';
export default class ProjectEdit extends Component {
  render() {
    return (
      <Edit
        resource={'project'}
        resourceId={this.props.params.id}
        component={ProjectForm}
      />
    );
  }
}
