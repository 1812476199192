import { Record } from 'immutable';
import accessRights from '../constants/accessRights';

const Viewer = Record(
  {
    id: null,
    displayName: null,
    email: null,
    name: null,
    isAdmin: false,
    accessRights: null,
    projects: null,
  },
  'viewer',
);

function generateAccessRights(viewer) {
  const result = {};

  if (viewer.projects && !viewer.isAdmin) {
    Object.keys(viewer.projects).map(projectId => {
      if (viewer.projects.hasOwnProperty(projectId)) {
        const project = viewer.projects[projectId];
        if (!result[projectId]) {
          result[projectId] = {};
        }
        Object.keys(accessRights).map(key => {
          if (viewer.isAdmin) {
            result[projectId][key] = true;
            result[key] = true;
          } else {
            const value = accessRights[key];
            const shifted = project & value;
            const checked = shifted === value;
            result[projectId][key] = checked;

            if (checked) {
              result[key] = true;
            } else if (typeof result[key] === 'undefined') {
              result[key] = false;
            }
          }
          return null;
        });
      }
      return null;
    });
  }
  return result;
}

class ViewerImpl extends Viewer {
  constructor(viewer) {
    const data = {
      id: viewer.id,
      email: viewer.email,
      displayName: viewer.displayName,
      name: viewer.name,
      isAdmin: viewer.isAdmin,
      projects: viewer.projects,
      accessRights: generateAccessRights(viewer),
    };
    super(data);
  }

  hasAnyProject() {
    return this.isAdmin || Object.keys(this.projects).length > 0;
  }

  // CORE RIGHTS

  showPassMenu() {
    return this.showPassList() || this.showTemplateList();
  }

  canUpdateTemplate(projectId) {
    return (
      this.isAdmin ||
      (this.accessRights[projectId] &&
        this.accessRights[projectId].TEMPLATE_UPDATE)
    );
  }
  canEditBackfields(projectId) {
    return this.canUpdateTemplate(projectId);
  }

  showPassList() {
    return this.isAdmin || this.accessRights.PASS_READ;
  }

  showTemplateList() {
    return this.isAdmin || this.accessRights.TEMPLATE_READ;
  }
  showCertificateList() {
    return this.isAdmin;
  }

  showImagesList() {
    return (
      this.isAdmin ||
      this.accessRights.IMAGE_MANAGE ||
      this.accessRights.TEMPLATE_READ ||
      this.accessRights.TEMPLATE_UPDATE ||
      this.accessRights.TEMPLATE_CREATE
      // TODO add pass_update/create in future, when pass dynamic images will be added into console
    );
  }

  canManageImages() {
    return this.isAdmin || this.accessRights.IMAGE_MANAGE;
  }

  // IDM

  showIdentityProjects() {
    return (
      this.isAdmin ||
      this.accessRights.PROJECT_CREATE ||
      this.accessRights.PROJECT_UPDATE ||
      this.accessRights.USER_UPDATE
    );
  }
  showIdentityUsers() {
    return this.isAdmin || this.accessRights.USER_UPDATE;
  }

  canCreateSubProject(projectId) {
    return (
      this.isAdmin ||
      (this.accessRights[projectId] &&
        this.accessRights[projectId].PROJECT_CREATE)
    );
  }

  canEditProject(projectId) {
    return (
      this.isAdmin ||
      (this.accessRights[projectId] &&
        this.accessRights[projectId].PROJECT_UPDATE)
    );
  }

  canManageUsersOnProject(projectId) {
    return (
      this.isAdmin ||
      (this.accessRights[projectId] && this.accessRights[projectId].USER_UPDATE)
    );
  }

  showIdentityManagementMenu() {
    return this.showIdentityProjects() || this.showIdentityUsers();
  }

  // TICKET MENU
  showTicketValidation() {
    return this.isAdmin;
  }

  showTicketOrderItem() {
    return this.showTicketAccounting() || this.showTicketValidation();
  }

  showTicketMenu() {
    return (
      this.showTicketAccounting() ||
      this.showTicketConfig() ||
      this.showTicketValidation() ||
      this.showTicketProduct()
    );
  }

  showTicketAccounting() {
    return this.isAdmin || this.accessRights.TICKET_ACCOUNTING;
  }

  showTicketConfig() {
    return this.isAdmin || this.accessRights.TICKET_ESHOP_READ;
  }

  showTicketProduct() {
    return (
      this.isAdmin ||
      (this.accessRights.TICKET_PRODUCT_MANAGE &&
        this.accessRights.TICKET_ESHOP_READ)
    );
  }

  canCreateTicketProduct() {
    return (
      this.isAdmin ||
      (this.accessRights.TICKET_PRODUCT_MANAGE &&
        this.accessRights.TICKET_ESHOP_READ)
    );
  }

  canEditTicketProduct() {
    return (
      this.isAdmin ||
      (this.accessRights.TICKET_PRODUCT_MANAGE &&
        this.accessRights.TICKET_ESHOP_READ)
    );
  }

  // REPORT RIGHTS
  showReportMenu() {
    return this.showPassList();
  }

  // STAMPCARD RIGHTS
  showStampCard() {
    return this.isAdmin || this.accessRights.STAMP_MANAGE;
  }

  // DOC

  showDocs() {
    return !!this;
  }
}

export default ViewerImpl;
