import React from 'react';
import { injectIntl, FormattedHTMLMessage } from 'react-intl';
import PropTypes from 'prop-types';
import InputRenderComponent from '../../../../../../inputs/InputRenderComponent';
import validateAttributedValues from '../../../../../../../../../models/template/validateAttributedValues';
import { Label } from 'semantic-ui-react';
import { uiSetTemplatePreviewLanguage } from '../../../../../../../../../actions/ui';
import { connect } from 'react-redux';

class FieldTypeText extends InputRenderComponent {
  static propTypes = {
    ...InputRenderComponent.propTypes,
    path: PropTypes.array.isRequired,
    fieldIndex: PropTypes.number.isRequired,
    fieldsType: PropTypes.string.isRequired,
    previewLanguage: PropTypes.string.isRequired,
  };

  handleAttributedValue = (e, value, lang) => {
    const { itemSet, data, fieldsType, fieldIndex } = this.props;
    const newData = data.fieldSetAttributedValue(
      fieldsType,
      fieldIndex,
      value,
      lang,
    );
    itemSet(newData);
  };

  render() {
    const { fieldsType, data, path, previewLanguage } = this.props;
    const languages = data.get('languages');
    const isAV = !!data.getIn(path.concat(['attributedValue']));

    if (fieldsType === 'backFields') {
      const out = [
        this.renderStringInput(path, isAV ? 'attributedValue' : 'value', {
          key: 'valueEdit',
          textArea: true,
          languages: languages,
          onChange: (e, value, lang) =>
            this.handleAttributedValue(e, value, lang),
        }),
      ];

      // validate html links in value or av as external validator
      let v = data.getIn(
        path.concat([isAV ? 'attributedValue' : 'value', 'value']),
      );
      if (v) {
        if (typeof v !== 'string') {
          v = v.get(previewLanguage);
        }

        const isValidV = validateAttributedValues(v);
        if (!isValidV) {
          out.push(
            <Label
              key={`warning`}
              basic
              color="yellow"
              pointing={'above'}
              style={{ marginTop: 0, marginBottom: '1em' }}
            >
              <FormattedHTMLMessage id={'CONTAINS_NON_VALID_LINK'} />
            </Label>,
          );
        }
      }

      if (isAV) {
        out.push(
          this.renderStringInput(path, 'value', {
            textArea: true,
            disabled: true,
            languages: languages,
          }),
        );
      }
      return out;
    } else {
      return this.renderStringInput(path, 'value', {
        languages: languages,
      });
    }
  }
}
export default connect(
  (state, props) => ({
    previewLanguage: state.ui.templatePreviewLanguage,
  }),
  { uiSetTemplatePreviewLanguage },
)(injectIntl(FieldTypeText));
