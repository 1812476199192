import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Button, Icon, Segment } from 'semantic-ui-react/dist/commonjs';
import FormFieldProject from '../../common/FormFieldProject';
import BackButton from '../../common/buttons/BackButton';
import { FormattedMessage } from 'react-intl';
import ProjectUserRights from './ProjectUserRights';

export default class ProjectEdit extends Component {
  static propTypes = {
    data: PropTypes.object,
    itemSet: PropTypes.func.isRequired,
    itemSave: PropTypes.func.isRequired,
    itemApply: PropTypes.func.isRequired,
  };

  onChange(e) {
    const { itemSet, data } = this.props;
    data[e.target.name] = e.target.value;
    itemSet(data);
  }

  handleSave = e => {
    e.preventDefault();
    const { itemSave, data } = this.props;
    itemSave(data);
  };

  handleApply = e => {
    e.preventDefault();
    const { itemApply, data } = this.props;
    itemApply(data);
  };

  onChangeCheckbox(e) {
    const { itemSet, data } = this.props;
    data[e.target.name] = !data[e.target.name];
    itemSet(data);
  }

  render() {
    const { data, changed, viewer } = this.props;
    return (
      <div>
        {viewer &&
          viewer.canEditProject(data.id) && (
            <Segment>
              <Form>
                <Form.Input
                  name="id"
                  label="Id"
                  defaultValue={data.id}
                  readOnly
                />
                <Form.Input
                  name="name"
                  label="Name"
                  onChange={(e, p) => this.onChange(e, p)}
                  defaultValue={data.name}
                />
                <Form.TextArea
                  name="description"
                  label="Description"
                  onChange={(e, p) => this.onChange(e, p)}
                  defaultValue={data.description}
                />
                <Form.Input
                  name="active"
                  label="Active"
                  type="checkbox"
                  onChange={(e, p) => this.onChangeCheckbox(e, p)}
                  checked={data.active}
                />

                {/*TODO maybe only for admin show there dropdown to change parent project*/

                data.parentId && (
                  <FormFieldProject title="Parent" projectId={data.parentId} />
                )}
              </Form>
              <Form style={{ marginTop: 30 }}>
                <Button disabled={!changed} primary onClick={this.handleSave}>
                  <Icon name="save" />
                  <FormattedMessage id="BUTTON_SAVE" />
                </Button>
                <Button
                  disabled={!changed}
                  secondary
                  onClick={this.handleApply}
                >
                  <Icon name="save" />
                  <FormattedMessage id="BUTTON_APPLY" />
                </Button>
                <BackButton />
              </Form>
            </Segment>
          )}
        {viewer &&
          viewer.canManageUsersOnProject(data.id) && (
            <Segment>
              <ProjectUserRights projectId={data.id} />
            </Segment>
          )}
      </div>
    );
  }
}
