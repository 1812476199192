import React, { Component } from 'react';
import Form from './Form';
import Edit from '../../common/Edit';
export default class ConfigEdit extends Component {
  render() {
    return (
      <Edit
        resource={'ticketEshop'}
        resourceId={this.props.params.id}
        component={Form}
      />
    );
  }
}
