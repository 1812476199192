import { defineMessages } from 'react-intl';
import defaultMessages from './messages-en.js';

const messagesToDefine = {};
Object.keys(defaultMessages).map(key => {
  messagesToDefine[key] = { id: key, defaultMessage: defaultMessages[key] };
  return key;
});

export default defineMessages(messagesToDefine);
