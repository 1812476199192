import React from 'react';
import PropTypes from 'prop-types';
import Errors from '../../../../../../../../models/Errors';
import { ObjectType } from '../../../../../../../../models/property';
import Template from '../../../../../../../../models/template/index';
import { getInvertColor } from '../../../../../../../../services/color';
import FieldPreviewValue from './FieldPreviewValue';
import FieldPreviewBase from './FieldPreviewBase';
import FieldPreviewLabel from './FieldPreviewLabel';
import FieldPreviewPrimary from './FieldPreviewPrimary';

export default class FieldPreview extends FieldPreviewBase {
  static propTypes = {
    ...FieldPreviewBase.propTypes,
    previewLanguage: PropTypes.string.isRequired,
    field: PropTypes.instanceOf(ObjectType).isRequired,
    errors: PropTypes.instanceOf(Errors).isRequired,
    data: PropTypes.instanceOf(Template).isRequired,
    itemSet: PropTypes.func.isRequired,
    fieldIndex: PropTypes.number.isRequired,
    fieldType: PropTypes.string.isRequired,
    fieldsType: PropTypes.string.isRequired,
    style: PropTypes.object,
  };

  getTextAlign = () => {
    const { field } = this.props;
    const textAlignment = field.getIn(['properties', 'textAlignment', 'value']);
    switch (textAlignment) {
      case 'PKTextAlignmentLeft':
        return 'left';
      case 'PKTextAlignmentCenter':
        return 'center';
      case 'PKTextAlignmentRight':
        return 'right';
      case 'PKTextAlignmentNatural':
        return '';
      default:
        return '';
    }
  };

  getFrontFieldStyle() {
    const style = {
      minWidth: 20,
      minHeight: 26,
      zIndex: 100,
      cursor: 'pointer',
      textAlign: this.getTextAlign(),
      fontSize: '15px',
    };

    return style;
  }

  getBackFieldStyle() {
    const style = {
      minWidth: 20,
      minHeight: 40,
      zIndex: 100,
      cursor: 'pointer',
      paddingTop: 10,
      marginLeft: 8,
      marginRight: 8,
      paddingBottom: 9,
      overflow: 'hidden',
      border: '1px solid gainsboro',
      borderLeft: 'none',
      borderRight: 'none',
      borderTop: 'none',
      textAlign: this.getTextAlign(),
    };
    return style;
  }

  tidy = html => {
    let d = document.createElement('div');
    d.innerHTML = html;
    return d.innerHTML;
  };

  getStyle(showError) {
    const { data } = this.props;
    const BackgroundColor = data.getBackgroundColor();
    const style = this.isBackfield()
      ? this.getBackFieldStyle()
      : this.getFrontFieldStyle();

    if (showError) {
      style.boxShadow = 'red 0px 0px 15px';
    }

    if (!this.hasLabel() && !this.hasValue()) {
      style.border = `1px solid ${getInvertColor(BackgroundColor)}`;
    }
    return style;
  }

  render() {
    const {
      data,
      fieldType,
      fieldsType,
      errors,
      field,
      previewLanguage,
      path,
    } = this.props;
    const showError = errors.hasErrors(path);

    let content = [
      <FieldPreviewLabel
        key={`fieldPreviewLabel`}
        field={field}
        fieldType={fieldType}
        data={data}
        fieldsType={fieldsType}
        previewLanguage={previewLanguage}
      />,

      <FieldPreviewValue
        key={`fieldPreviewValue`}
        data={data}
        field={field}
        fieldType={fieldType}
        fieldsType={fieldsType}
        previewLanguage={previewLanguage}
      />,
    ];
    if (fieldsType === 'primaryFields') {
      content = (
        <FieldPreviewPrimary
          data={data}
          field={field}
          fieldType={fieldType}
          fieldsType={fieldsType}
          previewLanguage={previewLanguage}
          passStyle={data.getPassStyle()}
        />
      );
    }

    return (
      <div
        className={`field designerHoverItem ${fieldsType}`}
        style={this.getStyle(showError)}
      >
        {content}
      </div>
    );
  }
}
