import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ResourceListCompleteDecorator from './ResourceListCompleteDecorator';
import { Form, Dropdown } from 'semantic-ui-react';
import { remove } from 'diacritics';

const removeDiacritics = text => {
  if (text) {
    return text
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toUpperCase();
  } else {
    return text;
  }
};

class ResourceFormDropdownImpl extends Component {
  static propTypes = {
    defaultValue: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
    ]),
    resource: PropTypes.string.isRequired,
  };
  handleChange = (e, p) => {
    if (this.props.onChange) {
      this.props.onChange(e, p);
    }
  };

  handleSearch = (options, query) => {
    return options.filter(opt => {
      if (opt.text) {
        return removeDiacritics(opt.text).includes(removeDiacritics(query));
      }
      return false;
    });
  };

  render() {
    const {
      resource,
      multiple,
      name,
      defaultValue,
      label,
      placeholder,
      list,
      disabled,
      valueKey,
      textKey,
      renderContent,
      isLabelHidden,
    } = this.props;
    let options = [];
    if (list.data) {
      options = Object.keys(list.data).map(key => {
        const opt = {
          text: list.data[key][textKey || 'name'],
          value: list.data[key][valueKey || 'id'],
          key: list.data[key][valueKey || 'id'],
        };
        if (renderContent) {
          opt.content = renderContent(list.data[key]);
        }
        return opt;
      });
      options.sort(compare);
    }
    return (
      <Form.Field>
        {!isLabelHidden && <label>{label}</label>}
        <Dropdown
          minCharacters={0}
          name={name || `${resource}Id`}
          selection={true}
          multiple={multiple}
          options={options}
          defaultValue={defaultValue || ''}
          placeholder={placeholder || `Please select ${resource}`}
          onChange={this.handleChange}
          onSearchChange={this.handleSearchChange}
          disabled={list.loading || disabled}
          loading={list.loading}
          onOpen={this.handleOpen}
          search={this.handleSearch}
        />
      </Form.Field>
    );
  }
}

export default class ResourceFormDropdown extends Component {
  static propTypes = {
    resource: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    defaultValue: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
    ]),
    value: PropTypes.object,
    renderLabel: PropTypes.func,
    error: PropTypes.bool,
  };

  componentWillMount() {
    const filterProps = [
      {
        columnName: 'name',
        name: 'name',
        type: 'like',
      },
    ];
    if (this.props.filterProps) {
      for (let i = 0; i < this.props.filterProps.length; i++) {
        filterProps.push(this.props.filterProps[i]);
      }
    }
    this.setState({ filterProps: filterProps });
  }

  handleChange = (e, p) => {
    if (this.props.onChange) {
      this.props.onChange(e, p);
    }
  };

  render() {
    return (
      <ResourceListCompleteDecorator
        resource={this.props.resource}
        component={ResourceFormDropdownImpl}
        filter={this.props.filter}
        componentProps={{
          ...this.props,
          onChange: this.handleChange,
          filterProps: this.state.filterProps,
        }}
      />
    );
  }
}

function compare(a, b) {
  if (a.text && b.text) {
    if (remove(a.text.toLowerCase()) < remove(b.text.toLowerCase())) return -1;
    if (remove(a.text.toLowerCase()) > remove(b.text.toLowerCase())) return 1;
  }
  return 0;
}
