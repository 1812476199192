import React, { Component } from 'react';
import CertificateForm from './CertificateForm';
import Edit from '../../common/Edit';
import ResourceListCompleteDecorator from '../../common/ResourceListCompleteDecorator';
const defaultValue = {};

class CertificateEdit extends Component {
  render() {
    if (this.props.list.loading) {
      // TODO return loading component
      return null;
    }

    if (this.props.list.data.length <= 0) {
      // TODO return no project avalable, so certificate can't be created
      return null;
    }

    defaultValue.projectId = this.props.list.data[0].id;

    return (
      <Edit
        resource={'certificate'}
        resourceId={'new'}
        component={CertificateForm}
        defaultValue={defaultValue}
      />
    );
  }
}

export default class CertificateCreate extends Component {
  render() {
    return (
      <ResourceListCompleteDecorator
        resource={'project'}
        component={CertificateEdit}
      />
    );
  }
}
