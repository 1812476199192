import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Button, Icon } from 'semantic-ui-react';
import FormGroupTimestamps from '../../common/FormGroupTimestamps';
import FormTextAreaJson from '../../common/FormTextAreaJSON';
import FormDropdownProject from '../../common/FormDropdownProject';
import FormFieldProject from '../../common/FormFieldProject';
import FormInputNumber from '../../common/FormInputNumber';
import FormInputCheckbox from '../../common/FormInputCheckbox';
import FormTab from '../../common/FormTab';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { uiSetFormTab } from '../../../actions/ui';
import { fetchTicketEshopVersions } from '../../../actions/ticket';
import BackButton from '../../common/buttons/BackButton';
import EshopFormTranslation from './FormTranslations';
// import FormPayment from './FormPayment';
import FormEmail from './FormMail';
import FormWysiwyg from './FormWysiwyg';
import { injectIntl } from 'react-intl';

const FORM_NAME = 'ticketEshop';

class EshopForm extends Component {
  static propTypes = {
    data: PropTypes.object,
    intl: PropTypes.object.isRequired,
    itemSet: PropTypes.func.isRequired,
    itemSetPropertyByPath: PropTypes.func.isRequired,
    itemSave: PropTypes.func.isRequired,
    itemCreate: PropTypes.func.isRequired,
  };

  handleChange = (e, p) => {
    const { itemSetPropertyByPath, data } = this.props;
    itemSetPropertyByPath(p.name, p.value, data);
  };

  handleSave = e => {
    e.preventDefault();
    const { itemSave, itemCreate, data } = this.props;
    if (data.id) {
      itemSave(data);
    } else {
      itemCreate(data);
    }
  };

  handleApply = e => {
    e.preventDefault();
    const { itemApply, itemCreate, data } = this.props;
    if (data.id) {
      itemApply(data);
    } else {
      itemCreate(data);
    }
  };

  handleChangeJSON = (e, p) => {
    this.props.itemSet(p.value);
  };

  componentWillMount() {
    this.props.fetchTicketEshopVersions();
  }

  render() {
    let {
      data,
      changed,
      viewer,
      eshopVersions,
      eshopVersionsLoading,
    } = this.props;

    if (eshopVersionsLoading) {
      // TODO -loader
      return null;
    }

    const versionOptions = [];
    eshopVersions.map(v => {
      return versionOptions.push({ text: v, key: v, value: v });
    });

    const tabOptions = {};

    tabOptions.form = {
      icon: 'table',
      name: 'Basic',
      value: 'form',
      content: (
        <div>
          {data.id ? (
            <FormFieldProject projectId={data.projectId} />
          ) : (
            <FormDropdownProject
              name="projectId"
              label="Project"
              placeholder="Project"
              onChange={this.handleChange}
              defaultValue={data.projectId}
            />
          )}

          <Form.Input
            name="name"
            label="Name"
            type="string"
            onChange={this.handleChange}
            defaultValue={data.name}
          />
          <Form.Input
            name="orderPrefix"
            label="order prefix"
            onChange={this.handleChange}
            defaultValue={data.orderPrefix}
          />
          <FormInputCheckbox
            name="active"
            label="Active"
            onChange={this.handleChange}
            defaultChecked={data.active}
            toggle
          />
          <Form.Dropdown
            placeholder="version"
            search
            selection
            options={versionOptions}
            name="eshopVersion"
            label="Version"
            onChange={this.handleChange}
            value={data.eshopVersion}
          />
          <FormGroupTimestamps data={data} />
        </div>
      ),
    };

    tabOptions.basket = {
      icon: 'shopping bag',
      name: 'Basket',
      content: (
        <div>
          <h2>Basket</h2>
          <FormInputNumber
            name="maxItems"
            label="Max items in basket"
            onChange={this.handleChange}
            defaultValue={data.maxItems}
          />
          <FormInputNumber
            name="maxPriceWithVat"
            label="Max price with vat"
            onChange={this.handleChange}
            defaultValue={data.maxPriceWithVat}
          />
        </div>
      ),
    };

    tabOptions.localization = {
      icon: 'globe',
      name: 'Localization',
      content: (
        <EshopFormTranslation data={data} handleChange={this.handleChange} />
      ),
    };
    /*
    tabOptions.payment = {
      icon: 'money',
      name: 'Payment',
      content: <FormPayment data={data} handleChange={this.handleChange} />,
    };
*/
    tabOptions.email = {
      icon: 'envelope',
      name: 'Email',
      content: <FormEmail data={data} handleChange={this.handleChange} />,
    };

    if (viewer.isAdmin) {
      tabOptions.Wysiwyg = {
        icon: 'edit',
        name: 'Wysiwyg',
        content: <FormWysiwyg data={data} handleChange={this.handleChange} />,
      };

      tabOptions.json = {
        icon: 'file text',
        name: 'Json',
        content: (
          <FormTextAreaJson
            name="json"
            onChange={this.handleChangeJSON}
            label="JSON"
            defaultValue={data}
          />
        ),
      };
    }

    return (
      <Form>
        <FormTab
          tabOptions={tabOptions}
          formName={FORM_NAME}
          defaultTab="form"
        />
        {viewer.isAdmin && (
          <Button disabled={!changed} primary onClick={this.handleSave}>
            <Icon name="save" />
            <FormattedMessage id="BUTTON_SAVE" />
          </Button>
        )}
        {viewer.isAdmin && (
          <Button disabled={!changed} secondary onClick={this.handleApply}>
            <Icon name="save" />
            <FormattedMessage id="BUTTON_APPLY" />
          </Button>
        )}
        <BackButton />
      </Form>
    );
  }
}

// This removes warnings for "undefined" react props
export default connect(
  (state, props) => ({
    viewer: state.viewer.data,
    eshopVersions: state.ticket.eshopVersions,
    eshopVersionsLoading: state.ticket.eshopVersionsLoading,
    form: state.ui.forms[FORM_NAME],
  }),
  { uiSetFormTab, fetchTicketEshopVersions },
)(injectIntl(EshopForm));
