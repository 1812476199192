import { StringProperty } from '../../../../models/property';
import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import {
  Dropdown,
  Checkbox,
  Form,
  Input,
  TextArea,
  Icon,
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import { uiSetTemplatePreviewLanguage } from '../../../../actions/ui';
import { List, OrderedMap } from 'immutable';
import LanguageCodes from '../../../../constants/languageCodes';
import Template from '../../../../models/template';
import Errors from '../../../../models/Errors';
import BaseInput from './BaseInput';
import { FormattedHTMLMessage } from 'react-intl';

const textAreaStyle = { width: '100%', maxHeight: 200 };

class StringTypeInput extends BaseInput {
  static propTypes = {
    textArea: PropTypes.bool,
    disabled: PropTypes.bool,
    keyBase: PropTypes.string,
    previewLanguage: PropTypes.string,
    languages: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.instanceOf(List),
    ]),
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    data: PropTypes.instanceOf(Template).isRequired,
    errors: PropTypes.instanceOf(Errors).isRequired,
    path: PropTypes.array.isRequired,
    itemSet: PropTypes.func.isRequired,
    onChange: PropTypes.func,
  };

  handleChange = (e, p) => {
    const { languages, previewLanguage } = this.props;
    const valueToSet = p.value ? p.value : undefined;

    let textValue = this.getValue();
    let fallback = this.getFallback();

    if ((textValue || textValue === '') && !!textValue.get) {
      textValue = textValue.set(previewLanguage, valueToSet);

      // check if value has some translation
      let isEmpty = true;
      languages.map(lang => {
        if (textValue.get(lang)) {
          isEmpty = false;
        }
        return lang;
      });
      // if value of each language is not set
      if (isEmpty) {
        textValue = undefined;
      }
    } else {
      textValue = valueToSet;
    }

    this.setValue(
      e,
      new StringProperty({
        value: textValue ? textValue : undefined,
        fallback: fallback,
      }),
    );
  };

  handleFallbackChange = (e, p) => {
    this.setValue(
      e,
      new StringProperty({
        value: this.getValue(),
        fallback: p.value ? p.value : undefined,
      }),
    );
  };

  checkboxChange = (e, p) => {
    const { languages, previewLanguage } = this.props;
    if (p.checked) {
      const langObjectJson = {};
      languages.map(item => {
        langObjectJson[item] = this.getValue();
        return item;
      });
      this.setValue(
        e,
        new StringProperty({ value: new OrderedMap(langObjectJson) }),
      );
    } else {
      let v = this.getValue().get(previewLanguage);
      if (v) {
        this.setValue(e, new StringProperty({ value: v }));
      } else {
        this.setValue(e, undefined);
      }
    }
  };

  languageChange = (e, p) => {
    this.props.uiSetTemplatePreviewLanguage(p.value);
  };

  render() {
    const {
      keyBase,
      label,
      errors,
      textArea,
      languages,
      previewLanguage,
      path,
      disabled,
      data,
    } = this.props;
    let text = this.getValue();

    const isPlaceholder = data.getIn(path) && data.getIn(path).isPlaceholder();

    let valueError = null;

    const localized = !!text && !!text.get;
    if (localized) {
      // TODO - if is localized, it cannot be placeholder (may be only warn)

      text = localized ? text.get(previewLanguage) : text;
      const errs = [];
      languages.forEach(lang => {
        if (errors.hasMissingPropertyOnPath(path.concat('value'), lang)) {
          errs.push(lang);
        }
      });
      if (errs.length > 0) {
        valueError = (
          <FormattedHTMLMessage
            id={'STRING_INPUT_TRANSLATIONS_VALUE_ERROR'}
            values={{
              lang: errs.join(', '),
              langLabel: errs.map(l => LanguageCodes[l]).join(', '),
            }}
          />
        );
      }
    } else {
      let basePath = path.concat([]);
      basePath.pop();

      if (
        errors.hasErrors(path.concat('value')) ||
        errors.hasMissingPropertyOnPath(basePath, path[path.length - 1]) ||
        errors.hasMissingPropertyOnPath(path, 'value')
      ) {
        valueError = (
          <FormattedHTMLMessage
            id={'STRING_INPUT_VALUE_ERROR'}
            values={{ examplePlaceholder: '#{string}' }}
          />
        );
      }
    }

    const options = [];
    if (languages) {
      languages.map(lang => {
        return options.push({
          text: LanguageCodes[lang],
          value: lang,
        });
      });
    }

    const out = [
      <Form.Field error={!!valueError} key={`${keyBase}.form.field`}>
        <label id={`label`} style={{ float: 'left' }}>
          {label}
        </label>
        <span>
          {languages && (
            <span style={{ width: '100%' }}>
              <Checkbox
                key={`${keyBase}.form.field.checkbox`}
                onChange={this.checkboxChange}
                toggle
                disabled={disabled}
                checked={localized}
                label="&nbsp;"
                style={{ float: 'right' }}
              />
              <Icon
                disabled={!localized}
                name="world"
                style={{ float: 'right' }}
              />
            </span>
          )}
          {localized && (
            <Dropdown
              value={previewLanguage}
              onChange={this.languageChange}
              disabled={disabled}
              style={{ float: 'right', marginRight: 30 }}
              options={options}
            />
          )}
        </span>
        {!textArea ? (
          <Input
            key={`${keyBase}.input`}
            error={!!valueError}
            style={{ width: '100%' }}
            value={text}
            type="string"
            onChange={this.handleChange}
            disabled={disabled}
          />
        ) : (
          textArea && (
            <TextArea
              autoHeight
              key={`${keyBase}.textarea`}
              style={textAreaStyle}
              value={text}
              type="string"
              onChange={this.handleChange}
              disabled={disabled}
              error={valueError}
            />
          )
        )}
      </Form.Field>,
    ];

    if (valueError) {
      out.push(this.renderErrorLabel(valueError, `${keyBase}.value.error`));
    }

    if (isPlaceholder) {
      let fallbackError = null;

      if (errors.hasErrors(path.concat('fallback'))) {
        fallbackError = (
          <FormattedHTMLMessage id={'STRING_INPUT_FALLBACK_ERROR'} />
        );
      } else if (!this.getFallback()) {
        fallbackError = (
          <FormattedHTMLMessage id={'STRING_INPUT_FALLBACK_MISSING'} />
        );
      }

      out.push(
        <Form.Field
          error={!!fallbackError}
          key={`${keyBase}.fallback.form.field`}
        >
          <label>{this.getFallbackLabel()}</label>
          {!textArea && (
            <Form.Input
              key={`${keyBase}.placeholder.input`}
              error={!!fallbackError}
              disabled={disabled}
              onChange={this.handleFallbackChange}
              value={this.getFallback()}
            />
          )}
          {textArea && (
            <TextArea
              style={textAreaStyle}
              autoHeight
              key={`${keyBase}.placeholder.input`}
              error={!!fallbackError}
              disabled={disabled}
              onChange={this.handleFallbackChange}
              value={this.getFallback()}
            />
          )}
        </Form.Field>,
      );

      if (fallbackError) {
        out.push(
          this.renderErrorLabel(fallbackError, `${keyBase}.fallback.error`),
        );
      }
    }

    return out;
  }
}

export default connect(
  (state, props) => ({
    previewLanguage: state.ui.templatePreviewLanguage,
  }),
  { uiSetTemplatePreviewLanguage },
)(injectIntl(StringTypeInput));
