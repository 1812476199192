import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Button, Message } from 'semantic-ui-react';
import { changePassword } from '../../actions/viewer';
import { addNotification } from '../../actions/notification';
import { AUTO_DISMISS } from '../../constants/toast';
import { injectIntl, FormattedMessage, defineMessages } from 'react-intl';

const messages = defineMessages({
  changePasswordHeader: {
    id: 'password.change.header',
    defaultMessage: 'Change password',
  },
  changePasswordAction: {
    id: 'password.change.action',
    defaultMessage: 'Change password',
  },
  currentPwd: {
    id: 'password.change.current.label',
    defaultMessage: 'Current password',
  },
  newPwd: {
    id: 'password.change.new.label',
    defaultMessage: 'New password',
  },
  newPwdRepeat: {
    id: 'password.change.new.again.label',
    defaultMessage: 'New password again',
  },
  changePasswordFailureTitle: {
    id: 'password.change.failure.title',
    defaultMessage: 'Change password failure.',
  },
  changePasswordFailureMessage: {
    id: 'password.change.failure.message',
    defaultMessage: 'Your current password is not valid or new password is not secure. New password must be between 8 and 128 characters, must contain at least one uppercase letter, one lowecase letter and at least one special character.',
  },
  changePasswordFailurePwdNotMatch: {
    id: 'password.change.failure.new.password.is.not.match',
    defaultMessage: 'New passwords are not match.',
  },
  changePasswordFailureInvalidCurrent: {
    id: 'password.change.failure.current.password.is.invalid',
    defaultMessage: 'Current password cannot be empty.',
  },
  changePasswordFailureInvalidNew: {
    id: 'password.change.failure.new.password.is.not.valid',
    defaultMessage: 'Your password must be between 8 and 128 characters, must contain at least one uppercase letter, one lowecase letter and at least one special character.',
  },
  changePasswordSuccessText: {
    id: 'password.change.success.text',
    defaultMessage: 'Your password was successfully changed.',
  },
  changePasswordSuccessTitle: {
    id: 'password.change.success.header',
    defaultMessage: 'Password changed',
  },
});


class User extends Component {
  state = {
    currentPwd: '',
    newPwd: '',
    newPwd1: '',
    currentPwdError: null,
    newPasswordError: null,
    showSuccessInfo: false,
  };

  validPassword = pwd => {
    const valid = pwd !== null && pwd !== '' && pwd.length >= 8
    return valid;
  };

  changePassword = () => {
    this.setState({ currentPwdError: null });
    const { intl } = this.props;

    if (this.state.currentPwd == null || this.state.currentPwd === '') {
      this.props.addNotification({
        title: intl.formatMessage(messages.changePasswordFailureTitle),
        message: intl.formatMessage(
          messages.changePasswordFailureInvalidCurrent,
        ),
        level: 'error',
        autoDismiss: AUTO_DISMISS,
      });
      this.setState({
        currentPwdError: intl.formatMessage(
          messages.changePasswordFailureInvalidCurrent,
        ),
      });
      return;
    }

    if (!this.validPassword(this.state.newPwd)) {
      this.props.addNotification({
        title: intl.formatMessage(messages.changePasswordFailureTitle),
        message: intl.formatMessage(messages.changePasswordFailureInvalidNew),
        level: 'error',
        autoDismiss: AUTO_DISMISS,
      });
      this.setState({
        newPasswordError: intl.formatMessage(
          messages.changePasswordFailureInvalidNew,
        ),
      });
      return;
    }

    if (this.state.newPwd !== this.state.newPwd1) {
      this.props.addNotification({
        title: intl.formatMessage(messages.changePasswordFailureTitle),
        message: intl.formatMessage(messages.changePasswordFailurePwdNotMatch),
        level: 'error',
        autoDismiss: AUTO_DISMISS,
      });
      this.setState({
        newPasswordError: intl.formatMessage(
          messages.changePasswordFailurePwdNotMatch,
        ),
      });
      return;
    }

    this.props.changePassword(
      this.state.currentPwd,
      this.state.newPwd,
      this.onSuccess,
      this.onFailure,
    );
  };

  onFailure = e => {
    this.setState({
      currentPwdError: this.props.intl.formatMessage(
        messages.changePasswordFailureMessage,
      ),
      newPasswordError: null,
    });
    this.props.addNotification({
      title: this.props.intl.formatMessage(messages.changePasswordFailureTitle),
      message: this.props.intl.formatMessage(
        messages.changePasswordFailureMessage,
      ),
      level: 'error',
      autoDismiss: AUTO_DISMISS,
    });
  };

  onSuccess = () => {
    this.setState({
      showSuccessInfo: true,
      currentPwd: '',
      newPwd: '',
      newPwd1: '',
      currentPwdError: null,
      newPasswordError: null,
    });
    this.props.addNotification({
      message: this.props.intl.formatMessage(
        messages.changePasswordSuccessText,
      ),
      level: 'success',
      autoDismiss: AUTO_DISMISS,
    });
  };

  changeForm = (e, d) => {
    var obj = {};
    obj[d.name] = d.value;
    obj.showSuccessInfo = false;
    this.setState(obj);
  };

  render() {
    const { intl } = this.props;
    return (
      <div>
        <h2>
          <FormattedMessage {...messages.changePasswordHeader} />
        </h2>
        <Message info>
            <p>
              <FormattedMessage {...messages.changePasswordFailureInvalidNew} />
            </p>
          </Message>
        {this.state.showSuccessInfo && (
          <Message success>
            <Message.Header>
              <FormattedMessage {...messages.changePasswordSuccessTitle} />
            </Message.Header>
            <p>
              <FormattedMessage {...messages.changePasswordSuccessText} />
            </p>
          </Message>
        )}
        <Form
          error={!!this.state.currentPwdError || !!this.state.newPasswordError}
        >
          <Form.Input
            required
            error={!!this.state.currentPwdError}
            name="currentPwd"
            type="password"
            value={this.state.currentPwd}
            onChange={this.changeForm}
            placeholder={intl.formatMessage(messages.currentPwd)}
            label={intl.formatMessage(messages.currentPwd)}
          />

          {!!this.state.currentPwdError && (
            <Message
              error
              title={this.state.currentPwdError}
              content={this.state.currentPwdError}
            />
          )}

          <Form.Input
            required
            error={!!this.state.newPasswordError}
            name="newPwd"
            type="password"
            onChange={this.changeForm}
            value={this.state.newPwd}
            placeholder={intl.formatMessage(messages.newPwd)}
            label={intl.formatMessage(messages.newPwd)}
          />

          <Form.Input
            required
            error={!!this.state.newPasswordError}
            name="newPwd1"
            type="password"
            value={this.state.newPwd1}
            onChange={this.changeForm}
            placeholder={intl.formatMessage(messages.newPwdRepeat)}
            label={intl.formatMessage(messages.newPwdRepeat)}
          />

          {!!this.state.newPasswordError && (
            <Message
              error
              title={this.state.newPasswordError}
              content={this.state.newPasswordError}
            />
          )}

          <Button onClick={this.changePassword} primary>
            <FormattedMessage {...messages.changePasswordAction} />
          </Button>
        </Form>
      </div>
    );
  }
}

export default connect((state, props) => ({ viewer: state.viewer }), {
  changePassword,
  addNotification,
})(injectIntl(User));
