export const FETCH_VIEWER_LOADING = 'FETCH_VIEWER_LOADING';
export const FETCH_VIEWER_SUCCESS = 'FETCH_VIEWER_SUCCESS';
export const FETCH_VIEWER_FAILURE = 'FETCH_VIEWER_FAILURE';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_CHANGE_PASSWORD = 'AUTH_CHANGE_PASSWORD';
export const AUTH_CHANGE_PASSWORD_SUCCESS = 'AUTH_CHANGE_PASSWORD_SUCCESS';
export const AUTH_CHANGE_PASSWORD_FAILURE = 'AUTH_CHANGE_PASSWORD_FAILURE';

export function fetchViewer() {
  return {
    type: FETCH_VIEWER_LOADING,
  };
}

export function logout() {
  return {
    type: AUTH_LOGOUT,
  };
}

export function login() {
  return {
    type: AUTH_LOGIN,
  };
}

export function changePassword(
  currentPassword,
  newPassword,
  onSuccess,
  onFailure,
) {
  return {
    type: AUTH_CHANGE_PASSWORD,
    currentPassword: currentPassword,
    newPassword: newPassword,
    onSuccess: onSuccess,
    onFailure: onFailure,
  };
}
