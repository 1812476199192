import { call, put } from 'redux-saga/effects';
import client, { CORE_API_URL, TICKET_API_URL } from '../services/api';
import {
  inUuidQueryBuilder,
  inQueryBuilder,
  likeQueryBuilder,
  ltQueryBuilder,
  gtQueryBuilder,
  eqQueryBuilder,
} from '../services/api.js';

import {
  CREATE_FAILURE,
  CREATE_SUCCESS,
  READ_FAILURE,
  READ_SUCCESS,
  UPDATE_FAILURE,
  UPDATE_SUCCESS,
  DELETE_FAILURE,
  DELETE_SUCCESS,
} from '../actions/resource';

export function getResourceRoutePrefix(resource) {
  if (
    resource === 'ticket' ||
    resource === 'ticketOrderItem' ||
    resource === 'ticketOrder' ||
    resource === 'ticketProduct' ||
    resource === 'ticketEshop' ||
    resource === 'ticketSale' ||
    resource === 'ticketTransaction'
  ) {
    return 'ticket';
  } else if (resource === 'user' || resource === 'project') {
    return 'identity';
  }
  return 'passes';
}

export function getApiForResource(resource) {
  if (
    resource === 'ticket' ||
    resource === 'ticketOrderItem' ||
    resource === 'ticketOrder' ||
    resource === 'ticketProduct' ||
    resource === 'ticketEshop' ||
    resource === 'ticketSale' ||
    resource === 'ticketTransaction'
  ) {
    return TICKET_API_URL;
  }
  return CORE_API_URL;
}
export function getApiResourceAlias(resource) {
  if (resource === 'ticketEshop') {
    return 'eshop';
  } else if (resource === 'ticketProduct') {
    return 'product';
  } else if (resource === 'ticketOrderItem') {
    return 'orderItem';
  } else if (resource === 'ticketOrder') {
    return 'order';
  } else if (resource === 'ticketTransaction') {
    return 'transaction';
  } else if (resource === 'ticketSale') {
    return 'sale';
  } else {
    return resource;
  }
}

export function* create(action) {
  const resource = action.resource;
  const payload = action.data;
  const success = action.success;
  const failure = action.failure;
  const requestId = action.requestId;
  try {
    let data = yield call(
      client,
      'POST',
      getApiForResource(resource) + '/v1/' + getApiResourceAlias(resource),
      payload,
    );
    if (success) {
      success(data, resource, data.id);
    }
    yield put({
      type: CREATE_SUCCESS,
      data,
      resource,
      id: data.id,
      requestId,
    });
  } catch (e) {
    if (failure) failure(e, resource, 'new');
    yield put({
      type: CREATE_FAILURE,
      message: e.message,
      resource: resource,
      requestId,
    });
  }
}

export function* read(action) {
  let { resource, id, requestId, success, failure } = action;
  try {
    let data = yield call(
      client,
      'GET',
      getApiForResource(resource) +
        '/v1/' +
        getApiResourceAlias(resource) +
        '/' +
        id,
      {},
    );
    if (success) {
      success(data, resource, id);
    }
    yield put({
      type: READ_SUCCESS,
      data,
      resource,
      id,
      requestId,
    });
  } catch (e) {
    if (failure) failure(e, resource, id);
    yield put({
      type: READ_FAILURE,
      message: e.message || e.statusText,
      error: e,
      resource: resource,
      requestId,
    });
  }
}

export function* update(action) {
  const id = action.data.id;
  const resource = action.resource;
  const payload = action.data;
  const requestId = action.requestId;
  const success = action.success;
  const failure = action.failure;
  try {
    let data = yield call(
      client,
      'PUT',
      getApiForResource(resource) +
        '/v1/' +
        getApiResourceAlias(resource) +
        '/' +
        id,
      payload,
    );
    if (success) {
      success(data, resource, id);
    }
    yield put({
      type: UPDATE_SUCCESS,
      data,
      resource,
      id,
      requestId,
    });
  } catch (e) {
    if (failure) failure(e, resource, id);
    yield put({
      type: UPDATE_FAILURE,
      message: e.message,
      resource: resource,
      requestId,
    });
  }
}

export function* remove(action) {
  const id = action.id;
  const resource = action.resource;
  const success = action.success;
  const failure = action.failure;
  const requestId = action.requestId;
  try {
    let data = yield call(
      client,
      'DELETE',
      getApiForResource(resource) +
        '/v1/' +
        getApiResourceAlias(resource) +
        '/' +
        id,
      {},
    );
    if (success) {
      success(data, resource, id);
    }
    yield put({
      type: DELETE_SUCCESS,
      data,
      resource,
      id,
      requestId,
    });
  } catch (e) {
    if (failure) failure(e, resource, id);
    yield put({
      type: DELETE_FAILURE,
      message: e.message,
      resource: resource,
      requestId,
    });
  }
}
export function prepareFilter(filter, filterProps) {
  let where = {
    deletedAt: null,
  };
  for (let i in filterProps) {
    if (filterProps.hasOwnProperty(i)) {
      const filterPropName = filterProps[i].name;
      const filterPropType = filterProps[i].type;
      const filterPropColumnName = filterProps[i].columnName;

      if (filterPropType === 'inUuid') {
        if (filter && filter[filterPropName] && filter[filterPropName].value) {
          where = inUuidQueryBuilder(
            where,
            filter[filterPropName].value,
            filterPropColumnName,
          );
        }
      }
      if (filterPropType === 'in') {
        if (filter && filter[filterPropName] && filter[filterPropName].value) {
          where = inQueryBuilder(
            where,
            filter[filterPropName].value,
            filterPropColumnName,
          );
        }
      }
      if (filterPropType === 'like') {
        if (filter && filter[filterPropName] && filter[filterPropName].value) {
          where = likeQueryBuilder(
            where,
            filter[filterPropName].value,
            filterPropColumnName,
          );
        }
      }
      if (filterPropType === 'eq') {
        if (filter && filter[filterPropName] && filter[filterPropName].value) {
          where = eqQueryBuilder(
            where,
            filter[filterPropName].value,
            filterPropColumnName,
          );
        }
      }
      if (filterPropType === 'gt') {
        if (filter && filter[filterPropName] && filter[filterPropName].value) {
          where = gtQueryBuilder(
            where,
            filter[filterPropName].value,
            filterPropColumnName,
          );
        }
      }
      if (filterPropType === 'gtOrEq') {
        if (filter && filter[filterPropName] && filter[filterPropName].value) {
          if (filter[filterPropName].value === 'eq') {
            where = eqQueryBuilder(where, 0, filterPropColumnName);
          } else {
            where = gtQueryBuilder(where, 0, filterPropColumnName);
          }
        }
      }
      if (filterPropType === 'lt') {
        if (filter && filter[filterPropName] && filter[filterPropName].value) {
          where = ltQueryBuilder(
            where,
            filter[filterPropName].value,
            filterPropColumnName,
          );
        }
      }
      if (filterPropType === 'day') {
        if (filter && filter[filterPropName] && filter[filterPropName].value) {
          const startOfDay = new Date(filter[filterPropName].value);
          const endOfDay = new Date(filter[filterPropName].value);
          endOfDay.setDate(endOfDay.getDate() + 1);
          where = gtQueryBuilder(where, startOfDay, filterPropColumnName);
          where = ltQueryBuilder(where, endOfDay, filterPropColumnName);
        }
      }
    }
  }
  return where;
}
