import React, { Component } from 'react';
import PropTypes from 'prop-types';
import languageCodes from '../../constants/languageCodes';
import { Form } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';

class FormDropdownLanguages extends Component {
  static propTypes = {
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    multiple: PropTypes.bool,
  };

  render() {
    const {
      intl,
      value,
      multiple,
      name,
      label,
      options,
      onChange,
    } = this.props;
    let languagesOptions = options
      ? options.toArray()
      : Object.keys(languageCodes);
    const languages = languagesOptions.map(key => {
      return {
        text: languageCodes[key],
        value: key,
      };
    });
    return (
      <Form.Dropdown
        name={name}
        search={true}
        options={languages}
        selection={true}
        multiple={multiple}
        onChange={onChange}
        value={value}
        label={
          label ||
          `${intl.formatHTMLMessage({
            id: 'FORM_DROPDOWN_LANGUAGES',
          })}`
        }
      />
    );
  }
}

export default injectIntl(FormDropdownLanguages);
