import _ from 'lodash';

export default function(
  state = {
    path: null,
    routeId: null,
  },
  action,
) {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE':
      state.path = action.payload.pathname;
      state.routeId = action.payload.state && action.payload.state.requestId;
      return _.merge({}, state);

    default:
      return state;
  }
}
