import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Menu, Segment } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { uiSetFormTab } from '../../actions/ui';
import { injectIntl } from 'react-intl';

class Tab extends Component {
  static propTypes = {
    tabOptions: PropTypes.object.isRequired,
    viewer: PropTypes.object.isRequired,
    formName: PropTypes.string.isRequired,
    defaultTab: PropTypes.string.isRequired,
  };

  handleTabClick = (e, { value }) => {
    this.props.uiSetFormTab(this.props.formName, value);
  };

  handleChangeJSON = (e, p) => {
    this.props.itemSet(p.value);
  };

  render() {
    const { form, tabOptions, defaultTab } = this.props;

    let activeItem = defaultTab;
    if (form && form.tab && tabOptions[form.tab]) {
      activeItem = form.tab;
    }

    return (
      <div>
        <Menu
          attached="top"
          tabular
          style={{
            borderBottomColor: tabOptions[activeItem].error
              ? '#DB2828'
              : undefined,
          }}
        >
          {Object.keys(tabOptions).map(key => {
            const opt = tabOptions[key];
            return (
              <Menu.Item
                style={{ color: opt.error ? '#DB2828' : undefined }}
                color={opt.error ? 'red' : undefined}
                icon={opt.icon}
                name={opt.name}
                value={key}
                key={key}
                active={activeItem === key}
                onClick={this.handleTabClick}
              />
            );
          })}
        </Menu>

        <Segment
          attached="bottom"
          style={{
            borderColor: tabOptions[activeItem].error ? '#DB2828' : undefined,
          }}
        >
          {tabOptions[activeItem].content}
        </Segment>
      </div>
    );
  }
}

export default connect(
  (state, props) => ({
    form: state.ui.forms[props.formName],
    viewer: state.viewer.data,
  }),
  { uiSetFormTab },
)(injectIntl(Tab));
