import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'semantic-ui-react';
import Highlight from './Highlight';

export default class JsonModal extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired,
  };

  render() {
    return (
      <Modal trigger={<Button>JSON</Button>} closeIcon={true}>
        <Modal.Header>{this.props.title}</Modal.Header>
        <Modal.Content image>
          <Modal.Description style={{ width: '100%' }}>
            <Highlight className="json">
              {`${JSON.stringify(this.props.data || {}, null, 2)}`}
            </Highlight>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}
