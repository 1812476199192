import { ADD_NOTIFICATION, REMOVE_NOTIFICATION } from '../actions/notification';
import _ from 'lodash';

export default function(state = {}, action) {
  switch (action.type) {
    case ADD_NOTIFICATION:
      state[action.id] = action.notification;
      return _.merge({}, state);
    case REMOVE_NOTIFICATION:
      delete state[action.id];
      return _.merge({}, state);

    default:
      return state;
  }
}
