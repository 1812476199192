import React, { Component } from 'react';
import CertificateForm from './CertificateForm';
import Edit from '../../common/Edit';
export default class FileEdit extends Component {
  render() {
    return (
      <Edit
        resource={'certificate'}
        resourceId={this.props.params.id}
        component={CertificateForm}
      />
    );
  }
}
