import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { Button } from 'semantic-ui-react';
import ProjectNewModal from './ProjectNewModal';
import { connect } from 'react-redux';

const resource = 'project';

class ActionClass extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    viewer: PropTypes.object.isRequired,
  };

  render() {
    const { viewer, item } = this.props;
    return (
      <Button.Group>
        <ProjectNewModal
          parentId={item.id}
          disabled={!viewer.canCreateSubProject(item.id)}
        />
        <Button
          as={Link}
          to={'/identity/' + resource + '/' + item.id}
          icon="edit"
          disabled={
            !(
              viewer.canEditProject(item.id) ||
              viewer.canManageUsersOnProject(item.id)
            )
          }
        />
      </Button.Group>
    );
  }
}

export default connect((state, props) => ({ viewer: state.viewer.data }))(
  ActionClass,
);
