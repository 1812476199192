import React, { Component } from 'react';
import PassEditTemplate from './PassEditTemplate';
import Edit from '../../common/Edit';
export default class PassEdit extends Component {
  render() {
    return (
      <Edit
        resource={'pass'}
        resourceId={this.props.params.id}
        component={PassEditTemplate}
      />
    );
  }
}
