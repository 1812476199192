import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  itemRead,
  itemSet,
  itemSetMetadata,
  itemSave,
  itemCreate,
  itemDestroy,
  itemDelete,
} from '../../actions/resource';
import { push } from '../../actions/routing';
import { addNotification } from '../../actions/notification';
import { AUTO_DISMISS } from '../../constants/toast.js';
import { Message, Loader } from 'semantic-ui-react';
import { getResourceRoutePrefix } from '../../saga/resource';
import WithRequestIdClass from './WithRequestIdComponent';

function assign(prop, value, obj) {
  if (typeof prop === 'string') prop = prop.split('.');
  if (prop.length > 1) {
    let e = prop.shift();
    assign(
      prop,
      value,
      (obj[e] =
        Object.prototype.toString.call(obj[e]) === '[object Object]' ||
        Object.prototype.toString.call(obj[e]) === '[object Array]'
          ? obj[e]
          : {}),
    );
  } else obj[prop[0]] = value;
}

class ResourceItemDecorator extends Component {
  static propTypes = {
    resourceId: PropTypes.string.isRequired,
    instance: PropTypes.object,
    resource: PropTypes.string.isRequired,
    push: PropTypes.func.isRequired,
    itemRead: PropTypes.func.isRequired,
    itemSet: PropTypes.func.isRequired,
    itemSetMetadata: PropTypes.func.isRequired,
    itemSave: PropTypes.func.isRequired,
    itemCreate: PropTypes.func.isRequired,
    itemDestroy: PropTypes.func.isRequired,
    itemDelete: PropTypes.func.isRequired,
    ignoreLoading: PropTypes.bool,
    ignoreError: PropTypes.bool,
    component: PropTypes.func.isRequired,
    componentProps: PropTypes.object,
  };

  componentWillMount() {
    this.init(this.props.instance, this.props.resourceId, this.props.requestId);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.resourceId !== nextProps.resourceId) {
      this.init(nextProps.instance, nextProps.resourceId, nextProps.requestId);
    }
  }

  init(item, id, requestId) {
    const isLoading = item && item.get('loading');
    if (!isLoading) {
      this.itemRead(this.props.resource, requestId, id);
    } else {
      // check timeout for refresh (if is readed before )
      if (item.timestamp) {
        //console.log(new Date().getTime() - item.timestamp.getTime())
        if (new Date().getTime() - item.timestamp.getTime() > 15 * 1000 * 60) {
          this.itemRead(this.props.resource, requestId, id);
        }
      }
    }
  }

  componentWillUnmount() {
    this.props.itemDestroy(this.props.resource, this.props.requestId);
  }

  itemReadSuccess = (data, resource, id) => {};
  itemReadFailure = (e, resource, id) => {
    if (this.props.failure) {
      this.props.failure(e, resource, id);
    }
    this.props.addNotification({
      title: `Read ${resource} ${id} failure`,
      message: e.message || e.statusText,
      level: 'error',
      autoDismiss: AUTO_DISMISS,
    });
  };

  itemRead = (resource, requestId, id) => {
    const { itemRead } = this.props;
    if (id !== 'new') {
      itemRead(
        resource,
        requestId,
        id,
        this.itemReadSuccess,
        this.itemReadFailure,
      );
    } else {
      this.itemSet(this.props.defaultValue || {});
    }
  };

  itemSetMetadata = (name, value) => {
    this.props.itemSetMetadata(
      this.props.resource,
      this.props.requestId,
      name,
      value,
    );
  };

  itemSet = data => {
    this.props.itemSet(this.props.resource, data, this.props.requestId);
  };

  itemSetPropertyByPath = (path, value, data) => {
    assign(path, value, data);
    this.itemSet(data);
  };

  itemSaveSuccess = (data, resource, id) => {
    this.props.addNotification({
      title: `Update success`,
      message: `${resource} ${id} was updated`,
      level: 'success',
      autoDismiss: AUTO_DISMISS,
    });
    this.props.push(`/${getResourceRoutePrefix(resource)}/${resource}`);
  };

  itemApplySuccess = (data, resource, id) => {
    this.props.addNotification({
      title: `Update success`,
      message: `${resource} ${id} was updated`,
      level: 'success',
      autoDismiss: AUTO_DISMISS,
    });
  };

  itemSaveFailure = (e, resource, id) => {
    this.props.addNotification({
      title: `Update ${resource} ${id} failure`,
      message: e.message,
      level: 'error',
      autoDismiss: AUTO_DISMISS,
    });
  };

  itemSave = (
    data,
    success = this.itemSaveSuccess,
    failure = this.itemSaveFailure,
  ) => {
    this.props.itemSave(
      this.props.resource,
      this.props.requestId,
      data,
      success,
      failure,
    );
  };

  itemApply = (
    data,
    success = this.itemApplySuccess,
    failure = this.itemSaveFailure,
  ) => {
    this.props.itemSave(
      this.props.resource,
      this.props.requestId,
      data,
      success,
      failure,
    );
  };

  itemCreateSuccess = (data, resource, id) => {
    this.props.addNotification({
      title: `Create success`,
      message: `${resource} ${id} was created`,
      level: 'success',
      autoDismiss: AUTO_DISMISS,
    });
    this.props.push(`/${getResourceRoutePrefix(resource)}/${resource}/${id}`);
  };

  itemCreateFailure = (e, resource, id) => {
    this.props.addNotification({
      title: `Create ${resource} ${id} failure`,
      message: e.message,
      level: 'error',
      autoDismiss: AUTO_DISMISS,
    });
  };

  itemCreate = (
    data,
    success = this.itemCreateSuccess,
    failure = this.itemCreateFailure,
  ) => {
    this.props.itemCreate(
      this.props.resource,
      this.props.requestId,
      data,
      success,
      failure,
    );
  };

  itemDeleteSuccess = (data, resource, id) => {
    this.props.addNotification({
      title: `Delete success`,
      message: `${resource} ${id} was deleted`,
      level: 'success',
      autoDismiss: AUTO_DISMISS,
    });
    this.props.push(`/${getResourceRoutePrefix(resource)}/${resource}`);
  };

  itemDeleteFailure = (e, resource, id) => {
    this.props.addNotification({
      title: `Delete ${resource} ${id} failure`,
      message: e.message,
      level: 'error',
      autoDismiss: AUTO_DISMISS,
    });
  };

  itemDelete = (
    success = this.itemDeleteSuccess,
    failure = this.itemDeleteFailure,
  ) => {
    this.props.itemDelete(
      this.props.resource,
      this.props.requestId,
      this.props.resourceId,
      success,
      failure,
    );
  };

  render() {
    const { instance, viewer, resource, ignoreLoading } = this.props;

    if (!instance || (instance.get('loading') && !ignoreLoading)) {
      return <Loader active inline="centered" />;
    }

    // resource item wrapper - create fallback on childerns (if not is loading and not data and error message - show in children what you need - in tables show only id, in form show error)
    if (!instance.get('data')) {
      return <span>{this.props.resourceId}</span>;
    }

    const childrenProps = {
      resource: resource,
      data: instance.get('data'),
      changed: instance.get('changed'),
      forceOpen: instance.get('forceOpen'),
      touched: instance.get('touched'),
      validation: instance.get('validation'),
      message: instance.get('message'),
      loading: instance.get('loading'),
      viewer: viewer,
      itemSet: this.itemSet,
      itemSave: this.itemSave,
      itemSetMetadata: this.itemSetMetadata,
      itemApply: this.itemApply,
      itemCreate: this.itemCreate,
      itemDelete: this.itemDelete,
      itemSetPropertyByPath: this.itemSetPropertyByPath,
      requestId: this.props.requestId,
      parentProps: this.props.parentProps,
    };

    const ChildComponent = this.props.component;

    if (instance.get('message') && !this.props.ignoreError) {
      return (
        <Message negative>
          <Message.Header>We are sorry but something is broken</Message.Header>
          <p>{instance.get('message')}</p>
        </Message>
      );
    }

    return <ChildComponent {...childrenProps} {...this.props.componentProps} />;
  }
}

const ResourceItemDecoratorWithConnect = connect(
  (state, props) => {
    const instance = state.resource.get(props.requestId);
    return {
      instance: instance,
      viewer: state.viewer.data,
    };
  },
  {
    push,
    itemRead,
    itemSave,
    itemSet,
    itemSetMetadata,
    itemCreate,
    itemDestroy,
    itemDelete,
    addNotification,
  },
)(ResourceItemDecorator);

export default class ResourceItemDecoratorClass extends Component {
  render() {
    return (
      <WithRequestIdClass>
        <ResourceItemDecoratorWithConnect {...this.props} />
      </WithRequestIdClass>
    );
  }
}
