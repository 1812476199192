import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Button, Icon } from 'semantic-ui-react';
import FormGroupTimestamps from '../../common/FormGroupTimestamps';
import BackButton from '../../common/buttons/BackButton';
import { FormattedMessage } from 'react-intl';

class UserForm extends Component {
  static propTypes = {
    data: PropTypes.object,
    itemSet: PropTypes.func.isRequired,
    itemSave: PropTypes.func.isRequired,
  };

  onChange(e, p) {
    const { itemSet, data } = this.props;
    data[e.target.name] = e.target.value;
    itemSet(data);
  }

  onChangeCheckbox(e, p) {
    const { itemSet, data } = this.props;
    data[e.target.name] = !data[e.target.name];
    itemSet(data);
  }

  handleSave = e => {
    e.preventDefault();
    const { itemSave, data } = this.props;
    itemSave(data);
  };

  handleApply = e => {
    e.preventDefault();
    const { itemApply, data } = this.props;
    data.displayName = data.displayName.trim();
    data.email = data.email.trim();
    itemApply(data);
  };

  render() {
    const { data, changed } = this.props;
    return (
      <Form>
        <Form.Input name="id" label="Id" defaultValue={data.id} disabled />
        <Form.Input
          name="displayName"
          label="Name"
          defaultValue={data.displayName}
          disabled={true}
        />
        <Form.Input
          name="email"
          label="Email"
          defaultValue={data.email}
          disabled={true}
        />
        <Form.Input
          disabled={true}
          name="isAdmin"
          label="Admin"
          type="checkbox"
          onChange={(e, p) => this.onChangeCheckbox(e, p)}
          checked={data.isAdmin}
        />
        <FormGroupTimestamps data={data} />
        <Button disabled={!changed} primary onClick={this.handleSave}>
          <Icon name="save" />
          <FormattedMessage id="BUTTON_SAVE" />
        </Button>
        <Button disabled={!changed} secondary onClick={this.handleApply}>
          <Icon name="save" />
          <FormattedMessage id="BUTTON_APPLY" />
        </Button>
        <BackButton />
      </Form>
    );
  }
}
export default connect((state, props) => ({}), {})(UserForm);
