import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PassImage from './PassImage';
import { Grid } from 'semantic-ui-react';

const imageTypes = [
  'logo',
  'icon',
  'footer',
  'strip',
  'thumbnail',
  'background',
];

export default class PassImages extends Component {
  static propTypes = {
    pass: PropTypes.object.isRequired,
    languages: PropTypes.object.isRequired,
    images: PropTypes.object,
    projectId: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  render() {
    return (
      <Grid columns={3}>
        {imageTypes.map(imageType => {
          return (
            <PassImage key={imageType} imageType={imageType} {...this.props} />
          );
        })}
      </Grid>
    );
  }
}
