import React, { Component } from 'react';
import { IntlProvider, FormattedNumber } from 'react-intl';
import PropTypes from 'prop-types';
export default class FieldPreviewValueCurrency extends Component {
  static propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    currency: PropTypes.string.isRequired,
    previewLanguage: PropTypes.string.isRequired,
  };

  render() {
    const { value, currency, previewLanguage } = this.props;
    return (
      <IntlProvider locale={previewLanguage}>
        <FormattedNumber
          {...{ style: 'currency' }}
          currency={currency}
          value={Number(value)}
        />
      </IntlProvider>
    );
  }
}
