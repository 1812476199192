import React from 'react';
import PropTypes from 'prop-types';
import Template from '../../../../../../../../models/template';
import FieldPreviewBase from './FieldPreviewBase';
import { Icon } from 'semantic-ui-react';

export default class FieldPreviewPrimary extends FieldPreviewBase {
  static propTypes = {
    ...FieldPreviewBase.propTypes,
    previewLanguage: PropTypes.string.isRequired,
    fieldsType: PropTypes.string.isRequired,
    data: PropTypes.instanceOf(Template).isRequired,
  };

  renderIcon = () => {
    return <Icon name="globe" style={{ height: 20 }} />;
  };

  render() {
    const { data } = this.props;
    const passStyle = data.getPassStyle();
    if (passStyle === 'eventTicket') {
      return (
        <div
          style={{
            color: data.getForegroundColor(),
            maxWidth: 290,
            verticalAlign: 'middle',
          }}
        >
          <div
            style={{
              color: 'white',
              height: 50,
              fontSize: 40,
              lineHeight: '40px',
              marginTop: 20,
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {this.isLocalizedValue() && this.renderIcon()}
            {this.getValueValue()}
          </div>
        </div>
      );
    }

    if (passStyle === 'storeCard' || passStyle === 'coupon') {
      return (
        <div
          style={{
            color: data.getForegroundColor(),
            maxWidth: 290,
          }}
        >
          <div
            style={{
              color: 'white',
              fontSize: 38,
              lineHeight: '38px',
              marginTop: 30,
              height: 40,
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {this.isLocalizedValue() && this.renderIcon()}
            {this.getValueValue() || <span>&nbsp;</span>}
          </div>
          <div
            style={{
              color: 'white',
              fontSize: 15,
              textTransform: 'uppercase',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {this.isLocalizedLabel() && this.renderIcon()}
            {this.getLabelValue()}
          </div>
        </div>
      );
    }

    if (passStyle === 'boardingPass') {
      return (
        <div
          style={{
            color: data.getForegroundColor(),
            maxWidth: 120,
          }}
        >
          <div
            style={{
              color: data.getLabelColor(),
              fontSize: 10,
              height: '1.3em',
              textTransform: 'uppercase',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {this.isLocalizedLabel() && this.renderIcon()}
            {this.getLabelValue()}
          </div>
          <div
            style={{
              color: data.getForegroundColor(),
              fontSize: 30,
              lineHeight: '30px',
              marginTop: 10,
              height: 34,
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {this.isLocalizedValue() && this.renderIcon()}
            {this.getValueValue()}
          </div>
        </div>
      );
    }

    if (passStyle === 'generic') {
      return (
        <div
          style={{
            color: data.getForegroundColor(),
            maxWidth: 200,
          }}
        >
          <div
            style={{
              color: data.getLabelColor(),
              fontSize: 10,
              height: '1.3em',
              textTransform: 'uppercase',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {this.isLocalizedLabel() && this.renderIcon()}
            {this.getLabelValue()}
          </div>
          <div
            style={{
              color: data.getForegroundColor(),
              fontSize: 30,
              lineHeight: '30px',
              marginTop: 10,
              height: 34,
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {this.isLocalizedValue() && this.renderIcon()}
            {this.getValueValue()}
          </div>
        </div>
      );
    }

    return null;
  }
}
