import { Image } from 'semantic-ui-react';
import { FormattedHTMLMessage } from 'react-intl';
import React from 'react';
import barcodeImage_128 from './../components/core/template/tabs/designer/barcode/barcodeImage_128.svg';
import barcodeImage_aztec from './../components/core/template/tabs/designer/barcode/barcodeImage_aztec.svg';
import barcodeImage_pdf417 from './../components/core/template/tabs/designer/barcode/barcodeImage_pdf417.svg';
import barcodeImage_qr from './../components/core/template/tabs/designer/barcode/barcodeImage_qr.svg';

const barcodeTypesValues = {
  PKBarcodeFormatQR: {
    isSquared: true,
    img: barcodeImage_qr,
  },
  PKBarcodeFormatPDF417: {
    isSquared: false,
    img: barcodeImage_pdf417,
  },
  PKBarcodeFormatAztec: {
    isSquared: true,
    img: barcodeImage_aztec,
  },
  PKBarcodeFormatCode128: {
    isSquared: false,
    img: barcodeImage_128,
  },
};

const barcodeFallbackValues = {
  PKBarcodeFormatQR: {
    isSquared: true,
    img: barcodeImage_qr,
  },
  PKBarcodeFormatPDF417: {
    isSquared: false,
    img: barcodeImage_pdf417,
  },
  PKBarcodeFormatAztec: {
    isSquared: true,
    img: barcodeImage_aztec,
  },
};

export const transitTypes = {
  PKTransitTypeAir: {
    img: 'plane',
  },
  PKTransitTypeBoat: {
    img: 'ship',
  },
  PKTransitTypeBus: {
    img: 'bus',
  },
  PKTransitTypeGeneric: {
    img: 'arrow right',
  },
  PKTransitTypeTrain: {
    img: 'train',
  },
};

function getValues(values) {
  return Object.keys(values).map(key => {
    return {
      key: key,
      value: key,
      text: (
        <pre>
          <FormattedHTMLMessage id={key.toUpperCase()} />
        </pre>
      ),
      content: (
        <span>
          <FormattedHTMLMessage id={key.toUpperCase()} />{' '}
          <Image height="28" src={values[key].img} />
        </span>
      ),
    };
  });
}

export const barcodeTypes = barcodeTypesValues;
export const barcodeTypeOptions = getValues(barcodeTypesValues);
export const barcodeFallbackOptions = getValues(barcodeFallbackValues);
