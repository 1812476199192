import React, { Component } from 'react';
import ResourceListCompleteDecorator from '../ResourceListCompleteDecorator';
import { Card, Icon, Image } from 'semantic-ui-react';

const PAGE_SIZE = 42;

class Bytes extends React.Component {
  formatBytes = () => {
    const i = Math.floor(Math.log(this.props.bytes) / Math.log(1024));
    return (
      (!this.props.bytes && '0 Bytes') ||
      (this.props.bytes / Math.pow(1024, i)).toFixed(2) +
        ' ' +
        ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][i]
    );
  };

  render() {
    return <span>{this.formatBytes()}</span>;
  }
}

const resource = 'image';

const filterProps = [
  {
    name: 'project',
    type: 'eq',
    columnName: 'projectId',
  },
  {
    name: 'type',
    type: 'eq',
    columnName: 'type',
  },
];

class ImageListDataGrid extends Component {
  // TODO dont use state, better paginable loading
  state = { max: PAGE_SIZE };

  constructor(props) {
    super(props);
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    document
      .getElementById('scroll-image-selection')
      .addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    // TODO - unregister event
    //document.getElementById('scroll-image-selection').removeEventListener('scroll', this.handleScroll);
  }

  handleScroll(event) {
    const element = document.getElementById('scroll-image-selection');
    if (element.scrollHeight - element.scrollTop === element.clientHeight) {
      this.setState({ max: this.state.max + PAGE_SIZE });
    }
  }

  render() {
    const { list, selectedId } = this.props;

    let l = [];
    if (list && list.data) {
      const to = Math.min(this.state.max, list.data.length);
      l = list.data.slice(0, to);
    }
    // TODO -better id of scrooling elemnt
    return (
      <Card.Group
        id={'scroll-image-selection'}
        itemsPerRow={6}
        doubling
        style={{
          maxHeight: 'calc(60vh)',
          overflow: 'auto',
        }}
      >
        {l.map(item => {
          return (
            <Card
              onClick={() => {
                this.props.handleChange(item);
              }}
              key={item.id}
              raised={item.id === selectedId}
              color={item.id === selectedId ? 'green' : undefined}
            >
              <Image src={item.url} centered fluid />

              {item.id === selectedId && (
                <Icon
                  size={'large'}
                  inverted
                  circular
                  name={'checkmark'}
                  color={'green'}
                  style={{
                    position: 'absolute',
                    right: -10,
                    top: -10,
                  }}
                />
              )}
              <Card.Content style={{ padding: '0.5em 0.5em' }}>
                <Card.Header
                  style={{
                    fontSize: '0.8em',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {item.name}
                </Card.Header>
                <Card.Meta style={{ fontSize: '0.5em' }}>
                  <span className="date" style={{ float: 'left' }}>
                    <Bytes bytes={item.fileSize} />
                  </span>
                  <span className="date" style={{ float: 'right' }}>
                    {item.width}x{item.height}
                  </span>
                </Card.Meta>
              </Card.Content>
            </Card>
          );
        })}
      </Card.Group>
    );
  }
}

export default class ImageList extends Component {
  render() {
    return (
      <ResourceListCompleteDecorator
        filter={{
          project: { value: this.props.projectId },
          type: { value: this.props.type },
        }}
        filterProps={filterProps}
        resource={resource}
        component={ImageListDataGrid}
        componentProps={{
          ...this.props,
        }}
      />
    );
  }
}
