import React, { Component } from 'react';
import { Menu, Image, Popup } from 'semantic-ui-react';
import { Link } from 'react-router';
import Logo from './../components/logo.svg';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { login, logout } from '../actions/viewer';
import { DOC_API_URL } from '../services/api';
import md5 from 'js-md5';

class VerticalMenu extends Component {
  render() {
    const { config, viewer, logout, navigation } = this.props;
    return (
      <div>
        <Menu.Item as={Link} to="/" style={{ height: 60 }}>
          <img
            className="logo"
            src={Logo}
            alt="logo"
            style={{
              height: 42,
            }}
          />
        </Menu.Item>
        {!viewer.data.email && (
          <Link to="/" onClick={() => login()}>
            <Menu.Item>
              <FormattedMessage id="MENU_LOGIN" />
            </Menu.Item>
          </Link>
        )}
        {viewer.data.email && (
          <Menu.Item style={{ textAlign: 'left' }}>
            <Menu.Header>
              <Image
                src={`https://www.gravatar.com/avatar/${md5(
                  viewer.data.email,
                )}?d=robohash`}
                avatar
                style={{
                  marginRight: 10,
                  background: 'rgba(255,255,255,0.1)',
                }}
              />
              <Popup
                inverted
                size="mini"
                position="bottom left"
                trigger={
                  <span
                    style={{
                      width: '120px',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: 'nline-block',
                    }}
                  >
                    {viewer.data.displayName}
                  </span>
                }
                content={viewer.data.email}
              />
            </Menu.Header>
            <Menu.Menu>
              <Link to="/user/detail">
                <Menu.Item
                  active={navigation.path === '/user/detail'}
                  style={{ textAlign: 'left' }}
                >
                  <FormattedMessage id="MENU_USER_DETAIL" />
                </Menu.Item>
              </Link>
              <Link to="/" onClick={() => logout()}>
                <Menu.Item style={{ textAlign: 'left' }}>
                  <FormattedMessage id="MENU_USER_LOGOUT" />
                </Menu.Item>
              </Link>
            </Menu.Menu>
          </Menu.Item>
        )}
        {viewer.data.showPassMenu() && (
          <Menu.Item>
            <Menu.Header>
              <FormattedMessage id="MENU_PASSES" />
            </Menu.Header>
            <Menu.Menu>
              {viewer.data.showPassList() && (
                <Link to="/passes/pass">
                  <Menu.Item active={navigation.path.includes('/passes/pass')}>
                    <FormattedMessage id="MENU_PASSES_PASS" />
                  </Menu.Item>
                </Link>
              )}
              {viewer.data.showTemplateList() && (
                <Link to="/passes/template">
                  <Menu.Item
                    active={navigation.path.includes('/passes/template')}
                  >
                    <FormattedMessage id="MENU_PASSES_TEMPLATE" />
                  </Menu.Item>
                </Link>
              )}
              {viewer.data.showImagesList() && (
                <Link to="/passes/image">
                  <Menu.Item active={navigation.path.includes('/passes/image')}>
                    <FormattedMessage id="MENU_PASSES_IMAGE" />
                  </Menu.Item>
                </Link>
              )}
              {viewer.data.showCertificateList() && (
                <Link to="/passes/certificate">
                  <Menu.Item
                    active={navigation.path.includes('/passes/certificate')}
                  >
                    <FormattedMessage id="MENU_PASSES_CERTIFICATE" />
                  </Menu.Item>
                </Link>
              )}
            </Menu.Menu>
          </Menu.Item>
        )}
        {viewer.data.showReportMenu() && (
          <Menu.Item>
            <Menu.Header>
              <FormattedMessage id="MENU_REPORT" />
            </Menu.Header>
            <Menu.Menu>
              <Link to="/report/passinwalletoverview">
                <Menu.Item
                  active={navigation.path.includes(
                    '/report/passinwalletoverview',
                  )}
                >
                  <FormattedMessage id="MENU_REPORT_PASSINWALLETOVERVIEW" />
                </Menu.Item>
              </Link>
              <Link to="/report/passatleastonceregisterinwalletoverview">
                <Menu.Item
                  active={navigation.path.includes(
                    '/report/passatleastonceregisterinwalletoverview',
                  )}
                >
                  <FormattedMessage id="MENU_REPORT_PASSATLEASTONCEREGISTERINWALLETOVERVIEW" />
                </Menu.Item>
              </Link>
              <Link to="/report/passdownloadrequestbytagsoverview">
                <Menu.Item
                  active={navigation.path.includes(
                    '/report/passdownloadrequestbytagsoverview',
                  )}
                >
                  <FormattedMessage id="MENU_REPORT_PASSDOWNLOADREQUESTBYTAGSOVERVIEW" />
                </Menu.Item>
              </Link>
              <Link to="/report/passpreviewrequestbytagsoverview">
                <Menu.Item
                  active={navigation.path.includes(
                    '/report/passpreviewrequestbytagsoverview',
                  )}
                >
                  <FormattedMessage id="MENU_REPORT_PASSPREVIEWREQUESTBYTAGSOVERVIEW" />
                </Menu.Item>
              </Link>
              <Link to="/report/activecardsoverview">
                <Menu.Item
                  active={navigation.path.includes(
                    '/report/activecardsoverview',
                  )}
                >
                  <FormattedMessage id="MENU_REPORT_ACTIVECARDSOVERVIEW" />
                </Menu.Item>
              </Link>
              <Link to="/report/links">
                <Menu.Item active={navigation.path.includes('/report/links')}>
                  <FormattedMessage id="MENU_REPORT_LINKS" />
                </Menu.Item>
              </Link>
            </Menu.Menu>
          </Menu.Item>
        )}
        {viewer.data.showIdentityManagementMenu() && (
          <Menu.Item>
            <Menu.Header>
              <FormattedMessage id="MENU_IDENTITY" />
            </Menu.Header>
            <Menu.Menu>
              {viewer.data.showIdentityProjects() && (
                <Link to="/identity/project">
                  <Menu.Item
                    active={navigation.path.includes('/identity/project')}
                  >
                    <FormattedMessage id="MENU_IDENTITY_PROJECT" />
                  </Menu.Item>
                </Link>
              )}
              {viewer.data.showIdentityUsers() && (
                <Link to="/identity/user">
                  <Menu.Item
                    active={navigation.path.includes('/identity/user')}
                  >
                    <FormattedMessage id="MENU_IDENTITY_USER" />
                  </Menu.Item>
                </Link>
              )}
            </Menu.Menu>
          </Menu.Item>
        )}
        {/*viewer.data.showTicketMenu() && (
          <Menu.Item>
            <Menu.Header>
              <FormattedMessage id="MENU_TICKET" />
            </Menu.Header>
            <Menu.Menu>
              {viewer.data.showTicketAccounting() && (
                <Link to="/ticket/ticketaccounting">
                  <Menu.Item
                    active={navigation.path.includes(
                      '/ticket/ticketaccounting',
                    )}
                  >
                    <FormattedMessage id="MENU_TICKET_TICKETACCOUNTING" />
                  </Menu.Item>
                </Link>
              )}
              {viewer.data.showTicketAccounting() && (
                <Link to="/ticket/ticketorder">
                  <Menu.Item
                    active={
                      navigation.path === '/ticket/ticketorder' ||
                      navigation.path
                        .toLowerCase()
                        .includes('/ticket/ticketorder/')
                    }
                  >
                    <FormattedMessage id="MENU_TICKET_TICKETORDER" />
                  </Menu.Item>
                </Link>
              )}
              {viewer.data.showTicketOrderItem() && (
                <Link to="/ticket/ticketorderitem">
                  <Menu.Item
                    active={
                      navigation.path === '/ticket/ticketorderitem' ||
                      navigation.path
                        .toLowerCase()
                        .includes('/ticket/ticketorderitem/')
                    }
                  >
                    <FormattedMessage id="MENU_TICKET_TICKETORDERITEM" />
                  </Menu.Item>
                </Link>
              )}
              {viewer.data.showTicketConfig() && (
                <Link to="/ticket/ticketeshop">
                  <Menu.Item
                    active={navigation.path
                      .toLowerCase()
                      .includes('/ticket/ticketeshop')}
                  >
                    <FormattedMessage id="MENU_TICKET_TICKETESHOP" />
                  </Menu.Item>
                </Link>
              )}
              {viewer.data.showTicketProduct() && (
                <Link to="/ticket/ticketproduct">
                  <Menu.Item
                    active={navigation.path
                      .toLowerCase()
                      .includes('/ticket/ticketproduct')}
                  >
                    <FormattedMessage id="MENU_TICKET_TICKETPRODUCT" />
                  </Menu.Item>
                </Link>
              )}
            </Menu.Menu>
          </Menu.Item>
                    )*/}
        <Menu.Item>
          <Menu.Header>
            <FormattedMessage id="MENU_API" />
          </Menu.Header>
          <Menu.Menu>
            <Link to="/api/endpoints">
              <Menu.Item
                active={navigation.path.includes('/api/endpoints')}
              >
                <FormattedMessage id="MENU_API_ENDPOINTS" />
              </Menu.Item>
            </Link>
            <Menu.Item href={DOC_API_URL}>
              <FormattedMessage id="MENU_API_DOCUMENTATION" />
            </Menu.Item>
          </Menu.Menu>
        </Menu.Item>
        <Menu.Item as={Link} to="/about">
          v{config.yourpassVersion}
        </Menu.Item>
      </div>
    );
  }
}

export default connect(
  state => ({
    config: state.config,
    viewer: state.viewer,
    navigation: state.navigation,
  }),
  { logout, login },
)(VerticalMenu);
