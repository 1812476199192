import { call, put, select } from 'redux-saga/effects';
import client from '../services/api';
import {
  LIST_PAGED_SUCCESS,
  LIST_PAGED_FAILURE,
  LIST_PAGED_EXPORT_SUCCESS,
  LIST_PAGED_EXPORT_FAILURE
} from '../actions/resourceListPaged';
import XLSX from "xlsx";

import {
  getApiForResource,
  getApiResourceAlias,
  prepareFilter,
} from './resource';

export function* listPaged(action) {
  let resource = action.resource;
  let requestId = action.requestId;
  const success = action.success;
  const failure = action.failure;

  const list = yield select(state => {
    return state.resourceListPaged.get(requestId);
  });
  let order = list.get('order');
  let orderBy = list.get('orderBy');
  let page = list.get('page');
  let limit = list.get('limit');
  let filterProps = list.get('filterProps');
  let filter = list.get('filter');
  let where = prepareFilter(filter, filterProps);
  let resourceQuery = `${getApiForResource(resource)}/v1/${getApiResourceAlias(
    resource,
  )}?where=${encodeURIComponent(JSON.stringify(where))}&page=${page ||
    1}&limit=${limit || 10}&order=${order || 'asc'}&orderBy=${orderBy || 'id'}`;
  try {
    let data = yield call(client, 'GET', resourceQuery, {});
    if (success) {
      success(data, resource, requestId);
    }
    yield put({
      type: LIST_PAGED_SUCCESS,
      data,
      resource,
      requestId,
      filter,
    });
  } catch (e) {
    if (failure) failure(e, resource, requestId);
    yield put({
      type: LIST_PAGED_FAILURE,
      message: e.message,
      resource: resource,
      requestId,
      filter,
    });
  }
}
export function* listPagedExport(action) {
  let resource = action.resource;
  let requestId = action.requestId;
  const list = yield select(state => {
    return state.resourceListPaged.get(requestId);
  });
  let order = list.get('order') || 'asc';
  let orderBy = list.get('orderBy') || 'id';
  let filterProps = list.get('filterProps');
  let filter = list.get('filter');
  let where = prepareFilter(filter, filterProps);
  const allData = [];
  const limit = 1000;
  try {
    let hasNext = true;
    let page = 1;

    while (hasNext) {
      let resourceQuery = `${getApiForResource(
        resource,
      )}/v1/${getApiResourceAlias(resource)}?where=${encodeURIComponent(
        JSON.stringify(where),
      )}&page=${page}&limit=${limit}&order=${order}&orderBy=${orderBy}&suppressCount=true`;

      let data = yield call(client, 'GET', resourceQuery, {});

      if (data && data.data) {
        data.data.forEach(i => {
          allData.push(i);
        });
      }

      page = page + 1;
      if (data.data === null || data.data.length < limit) {
        hasNext = false;
      }
    }
    const response = {
      data: allData,
      totalCount: allData.length,
    };
    const flattedData = response.data.map(flatten)
    var ws = XLSX.utils.json_to_sheet(flattedData,{ header: [
      "id",
      "url",
      "templateId",
      "projectId",
      "createdAt",
      "updatedAt",
      "deletedAt",
      "expirationDate",
      "voided",
      "firstRegisterAt",
      "lastRegisterAt",
      "firstUnregisterAt",
      "lastUnregisterAt",
      // "devices.android",
      // "devices.ios",
      // "devices.other",
      "devices.total",
    ]});
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'passes')
    XLSX.writeFile(wb, `export_passes_${new Date().toISOString()}.xlsx`)
    yield put({
      type: LIST_PAGED_EXPORT_SUCCESS,
      response,
      resource,
      requestId,
      filter,
    });
  }catch(e){
    console.error(e);
    yield put({
      type: LIST_PAGED_EXPORT_FAILURE,
      message: e.message,
      resource: resource,
      requestId,
      filter,
    });
  }
}

function flatten(data) {
  var result = {};
  function recurse(cur, prop) {
      if (cur===null){
        //skip null
      } else if (Object(cur) !== cur) {
          result[prop] = cur;
      } else if (Array.isArray(cur)) {
          for (var i = 0, l = cur.length; i < l; i++)
          recurse(cur[i], prop + "[" + i + "]");
          if (l === 0) result[prop] = [];
      } else {
          var isEmpty = true;
          for (var p in cur) {
              isEmpty = false;
              recurse(cur[p], prop ? prop + "." + p : p);
          }
          if (isEmpty && prop) {
            // skip empty object 
            // result[prop] = {};
          }
      }
  }
  recurse(data, "");
  return result;
};