export const CREATE_LOADING = 'CREATE_LOADING';
export const CREATE_FAILURE = 'CREATE_FAILURE';
export const CREATE_SUCCESS = 'CREATE_SUCCESS';
export const READ_LOADING = 'READ_LOADING';
export const READ_FAILURE = 'READ_FAILURE';
export const READ_SUCCESS = 'READ_SUCCESS';
export const SET_ITEM = 'SET_ITEM';
export const SET_IMMUTABLE_ITEM = 'SET_IMMUTABLE_ITEM';
export const SET_IMMUTABLE_ITEM_BY_PATH = 'SET_IMMUTABLE_ITEM_BY_PATH';
export const DESTROY_ITEM = 'DESTROY_ITEM';
export const UPDATE_LOADING = 'UPDATE_LOADING';
export const UPDATE_FAILURE = 'UPDATE_FAILURE';
export const UPDATE_SUCCESS = 'UPDATE_SUCCESS';
export const DELETE = 'DELETE';
export const DELETE_LOADING = 'DELETE_LOADING';
export const DELETE_FAILURE = 'DELETE_FAILURE';
export const DELETE_SUCCESS = 'DELETE_SUCCESS';
export const BIND_ITEM = 'BIND_ITEM';
export const UNBIND_ITEM = 'UNBIND_ITEM';
export const SET_ITEM_METADATA = 'SET_ITEM_METADATA';

export function itemRead(resource, requestId, id, success, failure) {
  return {
    type: READ_LOADING,
    resource,
    id,
    requestId,
    success,
    failure,
  };
}

export function itemDelete(resource, requestId, id, success, failure) {
  return {
    type: DELETE_LOADING,
    resource,
    id,
    requestId,
    success,
    failure,
  };
}

export function itemSave(resource, requestId, data, success, failure) {
  return {
    type: UPDATE_LOADING,
    resource,
    data,
    success,
    failure,
    requestId,
  };
}

export function itemCreate(resource, requestId, data, success, failure) {
  return {
    type: CREATE_LOADING,
    resource,
    data,
    success,
    failure,
    requestId,
  };
}

export function itemSet(resource, data, requestId) {
  return {
    type: SET_ITEM,
    resource,
    data,
    requestId,
  };
}

export function itemSetMetadata(resource, requestId, name, value) {
  return {
    type: SET_ITEM_METADATA,
    requestId,
    resource,
    name,
    value,
  };
}
export function itemBind(resource, requestId) {
  return {
    type: BIND_ITEM,
    resource,
    requestId,
  };
}
export function itemUnBind(resource, requestId) {
  return {
    type: UNBIND_ITEM,
    resource,
    requestId,
  };
}
export function itemDestroy(resource, requestId) {
  return {
    type: DESTROY_ITEM,
    resource,
    requestId,
  };
}
