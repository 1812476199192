import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Loader,
  Message,
  Form,
  Button,
  Modal,
  Icon,
  Dimmer,
} from 'semantic-ui-react';
import FormSelectProject from '../../common/FormDropdownProject';
import FormDropdownImageType from '../../common/FormDropdownImageType';
import {
  getImageProportion,
  getMimeTypeFromContent,
} from '../../../services/image';
import Edit from '../../common/Edit';
import { Checkboard } from 'react-color/lib/components/common';
import Dropzone from 'react-dropzone';
import { images } from '../../../constants/Image.config';
import { connect } from 'react-redux';
import { addNotification } from '../../../actions/notification';
import { AUTO_DISMISS } from '../../../constants/toast.js';
import { injectIntl, FormattedMessage } from 'react-intl';
import messages from '../../../messagesDefine.js';

class NewFileForm extends Component {
  static propTypes = {
    itemCreate: PropTypes.func.isRequired,
    itemSet: PropTypes.func.isRequired,
    itemSetMetadata: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    validation: PropTypes.object,
  };

  componentWillMount() {
    this.setState({
      dropzoneActive: false,
      modalOpen: false,
    });
  }

  onDragEnter = () => {
    this.setState({
      dropzoneActive: true,
    });
  };

  onDragLeave = () => {
    this.setState({
      dropzoneActive: false,
    });
  };

  applyMimeTypes(event) {
    this.setState({
      accept: event.target.value,
    });
  }

  handleChange = (e, p) => {
    const { itemSet, data, itemSetMetadata, intl } = this.props;
    data[p.name] = p.value;
    itemSet(data);
    if (data.url) {
      const imageDef = images[this.props.type || data.type];
      const errors = imageDef.validation(data.width, data.height);
      if (errors.length === 0) {
        this.resetValidation();
      } else {
        itemSetMetadata('validation', {
          isValid: false,
          errors: errors.map(err => intl.formatMessage(err.code, err.params)),
        });
      }
    }
  };

  handleDragAndDrop = (acceptedFiles, rejectedFiles) => {
    this.setState({
      dropzoneActive: false,
    });
    const { itemSet, itemSetMetadata, data, intl } = this.props;
    if (rejectedFiles.length > 0) {
      this.reset();
      itemSetMetadata('validation', {
        isValid: false,
        errors: [
          intl.formatMessage(messages.IMAGE_VALIDATION_UNSUPORTED_FILE, {
            mimeType: rejectedFiles[0].type,
            name: rejectedFiles[0].name,
          }),
        ],
      });
    } else if (acceptedFiles[0]) {
      const file = acceptedFiles[0];
      const reader = new FileReader();
      reader.addEventListener(
        'load',
        () => {
          getImageProportion(reader.result).then(({ width, height }) => {
            getMimeTypeFromContent(file).then(mimeType => {
              const type = mimeType;
              const errors = [];
              if (type !== 'image/png') {
                errors.push(
                  intl.formatMessage(
                    messages.IMAGE_VALIDATION_UNSUPORTED_FILE,
                    {
                      mimeType: type,
                      name: file.name,
                    },
                  ),
                );
              }
              data.width = width;
              data.height = height;
              data.url = reader.result;
              data.mimeType = type;
              if (!data.name) data.name = file.name;
              const imageDef = images[this.props.type || data.type];
              itemSet(data);
              const sizeErrors = imageDef
                .validation(width, height)
                .map(err => intl.formatMessage(err.code, err.params));

              if (sizeErrors && sizeErrors.length > 0) {
                sizeErrors.map(e => errors.push(e));
              }

              if (errors.length === 0) {
                this.resetValidation();
              } else {
                itemSetMetadata('validation', {
                  isValid: false,
                  errors: errors,
                });
              }
            }, alert);
          }, alert);
        },
        false,
      );
      reader.readAsDataURL(file);
    }
  };

  reset = () => {
    const { itemSet, data } = this.props;
    data.width = null;
    data.height = null;
    data.url = null;
    data.mimeType = null;
    if (!data.name) data.name = null;
    itemSet(data);
  };

  resetValidation = () => {
    const { itemSetMetadata } = this.props;
    itemSetMetadata('validation', {
      isValid: true,
      errors: [],
    });
  };

  handleSubmit = e => {
    this.props.itemCreate(
      this.props.data,
      (data, resource) => {
        if (this.props.onCreateSuccess) {
          this.props.onCreateSuccess(data, resource);
        }
        this.props.addNotification({
          title: `Create success`,
          message: `${resource} was created`,
          level: 'success',
          autoDismiss: AUTO_DISMISS,
        });
        if (this.props.onClose) {
          this.props.onClose();
        }
        this.setState({ modalOpen: false });
      },
      (e, resource, id) => {
        this.props.addNotification({
          title: `Create ${resource} failure`,
          message: e.message,
          level: 'error',
          autoDismiss: AUTO_DISMISS,
        });
      },
    );
  };

  handleOpen = () => {
    this.setState({ modalOpen: true });
    if (this.props.onOpen) {
      this.props.onOpen();
    }
  };

  handleClose = () => {
    this.setState({ modalOpen: false });
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  render() {
    const {
      data,
      validation,
      loading,
      message,
      buttonProps,
      intl,
      viewer,
    } = this.props;

    const typeLabel = intl.formatMessage(messages.IMAGE_TYPE);
    const projectLabel = intl.formatMessage(messages.PROJECT);
    const showForm = !(!this.props.type && !data.type);

    const isValid = !(validation && !validation.isValid);
    const showCreate = showForm && isValid && data.url;

    const dragZoneStyle = {
      width: '320px',
      height: '320px',
      borderWidth: '2px',
      borderColor: this.state.dropzoneActive
        ? 'rgb(0,255,0)'
        : 'rgb(102, 102, 102)',
      borderStyle: 'dashed',
      borderRadius: '5px',
    };

    const previewImageStyle = {
      left: 0,
      right: 0,
      bottom: 0,
      top: 0,
      margin: 'auto',
      position: 'absolute',
      maxWidth: '320px',
      maxHeight: '320px',
      border: '1px dotted grey',
      zIndex: 1000,
    };

    const dragInfoMessageStyle = {
      left: 0,
      right: 0,
      bottom: 0,
      top: 0,
      maxWidth: '200px',
      maxHeight: '90px',
      margin: 'auto',
      position: 'absolute',
      zIndex: 1000,
    };

    const dragContainerStyle = {
      position: 'relative',
      width: '320px',
      height: '320px',
    };

    if (!viewer.canManageImages()) {
      return null;
    }

    return (
      <Modal
        open={this.state.modalOpen}
        onClose={this.handleClose}
        onOpen={this.handleOpen}
        closeIcon="close"
        trigger={
          <Button {...buttonProps}>
            <Icon name="upload" />
            <FormattedMessage {...messages.UPLOAD_NEW_IMAGE} />
          </Button>
        }
      >
        <Modal.Header>
          <FormattedMessage {...messages.UPLOAD_NEW_IMAGE} />
        </Modal.Header>
        <Modal.Content>
          {loading && (
            <Modal.Description>
              <Dimmer active inverted>
                <Loader active inline="centered" />
              </Dimmer>
            </Modal.Description>
          )}

          {!loading && (
            <Modal.Description>
              {!isValid &&
                validation.errors.length > 0 && (
                  <Message
                    error
                    header="Invalid data"
                    list={validation.errors}
                  />
                )}

              {message && (
                <Message negative>
                  <Message.Header>
                    We are sorry but something is broken
                  </Message.Header>
                  <p>{message}</p>
                </Message>
              )}
              <Grid columns={2}>
                <Grid.Row>
                  <Grid.Column>
                    <Form onSubmit={this.handleSubmit}>
                      <FormSelectProject
                        disabled={!!this.props.projectId}
                        name="projectId"
                        label={projectLabel}
                        defaultValue={data.projectId || ''}
                        onChange={this.handleChange}
                      />
                      <FormDropdownImageType
                        disabled={!!this.props.type}
                        name="type"
                        label={typeLabel}
                        value={data.type || this.props.type || ''}
                        onChange={this.handleChange}
                      />

                      {showForm && (
                        <Form.Input
                          name="name"
                          label="Name"
                          onChange={this.handleChange}
                          value={data.name}
                        />
                      )}

                      {data &&
                        data.url && (
                          <Form.Field>
                            <label>
                              <FormattedMessage {...messages.MIME_TYPE} />
                            </label>
                            {data.mimeType}
                          </Form.Field>
                        )}

                      {data &&
                        data.url && (
                          <Form.Field>
                            <label>
                              <FormattedMessage {...messages.DIMENSION} />
                            </label>
                            {data.width} x {data.height}
                          </Form.Field>
                        )}
                    </Form>
                  </Grid.Column>
                  <Grid.Column>
                    {showForm && (
                      <div style={dragContainerStyle}>
                        <Dropzone
                          maxSize={2 * 1024 * 1024}
                          multiple={false}
                          onDrop={this.handleDragAndDrop}
                          accept="image/png"
                          onDragEnter={this.onDragEnter}
                          onDragLeave={this.onDragLeave}
                          style={dragZoneStyle}
                        >
                          {data &&
                            data.url && (
                              <img
                                alt="preview"
                                src={data.url}
                                style={previewImageStyle}
                              />
                            )}
                          {!(data && data.url) && (
                            <div style={dragInfoMessageStyle}>
                              <FormattedMessage {...messages.DRAG_IMAGE} />
                            </div>
                          )}
                          <Checkboard />
                        </Dropzone>
                      </div>
                    )}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Modal.Description>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            onClick={this.handleSubmit}
            disabled={!showCreate || loading}
          >
            <FormattedMessage {...messages.UPLOAD_NEW_IMAGE} />
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

const NewFileFormWithIntlAndConnect = connect(
  (state, props) => ({
    viewer: state.viewer.data,
  }),
  { addNotification },
)(injectIntl(NewFileForm));

export default class ImageCreateModal extends Component {
  //TODO - proptypes

  render() {
    return (
      <Edit
        resource={'image'}
        resourceId={'new'}
        defaultValue={{
          projectId: this.props.projectId,
          type: this.props.type,
          name: '',
        }}
        ignoreLoading={true}
        ignoreError={true}
        component={NewFileFormWithIntlAndConnect}
        componentProps={{
          onClose: this.props.onClose,
          onOpen: this.props.onOpen,
          buttonProps: this.props.buttonProps,
          projectId: this.props.projectId,
          type: this.props.type,
          redirect: this.props.redirect,
          onCreateSuccess: this.props.onCreateSuccess,
        }}
      />
    );
  }
}
