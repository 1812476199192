import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Menu, Segment, Form } from 'semantic-ui-react';
import { uiSetJsonEditFormat } from '../../actions/ui';
import { connect } from 'react-redux';
import uiJsonEditFormats from '../../constants/uiJsonEditFormats';
import FormTextAreaJson from './FormTextAreaJSON';
import ReactJsonSchemaForm from './jsonSchema';

class JsonSchemaForm extends Component {
  static propTypes = {
    formData: PropTypes.object.isRequired,
    schema: PropTypes.object.isRequired,
    uiSchema: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    uiSetJsonEditFormat: PropTypes.func.isRequired,
  };

  componentWillMount() {
    this.setState({ validJSONData: true, activeItem: 'form' });
  }

  handleChange = (e, p) => {
    this.props.onChange(p.value);
  };

  handleChangeForm = v => {
    this.props.onChange(v.formData);
  };

  handleItemClick = (e, { name }) => {
    this.props.uiSetJsonEditFormat(name);
  };

  render() {
    const { jsonEditFormat, schema } = this.props;
    const uiSchema = this.props.uiSchema ? this.props.uiSchema : {};
    return (
      <div>
        <Menu attached="top" tabular={true}>
          {uiJsonEditFormats.map((v, i) => (
            <Menu.Item
              name={v}
              key={i}
              active={jsonEditFormat === v}
              onClick={this.handleItemClick}
            />
          ))}
        </Menu>
        <Segment attached="bottom" className={'ui form'}>
          {jsonEditFormat === 'form' && (
            <ReactJsonSchemaForm
              schema={schema}
              uiSchema={uiSchema}
              onSubmit={this.handleChangeForm}
              onChange={this.handleChangeForm}
              formData={this.props.formData}
              liveValidate
            />
          )}
          {jsonEditFormat === 'plain' && (
            <Form>
              <FormTextAreaJson
                name="data"
                defaultValue={this.props.formData}
                onChange={this.handleChange}
              />
            </Form>
          )}
        </Segment>
      </div>
    );
  }
}

export default connect(
  (state, props) => ({
    jsonEditFormat: state.ui.jsonEditFormat,
  }),
  { uiSetJsonEditFormat },
)(JsonSchemaForm);
