import { FILTER_SET_VALUE, FILTER_SET_DATA } from '../actions';
import _ from 'lodash';

export default function(state = {}, action) {
  switch (action.type) {
    case FILTER_SET_VALUE:
      if (
        action &&
        action.data &&
        action.data.name &&
        !state[action.data.name]
      ) {
        state[action.data.name] = {
          value: [],
          loading: false,
        };
      }
      state[action.data.name].value = action.data.value;
      return _.merge({}, state);
    case FILTER_SET_DATA:
      if (
        action &&
        action.data &&
        action.data.name &&
        !state[action.data.name]
      ) {
        state[action.data.name] = {
          value: [],
          loading: false,
        };
      }
      state[action.data.name].data = action.data.data;
      return _.merge({}, state);

    default:
      return state;
  }
}
