import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ResourceDataGrid from '../../common/ResourceDataGrid';
import { Link } from 'react-router';
import { Button } from 'semantic-ui-react';
import JsonModal from '../../common/JsonModal';
import ResourceListPagedDecorator from '../../common/ResourceListPagedDecorator';
import Filter from '../../common/filter/Filter';

let fields = {
  displayName: {
    title: 'Name',
    input: 'text',
    readOnly: true,
  },
  email: {
    title: 'Email',
    input: 'text',
    readOnly: true,
  },
  isAdmin: {
    title: 'Admin',
    input: 'text',
    readOnly: true,
  },
  createdAt: {
    title: 'CreatedAt',
    type: 'date',
  },
  actions: {
    convertToValue: function(id, item) {
      return <Actions item={item} />;
    },
  },
};

const filterProps = [
  {
    name: 'displayName',
    type: 'like',
    columnName: 'displayName',
  },
  {
    name: 'email',
    type: 'like',
    columnName: 'email',
  },
];

const resource = 'user';

class Actions extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
  };

  render() {
    return (
      <Button.Group>
        <JsonModal
          title={`User  ${this.props.item.id}`}
          data={this.props.item}
        />
        <Button
          as={Link}
          to={'/identity/' + resource + '/' + this.props.item.id}
          icon="edit"
        />
      </Button.Group>
    );
  }
}

export default class UserList extends Component {
  render() {
    const filter = <Filter displayName={true} email={true} />;
    return (
      <ResourceListPagedDecorator
        resource={'user'}
        component={ResourceDataGrid}
        componentProps={{
          filter: filter,
          filterProps: filterProps,
          fields: fields,
          cols: ['displayName', 'email', 'isAdmin', 'createdAt', 'actions'],
        }}
      />
    );
  }
}
