export const LIST_COMPLETE_MERGE_PROPS = 'LIST_COMPLETE_MERGE_PROPS';
export const LIST_COMPLETE_LOADING = 'LIST_COMPLETE_LOADING';
export const LIST_COMPLETE_FAILURE = 'LIST_COMPLETE_FAILURE';
export const LIST_COMPLETE_SUCCESS = 'LIST_COMPLETE_SUCCESS';
export const LIST_COMPLETE_DESTROY = 'LIST_COMPLETE_DESTROY';
export const LIST_COMPLETE_INIT = 'LIST_COMPLETE_INIT';

export function listLoad(resource, requestId = 'list', success, failure) {
  return {
    type: LIST_COMPLETE_LOADING,
    resource,
    requestId,
    success,
    failure,
  };
}

export function listMergeProps(resource, requestId = 'list', value) {
  return {
    type: LIST_COMPLETE_MERGE_PROPS,
    resource,
    value,
    requestId,
  };
}

export function listDestroy(resource, requestId) {
  return {
    type: LIST_COMPLETE_DESTROY,
    resource,
    requestId,
  };
}
export function listInit(resource, requestId, limit) {
  return {
    type: LIST_COMPLETE_INIT,
    resource,
    requestId,
    limit,
  };
}
