import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Image, Icon } from 'semantic-ui-react';
import PassStyle from './passStyle/PassStyle';
import { Flipper, Front, Back } from 'react-flipper';
import BackFields from './fields/BackFields';
import './Designer.css';
import { FormattedMessage, injectIntl } from 'react-intl';
import AppStore from './appStore/AppStore';
import TemplateImage from './TemplateImage';
import designerImage from './designer.png';
import Errors from '../../../../../models/Errors';
import Template from '../../../../../models/template';

class Designer extends Component {
  static propTypes = {
    hasBackFieldError: PropTypes.bool.isRequired,
    hasFrontFieldError: PropTypes.bool.isRequired,
    previewLanguage: PropTypes.string.isRequired,
    errors: PropTypes.instanceOf(Errors).isRequired,
    data: PropTypes.instanceOf(Template).isRequired,
    itemSet: PropTypes.func.isRequired,
    windowWidth: PropTypes.number.isRequired,
  };

  componentWillMount() {
    this.setState({ isFlipped: false });
  }

  flip = () => {
    const { isFlipped } = this.state;
    this.setState({ isFlipped: !isFlipped });
  };

  render() {
    const {
      data,
      itemSet,
      errors,
      previewLanguage,
      hasBackFieldError,
      hasFrontFieldError,
    } = this.props;
    const foregroundColor = data.getForegroundColor();
    const backgroundColor = data.getBackgroundColor();

    const { isFlipped } = this.state;

    const displayStyleFront = {
      backgroundColor: backgroundColor ? backgroundColor : 'white',
      width: 320,
      height: 450,
      padding: 0,
      zIndex: 10,
      borderRadius: 10,
    };
    const displayStyleBack = {
      padding: 0,
      backgroundColor: backgroundColor ? backgroundColor : 'white',
      width: 320,
      height: 450,
      zIndex: 10,
      borderRadius: 10,
    };
    const backFieldsStyle = {
      borderRadius: 10,
      marginTop: 40,
      marginLeft: 5,
      marginRight: 5,
      backgroundColor: 'white',
      height: '405px',
      overflow: 'auto',
    };

    const passStyle = data.getPassStyle();

    switch (passStyle) {
      case 'boardingPass':
        displayStyleFront.borderRadius = 10;
        break;
      case 'coupon':
        displayStyleFront.borderRadius = 0;
        break;
      case 'storeCard':
        displayStyleFront.borderRadius = 10;
        break;
      case 'generic':
        displayStyleFront.borderRadius = 10;
        break;
      case 'eventTicket':
        displayStyleFront.borderRadius = 0;
        break;
      default:
        break;
    }

    const activeButton = {
      backgroundColor: 'limegreen',
    };

    return (
      <div className="previewColumn">
        <div className="header-preview">
          <div
            style={{
              width: 120,
              marginTop: 5,
              float: 'left',
            }}
          >
            <span
              style={{
                float: 'left',
                marginTop: 4,
                marginRight: 14,
                marginLeft: 15,
              }}
            >
              <FormattedMessage id="ICON" default="ICON" />:
            </span>
            <TemplateImage
              itemSet={itemSet}
              data={data}
              style={{ cursor: 'pointer' }}
              type={'icon'}
              previewLanguage={previewLanguage}
              previewColor={'black'}
            />
          </div>

          <Button.Group style={{ marginBottom: '20px' }}>
            <Button
              style={
                isFlipped
                  ? { color: hasFrontFieldError ? '#DB2828' : undefined }
                  : {
                      ...activeButton,
                      color: hasFrontFieldError ? '#DB2828' : undefined,
                    }
              }
              onClick={isFlipped ? this.flip : null}
            >
              {hasFrontFieldError && <Icon name={'attention'} />}
              <FormattedMessage id="FRONT" default="FRONT" />
            </Button>
            <Button
              style={
                !isFlipped
                  ? { color: hasBackFieldError ? '#DB2828' : undefined }
                  : {
                      ...activeButton,
                      color: hasBackFieldError ? '#DB2828' : undefined,
                    }
              }
              onClick={!isFlipped ? this.flip : null}
            >
              {hasBackFieldError && <Icon name={'attention'} />}
              <FormattedMessage id="BACK" default="BACK" />
            </Button>
          </Button.Group>
        </div>
        <div className="content-preview" style={{ minHeight: 850 }}>
          <div style={{ position: 'relative' }}>
            <Image
              src={designerImage}
              style={{ width: 400, position: 'absolute' }}
            />
            <div style={{ position: 'absolute', left: 42, top: 160 }}>
              <Flipper isFlipped={isFlipped} orientation="horizontal">
                <Front style={displayStyleFront}>
                  {!isFlipped && (
                    <PassStyle
                      data={data}
                      errors={errors}
                      itemSet={itemSet}
                      viewType="multiLines"
                      previewLanguage={previewLanguage}
                      flip={this.flip}
                    />
                  )}
                  {!isFlipped && (
                    <span
                      id="info"
                      onClick={() => {
                        this.flip();
                      }}
                      style={{
                        color: foregroundColor,
                        position: 'absolute',
                        bottom: 10,
                        right: 10,
                        fontSize: 20,
                        cursor: 'pointer',
                      }}
                    >
                      ⓘ
                    </span>
                  )}
                </Front>
                <Back style={displayStyleBack}>
                  <span
                    onClick={() => {
                      this.flip();
                    }}
                    style={{
                      cursor: 'pointer',
                      marginRight: 10,
                      float: 'right',
                      marginTop: 10,
                    }}
                  >
                    <FormattedMessage id="DONE" default="DONE" />
                  </span>
                  {isFlipped && (
                    <div style={backFieldsStyle}>
                      <AppStore data={data} itemSet={itemSet} />
                      <div
                        style={{
                          marginTop: 10,
                          backgroundColor: 'whitesmoke',
                          height: 30,
                          borderBottom: '1px solid gainsboro',
                          borderTop: '1px solid gainsboro',
                        }}
                      />
                      <BackFields
                        data={data}
                        errors={errors}
                        itemSet={itemSet}
                        viewType="multiLines"
                        previewLanguage={previewLanguage}
                      />
                    </div>
                  )}
                </Back>
              </Flipper>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(Designer);
