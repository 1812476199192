import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import { FormattedDate } from 'react-intl';

export default class FormGroupTimestamps extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
  };

  render() {
    const { data } = this.props;
    return (
      <Form.Group widths="equal">
        <Form.Field>
          <label>CreatedAt</label>
          {data.updatedAt && (
            <FormattedDate
              value={data.createdAt}
              year="numeric"
              month="short"
              day="2-digit"
              hour="2-digit"
              minute="2-digit"
              second="2-digit"
              timeZoneName="short"
              hour12={false}
            />
          )}
        </Form.Field>
        <Form.Field>
          <label>UpdatedAt</label>
          {data.updatedAt && (
            <FormattedDate
              value={data.updatedAt}
              year="numeric"
              month="short"
              day="2-digit"
              hour="2-digit"
              minute="2-digit"
              second="2-digit"
              timeZoneName="short"
              hour12={false}
            />
          )}
        </Form.Field>
        <Form.Field>
          <label>DeletedAt</label>
          {data.deletedAt && (
            <FormattedDate
              value={data.deletedAt}
              year="numeric"
              month="short"
              day="2-digit"
              hour="2-digit"
              minute="2-digit"
              second="2-digit"
              timeZoneName="short"
              hour12={false}
            />
          )}
        </Form.Field>
      </Form.Group>
    );
  }
}
