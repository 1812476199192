import React, { Component } from 'react';
import Field from './field/Field';
import PropTypes from 'prop-types';
import { Button, Popup } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import shortid from 'shortid';
import { List } from 'immutable';
import Errors from '../../../../../../models/Errors';
import Template from '../../../../../../models/template';
import { uiSetTemplateForceOpenField } from '../../../../../../actions/ui';
import { connect } from 'react-redux';

class SecondaryAndAuxiliaryFieldsields extends Component {
  static propTypes = {
    intl: PropTypes.object.isRequired,
    previewLanguage: PropTypes.string.isRequired,
    errors: PropTypes.instanceOf(Errors).isRequired,
    data: PropTypes.instanceOf(Template).isRequired,
    itemSet: PropTypes.func.isRequired,
    uiSetTemplateForceOpenField: PropTypes.func.isRequired,
  };

  addField = fieldsType => {
    const { itemSet, data, uiSetTemplateForceOpenField } = this.props;
    const key = shortid.generate();
    uiSetTemplateForceOpenField(key);
    itemSet(data.fieldAdd(fieldsType, key));
  };

  render() {
    const { data, intl, previewLanguage, errors, itemSet } = this.props;
    const passStyle = data.getPassStyle();

    const isSecondaryFieldExists = !!data.getIn([
      'jsonTemplate',
      passStyle,
      'properties',
      'secondaryFields',
      'items',
    ]);

    const secondaryFields = isSecondaryFieldExists
      ? data.getIn([
          'jsonTemplate',
          passStyle,
          'properties',
          'secondaryFields',
          'items',
        ])
      : new List();

    const isAuxiliaryFieldExists = !!data.getIn([
      'jsonTemplate',
      passStyle,
      'properties',
      'auxiliaryFields',
      'items',
    ]);

    const auxiliaryFields = isAuxiliaryFieldExists
      ? data.getIn([
          'jsonTemplate',
          passStyle,
          'properties',
          'auxiliaryFields',
          'items',
        ])
      : new List();

    const totalCount = auxiliaryFields.size + secondaryFields.size;

    return (
      <div
        className="fields"
        style={Object.assign(
          {
            display: 'table',
            paddingBottom: 10,
            maxWidth: 320,
            width: '100%',
            tableLayout: 'fixed',
          },
          this.props.style,
        )}
      >
        {4 > totalCount && (
          <Popup
            content={intl.formatHTMLMessage({
              id: 'secondaryFields'.toUpperCase(),
            })}
            trigger={
              <Button
                icon="plus"
                color="green"
                onClick={() => {
                  this.addField('secondaryFields');
                }}
                size="mini"
                style={{
                  position: 'absolute',
                  height: 20,
                  padding: 3,
                  left: -25,
                  margin: 10,
                }}
              />
            }
          />
        )}

        {secondaryFields.map((field, i) => {
          return (
            <Field
              key={field.getIn(['properties', 'key', 'value'])}
              errors={errors}
              data={data}
              itemSet={itemSet}
              previewLanguage={previewLanguage}
              field={field}
              fieldIndex={i}
              fieldsType={'secondaryFields'}
            />
          );
        })}

        {auxiliaryFields.map((field, i) => {
          return (
            <Field
              key={field.getIn(['properties', 'key', 'value'])}
              errors={errors}
              data={data}
              itemSet={itemSet}
              previewLanguage={previewLanguage}
              field={field}
              fieldIndex={i}
              fieldsType={'auxiliaryFields'}
            />
          );
        })}

        {4 > totalCount && (
          <Popup
            content={intl.formatHTMLMessage({
              id: 'auxiliaryFields'.toUpperCase(),
            })}
            trigger={
              <Button
                icon="plus"
                color="green"
                onClick={() => {
                  this.addField('auxiliaryFields');
                }}
                size="mini"
                style={{
                  position: 'absolute',
                  height: 20,
                  padding: 3,
                  right: -25,
                  margin: 10,
                }}
              />
            }
          />
        )}
      </div>
    );
  }
}

export default connect((state, props) => ({}), { uiSetTemplateForceOpenField })(
  injectIntl(SecondaryAndAuxiliaryFieldsields),
);
