import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Table } from 'semantic-ui-react';
import FormInputNumber from '../../common/FormInputNumber';
import languageCodes from '../../../constants/languageCodes';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { fetchTicketEshopMessages } from '../../../actions/ticket';

class EshopFormTranslation extends Component {
  static propTypes = {
    data: PropTypes.object,
    intl: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
  };

  componentWillMount() {
    const localeOpts = [];
    Object.keys(languageCodes).map(key => {
      const opt = {
        key: key,
        text: languageCodes[key],
        value: key,
      };
      localeOpts.push(opt);
      return opt;
    });
    this.setState({ localeOpts: localeOpts });

    let { data, fetchTicketEshopMessages } = this.props;

    fetchTicketEshopMessages(data.eshopVersion);
  }

  render() {
    let {
      data,
      handleChange,
      eshopMessages,
      eshopMessagesLoading,
    } = this.props;

    const defaultMessages = eshopMessages[data.eshopVersion];

    if (eshopMessagesLoading || !defaultMessages) {
      // TODO - loader
      return null;
    }

    const translationKeys = Object.keys(defaultMessages);

    return (
      <div>
        <h2>Locales</h2>
        <Form.Dropdown
          selection
          multiple
          options={this.state.localeOpts}
          name="locales"
          label="Locales"
          onChange={handleChange}
          defaultValue={data.locales}
        />
        <Form.Input
          name="currency"
          label="currency"
          onChange={handleChange}
          defaultValue={data.currency}
        />
        <FormInputNumber
          name="currencyCode"
          label="Currency code"
          onChange={handleChange}
          defaultValue={data.currencyCode}
        />
        <h3>Messages</h3>

        {translationKeys.map((tk, index) => {
          return (
            <Table celled key={index} compact={true}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell style={{ width: '30%' }}>
                    {tk}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <i>{defaultMessages[tk]}</i>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {data.locales.map((lang, index2) => {
                  let value = '';
                  if (
                    data.messages &&
                    data.messages[lang] &&
                    data.messages[lang][tk]
                  ) {
                    value = data.messages[lang][tk];
                  }
                  return (
                    <tr key={index2}>
                      <td>{languageCodes[lang]}</td>
                      <td>
                        <Form.TextArea
                          name={`messages.${lang}.${tk}`}
                          onChange={handleChange}
                          defaultValue={value}
                          autoHeight
                        />
                      </td>
                    </tr>
                  );
                })}
              </Table.Body>
            </Table>
          );
        })}
      </div>
    );
  }
}

// This removes warnings for "undefined" react props
export default connect(
  (state, props) => ({
    eshopMessages: state.ticket.eshopMessages,
    eshopMessagesLoading: state.ticket.eshopMessagesLoading,
    eshopMessagesError: state.ticket.eshopMessagesError,
  }),
  {
    fetchTicketEshopMessages,
  },
)(injectIntl(EshopFormTranslation));
