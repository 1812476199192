import React from 'react';
import { Form } from 'semantic-ui-react';
import PassStyleSwitcher from './passStyle/switcher/PassStyleSwitcher';
import { FormattedMessage, injectIntl } from 'react-intl';
import InputRenderComponent from '../../inputs/InputRenderComponent';

class DesignerTabRightForm extends InputRenderComponent {
  static propTypes = {
    ...InputRenderComponent.propTypes,
  };

  render() {
    const { data, itemSet } = this.props;
    const languages = data.get('languages');

    return (
      <Form>
        <PassStyleSwitcher itemSet={itemSet} data={data} />
        {this.renderStringInput([`jsonTemplate`], 'description', {
          languages,
        })}
        {this.renderStringInput([`jsonTemplate`], 'organizationName', {
          languages,
        })}
        {this.renderStringInput([`jsonTemplate`], 'groupingIdentifier')}
        {this.renderColorInput([`jsonTemplate`], 'backgroundColor')}
        {this.renderColorInput([`jsonTemplate`], 'labelColor')}
        {this.renderColorInput([`jsonTemplate`], 'foregroundColor')}
        {this.renderDateInput([`jsonTemplate`], 'expirationDate')}
        <div style={{ width: '100%', marginTop: 20 }}>
          <a
            style={{ float: 'left' }}
            target="_blank"
            rel="noopener noreferrer"
            href="https://developer.apple.com/library/content/documentation/UserExperience/Conceptual/PassKit_PG/index.html"
          >
            <FormattedMessage
              id="PKPASS_DOCUMENTATION"
              defaultMessage="PKPASS_DOCUMENTATION"
            />
          </a>
        </div>
      </Form>
    );
  }
}

export default injectIntl(DesignerTabRightForm);
