import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FormFieldWithErrors from './FormFieldWithErrors';
import Errors from '../../../models/Errors';
import { Form } from 'semantic-ui-react';

export default class FormTextInputFieldWithErrors extends Component {
  static propTypes = {
    errors: PropTypes.instanceOf(Errors),
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    defaultValue: PropTypes.string.isRequired,
    onChange: PropTypes.func,
  };

  render() {
    const { errors, name, label, defaultValue, onChange } = this.props;
    return (
      <FormFieldWithErrors errors={errors}>
        <Form.Input
          key={name}
          name={name}
          label={label}
          type="text"
          onChange={onChange}
          defaultValue={defaultValue}
        />
      </FormFieldWithErrors>
    );
  }
}
