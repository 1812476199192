import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { filterSet, filterSetData } from '../../../actions/index';
import FormDropdownImageType from '../FormDropdownImageType';

class FilterImageTypeDropdown extends Component {
  static propTypes = {
    value: PropTypes.array.isRequired,
    filterSet: PropTypes.func.isRequired,
    onChange: PropTypes.func,
  };

  handleChange = (e, p) => {
    this.props.filterSet({ name: 'imageType', value: p.value });
    if (this.props.onChange) {
      this.props.onChange(e, p);
    }
  };

  render() {
    const { value, label, name } = this.props;
    return (
      <FormDropdownImageType
        label={label}
        name={name}
        selection={true}
        multiple={true}
        onChange={this.handleChange}
        defaultValue={value}
      />
    );
  }
}

export default connect(
  (state, props) => ({
    value: state.filter.imageType ? state.filter.imageType.value : [],
  }),
  { filterSet, filterSetData },
)(FilterImageTypeDropdown);
