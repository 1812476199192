import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ResourceDataGrid from '../../common/ResourceDataGrid';
import { Link } from 'react-router';
import { Button } from 'semantic-ui-react';
import JsonModal from '../../common/JsonModal';
import ResourceListPagedDecorator from '../../common/ResourceListPagedDecorator';
import LabelProject from '../../common/LabelProject';

let fields = {
  name: {
    title: 'Name',
  },
  eshopVersion: {
    title: 'Version',
  },

  type: {
    title: 'Type',
  },
  projectId: {
    title: 'Project',
    input: 'text',
    readOnly: true,
    convertToValue: function(id) {
      return <LabelProject id={id} />;
    },
  },
  actions: {
    convertToValue: function(id, item) {
      return <Actions item={item} />;
    },
  },
};

const resource = 'ticketEshop';

class Actions extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
  };

  render() {
    return (
      <Button.Group>
        <JsonModal
          title={`Eshop ${this.props.item.id}`}
          data={this.props.item}
        />
        <Button
          as={Link}
          to={'/ticket/' + resource + '/' + this.props.item.id}
          icon="edit"
        />
      </Button.Group>
    );
  }
}

export default class ConfigList extends Component {
  render() {
    return (
      <ResourceListPagedDecorator
        resource={'ticketEshop'}
        component={ResourceDataGrid}
        componentProps={{
          resource: resource,
          fields: fields,
          newButton: (
            <Button
              floated="right"
              size="small"
              primary
              as={Link}
              to={'/ticket/' + resource + '/new'}
              icon="plus"
              title="New"
            />
          ),
          cols: ['name', 'projectId', 'eshopVersion', 'type', 'actions'],
        }}
      />
    );
  }
}
