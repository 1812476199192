import {
  READ_LOADING,
  READ_FAILURE,
  READ_SUCCESS,
  BIND_ITEM,
  UNBIND_ITEM,
  SET_ITEM,
  DESTROY_ITEM,
  SET_ITEM_METADATA,
  UPDATE_LOADING,
  UPDATE_SUCCESS,
  UPDATE_FAILURE,
  CREATE_LOADING,
  CREATE_SUCCESS,
  CREATE_FAILURE,
  DELETE_LOADING,
  DELETE_SUCCESS,
  DELETE_FAILURE,
} from '../actions/resource';
import { Record, Map } from 'immutable';
import Template from '../models/template/index';

const ResourceStruct = Record({
  resource: null,
  requestId: null,
  error: null,
  data: null,
  loading: false,
  bind: 0,
  changed: false,
  message: null,
  timestamp: null,
  touched: false,
  validation: {
    isValid: false,
    errors: [],
    showModal: false,
  },
});

export default function(state = new Map(), action) {
  const { data, message, requestId, resource } = action;
  let newState = state;
  switch (action.type) {
    case READ_LOADING:
      if (!state.get(requestId)) {
        newState = newState.set(requestId, new ResourceStruct());
      }
      let bind = 0;
      if (state.get(requestId)) {
        bind = newState.get(requestId).get('bind');
      }
      return newState
        .setIn([requestId, 'loading'], true)
        .setIn([requestId, 'data'], null)
        .setIn([requestId, 'message'], null)
        .setIn([requestId, 'changed'], false)
        .setIn([requestId, 'bind'], bind);

    case READ_SUCCESS:
      if (resource === 'template') {
        return state
          .setIn([requestId, 'data'], new Template(Object.assign({}, data)))
          .setIn([requestId, 'resource'], resource)
          .setIn([requestId, 'loading'], false)
          .setIn([requestId, 'timestamp'], new Date());
      } else {
        return state
          .setIn([requestId, 'data'], data)
          .setIn([requestId, 'resource'], resource)
          .setIn([requestId, 'loading'], false)
          .setIn([requestId, 'timestamp'], new Date());
      }

    case READ_FAILURE:
      return state
        .setIn([requestId, 'message'], message)
        .setIn([requestId, 'loading'], false);

    case CREATE_LOADING:
    case DELETE_LOADING:
    case UPDATE_LOADING:
      return state
        .setIn([requestId, 'message'], null)
        .setIn([requestId, 'loading'], true)
        .setIn([requestId, 'changed'], false);

    case CREATE_SUCCESS:
    case UPDATE_SUCCESS:
    case DELETE_SUCCESS:
      newState = state
        .setIn([data.id, 'data'], data)
        .setIn([requestId, 'loading'], false)
        .setIn([requestId, 'timestamp'], new Date());
      if (resource === 'template') {
        newState = newState.setIn(
          [requestId, 'data'],
          new Template(Object.assign({}, data)),
        );
        if (state.get(data.id)) {
          newState = newState.setIn([data.id, 'data'], data);
        }
      }
      return newState;

    case CREATE_FAILURE:
    case UPDATE_FAILURE:
    case DELETE_FAILURE:
      return state
        .setIn([requestId, 'message'], message)
        .setIn([requestId, 'loading'], false);

    case SET_ITEM:
      if (
        state.get(requestId) &&
        state.get(requestId).resource === 'template'
      ) {
        return state
          .setIn([requestId, 'data'], data)
          .setIn([requestId, 'changed'], true);
      }
      return state
        .setIn([requestId, 'data'], Object.assign({}, data))
        .setIn([requestId, 'changed'], true);

    case SET_ITEM_METADATA:
      return state.setIn([requestId, action.name], action.value);

    case BIND_ITEM:
      return state.setIn(
        [requestId, 'bind'],
        state.getIn([requestId, 'bind']) + 1,
      );

    case UNBIND_ITEM:
      return state.setIn(
        [requestId, 'bind'],
        state.getIn([requestId, 'bind']) - 1,
      );
    case DESTROY_ITEM:
      if (state.getIn([requestId, 'bind'])) {
        if (state.getIn([requestId, 'bind']) <= 0) {
          return state.delete(requestId);
        }
      }
      return state;
    default:
      return state;
  }
}
