import React, { Component } from 'react';
import FormInputCheckbox from '../../common/FormInputCheckbox';
import FormDropdownTicketEshop from '../../common/FormDropdownTicketEshop';
import LabelTicketEshop from '../../common/LabelTicketEshop';
import FormDropdownTemplate from '../../common/FormDropdownTemplate';
import { Form } from 'semantic-ui-react';
import FormTextInputFieldWithErrors from '../../common/form/FormTextInputFieldWithErrors';
import FormNumberInputWithErrors from '../../common/form/FormNumberInputWithErrors';
import FormFieldWithErrors from '../../common/form/FormFieldWithErrors';

export default class FormTabLocalization extends Component {
  render() {
    const { data, onChange, ticketEshop, errors } = this.props;
    return (
      <div>
        <FormInputCheckbox
          name="active"
          label="Active"
          onChange={onChange}
          defaultChecked={data.active}
          toggle={true}
        />

        {typeof data.id === 'undefined' ? (
          <FormDropdownTicketEshop
            name="eshopId"
            onChange={onChange}
            defaultValue={data.eshopId}
          />
        ) : (
          <Form.Field>
            <label>Eshop</label>
            <LabelTicketEshop id={data.eshopId} />
          </Form.Field>
        )}

        <FormTextInputFieldWithErrors
          name="name"
          label="Name"
          onChange={onChange}
          defaultValue={data.name}
          errors={errors}
        />

        <FormFieldWithErrors errors={errors}>
          <FormDropdownTemplate
            name="templateIds"
            multiple
            defaultValue={data.templateIds}
            onChange={this.props.onChange}
            filter={{
              project: { value: [ticketEshop.projectId] },
            }}
          />
        </FormFieldWithErrors>

        <FormNumberInputWithErrors
          name="order"
          label="Order - ordering products in eshop"
          onChange={onChange}
          defaultValue={data.order}
          errors={errors}
        />

        <FormNumberInputWithErrors
          name="maxItems"
          label="Max items - maximal count of item in one order"
          onChange={onChange}
          defaultValue={data.maxItems}
          errors={errors}
        />
        <FormTextInputFieldWithErrors
          name="units"
          label="Units"
          type="text"
          onChange={onChange}
          defaultValue={data.units}
          errors={errors}
        />

        <Form.Group widths="equal">
          <FormNumberInputWithErrors
            name="priceWithoutVat"
            label="Price Without Vat"
            onChange={onChange}
            defaultValue={data.priceWithoutVat}
            errors={errors}
          />
          <FormNumberInputWithErrors
            name="vat"
            label="Vat"
            onChange={onChange}
            defaultValue={data.vat}
            errors={errors}
          />
          <Form.Input
            name="priceWithVat"
            label="Price With Vat"
            disabled={true}
            value={data.priceWithoutVat + data.vat * data.priceWithoutVat}
          />
        </Form.Group>
      </div>
    );
  }
}
