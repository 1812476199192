export const getImageProportion = url => {
  return new Promise((resolve, reject) => {
    const img = document.createElement('img');
    img.onload = () => {
      // `naturalWidth`/`naturalHeight` aren't supported on <IE9. Fallback to normal width/height
      // The natural size is the actual image size regardless of rendering.
      // The 'normal' width/height are for the **rendered** size.
      const width = img.naturalWidth || img.width;
      const height = img.naturalHeight || img.height;
      // Do something with the width and height
      return resolve({ width, height });
    };

    img.onerror = () => {
      return reject(new Error('Cannot read image proportion'));
    };
    img.src = url;
  });
};

export const getMimeTypeFromContent = blob => {
  return new Promise((resolve, reject) => {
    var fileReader = new FileReader();
    fileReader.onloadend = function(e) {
      var arr = new Uint8Array(e.target.result).subarray(0, 4);
      var header = '';
      for (var i = 0; i < arr.length; i++) {
        header += arr[i].toString(16);
      }
      switch (header) {
        case '89504e47':
          resolve('image/png');
          break;
        case '47494638':
          resolve('image/gif');
          break;
        case 'ffd8ffe0':
        case 'ffd8ffe1':
        case 'ffd8ffe2':
          resolve('image/jpeg');
          break;
        default:
          resolve(null); // Or you can use the blob.type as fallback
          break;
      }
      // Check the file signature against known types
    };
    fileReader.readAsArrayBuffer(blob);
  });
};
