import pjson from '../package.json';
import messagesEn from './messages-en';

const ENVIROMENT =
  process.env.REACT_APP_ENVIROMENT ||
  window.APP_CONFIG?.environment ||
  'development';

const ENVIROMENT_COLOR =
  process.env.REACT_APP_ENVIROMENT_COLOR ||
  window.APP_CONFIG?.environmentColor ||
  'black';

const YOURPASS_VERSION =
  process.env.REACT_APP_YOURPASS_VERSION ||
  window.APP_CONFIG?.yourpassVersion ||
  `v${pjson.version}`;

const BUILD_TIMESTAMP = process.env.REACT_APP_BUILD_TIMESTAMP;

const defaultFrom = new Date();
defaultFrom.setDate(defaultFrom.getDate() - 30);
defaultFrom.setHours(0, 0, 0, 0);
const defaultTo = new Date();
defaultTo.setHours(23, 59, 59, 999);

const ticketAccountingFrom = new Date();
ticketAccountingFrom.setDate(defaultFrom.getDate() - 1);
ticketAccountingFrom.setHours(0, 0, 0, 0);

export default {
  config: {
    yourpassVersion: YOURPASS_VERSION,
    console: {
      env: ENVIROMENT,
      envColor: ENVIROMENT_COLOR,
      version: pjson.version,
      buildTimestamp: BUILD_TIMESTAMP,
    },
  },
  filter: {
    createdAtFrom: { value: null },
    createdAtTo: { value: null },
    from: { value: defaultFrom },
    to: { value: defaultTo },
    ticketAccountingFrom: { value: ticketAccountingFrom },
    ticketAccountingTo: { value: defaultTo },
    date: { value: defaultTo },
    interval: { value: 'daily' },
    imageType: { value: [] },
    project: { value: [] },
    template: { value: [] },
  },
  intl: {
    currentLocale: 'en',
    messages: { en: messagesEn },
  },
};
