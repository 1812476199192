import React, { Component } from 'react';
import PrimaryFields from '../fields/PrimaryFields';
import PropTypes from 'prop-types';
import AuxiliaryFields from '../fields/AuxiliaryFields';
import SecondaryFields from '../fields/SecondaryFields';
import TemplateImage from '../TemplateImage';
import Errors from '../../../../../../models/Errors';
import Template from '../../../../../../models/template';
import passStyleEventTicket from './passStyleEventTicket.svg';
import Barcodes from '../barcode/Barcodes';
import LogoLogoTextHeaderFields from './LogoTextHeaderFields';

export default class EventTicket extends Component {
  static propTypes = {
    previewLanguage: PropTypes.string.isRequired,
    errors: PropTypes.instanceOf(Errors).isRequired,
    data: PropTypes.instanceOf(Template).isRequired,
    itemSet: PropTypes.func.isRequired,
  };

  // TODO  background image vs strip image, see Figure 4-4Layout of an event ticket on https://developer.apple.com/library/content/documentation/UserExperience/Conceptual/PassKit_PG/Creating.html#//apple_ref/doc/uid/TP40012195-CH4-SW1

  render() {
    const { data, previewLanguage, itemSet, errors } = this.props;
    return (
      <div>
        <img
          src={passStyleEventTicket}
          style={{ position: 'absolute', top: 0 }}
          alt={``}
        />
        <LogoLogoTextHeaderFields
          data={data}
          previewLanguage={previewLanguage}
          errors={errors}
          itemSet={itemSet}
        />

        <TemplateImage
          itemSet={itemSet}
          data={data}
          type={'strip'}
          previewLanguage={previewLanguage}
        />
        <div
          style={{
            paddingLeft: 12,
            paddingRight: 12,
            position: 'absolute',
            top: 60,
            pointerEvents: 'none',
            width: '100%',
          }}
        >
          <PrimaryFields
            data={data}
            previewLanguage={previewLanguage}
            errors={errors}
            itemSet={itemSet}
          />
        </div>

        <div style={{ paddingLeft: 12, paddingRight: 12 }}>
          <SecondaryFields
            data={data}
            previewLanguage={previewLanguage}
            errors={errors}
            itemSet={itemSet}
          />
          <AuxiliaryFields
            data={data}
            previewLanguage={previewLanguage}
            errors={errors}
            itemSet={itemSet}
          />
        </div>
        <Barcodes
          data={data}
          errors={errors}
          itemSet={itemSet}
          previewLanguage={previewLanguage}
        />
      </div>
    );
  }
}
