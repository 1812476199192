import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ResourceLabel from './ResourceLabel';

export default class LabelProject extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
  };

  render() {
    if (this.props.disableLink) {
      return <ResourceLabel resource="certificate" id={this.props.id} />;
    }
    return (
      <ResourceLabel
        resource="certificate"
        id={this.props.id}
        link={`/passes/certificate/${this.props.id}`}
      />
    );
  }
}
