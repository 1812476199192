import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LabelProject from './LabelProject';
import { FormattedMessage } from 'react-intl';
import messages from '../../messagesDefine';
import { Form } from 'semantic-ui-react';

export default class FormFieldProject extends Component {
  static propTypes = {
    projectId: PropTypes.string.isRequired,
    title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  };

  render() {
    const title = !this.props.title ? (
      <FormattedMessage {...messages.PROJECT} />
    ) : (
      this.props.title
    );
    return (
      <Form.Field>
        <label>{title}</label>
        <LabelProject id={this.props.projectId} />
      </Form.Field>
    );
  }
}
