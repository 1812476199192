import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Icon } from 'semantic-ui-react';
import FormTextAreaJson from '../../common/FormTextAreaJSON';
import FormTab from '../../common/FormTab';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import BackButton from '../../common/buttons/BackButton';
import OrderEdit from './OrderEdit';
import OrderItemList from './OrderItemList';
import { sendEmail } from '../../../actions/sendEmail';
import { TICKET_API_URL } from '../../../services/api';

const FORM_NAME = 'ticketOrder';

class OrderForm extends Component {
  static propTypes = {
    data: PropTypes.object,
    itemSet: PropTypes.func.isRequired,
    itemSetPropertyByPath: PropTypes.func.isRequired,
    itemSave: PropTypes.func.isRequired,
    itemCreate: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
  };

  handleChange = (e, p) => {
    const { itemSetPropertyByPath, data } = this.props;
    itemSetPropertyByPath(p.name, p.value, data);
  };

  handleSave = e => {
    this.props.itemSave(this.props.data);
  };
  handleApply = e => {
    this.props.itemApply(this.props.data);
  };

  handleChangeJSON = json => {
    this.props.itemSet(json);
  };

  handleResendMailClick = data => {
    const { sendEmail } = this.props;
    sendEmail(data.id, data.orderNumber);
  };

  handleDownloadInvoice = e => {
    let url = `${TICKET_API_URL}/v1/order/${this.props.data.id}/invoice`;
    window.open(url, '_blank');
  };

  render() {
    let { data, changed, viewer, intl } = this.props;

    const tabOptions = {
      list: {
        name: intl.formatMessage({ id: 'ORDER_ITEM_LIST' }),
        icon: 'list',
        content: <OrderItemList data={data} />,
      },
      edit: {
        name: intl.formatMessage({ id: 'EDIT' }),
        icon: 'edit',
        content: <OrderEdit onChange={this.handleChange} data={data} />,
      },
    };

    if (viewer.isAdmin) {
      tabOptions.json = {
        icon: 'file text',
        name: 'Json',
        content: (
          <FormTextAreaJson
            name="json"
            onChange={this.handleChangeJSON}
            label="JSON"
            defaultValue={data}
          />
        ),
      };
    }

    return (
      <Form>
        <FormTab
          tabOptions={tabOptions}
          formName={FORM_NAME}
          defaultTab="list"
        />

        <Button
          type="button"
          onClick={this.handleDownloadInvoice}
          disabled={changed || data.paymentState !== 'paid'}
        >
          <Icon name="download" />Invoice
        </Button>

        <Button
          primary
          type="button"
          onClick={this.handleSave}
          disabled={!changed}
        >
          <Icon name="save" />Save
        </Button>
        <Button
          secondary
          type="button"
          onClick={this.handleApply}
          disabled={!changed}
        >
          <Icon name="save" />Apply
        </Button>
        <Button
          disabled={changed || data.paymentState !== 'paid'}
          primary
          type="button"
          onClick={() => this.handleResendMailClick(data)}
        >
          <Icon name="mail" />
          <FormattedMessage id="RESEND_MAIL" />
        </Button>
        <BackButton />
      </Form>
    );
  }
}

// This removes warnings for "undefined" react props
export default connect(
  (state, props) => ({
    viewer: state.viewer.data,
    form: state.ui.forms[FORM_NAME],
  }),
  { sendEmail },
)(injectIntl(OrderForm));
