import React, { Component } from 'react';
import HeaderFields from '../fields/HeaderFields';
import PropTypes from 'prop-types';
import StringInput from '../../../inputs/StringInput';
import { Popup } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import TemplateImage from '../TemplateImage';
import Errors from '../../../../../../models/Errors';
import Template from '../../../../../../models/template';
class LogoTextHeaderFields extends Component {
  static propTypes = {
    previewLanguage: PropTypes.string.isRequired,
    errors: PropTypes.instanceOf(Errors).isRequired,
    data: PropTypes.instanceOf(Template).isRequired,
    itemSet: PropTypes.func.isRequired,

    intl: PropTypes.object.isRequired,
  };

  handleChangeLogoText = (e, value) => {
    const { itemSet, data } = this.props;
    const newData = data.setLogoText(value);
    itemSet(newData);
  };

  render() {
    const { errors, data, previewLanguage, itemSet, intl } = this.props;

    const foregroundColor = data.getForegroundColor();
    const languages = data.get('languages');
    let value = data.getIn(['jsonTemplate', 'logoText', 'value']);
    if (value && !!value.get) {
      value = value.get(previewLanguage);
    }

    const cellStyle = {
      display: 'table-cell',
      verticalAlign: 'middle',
      marginLeft: 5,
    };

    return (
      <div
        className="header"
        style={{
          display: 'table',
          height: 60,
          width: 320,
          paddingLeft: 12,
          paddingRight: 12,
        }}
      >
        <div style={Object.assign({ maxWidth: 160 }, cellStyle)}>
          <TemplateImage
            itemSet={itemSet}
            data={data}
            type={'logo'}
            previewLanguage={previewLanguage}
          />
        </div>
        <div
          style={Object.assign({ maxWidth: '50%', minWidth: 30 }, cellStyle)}
        >
          <Popup
            trigger={
              <span
                className="designerHoverItem"
                style={{
                  color: foregroundColor,
                  height: 25,
                  minWidth: 50,
                  float: 'left',
                  fontWeight: 'bold',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                {value}
              </span>
            }
            position="right center"
            on="click"
          >
            <div style={{ width: 300 }}>
              <StringInput
                languages={languages}
                errors={errors}
                itemSet={itemSet}
                data={data}
                label={intl.formatHTMLMessage({
                  id: 'logoText'.toUpperCase(),
                })}
                path={[`jsonTemplate`, `logoText`]}
              />
            </div>
          </Popup>
        </div>

        <div style={Object.assign({ maxWidth: 65 }, cellStyle)}>
          <HeaderFields
            data={data}
            previewLanguage={previewLanguage}
            errors={errors}
            itemSet={itemSet}
            style={{ minHeight: 50 }}
          />
        </div>
      </div>
    );
  }
}

export default injectIntl(LogoTextHeaderFields);
