import React, { Component } from 'react';
import BoardingPass from './BoardingPass';
import Coupon from './Coupon';
import EventTicket from './EventTicket';
import Generic from './Generic';
import StoreCard from './StoreCard';
import PropTypes from 'prop-types';
import Errors from '../../../../../../models/Errors';
import Template from '../../../../../../models/template';

export default class PassStyle extends Component {
  static propTypes = {
    previewLanguage: PropTypes.string.isRequired,
    errors: PropTypes.instanceOf(Errors).isRequired,
    data: PropTypes.instanceOf(Template).isRequired,
    itemSet: PropTypes.func.isRequired,
  };

  render() {
    const { data, previewLanguage, itemSet, errors } = this.props;
    const passStyle = data.getPassStyle();

    let fields = null;
    switch (passStyle) {
      case 'boardingPass':
        fields = (
          <BoardingPass
            data={data}
            previewLanguage={previewLanguage}
            errors={errors}
            itemSet={itemSet}
          />
        );
        break;
      case 'coupon':
        fields = (
          <Coupon
            data={data}
            previewLanguage={previewLanguage}
            errors={errors}
            itemSet={itemSet}
          />
        );
        break;
      case 'eventTicket':
        fields = (
          <EventTicket
            data={data}
            previewLanguage={previewLanguage}
            errors={errors}
            itemSet={itemSet}
          />
        );
        break;
      case 'generic':
        fields = (
          <Generic
            data={data}
            previewLanguage={previewLanguage}
            errors={errors}
            itemSet={itemSet}
          />
        );
        break;
      case 'storeCard':
        fields = (
          <StoreCard
            data={data}
            previewLanguage={previewLanguage}
            errors={errors}
            itemSet={itemSet}
          />
        );
        break;
      default:
        return <div>Unsupported pass style</div>;
    }

    return fields;
  }
}
