import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { filterSet } from '../../../actions/index';
import { injectIntl } from 'react-intl';

class FilterDevices extends Component {
  static propTypes = {
    value: PropTypes.string.isRequired,
    filterSet: PropTypes.func.isRequired,
    onChange: PropTypes.func,
  };

  handleChange = (e, p) => {
    this.props.filterSet({ name: p.name, value: p.value });
    if (this.props.onChange) {
      this.props.onChange(e, {
        name: p.name,
        value: p.value,
      });
    }
  };

  render() {
    const { intl } = this.props;
    const devicesOptions = [
      {
        text: '-',
        value: '',
      },
      {
        text: intl.formatHTMLMessage({ id: 'SOME_DEVICES' }),
        value: 'gt',
      },
      {
        text: intl.formatHTMLMessage({ id: 'NONE_DEVICES' }),
        value: 'eq',
      },
    ];
    return (
      <Form.Select
        name={this.props.name}
        label={this.props.label}
        options={devicesOptions}
        value={this.props.value}
        onChange={this.handleChange}
      />
    );
  }
}

export default connect(
  (state, props) => ({
    value: state.filter[props.name] ? state.filter[props.name].value : '',
  }),
  { filterSet },
)(injectIntl(FilterDevices));
