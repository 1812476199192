import React from 'react';
import { Message } from 'semantic-ui-react';
import BaseReport from './BaseReport';
import { FormattedHTMLMessage } from 'react-intl';

export default class NoData extends BaseReport {
  render() {
    return (
      <Message warning>
        <Message.Header>
          <FormattedHTMLMessage id={'NO_DATA_TITLE'} />
        </Message.Header>
        <p>
          <FormattedHTMLMessage id={'NO_DATA_MESSAGE'} />
        </p>
      </Message>
    );
  }
}
