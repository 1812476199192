import React, { Component } from 'react';
import FormDropdownPeriod from './partials/FormDropdownPeriod';
import FormInputNumber from '../../common/FormInputNumber';
import FormInputDateTime from '../../common/FormInputDateTime';
import { Grid } from 'semantic-ui-react';

export default class FormTabPeriod extends Component {
  render() {
    const { data, onChange } = this.props;

    return (
      <div>
        <FormDropdownPeriod
          onChange={onChange}
          name="period.periodType"
          value={data.period.periodType}
        />

        {data.period.periodType === 'days' && (
          <FormInputNumber
            name="period.daysMaximalValidity"
            label="Maximum validity from the time of purchase (days)"
            onChange={onChange}
            defaultValue={data.period.daysMaximalValidity}
          />
        )}

        {data.period.periodType === 'interval' && (
          <Grid divided="vertically">
            <Grid.Row columns={2}>
              <Grid.Column>
                <FormInputDateTime
                  key={'period.intervalFrom'}
                  name="period.intervalFrom"
                  label="Valid from"
                  onChange={onChange}
                  defaultValue={data.period.intervalFrom}
                />
              </Grid.Column>
              <Grid.Column>
                <FormInputDateTime
                  key={'period.intervalTo'}
                  name="period.intervalTo"
                  label="Valid to"
                  onChange={onChange}
                  defaultValue={data.period.intervalTo}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
      </div>
    );
  }
}
