import React from 'react';
import { Form } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import FieldExample from './FieldExample';
import FieldTypeText from './fieldType/FieldTypeText';
import FieldTypeDate from './fieldType/FieldTypeDate';
import FieldTypeNumber from './fieldType/FieldTypeNumber';
import FieldTypeCurrency from './fieldType/FieldTypeCurrency';
import FieldAlignmentDropdown from './FieldAlignmentDropDown';
import Errors from '../../../../../../../../models/Errors';
import Template from '../../../../../../../../models/template';
import { ObjectType } from '../../../../../../../../models/property';
import InputRenderComponent from '../../../../../inputs/InputRenderComponent';

export default class FieldForm extends InputRenderComponent {
  static propTypes = {
    fieldIndex: PropTypes.number.isRequired,
    fieldsType: PropTypes.string.isRequired,
    fieldType: PropTypes.string.isRequired,
    data: PropTypes.instanceOf(Template).isRequired,
    errors: PropTypes.instanceOf(Errors).isRequired,
    field: PropTypes.instanceOf(ObjectType).isRequired,
    itemSet: PropTypes.func.isRequired,
    previewLanguage: PropTypes.string.isRequired,
    path: PropTypes.array.isRequired,
  };

  onSubmit = e => {
    e.preventDefault();
    e.stopPropagation();
    return false;
  };

  handleRemove = () => {
    const { data, itemSet, fieldsType, fieldIndex } = this.props;
    itemSet(data.removeField(fieldsType, fieldIndex));
  };

  render() {
    const {
      fieldsType,
      fieldType,
      fieldIndex,
      field,
      data,
      errors,
      itemSet,
      previewLanguage,
      path,
    } = this.props;

    const languages = data.get('languages');

    return (
      <Form>
        <FieldAlignmentDropdown
          data={data}
          errors={errors}
          field={field}
          fieldIndex={fieldIndex}
          fieldsType={fieldsType}
          itemSet={itemSet}
          path={path}
        />
        {this.renderStringInput(path, 'label', { languages })}
        {fieldType === 'text' && (
          <FieldTypeText
            data={data}
            errors={errors}
            field={field}
            previewLanguage={previewLanguage}
            fieldIndex={fieldIndex}
            fieldsType={fieldsType}
            itemSet={itemSet}
            path={path}
          />
        )}
        {fieldType === 'date' && (
          <FieldTypeDate
            data={data}
            errors={errors}
            field={field}
            previewLanguage={previewLanguage}
            fieldIndex={fieldIndex}
            fieldsType={fieldsType}
            itemSet={itemSet}
            path={path}
          />
        )}
        {fieldType === 'number' && (
          <FieldTypeNumber
            data={data}
            errors={errors}
            field={field}
            previewLanguage={previewLanguage}
            fieldIndex={fieldIndex}
            fieldsType={fieldsType}
            itemSet={itemSet}
            path={path}
          />
        )}
        {fieldType === 'currency' && (
          <FieldTypeCurrency
            data={data}
            errors={errors}
            field={field}
            previewLanguage={previewLanguage}
            fieldIndex={fieldIndex}
            fieldsType={fieldsType}
            itemSet={itemSet}
            path={path}
          />
        )}

        {this.renderStringInput(path, 'changeMessage', {
          languages,
          textArea: fieldsType === 'backFields',
        })}
        <FieldExample
          field={field}
          fieldType={fieldType}
          previewLanguage={previewLanguage}
        />
      </Form>
    );
  }
}
