import PropTypes from 'prop-types';
import InputRenderComponent from '../../../../../inputs/InputRenderComponent';

const options = [
  {
    key: 'PKTextAlignmentLeft',
    value: 'PKTextAlignmentLeft',
    text: 'PKTextAlignmentLeft',
  },
  {
    key: 'PKTextAlignmentCenter',
    value: 'PKTextAlignmentCenter',
    text: 'PKTextAlignmentCenter',
  },
  {
    key: 'PKTextAlignmentRight',
    value: 'PKTextAlignmentRight',
    text: 'PKTextAlignmentRight',
  },
  {
    key: 'PKTextAlignmentNatural',
    value: 'PKTextAlignmentNatural',
    text: 'PKTextAlignmentNatural',
  },
];

export default class FieldAlignmentDropDown extends InputRenderComponent {
  static propTypes = {
    fieldsType: PropTypes.string.isRequired,
    ...InputRenderComponent.propTypes,
  };

  render() {
    const { fieldsType, path } = this.props;
    if (fieldsType !== 'primaryFields' && fieldsType !== 'backFields') {
      return this.renderDropDownInput(path, 'textAlignment', options);
    } else {
      return null;
    }
  }
}
