import React from 'react';
import { Dropdown, Form } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import { StringProperty } from '../../../../models/property';
import PropTypes from 'prop-types';
import Template from '../../../../models/template';
import Errors from '../../../../models/Errors';
import BaseInput from './BaseInput';

export default class DropDownInput extends BaseInput {
  static propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
      .isRequired,
    data: PropTypes.instanceOf(Template).isRequired,
    errors: PropTypes.instanceOf(Errors).isRequired,
    path: PropTypes.array.isRequired,
    options: PropTypes.array.isRequired,
    itemSet: PropTypes.func.isRequired,
  };

  handleChange = (e, d) => {
    this.setValue(e, new StringProperty({ value: d.value }));
  };

  render() {
    const { label, options } = this.props;
    const value = this.getValue();
    return (
      <Form.Field key={`${this.pathToString()}.value`}>
        <label>{label}</label>
        <Dropdown
          style={{ width: '100%' }}
          className="ui floating dropdown labeled search icon button"
          options={options}
          value={value}
          onChange={this.handleChange}
        />
      </Form.Field>
    );
  }
}
injectIntl(DropDownInput);
