import React from 'react';
import { Popup, Button, Form } from 'semantic-ui-react';
import {
  barcodeTypes,
  barcodeTypeOptions,
  barcodeFallbackOptions,
} from './../../../../../../constants/passConstants';
import BarcodeImage from './BarcodeImage';
import Help from '../Help';
import InputRenderComponent from '../../../inputs/InputRenderComponent';
import TemplateImage from '../TemplateImage';
import PropTypes from 'prop-types';

export default class Barcodes extends InputRenderComponent {
  static propTypes = {
    ...InputRenderComponent.propTypes,
    previewLanguage: PropTypes.string.isRequired,
  };

  handleBarcodeChange = (e, value) => {
    const { data, itemSet } = this.props;
    itemSet(data.barcodeChangeType(value));
  };
  handleBarcodeFallbackChange = (e, value) => {
    const { data, itemSet } = this.props;
    itemSet(data.barcodeChangeFallbackType(value));
  };

  handleRemove = () => {
    const { data, itemSet } = this.props;
    itemSet(data.barcodeRemove());
  };

  handleChange = (value, name) => {
    const { data, itemSet } = this.props;
    itemSet(data.barcodeChangeProperty(name, value));
  };

  render() {
    const { data, errors, itemSet, previewLanguage } = this.props;
    const path = ['jsonTemplate', 'barcodes', 'items', 0, 'properties'];
    const fallbackPath = ['jsonTemplate', 'barcodes', 'items', 1, 'properties'];

    let altText = data.getIn(path.concat(['altText']));
    const barcodeType = data.getIn(path.concat(['format']));

    const hasBarcodeError = errors.hasErrors([`jsonTemplate`, `barcodes`]);

    const passStyle = data.getPassStyle();
    const barcodeTypeValue = data.getIn(path.concat(['format', 'value']));

    let bottomPosition = 5;
    if (
      barcodeTypes[barcodeTypeValue] &&
      !barcodeTypes[barcodeTypeValue].isSquared
    ) {
      bottomPosition = 30;
    }

    return (
      <div
        style={{
          position: 'absolute',
          bottom: bottomPosition,
          width: '100%',
          textAlign: 'center',
        }}
      >
        {passStyle === 'boardingPass' && (
          <div
            style={{
              position: 'absolute',
              bottom: '100%',
              left: 10,
              right: 10,
            }}
          >
            <TemplateImage
              itemSet={itemSet}
              data={data}
              type={'footer'}
              previewLanguage={previewLanguage}
            />
          </div>
        )}

        <Popup
          trigger={
            <div
              className="designerHoverItem"
              style={{
                maxWidth: 250,
                backgroundColor: 'white',
                paddingBottom: 5,
                paddingLeft: 10,
                paddingRight: 10,
                paddingTop: 10,
                borderRadius: 5,
                display: 'inline-block',
                border: hasBarcodeError ? '2px solid red' : undefined,
              }}
            >
              <BarcodeImage
                hasBarcodeError={hasBarcodeError}
                barcodeType={
                  barcodeType ? barcodeType.get('value') : barcodeType
                }
                color={data.getForegroundColor()}
                altText={altText ? altText.get('value') : altText}
              />
            </div>
          }
          position="right center"
          on="click"
        >
          <Form style={{ width: 400, marginBottom: 20 }}>
            {this.renderDropDownInput(path, 'format', barcodeTypeOptions, {
              onChange: this.handleBarcodeChange,
            })}
            {barcodeType &&
              barcodeType.get('value') === 'PKBarcodeFormatCode128' &&
              this.renderDropDownInput(
                fallbackPath,
                'format',
                barcodeFallbackOptions,
                { onChange: this.handleBarcodeFallbackChange },
              )}

            {this.renderStringInput(path, `altText`, {
              disabled: !barcodeType,
              onChange: (e, value) => this.handleChange(value, 'altText'),
            })}
            {this.renderStringInput(path, `message`, {
              disabled: !barcodeType,
              textArea: true,
              onChange: (e, value) => this.handleChange(value, 'message'),
            })}
            {this.renderStringInput(path, `messageEncoding`, {
              disabled: !barcodeType,
              onChange: (e, value) =>
                this.handleChange(value, 'messageEncoding'),
            })}
            <Help />
            {!!barcodeType && (
              <Button
                icon="trash"
                type="button"
                color="red"
                onClick={this.handleRemove}
                style={{ float: 'right' }}
              />
            )}
          </Form>
        </Popup>
      </div>
    );
  }
}
