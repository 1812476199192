import React, { Component } from 'react';
import { DISTRIBUTION_API_URL, CORE_API_URL } from '../../../services/api';

export default class PassSaveToGooglePay extends Component {
  render() {
    const { pass } = this.props;
    return (
      <div>
        <label>Link to Save to Google Pay object</label>
        <br />
        <a
          href={CORE_API_URL + '/v1/pass/' + pass.id + '/savetogooglepay'}
          target="_blank"
          rel="noopener noreferrer"
        >
          {CORE_API_URL + '/v1/pass/' + pass.id + '/savetogooglepay'}
        </a>
        <br />
        <br />

        <label>Link to JWT token to add in to Save to Google Pay</label>
        <br />
        <a
          href={
            DISTRIBUTION_API_URL + '/' + pass.id + '?format=savetogooglepayjwt'
          }
          rel="noopener noreferrer"
          target="_blank"
        >
          {DISTRIBUTION_API_URL + '/' + pass.id + '?format=savetogooglepayjwt'}
        </a>
      </div>
    );
  }
}
