export const UI_SET_JSON_EDIT_FORMAT = 'UI_SET_JSON_EDIT_FORMAT';
export const UI_FORM_SET_TAB = 'UI_FORM_SET_TAB';
export const UI_SET_SCROLL_HEIGHT = 'UI_SET_SCROLL_HEIGHT';
export const UI_SET_WINDOW_HEIGHT = 'UI_SET_WINDOW_HEIGHT';
export const UI_SET_DRAG_AND_DROP_PROPERTY = 'UI_SET_DRAG_AND_DROP_PROPERTY';
export const UI_SET_TEMPLATE_PREVIEV_LANGUAGE =
  'UI_SET_TEMPLATE_PREVIEV_LANGUAGE';
export const UI_LOAD_FORM_STORE_SUCCESS = 'UI_LOAD_FORM_STORE_SUCCESS';

export const UI_SET_TEMPLATE_LIST_VALIDATION =
  'UI_SET_TEMPLATE_LIST_VALIDATION';
export const UI_SET_TEMPLATE_FORCE_OPEN = 'UI_SET_TEMPLATE_FORCE_OPEN';

export function uiSetWindowWidth(height) {
  return {
    type: UI_SET_WINDOW_HEIGHT,
    value: height,
  };
}

export function uiSetScrollHeight(height) {
  return {
    type: UI_SET_SCROLL_HEIGHT,
    value: height,
  };
}

export function uiSetFormTab(form, tab) {
  return {
    type: UI_FORM_SET_TAB,
    form,
    tab,
  };
}

export function uiSetJsonEditFormat(format) {
  return {
    type: UI_SET_JSON_EDIT_FORMAT,
    format,
  };
}

export function uiSetDragAndDropProperty(property, value) {
  return {
    type: UI_SET_DRAG_AND_DROP_PROPERTY,
    property,
    value,
  };
}

export function uiSetTemplatePreviewLanguage(value) {
  return { type: UI_SET_TEMPLATE_PREVIEV_LANGUAGE, value };
}

export function uiSetTemplateListValidation(value) {
  return { type: UI_SET_TEMPLATE_LIST_VALIDATION, value };
}

export function uiSetTemplateForceOpenField(value) {
  return { type: UI_SET_TEMPLATE_FORCE_OPEN, value };
}
