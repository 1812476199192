import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RegisterItemDecorator from './RegisterItemDecorator';
import { Link } from 'react-router';

class LabelImpl extends Component {
  static propTypes = {
    data: PropTypes.object,
  };

  render() {
    return (
      <Link to={`/ticket/ticketProduct/${this.props.data.id}`}>
        {this.props.data.name}
      </Link>
    );
  }
}

export default class LabelUser extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
  };

  render() {
    return (
      <RegisterItemDecorator
        resource={'ticketProduct'}
        resourceId={this.props.id}
        component={LabelImpl}
      />
    );
  }
}
