import React from 'react';
import PropTypes from 'prop-types';
import { Button, Segment } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { addNotification } from '../../../../../actions/notification';
import Template from '../../../../../models/template';
import Errors from '../../../../../models/Errors';
import InputRenderComponent from '../../inputs/InputRenderComponent';

class Beacons extends InputRenderComponent {
  static propTypes = {
    itemSet: PropTypes.func,
    data: PropTypes.instanceOf(Template).isRequired,
    errors: PropTypes.instanceOf(Errors).isRequired,
  };

  add = () => {
    try {
      const { itemSet, data } = this.props;
      const newData = data.beaconAdd();
      itemSet(newData);
    } catch (e) {
      this.props.addNotification({
        title: 'Error',
        message: e.message,
        level: 'error',
      });
    }
  };

  remove = index => {
    try {
      const { itemSet, data } = this.props;
      const newData = data.beaconRemove(index);
      itemSet(newData);
    } catch (e) {
      this.props.addNotification({
        title: 'Error',
        message: e.message,
        level: 'error',
      });
    }
  };

  render() {
    const { data } = this.props;
    const path = ['jsonTemplate', 'beacons', 'items'];
    const beacons = data.getIn(path);
    return (
      <div>
        <h1>Beacons</h1>
        {beacons &&
          beacons.map((beacon, index) => {
            const path = [
              'jsonTemplate',
              'beacons',
              'items',
              index,
              'properties',
            ];

            return (
              <Segment key={index}>
                <Button
                  icon="trash"
                  color="red"
                  floated="right"
                  onClick={() => {
                    this.remove(index);
                  }}
                />
                {this.renderStringInput(path, 'proximityUUID')}
                {this.renderNumberInput(path, 'major')}
                {this.renderNumberInput(path, 'minor')}
                {this.renderStringInput(path, 'relevantText')}
              </Segment>
            );
          })}

        <Button primary icon="plus" onClick={this.add} />
      </div>
    );
  }
}

export default connect(state => ({}), {
  addNotification,
})(Beacons);
