import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Popup } from 'semantic-ui-react';
import Field from './field/Field';
import { injectIntl } from 'react-intl';
import shortid from 'shortid';
import { List } from 'immutable';
import Errors from '../../../../../../models/Errors';
import Template from '../../../../../../models/template';
import { uiSetTemplateForceOpenField } from '../../../../../../actions/ui';
import { connect } from 'react-redux';

class FieldsMobileView extends Component {
  static propTypes = {
    intl: PropTypes.object.isRequired,
    maxCount: PropTypes.number,
    previewLanguage: PropTypes.string.isRequired,
    errors: PropTypes.instanceOf(Errors).isRequired,
    data: PropTypes.instanceOf(Template).isRequired,
    itemSet: PropTypes.func.isRequired,
    uiSetTemplateForceOpenField: PropTypes.func.isRequired,
    fieldsType: PropTypes.string.isRequired,
  };

  addField = () => {
    const {
      itemSet,
      data,
      uiSetTemplateForceOpenField,
      fieldsType,
    } = this.props;
    const key = shortid.generate();
    uiSetTemplateForceOpenField(key);
    itemSet(data.fieldAdd(fieldsType, key));
    if (fieldsType === 'backFields') {
      setTimeout(this.scrollToBottom, 200);
    }
  };

  scrollToBottom = () => {
    this.element.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
      inline: 'start',
    });
  };

  render() {
    const {
      fieldsType,
      maxCount,
      intl,
      previewLanguage,
      data,
      errors,
      itemSet,
    } = this.props;
    let fields = data.getFields(fieldsType);
    if (!fields) {
      fields = new List();
    }

    const addStyleDefault = {
      position: 'absolute',
      height: 20,
      padding: 3,
      right: -25,
      margin: 10,
      pointerEvents: 'auto',
    };
    const addStyleBackFields = {
      position: 'absolute',
      bottom: -20,
      marginLeft: 140,
    };

    let orderedFields = fields
    if (fieldsType === 'headerFields'){
      orderedFields = fields.reverse()
    }

    return (
      <div
        className="fields"
        style={{
          display: 'table',
          minHeight: 40,
          paddingBottom: 10,
          width: fieldsType !== 'primaryFields' ? '100%' : undefined,
          tableLayout: 'fixed',
        }}
        ref={el => {
          this.element = el;
        }}
      >
        {orderedFields.map((field, i) => {
          const index = fieldsType === 'headerFields' ? fields.size - i - 1 : i;
          return (
            <Field
              key={field.getIn(['properties', 'key', 'value'])}
              errors={errors}
              data={data}
              itemSet={itemSet}
              fieldsType={fieldsType}
              field={field}
              fieldIndex={index}
              previewLanguage={previewLanguage}
            />
          );
        })}
        {(!maxCount || maxCount > fields.size) && (
          <Popup
            content={intl.formatHTMLMessage({ id: fieldsType.toUpperCase() })}
            trigger={
              <Button
                type="button"
                icon="plus"
                color="green"
                onClick={this.addField}
                size="mini"
                style={
                  fieldsType === 'backFields'
                    ? addStyleBackFields
                    : addStyleDefault
                }
              />
            }
          />
        )}
      </div>
    );
  }
}
export default connect((state, props) => ({}), { uiSetTemplateForceOpenField })(
  injectIntl(FieldsMobileView),
);
