import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Message, Button, Label, Icon } from 'semantic-ui-react';
import { ISSUE_URL } from '../../../../../services/api';
import FormInputCheckbox from '../../../../common/FormInputCheckbox';
import { injectIntl, FormattedMessage, defineMessages } from 'react-intl';

const messages = defineMessages({
  distributableCheckboxLabel: {
    id: 'core.template.distributable.checkbox.label',
    defaultMessage: 'Distributable',
  },
});

class TemplateDistributionFormField extends Component {
  static propTypes = {
    intl: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    canUpdate: PropTypes.bool.isRequired,
  };

  render() {
    let { data, changed, canUpdate, intl, onChange } = this.props;

    return (
      <div style={{ width: '100%', marginTop: 10, marginBottom: 10 }}>
        <Message>
          <Message.Header>
            <FormattedMessage
              id="core.template.distributable.header.label"
              defaultMessage="Distribution information"
            />
          </Message.Header>
          <div style={{ width: '100%', marginTop: 10, marginBottom: 10 }}>
            <FormInputCheckbox
              name="distributable"
              onChange={onChange}
              defaultChecked={data.get('distributable')}
              disabled={!canUpdate}
              toggle={true}
              label={intl.formatHTMLMessage(
                messages.distributableCheckboxLabel,
              )}
            />
          </div>
          {data.get('distributable') && (
            <Message.Content>
              <p>
                <a
                  href={`${ISSUE_URL}/${data.get('id')}`}
                  target="blank"
                >{`${ISSUE_URL}/${data.get('id')}`}</a>
              </p>
              <p>
                <Button
                  icon
                  labelPosition="left"
                  as={'a'}
                  href={`${ISSUE_URL}/${data.get('id')}?format=qrcode`}
                  target="blank"
                >
                  <Icon name="qrcode" />
                  <FormattedMessage
                    id="core.template.distributable.qrcode.button.label"
                    defaultMessage="qr code"
                  />
                </Button>
              </p>
            </Message.Content>
          )}
        </Message>
        {data.get('distributable') &&
          changed && (
            <Label
              basic
              color="yellow"
              pointing={'above'}
              style={{ marginTop: 0, marginBottom: '1em' }}
            >
              <FormattedMessage
                id="core.template.distributable.warning.label"
                defaultMessage="Template changes are not saved."
              />
            </Label>
          )}
      </div>
    );
  }
}

export default injectIntl(TemplateDistributionFormField);
