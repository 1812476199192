import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  itemRead,
  itemDestroy,
  itemBind,
  itemUnBind,
} from '../../actions/resource';
import { push } from '../../actions/routing';
import { addNotification } from '../../actions/notification';
import { AUTO_DISMISS } from '../../constants/toast.js';
import { Message, Loader } from 'semantic-ui-react';

const CACHE_INTERVAL = 1000 * 60 * 15;

class RegisterItemDecorator extends Component {
  static propTypes = {
    resourceId: PropTypes.string.isRequired,
    instance: PropTypes.object,
    resource: PropTypes.string.isRequired,
    push: PropTypes.func.isRequired,
    itemRead: PropTypes.func.isRequired,
    itemBind: PropTypes.func.isRequired,
    itemUnBind: PropTypes.func.isRequired,
    itemDestroy: PropTypes.func.isRequired,
    ignoreLoading: PropTypes.bool,
    ignoreError: PropTypes.bool,
    component: PropTypes.func.isRequired,
    componentProps: PropTypes.object,
  };

  getRequestId(props) {
    return props.resourceId;
  }

  componentWillMount() {
    this.init(
      this.props.instance,
      this.props.resourceId,
      this.getRequestId(this.props),
    );
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.resourceId !== nextProps.resourceId) {
      // component unbind
      this.props.itemUnBind(this.props.resource, this.getRequestId(this.props));
      // implement destroy item (if is not binded) after 5 minutes
      // TODO - may be moved into saga on take every of UNBIND_ITEM
      // TODO - set interval, clear interval to store to have only one clearing process,
      // TODO - another posibility: may by can run as task each 5 minutes and check last unbind time and bind count, and if time exceeded and bind is null then do clear
      setTimeout(() => {
        this.props.itemDestroy(
          this.props.resource,
          this.getRequestId(this.props),
        );
      }, CACHE_INTERVAL);

      this.init(
        nextProps.instance,
        nextProps.resourceId,
        this.getRequestId(nextProps),
      );
    }
  }

  init(item, id, requestId) {
    const isPrepared = item && item.get('data');
    const isLoading = item && item.get('loading');
    if (!isPrepared && !isLoading) {
      this.itemRead(this.props.resource, requestId, id);
    } else {
      // check timeout for refresh (if is readed before )
      if (item.timestamp) {
        //console.log(new Date().getTime() - item.timestamp.getTime())
        if (new Date().getTime() - item.timestamp.getTime() > CACHE_INTERVAL) {
          this.itemRead(this.props.resource, requestId, id);
        }
      }
    }
    this.props.itemBind(this.props.resource, requestId);
  }

  componentWillUnmount() {
    // component unbind
    this.props.itemUnBind(this.props.resource, this.getRequestId(this.props));
    // implement destroy item (if is not binded) after 5 minutes
    // TODO - may be moved into saga on take every of UNBIND_ITEM
    // TODO - set interval, clear interval to store to have only one clearing process,
    // TODO - another posibility: may by can run as task each 5 minutes and check last unbind time and bind count, and if time exceeded and bind is null then do clear
    setTimeout(() => {
      this.props.itemDestroy(
        this.props.resource,
        this.getRequestId(this.props),
      );
    }, CACHE_INTERVAL);
  }

  itemReadSuccess = (data, resource, id) => {};
  itemReadFailure = (e, resource, id) => {
    if (this.props.failure) {
      this.props.failure(e, resource, id);
    }
    this.props.addNotification({
      title: `Read ${resource} ${id} failure`,
      message: e.message || e.statusText,
      level: 'error',
      autoDismiss: AUTO_DISMISS,
    });
  };

  itemRead = (resource, requestId, id) => {
    if (id !== 'new') {
      this.props.itemRead(
        resource,
        requestId,
        id,
        this.itemReadSuccess,
        this.itemReadFailure,
      );
    } else {
      this.itemSet(this.props.defaultValue || {});
    }
  };

  itemSetMetadata = (name, value) => {
    this.props.itemSetMetadata(
      this.props.resource,
      this.getRequestId(this.props),
      name,
      value,
    );
  };

  render() {
    const { instance, viewer, resource } = this.props;
    if (!instance || (instance.get('loading') && !this.props.ignoreLoading)) {
      return <Loader active inline="centered" />;
    }

    // resource item wrapper - create fallback on childerns (if not is loading and not data and error message - show in children what you need - in tables show only id, in form show error)
    if (!instance.get('data')) {
      return <span>{this.props.resourceId}</span>;
    }

    const childrenProps = {
      resource: resource,
      data: instance.get('data'),
      changed: instance.get('changed'),
      touched: instance.get('touched'),
      validation: instance.get('validation'),
      message: instance.get('message'),
      loading: instance.get('loading'),
      viewer: viewer,
      parentProps: this.props.parentProps,
    };

    const ChildComponent = this.props.component;

    if (instance.get('message') && !this.props.ignoreError) {
      return (
        <Message negative>
          <Message.Header>We are sorry but something is broken</Message.Header>
          <p>{instance.get('message')}</p>
        </Message>
      );
    }

    if (ChildComponent) {
      return (
        <ChildComponent {...childrenProps} {...this.props.componentProps} />
      );
    }

    return null;
  }
}

export default connect(
  (state, props) => ({
    instance: state.resource.get(props.resourceId),
    viewer: state.viewer.data,
  }),
  {
    push,
    itemRead,
    itemDestroy,
    itemBind,
    itemUnBind,
    addNotification,
  },
)(RegisterItemDecorator);
