import {
  FETCH_REPORT_LOADING,
  FETCH_REPORT_SUCCESS,
  FETCH_REPORT_FAILURE,
} from '../actions';

export default function doc(state = {}, action) {
  switch (action.type) {
    case FETCH_REPORT_LOADING:
      if (!state[action.serviceType]) {
        state[action.serviceType] = {
          data: null,
          loading: false,
          query: null,
        };
      }
      state[action.serviceType].loading = true;
      state[action.serviceType].query = action.query;
      return Object.assign({}, state);
    case FETCH_REPORT_SUCCESS:
      state[action.serviceType] = {
        loading: false,
        data: action.data,
        query: action.query,
      };
      return Object.assign({}, state);
    case FETCH_REPORT_FAILURE:
      state[action.serviceType] = {
        loading: false,
        data: null,
        query: action.query,
      };
      return Object.assign({}, state);
    default:
      return state;
  }
}
