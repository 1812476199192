import React, { Component } from 'react';
import BasePassOverview from './BasePassOverview';

export default class PassAtLeastOnceRegisterInWalletOverview extends Component {
  render() {
    return (
      <BasePassOverview
        serviceType="at_least_once_register_in_wallet"
        description="This dashboard count passes which has been registered before end of period and hs not been deleted before start period"
      />
    );
  }
}
