import { Record } from 'immutable';

export default class Images extends Record({
  logo: undefined,
  footer: undefined,
  icon: undefined,
  thumbnail: undefined,
  strip: undefined,
  background: undefined,
}) {}
