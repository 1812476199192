import { call, put } from 'redux-saga/effects';
import client, { CORE_API_URL } from '../services/api';

import {
  PROJECT_BATCH_SAVE_SUCCESS,
  PROJECT_BATCH_SAVE_FAILURE,
} from '../actions/project';

import { addNotification } from '../actions/notification';

import { AUTO_DISMISS } from '../constants/toast.js';

export function* projectSaveBatch(action) {
  let resourceQuery = `${CORE_API_URL}/v1/project/batch`;

  const payload = [];

  action.data.map(p => {
    return payload.push({
      method: 'PUT',
      id: p.id,
      data: p,
    });
  });

  try {
    const data = [];
    const size = 10;
    for (let i = 0; i < payload.length; i += size) {
      var partialPayload = payload.slice(i, i + size);
      let partialData = yield call(
        client,
        'POST',
        resourceQuery,
        partialPayload,
      );
      partialData.forEach(p => {
        return data.push(p);
      });
    }

    yield put({
      type: PROJECT_BATCH_SAVE_SUCCESS,
      data,
      serviceType: action.serviceType,
    });
    yield put(
      addNotification({
        title: 'Projects saved success',
        message: `Batch saving of projects done`,
        level: 'success',
        autoDismiss: AUTO_DISMISS,
      }),
    );
  } catch (e) {
    yield put({
      type: PROJECT_BATCH_SAVE_FAILURE,
      message: e.message,
      serviceType: action.serviceType,
    });
    yield put(
      addNotification({
        title: 'Batch failure',
        message: e.message,
        level: 'error',
        autoDismiss: AUTO_DISMISS,
      }),
    );
  }
}
