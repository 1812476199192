import React, { Component } from 'react';
import TemplateEditForm from './TemplateEditForm';
import Edit from '../../common/Edit';
export default class TemplateEdit extends Component {
  render() {
    return (
      <Edit
        resource={'template'}
        resourceId={this.props.params.id}
        component={TemplateEditForm}
      />
    );
  }
}
