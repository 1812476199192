import React from 'react';
import PropTypes from 'prop-types';
import { Table, Loader, Grid } from 'semantic-ui-react';
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import { connect } from 'react-redux';
import Filter from '../common/filter/Filter';
import { inUuidQueryBuilder } from '../../services/api';
import { fetchReport } from '../../actions/index';
import { injectIntl } from 'react-intl';
import BaseReport from './BaseReport';
import COLORS from '../../constants/colors';
import { FormattedMessage } from 'react-intl';

class BasePassOverview extends BaseReport {
  static propTypes = {
    filter: PropTypes.object.isRequired,
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    const { filter, fetchReport, serviceType } = this.props;

    let where = {};
    if (filter.template && filter.template.value) {
      where = inUuidQueryBuilder(where, filter.template.value, 'templateId');
    }
    if (filter.project && filter.project.value) {
      where = inUuidQueryBuilder(where, filter.project.value, 'projectId');
    }

    const query = {
      from: filter.from.value,
      to: filter.to.value,
      interval: filter.interval.value,
      where: where,
    };
    fetchReport(serviceType, query);
  };

  render() {
    const { report, description } = this.props;
    if (!report) {
      return <Loader active inline="centered" />;
    }

    const interval = report.query.interval;
    const data = report.data;

    let sorted = [];
    if (data) {
      sorted = [].concat(data);
      sorted.sort(function(a, b) {
        let c = new Date(a.from);
        let d = new Date(b.from);
        return c.getTime() - d.getTime();
      });

      sorted = sorted
        .map(d => {
          d.formatedLabel = this.getLabel(d.from, interval);
          return d;
        })
        .reverse();
    }

    let labelId;
    switch (report.query.interval) {
      case 'daily':
        labelId = 'INCREASE_PER_DAY';
        break;
      case 'weekly':
        labelId = 'INCREASE_PER_WEEK';
        break;
      case 'monthly':
        labelId = 'INCREASE_PER_MONTH';
        break;
      default:
        break;
    }

    return (
      <div>
        <p>{description}</p>
        <Filter
          project={true}
          template={true}
          from={true}
          to={true}
          interval={true}
          onFilter={() => this.fetchData()}
        />
        <Grid>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <div>
                <h3>Line chart</h3>
                {report.loading && <Loader active inline="centered" />}
                {!report.loading && (
                  <ResponsiveContainer minWidth={468} minHeight={300}>
                    <LineChart data={report.data}>
                      <XAxis dataKey="formatedLabel" />
                      <YAxis />
                      <CartesianGrid strokeDasharray="3 3" />
                      <Tooltip />
                      <Legend />
                      <Line
                        type="monotone"
                        dataKey="value"
                        stroke={COLORS[0]}
                        strokeWidth={2}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                )}
              </div>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <div>
                <h3>Data table</h3>
                {report.loading && <Loader active inline="centered" />}
                {!report.loading && (
                  <Table celled>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>
                          <FormattedMessage
                            id="PERIOD"
                            defaultMessage="PERIOD"
                          />
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          <FormattedMessage id="COUNT" defaultMessage="COUNT" />
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          <FormattedMessage
                            id={labelId}
                            defaultMessage={labelId}
                          />
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {sorted.map((v, i) => (
                        <Table.Row key={i}>
                          <Table.Cell>{v.formatedLabel}</Table.Cell>
                          <Table.Cell>{'' + v.value}</Table.Cell>
                          <Table.Cell>
                            {sorted[i + 1]
                              ? sorted[i].value - sorted[i + 1].value
                              : '-'}
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                )}
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

export default injectIntl(
  connect(
    (state, props) => ({
      filter: state.filter,
      report: state.report[props.serviceType],
    }),
    { fetchReport },
  )(BasePassOverview),
);
