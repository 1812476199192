import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Icon, Loader, Step } from 'semantic-ui-react';
import { connect } from 'react-redux';
import BackButton from '../../common/buttons/BackButton';
import LabelProject from '../../common/LabelProject';
import FormDropdownProject from '../../common/FormDropdownProject';
import FormTab from '../../common/FormTab';
import FormTextAreaJson from '../../common/FormTextAreaJSON';
import { FormattedHTMLMessage, FormattedMessage, injectIntl } from 'react-intl';
import { addNotification } from '../../../actions/notification';
import { AUTO_DISMISS } from '../../../constants/toast';
import DeleteButton from '../../common/buttons/DeleteButton';
import client, { CORE_API_URL } from '../../../services/api';
import { store } from '../../../store/index';

const { Content, Description, Group, Title } = Step;

const FORM_NAME = 'certificate';

class CertificateForm extends Component {
  static propTypes = {
    data: PropTypes.object,
    itemSet: PropTypes.func.isRequired,
    itemSave: PropTypes.func.isRequired,
    addNotification: PropTypes.func.isRequired,
  };

  componentWillMount() {
    this.setState({ file: null, uploading: false });
  }

  handleChange = (e, p) => {
    const { itemSet, data } = this.props;
    data[p.name] = p.value;
    itemSet(data);
  };

  handleChangeJSON = (event, json) => {
    this.props.itemSet(json.value);
  };

  handleApply = () => {
    const { itemApply, data } = this.props;
    itemApply(data);
  };

  handleSubmit = e => {
    if (this.props.data.id) {
      this.props.itemSave(this.props.data);
    } else {
      this.props.itemCreate(this.props.data);
    }
  };

  handleUploadError = e => {
    const { addNotification, intl } = this.props;
    addNotification({
      title: intl.formatMessage({
        id: `CERTIFICATE_UPLAOAD_FAILURE_TITLE`,
      }),
      message: e.message || e.statusText,
      level: 'error',
      autoDismiss: AUTO_DISMISS,
    });
  };

  downloadFile = (e, i) => {
    e.preventDefault();

    client('GET', `${CORE_API_URL}/v1/certificate/${this.props.data.id}/csr`)
      .then(res => res.blob())
      .then(blob => {
        var exportUrl = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = exportUrl;
        link.download = `${this.props.data.name || this.props.data.id} CSR.pem`;

        link.dispatchEvent(
          new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window,
          }),
        );

        setTimeout(() => {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(exportUrl);
          link.remove();
        }, 100);
      });
  };

  uploadFile = (e, i) => {
    e.preventDefault();
    const { data, addNotification, intl } = this.props;
    const { file } = this.state;
    if (file) {
      var xhr = new XMLHttpRequest();
      xhr.onprogress = function(e) {
        // TODO show prohression
      };

      xhr.onload = e => {
        this.setState({ uploading: false });
        if (xhr.status === 200) {
          addNotification({
            title: intl.formatMessage({
              id: 'CERTIFICATE_UPLAOAD_SUCCESS_TITLE',
            }),
            message: intl.formatMessage({
              id: 'CERTIFICATE_UPLAOAD_SUCCESS_MESSAGE',
            }),
            level: 'success',
            autoDismiss: AUTO_DISMISS,
          });
        } else {
          this.handleUploadError({
            message: `${xhr.status} - ${xhr.responseText}`,
          });
        }
      };

      xhr.onerror = e => {
        this.setState({ uploading: false });
        this.handleUploadError(e);
      };

      const url = `${CORE_API_URL}/v1/certificate/${data.id}/upload`;
      xhr.open('post', url, true);
      xhr.withCredentials = true;
      const state = store.getState();
      if (state.token && state.token.token) {
        xhr.setRequestHeader('Authorization', `Bearer ${state.token.token}`);
      }
      var formData = new FormData(e.target);
      this.setState({ uploading: true });
      xhr.send(formData);
    } else {
      this.handleUploadError({
        message: `Please select file`,
      });
    }
  };

  handleDelete = () => {
    const { itemDelete } = this.props;
    itemDelete();
  };

  render() {
    if (this.state.uploading) {
      return <Loader active inline="centered"/>;
    }

    const { data, viewer, intl, changed } = this.props;

    const tabOptions = {
      form: {
        name: intl.formatMessage({ id: 'EDIT' }),
        icon: 'edit',
        content: (
          <div>
            <Form>
              <Form.Input
                name="name"
                label="Name"
                defaultValue={data.name}
                onChange={this.handleChange}
              />
              {!(!data.id || viewer.isAdmin) && (
                <Form.Field>
                  <label>
                    <FormattedHTMLMessage id="PROJECT"/>
                  </label>
                  <LabelProject id={data.projectId}/>
                </Form.Field>
              )}

              {(!data.id || viewer.isAdmin) && (
                <FormDropdownProject
                  name="projectId"
                  label="Project"
                  placeholder="Project"
                  onChange={this.handleChange}
                  defaultValue={data.projectId}
                />
              )}
            </Form>
            <br/>
            {data.id && (
              <div>
                <h3>Upload new certificate</h3>
                <Group>
                  <Step>
                    <Icon name="download"/>
                    <Content>
                      <Title>Create CSR</Title>
                      <Description>
                        <Form>
                          <p>Create and download CSR file</p>
                          <Form.Button onClick={this.downloadFile}>
                            Create CSR
                          </Form.Button>
                        </Form>
                      </Description>
                    </Content>
                  </Step>
                  <Step active>
                    <Icon name="apple"/>
                    <Content>
                      <Title>Apple</Title>
                      <Description>
                        <Form>
                          <p>Generate new public part of certificate.</p>
                          <p>
                            Please visit{' '}
                            <a
                              rel="noopener noreferrer"
                              target="_blank"
                              href="https://developer.apple.com/account/ios/certificate/"
                            >
                              Apple certificates
                            </a>.
                          </p>
                        </Form>
                      </Description>
                    </Content>
                  </Step>

                  <Step>
                    <Icon name="upload"/>
                    <Content>
                      <Title>Upload</Title>
                      <Description>
                        <Form
                          onSubmit={this.uploadFile}
                          action={`${CORE_API_URL}/v1/certificate/${
                            data.id
                          }/upload`}
                          method="POST"
                          encType="multipart/form-data"
                        >
                          <Form.Input
                            placeholder="Upload new public"
                            type="file"
                            name="file"
                            onChange={e => {
                              this.setState({ file: e.target.files[0] });
                            }}
                          />
                          <Form.Button
                            type="submit"
                            disabled={!this.state.file}
                          >
                            Upload
                          </Form.Button>
                        </Form>
                      </Description>
                    </Content>
                  </Step>
                </Group>
              </div>
            )}
          </div>
        ),
      },
    };

    if (viewer.isAdmin) {
      tabOptions.json = {
        icon: 'file text',
        name: 'Json',
        content: (
          <Form>
            <FormTextAreaJson
              onChange={this.handleChangeJSON}
              label="JSON"
              name="json"
              defaultValue={data}
            />
          </Form>
        ),
      };
    }

    return (
      <div>
        <FormTab
          tabOptions={tabOptions}
          formName={FORM_NAME}
          defaultTab="form"
        />

        <br/>
        <br/>
        <br/>
        <Button
          type="button"
          primary
          disabled={!changed}
          onClick={this.handleSubmit}
        >
          <Icon name="save"/>
          <FormattedMessage id="BUTTON_SAVE"/>
        </Button>

        {!!this.props.data.id && (
          <Button
            type="button"
            secondary
            onClick={this.handleApply}
            disabled={!changed}
          >
            <Icon name="save"/>
            <FormattedMessage id="BUTTON_APPLY"/>
          </Button>
        )}

        {!!this.props.data.id && <DeleteButton onDelete={this.handleDelete}/>}

        <BackButton/>
      </div>
    );
  }
}

export default connect((state, props) => ({}), { addNotification })(
  injectIntl(CertificateForm),
);
