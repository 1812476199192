import React from 'react';
import { StringProperty } from '../../../../models/property';
import InputDateTime from '../../../common/InputDateTime';
import { Form } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import Template from '../../../../models/template';
import Errors from '../../../../models/Errors';
import BaseInput from './BaseInput';
import { FormattedHTMLMessage } from 'react-intl';

export default class DatePropertyInput extends BaseInput {
  static propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    data: PropTypes.instanceOf(Template).isRequired,
    errors: PropTypes.instanceOf(Errors).isRequired,
    path: PropTypes.array.isRequired,
    itemSet: PropTypes.func.isRequired,
  };

  handleChange = (date, d) => {
    const { data, path, itemSet } = this.props;
    let value = data.getIn(path);
    const fallback = value ? value.get('fallback') : undefined;

    if (date === '') {
      itemSet(data.deleteIn(path));
      return;
    }
    const toSave = d.value ? d.value : date;

    itemSet(
      data.setIn(
        path,
        new StringProperty({ value: toSave, fallback: fallback }),
      ),
    );
  };

  handleDateFallbackChange = (e, p) => {
    const { data, path, itemSet } = this.props;
    let value = data.getIn(path);
    itemSet(
      data.setIn(
        path,
        new StringProperty({
          value: value ? value.get('value') : undefined,
          fallback: p.value ? p.value : undefined,
        }),
      ),
    );
  };

  render() {
    const { label, errors } = this.props;
    const { data, path } = this.props;
    let value = data.getIn(path);
    const isPlaceholder = value ? value.isPlaceholder('date') : false;

    let valueError = null;

    let basePath = path.concat([]);
    basePath.pop();

    if (
      (errors.hasErrors(path.concat('value')) && !isPlaceholder) ||
      errors.hasMissingPropertyOnPath(basePath, path[path.length - 1]) ||
      errors.hasMissingPropertyOnPath(path, 'value')
    ) {
      valueError = (
        <FormattedHTMLMessage
          id={'DATE_INPUT_VALUE_ERROR'}
          values={{ examplePlaceholder: '#{date}' }}
        />
      );
    }

    const out = [
      <Form.Field error={!!valueError} key={`${this.pathToString()}.value`}>
        <label>{label}</label>
        <InputDateTime
          name={'value'}
          label={label}
          error={!!valueError}
          defaultValue={this.getValue()}
          onChange={this.handleChange}
          disableTimeZone={isPlaceholder}
        />
      </Form.Field>,
    ];

    if (valueError) {
      out.push(
        this.renderErrorLabel(valueError, `${this.pathToString()}.value.error`),
      );
    }

    if (isPlaceholder) {
      let fallbackError = null;

      if (errors.hasErrors(path.concat('fallback'))) {
        fallbackError = (
          <FormattedHTMLMessage id={'DATE_INPUT_FALLBACK_ERROR'} />
        );
      } else if (!this.getFallback()) {
        fallbackError = (
          <FormattedHTMLMessage id={'DATE_INPUT_FALLBACK_MISSING'} />
        );
      }

      out.push(
        <Form.Field
          error={!!fallbackError}
          key={`${this.pathToString()}.fallback`}
        >
          <label>{this.getFallbackLabel()}</label>
          <InputDateTime
            name={'fallback'}
            error={!!fallbackError}
            onChange={this.handleDateFallbackChange}
            defaultValue={value ? value.get('fallback') : undefined}
          />
        </Form.Field>,
      );

      if (fallbackError) {
        out.push(
          this.renderErrorLabel(
            fallbackError,
            `${this.pathToString()}.fallback.error`,
          ),
        );
      }
    }

    return out;
  }
}
