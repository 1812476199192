import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Dropdown } from 'semantic-ui-react';
import { remove } from 'diacritics';

import { imageTypesOptions } from '../../constants/Image.config';

export default class FormImageTypeDropdown extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    multiple: PropTypes.bool,
    disabled: PropTypes.bool,
  };
  handleChange = (e, p) => {
    if (this.props.onChange) {
      this.props.onChange(e, p);
    }
  };

  render() {
    const {
      multiple,
      name,
      value,
      label,
      placeholder,
      onChange,
      disabled,
    } = this.props;

    imageTypesOptions.sort(compare);
    return (
      <Form.Field>
        <label>{label}</label>
        <Dropdown
          disabled={disabled}
          multiple={multiple}
          name={name}
          placeholder={placeholder}
          selection={true}
          options={imageTypesOptions}
          value={value}
          onChange={onChange}
        />
      </Form.Field>
    );
  }
}

function compare(a, b) {
  if (a.text && b.text) {
    if (remove(a.text.toLowerCase()) < remove(b.text.toLowerCase())) return -1;
    if (remove(a.text.toLowerCase()) > remove(b.text.toLowerCase())) return 1;
  }
  return 0;
}
