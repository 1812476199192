import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Template from '../../../../models/template';
import { Label } from 'semantic-ui-react';

export default class BaseInput extends Component {
  static propTypes = {
    data: PropTypes.instanceOf(Template).isRequired,
    path: PropTypes.array.isRequired,
    itemSet: PropTypes.func.isRequired,
    onChange: PropTypes.func,
  };

  getFallbackLabel() {
    const { label } = this.props;
    return <span>{label} fallback</span>;
  }

  setValue(e, value) {
    const { onChange, data, path, itemSet } = this.props;
    if (onChange) {
      onChange(e, value);
    } else {
      if (value && value.get('value')) {
        // TODO - may be check if contains any placeholder and if is not remove fallback
        itemSet(data.setIn(path, value));
      } else {
        itemSet(data.deleteIn(path));
      }
    }
  }

  getValue = () => {
    const { data, path } = this.props;
    let value = data.getIn(path);
    return value ? value.get('value') : undefined;
  };

  getFallback = () => {
    const { data, path } = this.props;
    let value = data.getIn(path);
    return value ? value.get('fallback') : undefined;
  };

  pathToString() {
    const { path } = this.props;
    return path.join();
  }

  renderErrorLabel = (content, key) => {
    return (
      <Label
        key={key}
        basic
        color="red"
        pointing={'above'}
        style={{ marginTop: 0, marginBottom: '1em' }}
      >
        {content}
      </Label>
    );
  };
}
