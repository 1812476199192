import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addNotification, removeNotification } from '../actions/notification';
import { uiSetWindowWidth } from '../actions/ui';
import Breadcrumb from '../components/Breadcrumb';
import NotificationSystem from 'react-notification-system';
import VerticalMenu from '../containers/VerticalMenu';
import Helmet from 'react-helmet';
import translate from './../messages-en';
import { injectIntl } from 'react-intl';
import { fetchViewer } from '../actions/viewer';
import { Sidebar, Loader, Menu, Icon } from 'semantic-ui-react';
export const RESPONSIVE_MENU_BREAKPOINT = 1240;

class Layout extends Component {
  static propTypes = {
    intl: PropTypes.object.isRequired,
    viewer: PropTypes.object.isRequired,
    notification: PropTypes.object.isRequired,
    navigation: PropTypes.object.isRequired,
    addNotification: PropTypes.func.isRequired,
    removeNotification: PropTypes.func.isRequired,
    uiSetWindowWidth: PropTypes.func.isRequired,
  };

  _notificationSystem = React.createRef();

  componentWillMount() {
    this.props.fetchViewer();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
  }

  handleWindowResize = () => {
    const { uiSetWindowWidth } = this.props;
    uiSetWindowWidth(window.innerWidth);
  };

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowResize);
  }

  componentWillReceiveProps() {
    const { notification, removeNotification } = this.props;
    if (notification) {
      Object.keys(notification).map(id => {
        const ns = this._notificationSystem.current;
        ns.addNotification(notification[id]);
        removeNotification(id);
        return id;
      });
    }
  }

  getHelmetTitle() {
    const { intl, navigation } = this.props;
    let path = '';
    let label = '';
    navigation.path.split('/').forEach(item => {
      if (item !== '') {
        path += `_${item}`;
        const translateId = `MENU${path.toUpperCase()}`;
        if (translate[translateId]) {
          if (label !== '') {
            label += ' - ';
          }
          label += intl.formatHTMLMessage({ id: translateId });
        }
      }
    });
    return '' ? null : label;
  }

  state = {
    visible: false,
    zIndex: undefined,
  };

  handlePusher = () => {
    const { visible } = this.state;
    if (visible) {
      this.setState({ visible: false });
      setTimeout(() => {
        this.setState({ zIndex: undefined });
      }, 400);
    } else {
      this.setState({ zIndex: -1 });
    }
  };

  handleToggle = () => {
    this.setState({
      visible: !this.state.visible,
    });
  };

  render() {
    const { visible, zIndex } = this.state;
    const { config, windowWidth, viewer, register } = this.props;

    const mobileMenu = windowWidth < RESPONSIVE_MENU_BREAKPOINT;

    if (
      viewer.loading ||
      !viewer.fetched ||
      !register.get('project') ||
      register.get('project').loading
    ) {
      return <Loader active inline="centered" />;
    }

    return (
      <div>
        <Helmet>
          <title>{this.getHelmetTitle()}</title>
        </Helmet>
        <NotificationSystem ref={this._notificationSystem} />

        {mobileMenu && (
          <Sidebar.Pushable>
            <Sidebar
              as={Menu}
              animation="overlay"
              fixed="left"
              inverted
              vertical
              size={'small'}
              visible={visible}
              style={{ backgroundColor: config.console.envColor, zIndex: 100 }}
            >
              <VerticalMenu />
            </Sidebar>
            <Sidebar.Pusher
              dimmed={visible}
              onClick={this.handlePusher}
              style={{ minHeight: '100vh' }}
            >
              <Menu
                fixed="top"
                inverted
                style={{ backgroundColor: config.console.envColor }}
              >
                <Menu.Item onClick={this.handleToggle}>
                  <Icon name="sidebar" />
                </Menu.Item>
                <Menu.Item>Yourpass Console</Menu.Item>
              </Menu>
            </Sidebar.Pusher>
          </Sidebar.Pushable>
        )}
        {!mobileMenu && (
          <div>
            <Menu
              vertical
              fixed="left"
              inverted
              className="hide-print"
              style={{
                overflowY: 'scroll',
                backgroundColor: config.console.envColor,
              }}
            >
              <VerticalMenu />
            </Menu>
          </div>
        )}

        <div
          key={'content'}
          className="layout"
          style={
            mobileMenu
              ? {
                  padding: 20,
                  minHeight: '100hv',
                  paddingTop: 20,
                  top: 40,
                  bottom: 0,
                  right: 0,
                  position: 'fixed',
                  width: '100%',
                  zIndex: zIndex,
                  overflow: 'auto',
                  height: '100%',
                  paddingBottom: 50,
                }
              : { marginLeft: '15rem', padding: 30, minHeight: '100hv' }
          }
        >
          <Breadcrumb />
          {this.props.children}
        </div>
      </div>
    );
    /*Responsive.onlyTablet.maxWidth*/
  }
}

export default connect(
  state => ({
    config: state.config,
    viewer: state.viewer,
    register: state.register,
    notification: state.notification,
    navigation: state.navigation,
    windowWidth: state.ui.windowWidth,
  }),
  { addNotification, removeNotification, uiSetWindowWidth, fetchViewer },
)(injectIntl(Layout));
