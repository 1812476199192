import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import { Form, Label, Segment } from 'semantic-ui-react';
import LabelProject from '../common/LabelProject';

const messages = defineMessages({
  displayName: {
    id: 'user.profile.display.name',
    defaultMessage: 'Display name',
  },
  email: {
    id: 'user.profile.email',
    defaultMessage: 'Email',
  },
});

class UserProfile extends Component {
  render() {
    const { viewer, intl } = this.props;
    return (
      <div>
        <Segment>
          <h3>
            <FormattedMessage
              id="user.profile.header"
              defaultMessage="Profile"
            />
          </h3>
          <Form>
            <Form.Field>
              <label>{intl.formatMessage(messages.displayName)}</label>
              <input
                disabled={true}
                placeholder={intl.formatMessage(messages.displayName)}
                value={viewer.displayName}
              />
            </Form.Field>
            <Form.Field>
              <label>{intl.formatMessage(messages.email)}</label>
              <input
                disabled={true}
                placeholder={intl.formatMessage(messages.email)}
                value={viewer.email}
              />
            </Form.Field>
          </Form>
        </Segment>

        {!viewer.isAdmin && (
          <Segment>
            <h3>
              <FormattedMessage
                id="user.project.list"
                defaultMessage="Available projects"
              />
            </h3>

            {Object.keys(viewer.projects).map(key => (
              <Label>
                <LabelProject key={key} id={key} disableLink={true} />
              </Label>
            ))}
          </Segment>
        )}
      </div>
    );
  }
}

export default connect((state, props) => ({ viewer: state.viewer.data }), {})(
  injectIntl(UserProfile),
);
