import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ResourceDataGrid from '../../common/ResourceDataGrid';
import { Link } from 'react-router';
import { Button } from 'semantic-ui-react';
import JsonModal from '../../common/JsonModal';
import ResourceListPagedDecorator from '../../common/ResourceListPagedDecorator';
import LabelTicketEshop from '../../common/LabelTicketEshop';
import Filter from '../../common/filter/Filter';
import CheckPaymentState from './CheckPaymentState';

const filterProps = [
  {
    name: 'eshopId',
    type: 'inUuid',
    columnName: 'eshopId',
  },
  {
    name: 'paymentState',
    type: 'inUuid',
    columnName: 'paymentState',
  },
  {
    name: 'orderNumber',
    type: 'like',
    columnName: 'orderNumber',
  },
  {
    name: 'email',
    type: 'like',
    columnName: 'email',
  },
];

let fields = {
  eshopId: {
    title: 'Eshop',
    input: 'text',
    readOnly: true,
    convertToValue: function(id) {
      return <LabelTicketEshop id={id} />;
    },
  },
  orderNumber: {
    title: 'Order number',
    link: id => {
      return `/ticket/ticketOrder/${id}`;
    },
    input: 'text',
    readOnly: true,
  },
  yearSerial: {
    title: 'Serial Year',
    input: 'text',
    readOnly: true,
  },
  customer: {
    title: 'Customer',
    convertToValue: function(id, item) {
      const link = `/ticket/ticketOrder/${item.id}`;
      return <a href={link}>{item.email}</a>;
    },
  },
  price: {
    title: 'Price (without VAT/with VAT)',
    convertToValue: function(id, item) {
      return `${item.totalWithoutVat}/${item.totalWithVat}`;
    },
  },
  createdAt: {
    title: 'Created',
    input: 'date',
    type: 'date',
  },
  paymentState: {
    title: 'PAYMENT_STATE',
    input: 'text',
    convertToValue: function(id, item) {
      return `${item.paymentState}`;
    },
  },
  paymentMethod: {
    title: 'Payment',
    input: 'text',
    convertToValue: function(id, item) {
      return `${item.paymentMethod}`;
    },
  },
  actions: {
    convertToValue: function(id, item, refresh) {
      return <Actions item={item} refresh={refresh}/>;
    },
  },
};

const resource = 'ticketOrder';

class Actions extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    refresh: PropTypes.func.isRequired,
  };

  render() {
    return (
      <Button.Group>
        <JsonModal
          title={`Order ${this.props.item.id}`}
          data={this.props.item}
        />
        <CheckPaymentState order={this.props.item} refresh={this.props.refresh}/>
        <Button
          as={Link}
          to={'/ticket/' + resource + '/' + this.props.item.id}
          icon="edit"
        />
      </Button.Group>
    );
  }
}

export default class OrderList extends Component {
  render() {
    const filter = (
      <Filter
        eshopId={true}
        paymentState={true}
        orderNumber={true}
        email={true}
      />
    );
    return (
      <ResourceListPagedDecorator
        resource={'ticketOrder'}
        component={ResourceDataGrid}
        componentProps={{
          filter: filter,
          filterProps: filterProps,
          resource: resource,
          fields: fields,
          cols: [
            'eshopId',
            'orderNumber',
            'yearSerial',
            'customer',
            'price',
            'createdAt',
            'paymentState',
            'paymentMethod',
            'actions',
          ],
          csv: true,
        }}
      />
    );
  }
}
