import React, { Component } from 'react';
import UserChangePassword from './UserChangePassword';
import UserProfile from './UserProfile';
import FormTab from '../common/FormTab';

import { defineMessages, injectIntl } from 'react-intl';

const messages = defineMessages({
  userTabProfile: {
    id: 'user.tab.profile',
    defaultMessage: 'Profile',
  },
  userTabChangePassword: {
    id: 'user.tab.change.password',
    defaultMessage: 'Change password',
  },
});

class User extends Component {
  render() {
    const { intl } = this.props;
    const tabOptions = {
      profile: {
        name: intl.formatMessage(messages.userTabProfile),
        icon: 'user',
        content: <UserProfile />,
      },
      localization: {
        icon: 'lock',
        name: intl.formatMessage(messages.userTabChangePassword),
        content: <UserChangePassword />,
      },
    };

    return (
      <div>
        <FormTab
          tabOptions={tabOptions}
          formName={'USER_TAB'}
          defaultTab="profile"
        />
      </div>
    );
  }
}

export default injectIntl(User);
