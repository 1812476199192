import React, { Component } from 'react';
import PropTypes from 'prop-types';
import boardingPass from './boardingPass.svg';
import coupon from './coupon.svg';
import eventTicket from './eventTicket.svg';
import generic from './generic.svg';
import storeCard from './storeCard.svg';
import { injectIntl, FormattedMessage } from 'react-intl';

const options = {
  boardingPass: { image: boardingPass, id: 'BOARDING_PASS' },
  coupon: { image: coupon, id: 'COUPON' },
  eventTicket: { image: eventTicket, id: 'EVENT_TICKET' },
  generic: { image: generic, id: 'GENERIC' },
  storeCard: { image: storeCard, id: 'STORE_CARD' },
};

class PassStyleSwitcher extends Component {
  static propTypes = {
    itemSet: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
  };

  changePassStyle = (e, { value }) => {
    const { itemSet, data } = this.props;
    itemSet(data.changePassStyle(value));
  };

  render() {
    const { data } = this.props;
    const passStyle = data.getPassStyle();
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          width: '100%',
        }}
      >
        {Object.keys(options).map(key => {
          return (
            <div
              key={key}
              style={{
                flexDirection: 'column',
                justifyContent: 'center',
                position: 'relative',
                display: 'block',
                borderRadius: 5,
                cursor: 'pointer',
              }}
              onClick={e => {
                this.changePassStyle(e, { value: key });
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  top: '44px',
                  color: 'white',
                  alignSelf: 'center',
                  fontSize: '10px',
                  textAlign: 'center',
                  margin: '10px',
                  lineHeight: '10px',
                  left: 0,
                  right: 0,
                  zIndex: 10,
                }}
              >
                <FormattedMessage
                  id={options[key].id}
                  default={options[key].id}
                />
              </div>
              <img
                src={options[key].image}
                alt="presentation"
                style={Object.assign(
                  { padding: 5, maxWidth: 68, height: 'auto' },
                  passStyle === key
                    ? {}
                    : { filter: 'opacity(0.5) grayscale(1)' },
                )}
              />
            </div>
          );
        })}
      </div>
    );
  }
}
export default injectIntl(PassStyleSwitcher);
